import { SelectedTagI, SelectedTagWithObjectIdI, TagI } from "../../../analytics/tags/types"
import { CreateCustomFieldPayload } from "../../../identity/custom-fields/types/CreateCustomFieldPayload"
import { CreateCustomFieldObjectByNameRecord } from "../../../identity/custom-fields/types/CustomFieldValue"
import { CreatePurchaseOrderDTO, PurchaseOrders } from "../../purchase-orders/types/PurchaseOrders"
import { CreatePurchaseRequestDTO, PurchaseRequestDetails } from "../../purchase-requests/types/PurchaseRequests"

export const addLineIdToTags = (tags: SelectedTagI[], lineId: string): SelectedTagWithObjectIdI[] => {
    return tags.map((tag) => ({ ...tag, objectId: lineId }))
}

export const hasPendingLineTags = (
    pendingTags: Record<string, TagI[]>,
    purchaseRequest: CreatePurchaseRequestDTO | CreatePurchaseOrderDTO
) => purchaseRequest.lines.some((line) => line.temporaryId && pendingTags[line.temporaryId]?.length > 0)

export const hasPendingCustomFieldValues = (
    pendingCustomFieldValues: Record<string, Record<string, CreateCustomFieldPayload>>,
    purchaseRequest: CreatePurchaseRequestDTO | CreatePurchaseOrderDTO
) =>
    purchaseRequest.lines.some(
        (line) => line.temporaryId && Object.values(pendingCustomFieldValues[line.temporaryId] ?? {})?.length > 0
    )

export const hasPendingCustomFieldObjects = (pendingCustomFieldObjects: CreateCustomFieldObjectByNameRecord) => {
    return Object.values(pendingCustomFieldObjects).some((customFields) => Object.keys(customFields).length > 0)
}

export function isPurchaseOrder(transaction: PurchaseRequestDetails | PurchaseOrders): transaction is PurchaseOrders {
    return (transaction as PurchaseOrders).shortId !== undefined
}

export const isPurchaseRequest = (
    transaction: PurchaseRequestDetails | PurchaseOrders
): transaction is PurchaseRequestDetails => {
    return !isPurchaseOrder(transaction)
}

export const getGaugeColorFromRemaining = (remaining: number) => {
    if (!Number.isFinite(remaining)) return "var(--color-grey-lighter)"
    if (remaining > 0) return "var(--color-yellow)"
    if (remaining === 0) return "var(--color-green)"
    return "var(--color-red)"
}
