import { Tag } from "react-feather"

import { AssignTagGroupConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEventTypeToObjectType } from "~/domains/orchestration/flows/core"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    AssignTagGroupNode as AssignTagGroupNodeType,
    EditorNode,
    Event,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const assignTagGroupNode = (eventTriggerEventType: Event | null): Omit<AssignTagGroupNodeType, keyof Node> => ({
    objectId: `{{ trigger_event.id }}`,
    objectType: eventTriggerEventType ? adaptEventTypeToObjectType(eventTriggerEventType) : null,
    nodes: [],
    nextNode: null,
    type: NodeType.ASSIGN_TAG_GROUP_NODE,
})

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID, AllAdvancedFieldsUnion.OBJECT_TYPE]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.ASSIGN_TAG_GROUP_NODE) return false
    return Boolean(node.objectId && node.objectType && node.nodes.length)
}

const assignTagGroupConfig: Configuration<NodeType.ASSIGN_TAG_GROUP_NODE> = {
    type: NodeType.ASSIGN_TAG_GROUP_NODE,
    baseSlug: "assign_tag_group",
    Icon: Tag,
    Configuration: AssignTagGroupConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): AssignTagGroupNodeType => ({
        ...assignTagGroupNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { assignTagGroupConfig, advancedFields as assignTagGroupAdvancedFields }
