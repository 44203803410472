import { useCallback, useEffect, useMemo, useState } from "react"

import { useDocumentsApi } from "~/domains/identity/documents/documentsApi"
import { DocumentDetailsI } from "~/domains/identity/documents/types/Document"
import { isResultSuccess } from "~/types/Result"

export const useGetDocumentById = (documentId: string | undefined) => {
    const [document, setDocument] = useState<DocumentDetailsI | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const documentsApi = useDocumentsApi()

    const fetchDocument = useCallback(
        async (docId: string) => {
            setLoading(true)
            setError(false)

            const result = await documentsApi.getDocument(docId)
            if (isResultSuccess(result)) {
                setDocument(result.result)
            } else {
                setError(true)
            }
            setLoading(false)
        },
        [documentsApi]
    )

    useEffect(() => {
        if (!documentId) return

        fetchDocument(documentId)
    }, [documentId, fetchDocument])

    return useMemo(() => {
        return {
            loading,
            error,
            document,
            refetch: fetchDocument,
        }
    }, [document, loading, error, fetchDocument])
}
