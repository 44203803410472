import { MenuItem, TextField, Tooltip, styled } from "@mui/material"
import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { MessageDescriptor, defineMessage, useIntl } from "react-intl"

import {
    useCreateCustomField,
    useSavePendingCustomFieldObject,
    useUpdateCustomFieldObject,
} from "~/domains/identity/custom-fields/hooks"
import { CustomFieldObjectType } from "~/domains/identity/custom-fields/types/CustomFieldObjectType"
import {
    CreateCustomFieldObjectPayload,
    CustomFieldValueI,
} from "~/domains/identity/custom-fields/types/CustomFieldValue"
import { useSaveBuffer } from "~/hooks"

import "./CustomFieldObjectAsTextfield.scss"

const MAX_LENGTH = 1000

const noneMessage = defineMessage({
    id: "customfields.company.none",
    defaultMessage: "None",
})

const CustomFieldContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
})

const CustomFieldLabel = styled("div")({
    color: "var(--color-grey-light)",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
})

const CustomFieldValue = styled("div")({
    color: "var(--color-black)",
})

interface Props<T extends Record<keyof T, MessageDescriptor>> {
    customField: CustomFieldValueI
    label: string
    enumMessages?: T
    isTemporaryId?: boolean
    isReadOnly?: boolean
    isRequired?: boolean
    className?: string
    errorMessage?: string
    placeholder?: string
}

export const CustomFieldObjectAsTextfield = <T extends Record<keyof T, MessageDescriptor>>({
    customField,
    label,
    enumMessages,
    isTemporaryId = false,
    isReadOnly = false,
    isRequired = false,
    className,
    errorMessage,
    placeholder,
}: Props<T>) => {
    const [inputValue, setInputValue] = useState<string>("")

    const {
        id: customFieldId,
        name: customFieldName,
        objectId,
        value,
        contextId,
        contextType,
        organizationId,
    } = customField

    const { formatMessage } = useIntl()
    const createCustomField = useCreateCustomField()
    const updateCustomFieldObject = useUpdateCustomFieldObject(objectId, customField)
    const savePendingCustomFieldObject = useSavePendingCustomFieldObject(customField)

    useEffect(() => {
        if (value) {
            setInputValue(value)
        }
    }, [value])

    const save = useCallback(() => {
        const createCustomFieldObjectPayload: CreateCustomFieldObjectPayload = {
            name: customFieldName,
            value: inputValue,
            objectId,
            contextId,
            contextType: contextType ? CustomFieldObjectType[contextType] : undefined,
            organizationId,
        }
        if (contextId && !isTemporaryId) {
            if (!value) {
                createCustomField(createCustomFieldObjectPayload, true)
            } else {
                updateCustomFieldObject(customFieldId, inputValue)
            }
        } else {
            savePendingCustomFieldObject(createCustomFieldObjectPayload)
        }
    }, [
        createCustomField,
        updateCustomFieldObject,
        savePendingCustomFieldObject,
        inputValue,
        objectId,
        customFieldName,
        contextId,
        contextType,
        isTemporaryId,
        organizationId,
        value,
        customFieldId,
    ])

    const { setHasChanges } = useSaveBuffer(
        save,
        contextId && !isTemporaryId ? undefined : { savingTime: 0, displaySavedTime: 0 }
    )

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value
            setInputValue(newValue)
            setHasChanges(true)
        },
        [setInputValue, setHasChanges]
    )

    const renderEnumOptionItems = useCallback(() => {
        return enumMessages && Object.entries(enumMessages).length
            ? Object.entries(enumMessages).map(([key]) => (
                  <MenuItem key={key} value={key}>
                      {enumMessages?.[key] ? formatMessage(enumMessages[key]) : key}
                  </MenuItem>
              ))
            : null
    }, [enumMessages, formatMessage])

    const getCustomFieldValue = (): string => {
        return enumMessages?.[inputValue]
            ? formatMessage(enumMessages[inputValue])
            : (inputValue ?? formatMessage(noneMessage))
    }

    const textfieldConfig = {
        id: `customfield-${customFieldId}-textfield`,
        name: `customfield-${customFieldName}-textfield`,
        label,
        value: inputValue,
        onChange,
        required: isRequired,
        fullWidth: true,
        className,
        error: !!errorMessage,
        helperText: errorMessage ? errorMessage : undefined,
        placeholder: placeholder,
    }

    if (isReadOnly && !inputValue) {
        return
    }

    if (isReadOnly) {
        const customFieldValue = getCustomFieldValue() || noneMessage.defaultMessage

        return (
            <CustomFieldContainer>
                <CustomFieldLabel>{label}</CustomFieldLabel>
                <Tooltip title={customFieldValue} placement="bottom-start">
                    <CustomFieldValue className="truncated-text">{customFieldValue}</CustomFieldValue>
                </Tooltip>
            </CustomFieldContainer>
        )
    }

    return (
        <>
            {enumMessages ? (
                <TextField {...textfieldConfig} select>
                    {renderEnumOptionItems()}
                </TextField>
            ) : (
                <TextField {...textfieldConfig} inputProps={{ maxLength: MAX_LENGTH }} />
            )}
        </>
    )
}
