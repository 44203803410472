import {
    DataGridPremium,
    DataGridPremiumProps,
    GridInitialState,
    GridPaginationModel,
    GridRowParams,
    GridSortModel,
    GridState,
    MuiEvent,
    useGridApiRef,
} from "@mui/x-data-grid-premium"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Card } from "~/components"
import { globalActions, selectDataGridCommonState, selectDataGridStateByStateKey } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const AUTO_SIZE_OPTIONS = {
    includeHeaders: true,
    includeOutliers: false,
    outliersFactor: 1,
    expand: true,
}

interface DataGridPremiumWithStateProps extends DataGridPremiumProps {
    stateKey: string
    initialState?: GridInitialState
    paginationModel?: GridPaginationModel
    onPaginationModelChange?: (paginationModel: GridPaginationModel) => void
    onSortModelChange?: (sortModel: GridSortModel) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleGetSecondaryClickTarget?: (params: GridRowParams<any>) => string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRowClick?: (params: GridRowParams<any>, event: MuiEvent<React.MouseEvent<HTMLElement>>) => void
}

export const DataGridPremiumWithState = ({
    stateKey,
    initialState = {},
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    handleGetSecondaryClickTarget,
    onRowClick,
    ...props
}: DataGridPremiumWithStateProps) => {
    const navigate = useNavigate()
    const gridRef = useRef<HTMLDivElement>(null)
    const [isApiReady, setIsApiReady] = useState(false)
    const [gridSortModel, setGridSortModel] = useState<GridSortModel>(initialState.sorting?.sortModel || [])

    const dataGridApiRef = useGridApiRef()

    const dispatch = useAppDispatch()
    const gridInitialState = useAppSelector((state) => selectDataGridStateByStateKey(state, stateKey)) || initialState
    const gridCommonState = useAppSelector(selectDataGridCommonState)

    // Save state to Redux store
    useLayoutEffect(() => {
        const saveDataGridState = () => {
            if (!dataGridApiRef?.current?.exportState) return

            const currentState = dataGridApiRef.current.exportState()
            dispatch(globalActions.setDataGridState({ stateKey, state: currentState }))
        }

        window.addEventListener("beforeunload", saveDataGridState)

        return () => {
            window.removeEventListener("beforeunload", saveDataGridState)
            saveDataGridState()
        }
    }, [dataGridApiRef, stateKey, dispatch])

    // Auto size columns
    const autosizeColumns = useCallback(() => {
        if (!dataGridApiRef?.current?.autosizeColumns) return

        dataGridApiRef.current.autosizeColumns(AUTO_SIZE_OPTIONS)
    }, [dataGridApiRef])

    useLayoutEffect(() => {
        const rafId = window.requestAnimationFrame(autosizeColumns)

        return () => {
            window.cancelAnimationFrame(rafId)
        }
    }, [autosizeColumns])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSecondaryClickRow = (param: GridRowParams<any>, event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        if (!handleGetSecondaryClickTarget) {
            onRowClick?.(param, event)
            return
        }

        // ctrl + click open the invoice in a new tab
        const url = handleGetSecondaryClickTarget?.(param)

        if (event.ctrlKey || event.metaKey) {
            window.open(url, "_blank")
            return
        }

        navigate(url)
    }

    const handleSortModelChange = (newSortModel: GridSortModel) => {
        setGridSortModel(newSortModel)
        onSortModelChange?.(newSortModel)
        autosizeColumns()
    }

    const handleStateChange = (newState: GridState) => {
        const newDensity = newState.density.value
        dispatch(globalActions.setDataGridCommonState({ density: newDensity }))
    }

    // Add this effect to track API readiness
    useEffect(() => {
        if (!dataGridApiRef.current) return

        if (gridInitialState.sorting?.sortModel?.length) {
            setGridSortModel(gridInitialState.sorting?.sortModel)
        }
        setIsApiReady(true)
    }, [dataGridApiRef, gridInitialState.sorting?.sortModel])

    // Set initial pagination model
    useEffect(() => {
        const initialPaginationModel = gridInitialState?.pagination?.paginationModel
        const initialPageSize = initialPaginationModel?.pageSize || 0
        const initialPage = initialPaginationModel?.page || 0

        if (
            onPaginationModelChange &&
            initialPaginationModel &&
            (initialPage !== paginationModel?.page || initialPageSize !== paginationModel?.pageSize)
        ) {
            onPaginationModelChange(initialPaginationModel as GridPaginationModel)
        }
        // This should trigger only once at initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div ref={gridRef}>
            <Card sx={props.sx}>
                {isApiReady && (
                    <DataGridPremium
                        {...props}
                        key={gridRef.current?.id}
                        apiRef={dataGridApiRef}
                        initialState={gridInitialState}
                        sortModel={gridSortModel}
                        density={gridCommonState.density}
                        disableRowGrouping
                        disableAggregation
                        onSortModelChange={handleSortModelChange}
                        onStateChange={handleStateChange}
                        onPaginationModelChange={onPaginationModelChange}
                        onRowClick={handleSecondaryClickRow}
                    />
                )}
            </Card>
        </div>
    )
}
