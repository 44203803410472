import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, SetInvoiceLifecycleStatusNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const SetInvoiceLifecycleStatusConfiguration: FC<ConfigurationProps<SetInvoiceLifecycleStatusNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const statusToSet = adaptTextToExpression(e.target.value)
        setCurrentNode({ ...currentNode, statusToSet: statusToSet })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <TextField
                    label={formatMessage(messages.setInvoiceLifecycleStatusConfiguration.statusLabel)}
                    value={adaptExpressionToText(currentNode.statusToSet)}
                    onChange={handleChangeStatus}
                />
            </Stack>

            <AdvancedFields<SetInvoiceLifecycleStatusNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
