import * as Sentry from "@sentry/browser"
import dayjs from "dayjs"
import { useCallback, useEffect } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useManagePaymentMethodDetails } from "~/domains/payment/payment-method-details/hooks"
import { useGetPaymentTermsByObjectQuery } from "~/domains/payment/payment-terms/api/paymentTermsApi"
import {
    useCreatePaymentMutation,
    useCreatePaymentObjectMutation,
    useGetPaymentByObjectIdQuery,
    useUpdatePaymentMutation,
} from "~/domains/payment/payment/api/paymentApi"
import { DocumentData, Payment, PaymentStatus } from "~/domains/payment/payment/types"
import { ObjectType } from "~/domains/payment/types"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    paymentInitFailed: {
        id: "payment.document.paymentDetails.paymentInitFailed",
        defaultMessage: "The payment initialization has failed",
    },
    paymenUpdatedFailed: {
        id: "payment.document.paymentDetails.paymentUpdatedFailed",
        defaultMessage: "The payment update has failed",
    },
})

interface ManagePaymentOptions {
    skip?: boolean
}

interface ManagePayment {
    documentId: string
    payerId?: string | null
    payeeId?: string | null
    documentData?: DocumentData
}

export const useManagePayment = (
    { documentId, payerId, payeeId, documentData }: ManagePayment,
    options?: ManagePaymentOptions
) => {
    const { formatMessage } = useIntl()

    const {
        data: paymentData,
        isLoading: isPaymentLoading,
        isSuccess: isPaymentSuccess,
    } = useGetPaymentByObjectIdQuery(documentId, { skip: !documentId, refetchOnMountOrArgChange: true })
    const payments = paymentData?.items ?? []
    const payment = payments[0] ?? null

    const [createPayment, { isLoading: isCreatingPayment }] = useCreatePaymentMutation()
    const [updatePayment, { isLoading: isUpdatingPayment }] = useUpdatePaymentMutation()
    const [createPaymentObject, { isLoading: isCreatingPaymentObject }] = useCreatePaymentObjectMutation()
    const { data: payerPaymentTermsData, isLoading: isPayerPaymentTermsLoading } = useGetPaymentTermsByObjectQuery(
        payerId ?? "",
        { skip: !payerId }
    )
    const payerPaymentTerms = payerPaymentTermsData?.items ?? []
    const payerPaymentTerm = payerPaymentTerms[0] ?? null

    const { data: payeePaymentTermsData, isLoading: isPayeePaymentTermsLoading } = useGetPaymentTermsByObjectQuery(
        payeeId ?? "",
        { skip: !payeeId }
    )
    const payeePaymentTerms = payeePaymentTermsData?.items ?? []
    const payeePaymentTerm = payeePaymentTerms[0] ?? null

    const { initializePaymentMethodDetails, isLoading: isInitializingPaymentMethods } = useManagePaymentMethodDetails({
        payerId,
        payeeId,
        documentData,
    })

    const getScheduledDate = (): string | null => {
        const dueDate = documentData?.dueDate ?? null
        const issueDate = documentData?.issueDate ?? null

        if (dueDate) return dueDate

        if (!dueDate && !issueDate) return null

        const dueDays = payeePaymentTerm?.due_days ?? payerPaymentTerm?.due_days

        if (dueDays && dueDays > 0) {
            return dayjs(issueDate)?.add(dueDays, "day").format("YYYY-MM-DD")
        }

        return issueDate
    }

    const isSoonerThanPaymentTerms = () => {
        if (!documentData?.dueDate || !documentData.issueDate) return false
        const dueDays = payeePaymentTerm?.due_days ?? payerPaymentTerm?.due_days ?? 0

        return dayjs(documentData.dueDate).isBefore(dayjs(documentData.issueDate).add(dueDays, "day"))
    }

    const currentUserId = useAppSelector(selectUserId)
    const isLoading =
        isPaymentLoading ||
        isCreatingPayment ||
        isCreatingPaymentObject ||
        isInitializingPaymentMethods ||
        isPayeePaymentTermsLoading ||
        isPayerPaymentTermsLoading

    const initializePayment = useCallback(async () => {
        try {
            const { paymentMethodId, payeePaymentMethodDetailsId, payerPaymentMethodDetailsId } =
                await initializePaymentMethodDetails()
            const scheduledDate = getScheduledDate()

            const dueDate = documentData?.dueDate ?? null
            const payload: Payment = {
                amount_sent_data: documentData?.total ?? null,
                payer_id: payerId ?? "",
                payee_id: payeeId ?? "",
                status: PaymentStatus.DRAFT,
                executed_by_id: currentUserId,
                authorized_by_id: currentUserId,
                payment_metadata: {
                    invoice_number: documentData?.number ?? "",
                },
                ...(dayjs(scheduledDate).isValid() ? { date_scheduled: scheduledDate } : {}),
                ...(paymentMethodId && { payment_method_id: paymentMethodId }),
                ...(payeePaymentMethodDetailsId && { destination_cash_id: payeePaymentMethodDetailsId }),
                ...(payerPaymentMethodDetailsId && { origin_cash_id: payerPaymentMethodDetailsId }),
                ...(dueDate && {
                    value_date_expected: dueDate,
                }),
            }

            const newPayment = await createPayment(payload).unwrap()

            if (newPayment?.id) {
                const paymentObjectPayload = {
                    payment_id: newPayment.id,
                    object_id: documentId,
                    object_type: ObjectType.TRANSACTION,
                    link_created_by_id: currentUserId,
                    link_creation_datetime: new Date().toISOString(),
                }
                await createPaymentObject(paymentObjectPayload).unwrap()
            }
        } catch (error) {
            toast.error(formatMessage(messages.paymentInitFailed))
            Sentry.captureException(error, {
                extra: {
                    documentId,
                    payeeId,
                    payerId,
                },
            })
        }
    }, [
        documentData?.total,
        payerId,
        payeeId,
        currentUserId,
        documentId,
        payeePaymentTerm,
        payerPaymentTerm,
        createPayment,
        createPaymentObject,
        formatMessage,
        initializePaymentMethodDetails,
    ])

    const editPayment = useCallback(
        async (paymentInfo: Partial<Payment>) => {
            try {
                await updatePayment({
                    id: payment?.id,
                    ...paymentInfo,
                })?.unwrap()
            } catch (error) {
                toast.error(formatMessage(messages.paymenUpdatedFailed))
                Sentry.captureException(error, {
                    extra: {
                        documentId,
                        payeeId,
                        payerId,
                    },
                })
            }
        },
        [updatePayment, payment?.id, formatMessage, documentId, payeeId, payerId]
    )

    useEffect(() => {
        if (!options?.skip && !isPaymentLoading && !payment && isPaymentSuccess) {
            initializePayment()
        }
    }, [payment, isPaymentLoading, isPaymentSuccess, options?.skip])

    return {
        isSoonerDueDate: isSoonerThanPaymentTerms(),
        hasPayementTerms: payeePaymentTerm || payerPaymentTerm,
        isLoading,
        isUpdatingPayment,
        payment,
        editPayment,
        getScheduledDate,
    }
}
