import { useCallback, useEffect, useRef } from "react"

import {
    InvoiceCheckWorkflowWithUsersI,
    InvoiceReviewerWithUsersI,
    InvoiceWorkflowCheckWithUsers,
    ReviewerType,
} from "~/domains/orchestration/flows-v0/types/ApiWorkflow"
import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { parseInvoice } from "~/domains/transactions/invoices/types/InvoiceParsers"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useInvoiceActions } from "~/store/workflow/hooks/useInvoiceActions"
import { useInvoiceWorkflowReviewers } from "~/store/workflow/hooks/useInvoiceWorkflowReviewers"
import { OrganizationId, UserI } from "~/types"

import { invoiceActions } from "../invoiceSlice"

type InvoiceStateWithWorkflow = {
    withinWorkflow: true
    checkDone: number
    totalChecks: number
    reviewers: InvoiceReviewerWithUsersI[]
    approvers: UserI[]
    refusers: UserI[]
}
type InvoiceStateWithoutWorkflow = {
    withinWorkflow: false
    checkDone?: never
    totalChecks?: never
    reviewers?: never
    approvers?: never
    refusers?: never
}

type InvoiceUserCanApprove = {
    loading: false
    currentUserCanValidate: true
    currentUserApproval: boolean | undefined
    approve: () => void
    refuse: () => void
    statusLoading: boolean
}

type InvoiceUserCannotApprove = {
    loading: false
    currentUserCanValidate: false
    approve?: never
    refuse?: never
    statusLoading?: never
    currentUserApproval?: never
}

type InvoiceLoadingState = {
    loading: true
    withinWorkflow?: never
    checkDone?: never
    totalChecks?: never
    currentUserCanValidate?: never
    approve?: never
    refuse?: never
    statusLoading?: never
    currentUserApproval?: never
    reviewers?: never
    approvers?: never
    refusers?: never
}

type InvoiceValidationState =
    | InvoiceLoadingState
    | (InvoiceStateWithWorkflow & InvoiceUserCanApprove)
    | (InvoiceStateWithWorkflow & InvoiceUserCannotApprove)
    | (InvoiceStateWithoutWorkflow & InvoiceUserCanApprove)
    | (InvoiceStateWithoutWorkflow & InvoiceUserCannotApprove)

export const useInvoiceValidationActions = (
    organizationId: OrganizationId | null | undefined,
    invoiceId: string | undefined
): InvoiceValidationState => {
    const currentUser = useAppSelector(selectUser)
    const { workflowCheck, loading } = useInvoiceWorkflowReviewers(organizationId, invoiceId)
    const { approveInvoice, refuseInvoice, loading: validationLoading } = useInvoiceActions(organizationId, invoiceId)

    const workflowReviewsRef = useRef<InvoiceWorkflowCheckWithUsers | undefined>(workflowCheck)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (JSON.stringify(workflowReviewsRef.current) !== JSON.stringify(workflowCheck)) {
            workflowReviewsRef.current = workflowCheck
            if (invoiceId && workflowReviewsRef.current) {
                invoiceApi.getById(invoiceId).then((invoiceData) => {
                    const invoice = parseInvoice(invoiceData)
                    dispatch(invoiceActions.setInvoice(invoice))
                })
            }
        }
    }, [workflowCheck, invoiceId, dispatch])

    const approveFromWorkflow = useCallback(async () => {
        await approveInvoice()
    }, [approveInvoice])

    const refuseFromWorkflow = useCallback(async () => {
        await refuseInvoice()
    }, [refuseInvoice])

    if (loading) {
        return { loading: true }
    }

    if (workflowCheck) {
        // we filter the workflows where the current user is a reviewer
        const workflowChecksForUser =
            workflowCheck.checkStatus.filter((check) =>
                check.reviewers.some(
                    (reviewer) => reviewer.type === ReviewerType.USER && reviewer.value.id === currentUser.id
                )
            ) ?? []

        const approvers = workflowCheck.approvers.map((approver) => approver.id)

        // We select the workflow check with the least number of missing reviewers
        const currentWorkflowCheck = workflowChecksForUser.reduce(
            (acc: InvoiceCheckWorkflowWithUsersI | null, check: InvoiceCheckWorkflowWithUsersI) => {
                if (!acc) return check
                const checkApprovals = check.reviewers.filter(
                    (reviewer) => reviewer.type === ReviewerType.USER && approvers.includes(reviewer.value.id)
                ).length
                const accApprovals = acc.reviewers.filter(
                    (reviewer) => reviewer.type === ReviewerType.USER && approvers.includes(reviewer.value.id)
                ).length
                if (check.checkThreshold - checkApprovals < acc.checkThreshold - accApprovals) return check
                return acc
            },
            null
        )
        if (currentWorkflowCheck) {
            const hasApproved = workflowCheck.approvers.some((user) => user.id === currentUser.id)
            const hasRefused = workflowCheck.refusers.some((user) => user.id === currentUser.id)
            return {
                loading: false,
                withinWorkflow: true,
                currentUserCanValidate: true,
                checkDone: workflowCheck.approvers.length,
                totalChecks: currentWorkflowCheck.checkThreshold,
                reviewers: currentWorkflowCheck.reviewers,
                statusLoading: validationLoading,
                approve: approveFromWorkflow,
                refuse: refuseFromWorkflow,
                approvers: workflowCheck.approvers,
                refusers: workflowCheck.refusers,
                currentUserApproval: hasApproved ? true : hasRefused ? false : undefined,
            }
        } else {
            // We select the workflow check with the least number of missing reviewers
            const currentWorkflowCheck = workflowCheck.checkStatus.reduce(
                (acc: InvoiceCheckWorkflowWithUsersI | null, check: InvoiceCheckWorkflowWithUsersI) => {
                    if (!acc) return check
                    if (check.checkThreshold < acc.checkThreshold) return check
                    return acc
                },
                null
            )
            if (currentWorkflowCheck) {
                return {
                    loading: false,
                    withinWorkflow: true,
                    reviewers: currentWorkflowCheck.reviewers,
                    currentUserCanValidate: false,
                    checkDone: workflowCheck.approvers.length,
                    totalChecks: currentWorkflowCheck.checkThreshold,
                    approvers: workflowCheck.approvers,
                    refusers: workflowCheck.refusers,
                }
            }
        }
    }
    return {
        loading: false,
        withinWorkflow: false,
        currentUserCanValidate: false,
        currentUserApproval: undefined,
    }
}
