import { Zap } from "react-feather"

import { EventTriggerConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { EventTriggerNode } from "~/domains/orchestration/flows/components/nodes"
import { isEvent } from "~/domains/orchestration/flows/types"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    EventTriggerNode as EventTriggerNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const eventTriggerNode: Omit<EventTriggerNodeType, keyof Node> = {
    event: null,
    nextNode: null,
    filter: {
        conditions: [],
    },
    type: NodeType.EVENT_TRIGGER_NODE,
}

const advancedFields: (keyof typeof eventTriggerNode)[] = []

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.EVENT_TRIGGER_NODE) return false

    return isEvent(node.event ?? "")
}

const eventTriggerConfig: Configuration<NodeType.EVENT_TRIGGER_NODE> = {
    type: NodeType.EVENT_TRIGGER_NODE,
    baseSlug: "event_trigger",
    Icon: Zap,
    Configuration: EventTriggerConfiguration,
    advancedFields,
    Node: EventTriggerNode,
    factory: (props: FactoryProps): EventTriggerNodeType => ({
        ...eventTriggerNode,
        ...createCommonNodeProperties(props),
        error: true,
    }),
    validateNode,
}

export { eventTriggerConfig, advancedFields as eventTriggerAdvancedFields }
