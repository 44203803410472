import { ArrowRight } from "react-feather"

import { UpdateTripletexLedgerConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    UpdateTripletexLedgerNode as UpdateTripletexLedgerNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const updateTripletexLedgerNode: Omit<UpdateTripletexLedgerNodeType, keyof Node> = {
    ledgerId: `''`,
    ledgerDate: `''`,
    ledgerDescription: ``,
    accountToCredit: ``,
    accountToDebit: ``,
    amount: `''`,
    amountGross: `''`,
    currency: `'EUR'`,
    nextNode: null,
    type: NodeType.UPDATE_TRIPLETEX_LEDGER_NODE,
}

const advancedFields: (keyof typeof updateTripletexLedgerNode)[] = [
    AllAdvancedFieldsUnion.CURRENCY,
    AllAdvancedFieldsUnion.AMOUNT_GROSS,
    AllAdvancedFieldsUnion.AMOUNT,
    AllAdvancedFieldsUnion.LEDGER_ID,
    AllAdvancedFieldsUnion.LEDGER_DATE,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.UPDATE_TRIPLETEX_LEDGER_NODE) return false
    return Boolean(node.ledgerId)
}

const updateTripletexLedgerConfig: Configuration<NodeType.UPDATE_TRIPLETEX_LEDGER_NODE> = {
    type: NodeType.UPDATE_TRIPLETEX_LEDGER_NODE,
    baseSlug: "update-tripletex-ledger",
    Icon: ArrowRight,
    Configuration: UpdateTripletexLedgerConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): UpdateTripletexLedgerNodeType => ({
        ...updateTripletexLedgerNode,
        ...createCommonNodeProperties(props),
        type: NodeType.UPDATE_TRIPLETEX_LEDGER_NODE,
    }),
    validateNode,
}

export { updateTripletexLedgerConfig, advancedFields as updateTripletexLedgerAdvancedFields }
