import { Typography } from "@mui/material"
import { FC, UIEvent, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, Size } from "~/components"
import { AknowledgeCheckItem } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgeCheckItem"

const messages = defineMessages({
    title: {
        id: "identity.account.acknowledgePurchasePolicyWall.stepThreeTitle",
        defaultMessage: "Acknowledgement of the purchase policy summary",
    },
})

interface Props {
    onConfirm: (confirmDate: Date) => void
}

export const AknowledgePurchasePolicySummaryModal: FC<Props> = ({ onConfirm }) => {
    const { formatMessage } = useIntl()
    const [scrollToBottom, setScrollToBottom] = useState(false)
    const checkHasScrollToBottom = (element: HTMLDivElement | null) => {
        // If the user has scrolled to the bottom
        if (!element) return
        if (element.clientHeight + element.scrollTop >= element.scrollHeight - 20) {
            setScrollToBottom(true)
        }
    }
    const onScroll = (event: UIEvent<HTMLDivElement>) => {
        checkHasScrollToBottom(event.currentTarget)
    }
    return (
        <Modal open size={Size.XL}>
            <Modal.Header>
                <Typography variant="h4" gutterBottom>
                    {formatMessage(messages.title)}
                </Typography>
            </Modal.Header>
            <Modal.Content onScroll={onScroll} ref={checkHasScrollToBottom}>
                <AknowledgeCheckItem
                    primary="Respect des Processus S2C et P2P"
                    secondary={
                        <>
                            <Typography component="div">
                                Suivre rigoureusement les deux sous-processus :<br />
                                - Source-to-Contract (S2C) pour la sélection des fournisseurs, la négociation et la
                                contractualisation.
                                <br />
                                - Procure-to-Pay (P2P) pour la gestion des commandes, des factures et des paiements.
                                <br />
                                Utiliser les outils et modèles standardisés (bons de commande, contrats types, grilles
                                d’évaluation).
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Validation Budgétaire Obligatoire"
                    secondary={
                        <>
                            <Typography component="div">
                                Aucune dépense ne doit être engagée sans validation budgétaire préalable.
                            </Typography>
                            <Typography component="div">
                                Respecter les circuits d&apos;approbation définis selon les seuils financiers.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Mise en Concurrence Systématique"
                    secondary={
                        <>
                            <Typography component="div">
                                Consulter plusieurs fournisseurs, en respectant les seuils financiers.
                            </Typography>
                            <Typography component="div">
                                Justifier toute exception à la mise en concurrence avec un dossier formel validé.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Contrôle des Fournisseurs (KYS)"
                    secondary={
                        <>
                            <Typography component="div">
                                Réaliser des contrôles de Know Your Supplier (KYS) allégés ou renforcés selon la
                                criticité du fournisseur.
                            </Typography>
                            <Typography component="div">
                                Vérifier la conformité légale, la situation financière et les risques éthiques des
                                fournisseurs avant la contractualisation.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Traçabilité et Archivage des Données"
                    secondary={
                        <>
                            <Typography component="div">
                                Documenter chaque étape du processus d’achat (de la demande à la facturation).
                            </Typography>
                            <Typography component="div">
                                Conserver les preuves des décisions, validations et communications pour les audits
                                internes et externes.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Gestion des Risques Fournisseurs"
                    secondary={
                        <>
                            <Typography component="div">
                                Identifier les fournisseurs critiques et mettre en place un suivi de performance avec
                                des KPI adaptés.
                            </Typography>
                            <Typography component="div">
                                Prévoir des plans de continuité d’activité pour les fournisseurs stratégiques.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Conformité RSE et Prévention du Travail Dissimulé"
                    secondary={
                        <>
                            <Typography component="div">
                                S’assurer que les fournisseurs respectent les normes sociales, environnementales et
                                éthiques.
                            </Typography>
                            <Typography component="div">
                                Contrôler les documents relatifs à la régularité sociale et fiscale des prestataires.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Sécurité des Informations et Confidentialité"
                    secondary={
                        <>
                            <Typography component="div">
                                Protéger les données sensibles en utilisant des clauses de confidentialité dans les
                                contrats.
                            </Typography>
                            <Typography component="div">
                                Restreindre l’accès aux informations d’achat aux personnes autorisées uniquement.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Signalement des Incidents et Gestion des Conflits"
                    secondary={
                        <>
                            <Typography component="div">
                                Déclarer tout incident opérationnel ou éthique (fraude, non-conformité, conflit
                                d’intérêts) via les canaux internes prévus.
                            </Typography>
                            <Typography component="div">
                                Utiliser le réseau des référents anticorruption en cas de suspicion de pratiques
                                douteuses.
                            </Typography>
                        </>
                    }
                />
                <AknowledgeCheckItem
                    primary="Formation Continue et Sensibilisation"
                    secondary={
                        <>
                            <Typography component="div">
                                Participer aux formations sur les procédures d’achat, la conformité réglementaire et la
                                lutte contre la corruption.
                            </Typography>
                            <Typography component="div">
                                Se tenir informé des mises à jour de la politique d’achat et des nouvelles procédures.
                            </Typography>
                        </>
                    }
                />
            </Modal.Content>
            <Modal.Footer>
                <Button disabled={!scrollToBottom} onClick={() => onConfirm(new Date())}>
                    {formatMessage(commonMessages.validate)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
