import { Square } from "react-feather"

import { ApprovePurchaseRequestLineConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    ApprovePurchaseRequestLineNode as ApprovePurchaseRequestLineNodeType,
    EditorNode,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const approvePurchaseRequestLineNode: Omit<ApprovePurchaseRequestLineNodeType, keyof Node> = {
    purchaseRequestLineId: `{{ trigger_event.id }}`,
    purchaseRequestId: `{{ trigger_event.purchaseRequestId }}`,
    nextNode: null,
    type: NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
}

const advancedFields: (keyof typeof approvePurchaseRequestLineNode)[] = [
    AllAdvancedFieldsUnion.PURCHASE_REQUEST_ID,
    AllAdvancedFieldsUnion.PURCHASE_REQUEST_LINE_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE) return false
    return Boolean(node.purchaseRequestId && node.purchaseRequestLineId)
}

const approvePurchaseRequestLineConfig: Configuration<NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE> = {
    type: NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
    baseSlug: "approve-purchase-request-line",
    Icon: Square,
    Configuration: ApprovePurchaseRequestLineConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): ApprovePurchaseRequestLineNodeType => ({
        ...approvePurchaseRequestLineNode,
        ...createCommonNodeProperties({ ...props, error: false }),
    }),
    validateNode,
}

export { approvePurchaseRequestLineConfig, advancedFields as approvePurchaseRequestLineAdvancedFields }
