import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid,
    List,
    ListItem,
    Stack,
    Table,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { ChevronDown } from "react-feather"
import { MessageDescriptor, defineMessages } from "react-intl"
import { generatePath, useParams } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Chip, HeaderH1, ProgressBar, SafeFormattedMessage, TitlePlacement } from "~/components"
import {
    useGetCustomFormQuery,
    useGetCustomFormStatsBySurveyQuery,
    useGetSurveyAnswersQuery,
    useGetSurveyMetadataQuery,
} from "~/domains/identity/custom-forms/api/customFormsApi"
import { CustomFormsAnswerFile } from "~/domains/identity/custom-forms/components/CustomFormsAnswerFile"
import { CUSTOM_FORMS_FORM } from "~/domains/identity/custom-forms/routes"
import {
    CustomFormSection,
    CustomFormSurveyStatusEnum,
    QuestionValue,
} from "~/domains/identity/custom-forms/types/CustomForms"
import { useAppSelector } from "~/store/hooks"
import { fetchAndDecodeOrganization } from "~/store/organization/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { OrganizationI } from "~/types"

const statusMessages: Record<CustomFormSurveyStatusEnum, MessageDescriptor> = defineMessages({
    [CustomFormSurveyStatusEnum.InProgress]: {
        id: "common.status.inProgress",
        defaultMessage: "In progress",
    },
    [CustomFormSurveyStatusEnum.Completed]: {
        id: "common.status.completed",
        defaultMessage: "Completed",
    },
    [CustomFormSurveyStatusEnum.Created]: {
        id: "common.status.created",
        defaultMessage: "Created",
    },
})

const messages: Record<string, MessageDescriptor> = defineMessages({
    surveyResults: {
        id: "smartForms.surveyResults",
        defaultMessage: "Survey results",
    },
    organization: {
        id: "smartForms.organization",
        defaultMessage: "Organization",
    },
    respondents: {
        id: "smartForms.respondents",
        defaultMessage: "Respondents",
    },
    metrics: {
        id: "smartForms.tabHeader.metrics",
        defaultMessage: "Metrics",
    },
    totalScore: {
        id: "smartForms.totalScore",
        defaultMessage: "Total score",
    },
    answers: {
        id: "smartForms.answers",
        defaultMessage: "Answers",
    },
    alertingrScore: {
        id: "smartForms.surveyScore",
        defaultMessage: "Alerting score",
    },
})

const getAnswerContent = (answerContent: QuestionValue) => {
    if (typeof answerContent === "string") {
        return <strong>{answerContent}</strong>
    }

    if (Array.isArray(answerContent)) {
        return <strong>{answerContent.join(", ")}</strong>
    }

    if (answerContent instanceof Date) {
        return <strong>{dayjs(answerContent).format("DD/MM/YYYY")}</strong>
    }

    if (typeof answerContent === "object" && answerContent !== null && "documentId" in answerContent) {
        return <CustomFormsAnswerFile answerContent={answerContent} />
    }

    if (typeof answerContent === "boolean") {
        return answerContent ? (
            <strong>
                <SafeFormattedMessage {...commonMessages.radioYes} />
            </strong>
        ) : (
            <strong>
                <SafeFormattedMessage {...commonMessages.radioNo} />
            </strong>
        )
    }

    return answerContent?.toString()
}

export const CustomFormSurveyResults = () => {
    const [partnerName, setPartnerName] = useState<string>()
    const { formId, surveyId } = useParams()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: form } = useGetCustomFormQuery(
        {
            organisationId: currentOrganizationId!,
            formId: formId!,
        },
        {
            skip: !currentOrganizationId || !formId,
        }
    )

    const { data: surveyAnswers } = useGetSurveyAnswersQuery(
        {
            organizationId: currentOrganizationId!,
            formId: formId!,
            surveyId: surveyId!,
        },
        {
            skip: !currentOrganizationId || !formId || !surveyId,
        }
    )

    const { data: surveyStats } = useGetCustomFormStatsBySurveyQuery(
        {
            organizationId: currentOrganizationId!,
            formId: formId!,
            surveyId: surveyId!,
        },
        {
            skip: !currentOrganizationId || !formId || !surveyId,
        }
    )

    const { data: surveyMetadata } = useGetSurveyMetadataQuery(
        {
            organizationId: currentOrganizationId!,
            formId: formId!,
            surveyId: surveyId!,
        },
        {
            skip: !currentOrganizationId || !formId || !surveyId,
        }
    )

    const sections = useMemo(() => {
        if (!form || !surveyAnswers) {
            return []
        }

        return form.sections.map((section) => ({
            ...section,
            questions: section.questions.map((question) => {
                const answer = surveyAnswers.find((a) => a.questionId === question.id)
                return {
                    ...question,
                    savedValue: answer?.value,
                }
            }),
        })) as CustomFormSection[]
    }, [form, surveyAnswers])

    useEffect(() => {
        if (!surveyMetadata?.respondentOrganizationId) {
            return
        }

        const fetchPartnerName = async () => {
            const partnerOrganization: OrganizationI = await fetchAndDecodeOrganization(
                surveyMetadata.respondentOrganizationId
            )
            setPartnerName(partnerOrganization.name)
        }

        fetchPartnerName()
    }, [surveyMetadata])

    return (
        <div>
            <HeaderH1
                backLink={formId && generatePath(CUSTOM_FORMS_FORM, { formId })}
                title={<SafeFormattedMessage {...messages.surveyResults} />}
            />
            <Box data-testid="box" className="main-box">
                <Grid container spacing={3} justifyContent="stretch" flexDirection={{ xs: "column", sm: "row" }}>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ p: 2 }}>
                            <Typography variant="h6">{partnerName}</Typography>
                            <Stack gap={2} sx={{ maxWidth: "400px" }}>
                                {surveyMetadata?.status?.status === CustomFormSurveyStatusEnum.InProgress ? (
                                    <ProgressBar
                                        title={`${surveyMetadata?.status?.percentage}%`}
                                        titlePlacement={TitlePlacement.TOP}
                                        values={[
                                            {
                                                percentage: surveyMetadata?.status?.percentage / 100,
                                                color: "var(--color-green)",
                                            },
                                        ]}
                                        className="w-full"
                                    />
                                ) : (
                                    <Stack direction="row" gap={2} marginTop={1}>
                                        <Chip
                                            variant={
                                                surveyMetadata?.status?.status === CustomFormSurveyStatusEnum.Completed
                                                    ? "success"
                                                    : "neutral"
                                            }
                                        >
                                            <SafeFormattedMessage
                                                {...statusMessages[
                                                    surveyMetadata?.status?.status as CustomFormSurveyStatusEnum
                                                ]}
                                            />
                                        </Chip>
                                    </Stack>
                                )}
                                <Stack direction="column" gap={2}>
                                    <Typography variant="body1">
                                        <SafeFormattedMessage {...commonMessages.createdDate} />:{" "}
                                        <strong>{dayjs(surveyMetadata?.createdAt).format("DD/MM/YYYY")}</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        <SafeFormattedMessage {...commonMessages.updatedDate} />:{" "}
                                        <strong>{dayjs(surveyMetadata?.updatedAt).format("DD/MM/YYYY")}</strong>
                                    </Typography>
                                    <SafeFormattedMessage {...messages.respondents} />:
                                    {surveyMetadata?.respondentContacts?.map((respondent) => (
                                        <Typography key={respondent.userId} variant="body1">
                                            <a key={respondent.userId} href={`mailto:${respondent.email}`}>
                                                <u>{respondent.email}</u>
                                            </a>
                                        </Typography>
                                    ))}
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 2 }}>
                            <Typography variant="h6" marginBottom={1}>
                                <SafeFormattedMessage {...messages.metrics} />
                            </Typography>
                            <Typography variant="body2" marginBottom={1} marginTop={1}>
                                <SafeFormattedMessage {...messages.alertingrScore} />:{" "}
                                <strong>{surveyStats?.alertScore}</strong>
                            </Typography>
                            <Typography variant="body2" marginBottom={1} marginTop={1}>
                                <SafeFormattedMessage {...messages.totalScore} />:{" "}
                                <strong>{surveyStats?.metrics?.total?.value}</strong>
                            </Typography>
                            <Typography variant="body2">
                                <SafeFormattedMessage {...messages.answers} />:
                            </Typography>
                            <List>
                                {surveyStats?.metrics?.sections?.map((section) => (
                                    <ListItem key={section.sectionId}>
                                        {section.name}: <strong>{section.metric.value}</strong>
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ padding: 2, backgroundColor: "var(--color-light-gray)" }}>
                            <Typography variant="h6" marginBottom={1}>
                                <SafeFormattedMessage {...messages.answers} />
                            </Typography>
                            {sections?.map((section) => (
                                <Accordion key={section.id}>
                                    <AccordionSummary
                                        expandIcon={<ChevronDown />}
                                        aria-controls={`panel-${section.title}-content`}
                                        id={`panel-${section.title}-header`}
                                    >
                                        <Typography>{section.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Table>
                                            {section.questions.map((question) => {
                                                return (
                                                    <TableRow key={question.id}>
                                                        <TableCell width="50%">{question.questionContent}</TableCell>
                                                        <TableCell width="50%">
                                                            {getAnswerContent(question.savedValue)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
