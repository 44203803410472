import { Stack, Typography } from "@mui/material"
import React, { useMemo } from "react"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { InvitationItemI } from "~/domains/identity/account/types/UserTypes"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { accountActions } from "~/store/account/accountSlice"
import { useAppDispatch } from "~/store/hooks"
import { useFetchOrganizations } from "~/store/organization/hooks"
import { organizationActions } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    invitations: {
        id: "account.organization.invitation.invitationsToast",
        defaultMessage: "You have {count, plural, one {an invitation} other {invitations}} to join an organization:",
    },
})

interface NewInvitationsToastContentProps {
    invitations: InvitationItemI[]
}

export const NewInvitationsToastContent: React.FC<NewInvitationsToastContentProps> = ({ invitations }) => {
    const dispatch = useAppDispatch()

    // useMemo prevents unnecessary re-fetching by ensuring a stable reference for the organizationsIds array,
    // avoiding issues with shallow comparisons in useFetchOrganizations.
    const organizationsIds = useMemo(() => invitations.map((invitation) => invitation.id), [invitations])

    const { organizations } = useFetchOrganizations(organizationsIds, true)

    const reload = () => {
        setTimeout(() => {
            window.location.reload()
        }, 500)
    }

    const acceptInvitation = async (invitationId: string) => {
        await organizationApi.acceptInvitation(invitationId)
        dispatch(accountActions.acceptInvitation(invitationId))
        dispatch(organizationActions.setCurrentOrganization(invitationId))

        reload()
    }

    const refuseInvitation = async (invitationId: string) => {
        await organizationApi.refuseInvitation(invitationId)
        dispatch(accountActions.refuseInvitation(invitationId))

        reload()
    }

    return (
        <Stack spacing={2}>
            <Typography>
                <SafeFormattedMessage {...messages.invitations} values={{ count: organizations.length }} />
            </Typography>
            {organizations.map((organization, index) => (
                <Stack key={index} gap={1}>
                    <Typography>{organization.name}</Typography>
                    <Stack direction="row" gap={1}>
                        <Button
                            type="primary-light"
                            onClick={() => acceptInvitation(organization.id)}
                            sx={{ textWrap: "unset" }}
                        >
                            <SafeFormattedMessage {...commonMessages.accept} />
                        </Button>
                        <Button
                            type="error-light"
                            onClick={() => refuseInvitation(organization.id)}
                            sx={{ textWrap: "unset" }}
                        >
                            <SafeFormattedMessage {...commonMessages.refuse} />
                        </Button>
                    </Stack>
                </Stack>
            ))}
        </Stack>
    )
}
