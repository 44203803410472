import { Tooltip } from "@mui/material"
import { ArrowDownCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button } from "~/components"
import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"

const messages = defineMessages({
    button: { id: "buyer.invoice.actions.button.removeApproval", defaultMessage: "Remove approval" },
})

interface RemoveApprovalInvoiceProps {
    handleRemoveApproval: () => void
    display?: "button" | "menu-item"
}

export const RemoveApprovalInvoice = ({ handleRemoveApproval, display = "button" }: RemoveApprovalInvoiceProps) => {
    const { formatMessage } = useIntl()

    return display === "button" ? (
        <Tooltip title={formatMessage(messages.button)} arrow>
            <div>
                <Button onClick={handleRemoveApproval} type="error-light">
                    <ArrowDownCircle size={16} />
                    <SafeFormattedMessage {...messages.button} />
                </Button>
            </div>
        </Tooltip>
    ) : (
        <Button onClick={handleRemoveApproval} type="menu-item">
            <ArrowDownCircle size={16} />
            <SafeFormattedMessage {...messages.button} />
        </Button>
    )
}
