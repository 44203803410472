import { InvoiceBackendVersion, InvoiceStatus } from "~/types"
import { InvoiceI } from "~/types"

type Action =
    | "import"
    | "confirm"
    | "validate"
    | "delete"
    | "request"
    | "removeApproval"
    | "resolveAllRequests"
    | "unmarkAsPaid"
    | "review"
    | "confirmPaymentReceived"

export function canDoAction(invoice: InvoiceI, action: Action) {
    switch (action) {
        case "import":
            return invoice.version !== InvoiceBackendVersion.V1 && invoice.status === InvoiceStatus.DRAFT
        case "confirm":
            return ![InvoiceStatus.DRAFT, InvoiceStatus.CONFIRMED, InvoiceStatus.VALIDATED].includes(invoice.status)
        case "validate":
            return invoice.status === InvoiceStatus.CONFIRMED
        case "delete":
            return (
                invoice.version !== InvoiceBackendVersion.V1 &&
                [InvoiceStatus.DRAFT, InvoiceStatus.CONFIRMED, InvoiceStatus.INFO_REQUESTED].includes(invoice.status)
            )
        case "request":
            return [InvoiceStatus.CONFIRMED, InvoiceStatus.INFO_REQUESTED].includes(invoice.status)
        case "removeApproval":
            return invoice.status === InvoiceStatus.VALIDATED
        case "resolveAllRequests":
            return invoice.status === InvoiceStatus.INFO_REQUESTED
        case "unmarkAsPaid":
            return invoice.status === InvoiceStatus.MARKED_AS_PAID
        case "review":
            return [InvoiceStatus.CONFIRMED, InvoiceStatus.INFO_REQUESTED].includes(invoice.status)
        case "confirmPaymentReceived":
            return invoice.status === InvoiceStatus.MARKED_AS_PAID
    }
}
