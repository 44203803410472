import { Stack, Tooltip } from "@mui/material"
import classNames from "classnames"
import { useState } from "react"
import { AlertCircle, CheckCircle, Clock, Edit, Eye } from "react-feather"
import { defineMessages } from "react-intl"

import { Card, Chip, SafeFormattedMessage } from "~/components"
import { DocumentObjectType } from "~/domains/identity/documents/types"
import { SavePayloadType } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/types"
import { ApprovalStatus } from "~/domains/orchestration/flows-v0/types/Approval"
import { ModalSelectOrganization } from "~/domains/transactions/_shared/components/ModalSelectOrganization/ModalSelectOrganization"
import { Address } from "~/domains/transactions/purchase-orders/types"
import { OrganizationAddressI, OrganizationId } from "~/types"

import { OrganizationDetailsModal } from "./OrganizationDetailsModal"

const messages = defineMessages({
    currentUserSideLabel: {
        id: "purchase.organization.currentUserSideLabel",
        defaultMessage: "Me",
    },
    supplierPassed: {
        id: "purchase.organization.supplier.passed",
        defaultMessage: "This vendor is approved",
    },
    supplierPending: {
        id: "purchase.organization.supplier.pending",
        defaultMessage: "Validation pending",
    },
    supplierRefused: {
        id: "purchase.organization.supplier.refused",
        defaultMessage: "This vendor is refused",
    },
})

const addressToOrganizationAddress = (address: Address): OrganizationAddressI => ({
    addressLine: address.street,
    secondaryAddressLine: address.street2 ?? "",
    zipCode: address.zipCode,
    city: address.city,
    country: address.country,
})

interface OrganizationProps {
    title: string
    name: string | undefined
    id: OrganizationId | null
    objectType: DocumentObjectType
    isCurrentUserSide?: boolean
    billingAddress?: Address | null
    shippingAddress?: Address | null
    onSave?: (payload: SavePayloadType) => void
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    showOrganizationSelector?: boolean
    selectOrganizationLabel?: string
    readOnly?: boolean
    partnerApprovalStatus?: ApprovalStatus
}

export const Organization = ({
    title,
    name,
    id,
    isCurrentUserSide,
    billingAddress,
    shippingAddress,
    onSave,
    withOrganizationAddress = true,
    withSendingAddress = false,
    showOrganizationSelector,
    selectOrganizationLabel,
    readOnly,
    objectType,
    partnerApprovalStatus,
}: OrganizationProps) => {
    const [displayDetailsModal, setDisplayDetailsModal] = useState(false)
    const [displaySelectOrganizationModal, setDisplaySelectOrganizationModal] = useState(false)

    const showDetailsModal = () => setDisplayDetailsModal(true)
    const hideDetailsModal = () => setDisplayDetailsModal(false)

    const showSelectOrganizationModal = () => setDisplaySelectOrganizationModal(true)
    const hideSelectOrganizationModal = () => setDisplaySelectOrganizationModal(false)

    const getApprovalStatusIcon = (status: ApprovalStatus | undefined) => {
        switch (status) {
            case ApprovalStatus.PASSED:
                return {
                    icon: <CheckCircle color="var(--color-green)" size={20} />,
                    message: messages.supplierPassed,
                }
            case ApprovalStatus.REFUSED:
                return {
                    icon: <AlertCircle color="var(--color-red)" size={20} />,
                    message: messages.supplierRefused,
                }
            case ApprovalStatus.PENDING:
                return {
                    icon: <Clock color="var(--color-grey-light)" size={20} />,
                    message: messages.supplierPending,
                }
            default:
                return null
        }
    }

    const approvalStatusIcon = getApprovalStatusIcon(partnerApprovalStatus)

    const approvalStatus =
        partnerApprovalStatus && approvalStatusIcon ? (
            <Tooltip title={<SafeFormattedMessage {...approvalStatusIcon.message} />} placement="bottom">
                {approvalStatusIcon.icon}
            </Tooltip>
        ) : null

    return (
        <Card
            header={
                <Stack direction="row" alignItems="center" gap={1}>
                    <h4>{title}</h4>
                    {isCurrentUserSide && (
                        <Chip variant="primary" size="medium" borderLess>
                            <SafeFormattedMessage {...messages.currentUserSideLabel} />
                        </Chip>
                    )}
                </Stack>
            }
            className={classNames("h-full", { highlighted: isCurrentUserSide })}
        >
            {showOrganizationSelector ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    width="100%"
                    onClick={showSelectOrganizationModal}
                >
                    {name ? (
                        <Tooltip title={name} placement="bottom">
                            <span className="truncate-text">{name}</span>
                        </Tooltip>
                    ) : (
                        selectOrganizationLabel
                    )}
                    <Stack direction="row" alignItems="center" gap={1} className="cursor-pointer">
                        {approvalStatus}
                        {!readOnly ? <Edit size={20} /> : <Eye size={20} />}
                    </Stack>
                </Stack>
            ) : (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    width="100%"
                    onClick={showDetailsModal}
                >
                    <Tooltip title={name} placement="bottom">
                        <span className="truncate-text">{name || "-"}</span>
                    </Tooltip>
                    <Stack direction="row" alignItems="center" gap={1} className="cursor-pointer">
                        {approvalStatus}
                        {!readOnly && onSave ? <Edit size={20} /> : <Eye size={20} />}
                    </Stack>
                </Stack>
            )}

            {displayDetailsModal && id && (
                <OrganizationDetailsModal
                    organizationId={id}
                    open={displayDetailsModal}
                    onClose={hideDetailsModal}
                    onSave={onSave}
                    withOrganizationAddress={withOrganizationAddress}
                    withSendingAddress={withSendingAddress}
                    shippingAddress={shippingAddress ? addressToOrganizationAddress(shippingAddress) : undefined}
                    billingAddress={billingAddress ? addressToOrganizationAddress(billingAddress) : undefined}
                    readOnly={readOnly}
                />
            )}

            {displaySelectOrganizationModal ? (
                <ModalSelectOrganization
                    objectType={objectType}
                    organizationId={id}
                    organizationName={name}
                    open={displaySelectOrganizationModal}
                    close={hideSelectOrganizationModal}
                />
            ) : null}
        </Card>
    )
}
