import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
    Chip,
    TextField,
} from "@mui/material"
import React, { FC, useCallback } from "react"

import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI } from "~/types"

interface SharedUserSelectProps {
    members: OrganizationMemberUserI[]
    selectedUsers: OrganizationMemberUserI[]
    onSelectUsers: (event: React.SyntheticEvent, values: OrganizationMemberUserI[]) => void
    label: string
    loading?: boolean
    showIcon?: boolean
}

const renderTags = (value: OrganizationMemberUserI[], getTagProps: AutocompleteRenderGetTagProps) => (
    <>
        {value.map((option, index) => (
            <Chip
                {...getTagProps({ index })}
                key={option.userId}
                label={option.user?.fullName ?? ""}
                variant="outlined"
            />
        ))}
    </>
)

const getOptionLabel = (option: OrganizationMemberUserI) => option?.user?.fullName ?? ""

const isOptionEqualToValue = (option: OrganizationMemberUserI, value: OrganizationMemberUserI) =>
    option.userId === value.userId

export const SharedUserSelect: FC<SharedUserSelectProps> = ({
    selectedUsers,
    onSelectUsers,
    label,
    loading = false,
}) => {
    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)
    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => <TextField {...params} label={label} />,
        [label]
    )

    return (
        <Autocomplete
            multiple
            options={members}
            value={selectedUsers}
            onChange={onSelectUsers}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={renderInput}
            loading={loading}
            renderTags={renderTags}
        />
    )
}
