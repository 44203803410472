import { Stack } from "@mui/material"
import { defineMessages } from "react-intl"

import { getCountryFlag } from "~/components/CountrySelector/CountrySelector"
import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"
import { getCountryName } from "~/types/CountryCode"
import { CountryCode } from "~/types/CountryCode"

const messages = defineMessages({
    selectCountry: {
        id: "company.selectCountry",
        defaultMessage: "Select country",
    },
})

interface CountrySelectorOptionProps {
    option: CountryCode
    key: string
}

export const CountrySelectorOption = ({ option, ...props }: CountrySelectorOptionProps) => {
    const { key, ...otherProps } = props

    return (
        <Stack direction="row" alignItems="center" gap={1} {...otherProps}>
            {option === CountryCode.UNKNOWN ? (
                <SafeFormattedMessage {...messages.selectCountry} />
            ) : (
                <Stack direction="row" alignItems="center" gap={1}>
                    {getCountryFlag(option)}
                    {getCountryName(option)} ({option})
                </Stack>
            )}
        </Stack>
    )
}
