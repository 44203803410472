import { Stack, styled } from "@mui/material"
import {
    Connection,
    Node,
    NodeMouseHandler,
    ReactFlow,
    SnapGrid,
    XYPosition,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from "@xyflow/react"
import { Background, BackgroundVariant, MiniMap } from "@xyflow/react"
import { ControlButton, Controls, Panel } from "@xyflow/react"
import { FC, useCallback, useEffect } from "react"
import { AlertTriangle, Grid } from "react-feather"

import { ButtonEdge } from "~/domains/orchestration/flows/components/ButtonEdge"
import { ButtonEdgeWithLabel } from "~/domains/orchestration/flows/components/ButtonEdgeWithLabel"
import { EdgeWithLabel } from "~/domains/orchestration/flows/components/EdgeWithLabel"
import { NODE_WIDTH } from "~/domains/orchestration/flows/constants"
import {
    FLOW_NODE_TYPES,
    adaptFlowToEdges,
    adaptFlowToNodes,
    getLayoutedNodes,
} from "~/domains/orchestration/flows/core"
import { Flow, NodeType } from "~/domains/orchestration/flows/types"

const StyledControls = styled(Controls)`
    position: absolute;
    top: 10px;
    box-shadow: none;
    height: fit-content;
    border: none;
`

const StyledError = styled(Panel)`
    color: var(--color-yellow);
    padding: var(--spacing-md);
    background-color: var(--sidebar-color);
    border-radius: var(--border-radius-sm);
`

const ZOOM_OPTIONS = {
    minZoom: 0.1,
    fitViewPadding: 50,
} as const

const SNAP_OPTIONS: SnapGrid = [50, 50] as const

const PRO_OPTIONS = {
    hideAttribution: true,
} as const

const EDGE_TYPES = {
    button: ButtonEdge,
    buttonWithLabel: ButtonEdgeWithLabel,
    withLabel: EdgeWithLabel,
} as const

export interface FlowBuilderProps {
    flow: Flow
    currentNodeType: NodeType | null
    onConnect: (params: Connection) => void
    onNodeClick: NodeMouseHandler<Node>
    onPaneClick: (event: React.MouseEvent) => void
    hasError?: boolean
    error: string | null
    onCreateNode: (nodeType: NodeType, position: XYPosition) => void
    onAutoLayout: (nodes: Node[]) => void
    onUpdatePosition: (node: Node) => void
}

export const FlowBuilder: FC<FlowBuilderProps> = ({
    flow,
    currentNodeType,
    onConnect,
    onNodeClick,
    onPaneClick,
    hasError,
    error,
    onCreateNode,
    onAutoLayout,
    onUpdatePosition,
}) => {
    const { fitView, screenToFlowPosition } = useReactFlow()

    const [nodes, setNodes, onNodesChange] = useNodesState(adaptFlowToNodes(flow))
    const [edges, setEdges, onEdgesChange] = useEdgesState(adaptFlowToEdges(flow))

    const fitViewDelayed = useCallback(() => {
        const timeoutId = setTimeout(fitView, 50)
        return () => clearTimeout(timeoutId)
    }, [fitView])

    const handleAutoLayout = useCallback(async () => {
        const layoutedNodes = await getLayoutedNodes(nodes, edges)
        onAutoLayout(layoutedNodes)
        fitViewDelayed()
    }, [nodes, edges, onAutoLayout, fitViewDelayed])

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault()
        event.dataTransfer.dropEffect = "move"
    }, [])

    const handleDragStop = useCallback(
        (event: React.MouseEvent, node: Node): void => {
            event.preventDefault()
            onUpdatePosition(node)
        },
        [onUpdatePosition]
    )

    const handleDragSelectionStop = useCallback(
        (event: React.MouseEvent, nds: Node[]): void => {
            event.preventDefault()
            nds.forEach(onUpdatePosition)
        },
        [onUpdatePosition]
    )

    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            if (!currentNodeType) return
            event.preventDefault()

            const position = screenToFlowPosition({
                x: Math.floor(event.clientX - NODE_WIDTH),
                y: Math.floor(event.clientY),
            })

            onCreateNode(currentNodeType, position)
            if (flow.nodes.length === 0) {
                fitViewDelayed()
            }
        },
        [currentNodeType, screenToFlowPosition, onCreateNode, fitViewDelayed, flow.nodes.length]
    )

    useEffect(() => {
        fitViewDelayed()
    }, [fitViewDelayed])

    useEffect(() => {
        setNodes(adaptFlowToNodes(flow))
        setEdges(adaptFlowToEdges(flow))
    }, [flow, setNodes, setEdges])

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={FLOW_NODE_TYPES}
            edgeTypes={EDGE_TYPES}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onDragOver={handleDragOver}
            onSelectionDragStop={handleDragSelectionStop}
            onDrop={handleDrop}
            onNodeDragStop={handleDragStop}
            onNodeClick={onNodeClick}
            onPaneClick={onPaneClick}
            snapToGrid
            snapGrid={SNAP_OPTIONS}
            minZoom={ZOOM_OPTIONS.minZoom}
            fitView
            fitViewOptions={{
                padding: ZOOM_OPTIONS.fitViewPadding,
            }}
            proOptions={PRO_OPTIONS}
        >
            <StyledControls>
                <ControlButton onClick={handleAutoLayout}>
                    <Grid />
                </ControlButton>
            </StyledControls>
            {hasError && (
                <StyledError position="top-center">
                    <Stack alignItems="center" gap={1} direction="row">
                        <AlertTriangle size={18} color="var(--color-yellow)" />
                        {error}
                    </Stack>
                </StyledError>
            )}
            <Background color="var(--primary-color)" variant={BackgroundVariant.Dots} />
            <MiniMap nodeStrokeWidth={1} bgColor="transparent" position="bottom-left" />
        </ReactFlow>
    )
}
