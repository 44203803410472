import { Autocomplete, TextFieldVariants, createFilterOptions } from "@mui/material"
import React, { SyntheticEvent, useEffect, useState } from "react"

import { CountrySelectorAutoCompleteTextField } from "~/components/CountrySelector/CountrySelectorAutoCompleteTextField"
import { CountrySelectorOption } from "~/components/CountrySelector/CountrySelectorOption"
import { AllCountries, CountryCode, getCountryName, isValidCountryCode } from "~/types"

const AllCountriesAndUnknown = [CountryCode.UNKNOWN, ...AllCountries]

export const getCountryFlag = (countryCode: CountryCode) =>
    isValidCountryCode(countryCode) ? (
        <img
            loading="lazy"
            width="24"
            src={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.webp`}
            srcSet={`https://flagcdn.com/w80/${countryCode.toLowerCase()}.webp 2x`}
            alt=""
            style={{ borderRadius: "4px" }}
        />
    ) : null

const filterOptions = createFilterOptions({
    stringify: (option: CountryCode) => (option === CountryCode.UNKNOWN ? "" : `${option} ${getCountryName(option)}`),
})

interface CountrySelectorProps {
    value: CountryCode | null
    onChange: (value: CountryCode | null) => void
    variant?: TextFieldVariants
    label?: string
    required?: boolean
    readonly?: boolean
    disabled?: boolean
    error?: boolean
    helperText?: React.ReactNode
}

export const CountrySelector: React.FC<CountrySelectorProps> = ({
    value,
    onChange,
    variant = "outlined",
    label,
    error,
    helperText,
    disabled = false,
    required = false,
    readonly = false,
}) => {
    const [inputText, setInputText] = useState<string>(value || "")
    const [renderedValue, setRenderedValue] = useState<CountryCode | null>(value || null)

    const onInputChange = (_event: SyntheticEvent, localValue: string) => setInputText(localValue)

    useEffect(() => {
        if (!value) return

        setRenderedValue(value)
    }, [value])

    const handleChange = (_event: unknown, code: CountryCode | null) => {
        if (!code) return

        setRenderedValue(code)
        onChange(code)
    }

    return (
        <Autocomplete
            className={`${renderedValue === CountryCode.UNKNOWN ? "fullsize" : ""}`}
            value={renderedValue ?? undefined}
            inputValue={inputText}
            onInputChange={onInputChange}
            options={AllCountriesAndUnknown}
            filterOptions={filterOptions}
            disableClearable
            readOnly={readonly}
            disabled={disabled}
            onChange={handleChange}
            autoHighlight
            renderOption={(props, option) => <CountrySelectorOption option={option} {...props} />}
            renderInput={(params) => (
                <CountrySelectorAutoCompleteTextField
                    params={params}
                    variant={variant}
                    error={error}
                    helperText={helperText}
                    label={label}
                    required={required}
                    disabled={disabled}
                    renderedValue={renderedValue}
                />
            )}
            fullWidth
        />
    )
}
