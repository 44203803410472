/* eslint-disable complexity */
import { Stack } from "@mui/material"
import { useEffect, useMemo, useState } from "react"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, ShareButton } from "~/components"
import { useFetchPartnership } from "~/domains/identity/partners/store/hooks"
import useSupplierAllowed from "~/domains/identity/partners/store/hooks/useSupplierAllowed"
import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import {
    ApprovePO,
    CancelEditPO,
    ClosePO,
    DeletePO,
    DownloadPOPdf,
    EditPO,
    OutForDeliveryPO,
    ReOpenPO,
    ReceiveOrDeliverPO,
    RejectPO,
    SavePO,
    SendPO,
    SubmitPO,
} from "~/domains/transactions/purchase-orders/components/Actions"
import {
    canClosePurchaseOrder,
    canDeletePurchaseOrder,
    canDownloadPurchaseOrder,
    canEditPurchaseOrder,
    canMarkAllInPreparationPurchaseOrder,
    canMarkOutForDeliveryPurchaseOrder,
    canReOpenPurchaseOrder,
    canReceiveOrDeliverPurchaseOrder,
    canSendPurchaseOrder,
    canSubmitPurchaseOrder,
    isStatusApprovable,
} from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import { allLinesRejected } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { AuthorizationName, OrganizationI, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

import { MarkAllInPreparation } from "./MarkAllInPreparation"

interface PropsPOActions {
    PO: PurchaseOrders
    organization: OrganizationI
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    mode: PurchaseViewType
    viewType: ViewTypeI
    allLinesApproved: boolean
    needsNewApproval: boolean
}

export const ActionsHeader: React.FC<PropsPOActions> = ({
    PO,
    organization,
    buyerOrganization,
    supplierOrganization,
    mode,
    viewType,
    allLinesApproved,
    needsNewApproval,
}) => {
    const { partnershipData, loading: partnershipDataLoading } = useFetchPartnership(organization.id, PO.supplierId)

    const [, setSupplierAllowed] = useState(false)

    const areAllLinesRejected = useMemo(() => allLinesRejected(PO.lines, viewType), [PO.lines, viewType])

    const { hasPermission: hasPOEditPermission } = useHasPermissions(
        organization.id ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const supplierAllowed = useSupplierAllowed(partnershipData, partnershipDataLoading)

    useEffect(() => {
        setSupplierAllowed(supplierAllowed)

        return () => {
            setSupplierAllowed(false)
        }
    }, [partnershipData, partnershipDataLoading, supplierAllowed])

    const canSubmit = canSubmitPurchaseOrder(PO.statusPresentation)

    const canSend = canSendPurchaseOrder(PO.statusPresentation, viewType)

    const canEdit = canEditPurchaseOrder(PO.statusPresentation, hasPOEditPermission)

    const canDownload = canDownloadPurchaseOrder(PO.statusPresentation)

    const canDelete = canDeletePurchaseOrder(PO.statusPresentation)

    const canClose = canClosePurchaseOrder(PO.statusPresentation)

    const canReOpen = canReOpenPurchaseOrder(PO.statusPresentation)

    const canReject = isStatusApprovable(PO.statusPresentation, viewType) && !areAllLinesRejected

    const canApprove = isStatusApprovable(PO.statusPresentation, viewType) && !allLinesApproved

    const canMarkAllInPreparation = canMarkAllInPreparationPurchaseOrder(
        PO.statusPresentation,
        PO.fulfillmentPresentation,
        viewType
    )

    const canMarkOutForDelivery = canMarkOutForDeliveryPurchaseOrder(
        PO.statusPresentation,
        PO.fulfillmentPresentation,
        viewType
    )

    const canReceiveOrDeliver = canReceiveOrDeliverPurchaseOrder(
        PO.statusPresentation,
        PO.fulfillmentPresentation,
        viewType
    )

    const actions = [
        ...(canEdit ? [{ render: <EditPO PO={PO} display="menu-item" /> }] : []),
        {
            render: (
                <ShareButton
                    objectId={PO.id}
                    objectType={SharedObjectType.PurchaseOrder}
                    organizationId={organization.id}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                    buttonMessage={commonMessages.shareInternally}
                />
            ),
        },
        ...(canDownload ? [{ render: <DownloadPOPdf purchaseOrder={PO} /> }] : []),
        ...(canDelete ? [{ render: <DeletePO PO={PO} viewType={viewType} display="menu-item" /> }] : []),
        ...(canReOpen ? [{ render: <ReOpenPO PO={PO} display="menu-item" /> }] : []),
    ]

    return (
        <Stack direction="row" spacing={1}>
            {mode === PurchaseViewType.VIEW ? (
                <>
                    <ActionsMenu isActionIcon={false} actions={actions} />

                    {canSubmit && <SubmitPO PO={PO} />}

                    {canReject && <RejectPO PO={PO} needsNewApproval={needsNewApproval} />}

                    {canApprove && <ApprovePO PO={PO} needsNewApproval={needsNewApproval} />}

                    {canSend && <SendPO PO={PO} viewType={viewType} />}

                    {canMarkAllInPreparation && <MarkAllInPreparation PO={PO} />}

                    {canMarkOutForDelivery && <OutForDeliveryPO PO={PO} />}

                    {canReceiveOrDeliver && <ReceiveOrDeliverPO PO={PO} viewType={viewType} />}

                    {canClose && <ClosePO PO={PO} viewType={viewType} />}
                </>
            ) : (
                <>
                    <CancelEditPO PO={PO} />
                    <SavePO PO={PO} />
                </>
            )}
        </Stack>
    )
}
