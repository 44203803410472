import { Alert, Autocomplete, Chip, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    CheckNode,
    ConfigurationProps,
    TeamReviewer,
    UserReviewer,
    UserType,
    isTeamReviewer,
    isUserReviewer,
} from "~/domains/orchestration/flows/types"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers, useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI, OrganizationTeamI } from "~/types"

import { ConfigurationNode } from "./ConfigurationNode"
import { SharedUserSelect } from "./SharedUserSelect"

const getReviewerNames = (users: OrganizationMemberUserI[], teams: OrganizationTeamI[]) => {
    const reviewerNames = [
        ...users.map((user) => user.user?.fullName).filter(Boolean),
        ...teams.map((team) => team.name),
    ]
    return reviewerNames.join(", ") || ""
}

const getReviewers = (users: OrganizationMemberUserI[], teams: OrganizationTeamI[]) => {
    const usersReviewers: UserReviewer[] = users.map((user) => ({
        userId: user.userId,
        type: UserType.USER,
    }))

    const teamsReviewers: TeamReviewer[] = teams.map((team) => ({
        teamId: team.teamId,
        type: UserType.TEAM,
    }))

    return [...usersReviewers, ...teamsReviewers]
}

export const CheckNodeConfiguration: FC<ConfigurationProps<CheckNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)
    const { teams: allTeams, loading: loadingAllTeams } = useFetchOrganizationTeams(organization!.id, true)

    const [users, setUsers] = useState<OrganizationMemberUserI[]>([])
    const [teams, setTeams] = useState<OrganizationTeamI[]>([])

    const [currentNode, setCurrentNode] = useState(selectedNode)
    const handleSelectUser = (_, values: OrganizationMemberUserI[] = []) => {
        setUsers(values)
    }

    const handleSelectTeam = (_, values: OrganizationTeamI[]) => {
        setTeams(values)
    }

    const handleChangeThreshold =
        (field: "passThreshold" | "refuseThreshold") => (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseInt(e.target.value || "0", 10)

            if (Number.isInteger(value)) {
                setCurrentNode({ ...currentNode, [field]: value })
            }
        }

    useEffect(() => {
        if (members.length && allTeams.length) {
            setCurrentNode({
                ...currentNode,
                reviewers: getReviewers(users, teams),
                metadata: {
                    ...currentNode.metadata,
                    additionalInformation: getReviewerNames(users, teams),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, teams, members.length, allTeams.length])

    useEffect(() => {
        // Initialize users and teams based on selectedNode.reviewers
        const initialUsers = members.filter((member) =>
            selectedNode.reviewers
                .filter(isUserReviewer)
                .map((r) => r.userId)
                .includes(member.userId)
        )
        const initialTeams = allTeams.filter((team) =>
            selectedNode.reviewers
                .filter(isTeamReviewer)
                .map((r) => r.teamId)
                .includes(team.teamId)
        )

        if (initialUsers.length || initialTeams.length) {
            setUsers(initialUsers)
            setTeams(initialTeams)
        }

        if (initialUsers.length || initialTeams.length) {
            setCurrentNode({
                ...currentNode,
                reviewers: getReviewers(initialUsers, initialTeams),

                metadata: {
                    ...currentNode.metadata,
                    additionalInformation: getReviewerNames(initialUsers, initialTeams),
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [members, allTeams, selectedNode.reviewers])

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Alert severity="info">
                <SafeFormattedMessage {...messages.checkNodeConfiguration.warning} />
            </Alert>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.checkNodeConfiguration.usersTitle} />
                <SharedUserSelect
                    members={members}
                    selectedUsers={users}
                    onSelectUsers={handleSelectUser}
                    label={formatMessage(messages.checkNodeConfiguration.user)}
                    loading={Boolean(members.length)}
                />
                <SafeFormattedMessage tagName="h5" {...messages.checkNodeConfiguration.teamsTitle} />

                <Autocomplete
                    multiple
                    autoComplete
                    loading={loadingAllTeams}
                    options={allTeams}
                    value={teams}
                    renderTags={(value, getTagProps) => (
                        <Stack direction="row" gap={1}>
                            {value.map((option, index) => {
                                const { onDelete } = getTagProps({ index })
                                const name = option?.name ?? ""
                                return <Chip key={option.teamId} label={name} variant="outlined" onDelete={onDelete} />
                            })}
                        </Stack>
                    )}
                    getOptionKey={(option) => option.teamId}
                    getOptionLabel={(option) => option?.name ?? ""}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.teamId === value.teamId}
                    onChange={handleSelectTeam}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.checkNodeConfiguration.team)} />
                    )}
                />
            </Stack>

            <Stack gap={2} className={configurationNodeItemClassName}>
                <TextField
                    label={formatMessage(messages.checkNodeConfiguration.passThreshold)}
                    value={currentNode.passThreshold}
                    size="small"
                    onChange={handleChangeThreshold("passThreshold")}
                    type="number"
                    inputProps={{ min: 1 }}
                />

                <TextField
                    label={formatMessage(messages.checkNodeConfiguration.refuseThreshold)}
                    value={currentNode.refuseThreshold}
                    size="small"
                    onChange={handleChangeThreshold("refuseThreshold")}
                    type="number"
                    inputProps={{ min: 1 }}
                />
            </Stack>
            <AdvancedFields<CheckNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
