import { Suspense, lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"
import { Loader } from "~/components"
import { EDIT_TASK_ROUTE, TASKS_LIST_ROUTE } from "~/domains/communication/tasks/routes"

const TasksPage = lazy(() =>
    import("~/domains/communication/tasks/pages/TasksPage").then(({ TasksPage: TaskPageComponent }) => ({
        default: TaskPageComponent,
    }))
)

const EditTaskPage = lazy(() =>
    import("~/domains/communication/tasks/pages/EditTaskPage").then(({ EditTaskPage: EditTaskPageComponent }) => ({
        default: EditTaskPageComponent,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="tasks_route">
            <Route
                path={TASKS_LIST_ROUTE}
                element={
                    <Suspense fallback={<Loader />}>
                        <TasksPage />
                    </Suspense>
                }
            />
            <Route
                path={EDIT_TASK_ROUTE}
                element={
                    <Suspense fallback={<Loader />}>
                        <EditTaskPage />
                    </Suspense>
                }
            />
        </Route>,
    ],
}
