/* eslint-disable complexity */
import { Stack } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { ActionsMenu, Button } from "~/components"
import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { InvoiceAuditLogs } from "~/domains/transactions/invoices-v1/components/InvoiceAuditLogs"
import { InvoiceLifeCycleNextButton } from "~/domains/transactions/invoices-v1/components/InvoiceLifeCycleNextButton"
import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { EDIT_INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import {
    ApproveInvoice,
    DeleteInvoice,
    DownloadPdfInvoice,
    EditInvoice,
    RejectInvoice,
} from "~/domains/transactions/invoices/components/Actions"
import { ImportInvoice } from "~/domains/transactions/invoices/components/Actions/ImportInvoice"
import { RemoveApprovalInvoice } from "~/domains/transactions/invoices/components/Actions/RemoveApprovalInvoice"
import { RequestChangeInvoice } from "~/domains/transactions/invoices/components/Actions/RequestChangeInvoice"
import { ResolveAllRequestsInvoice } from "~/domains/transactions/invoices/components/Actions/ResolveAllRequestsInvoice"
import { ValidateInvoice } from "~/domains/transactions/invoices/components/Actions/ValidateInvoice"
import { canDoAction } from "~/domains/transactions/invoices/utils/canDoAction"
import { useInvoiceValidationActions } from "~/store/invoice/hooks"
import {
    AuthorizationName,
    EventI,
    InvoiceBackendVersion,
    InvoiceI,
    InvoiceStatus,
    OrganizationId,
    UserI,
} from "~/types"

const messages = defineMessages({
    validate: { id: "buyer.invoice.actions.button.validate", defaultMessage: "Validate document" },
    removeApproval: { id: "buyer.invoice.actions.button.removeApproval", defaultMessage: "Remove approval" },
    schedule: { id: "buyer.invoice.actions.button.schedule", defaultMessage: "Schedule payment" },
    paymentDone: { id: "buyer.invoice.actions.button.paymentDone", defaultMessage: "Payment's done" },
    unmarkAsPaid: { id: "invoice.ocrInvoice.unmarkAsPaid", defaultMessage: "Unmark as paid" },
})

interface ActionsHeaderProps {
    organizationId?: OrganizationId
    invoice: InvoiceI
    status: InvoiceStatus
    involvedUsers: UserI[]
    handleDownloadPDF: () => void
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    handleMoveToTrash?: () => void
    handleUnmarkAsPaid?: () => void
    handleRemoveApproval?: () => void
    events?: EventI[]
}

export function ActionsHeader({
    organizationId,
    invoice,
    involvedUsers,
    handleMoveToTrash,
    handleRemoveApproval,
    handleUnmarkAsPaid,
    setDisplayModal,
}: ActionsHeaderProps) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    /* const { status: pennylaneStatus } = useInvoicePennylaneSynchronizationStatus(invoice) */

    const { hasPermission: hasInvoicesApprovePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.APPROVE,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )

    const { withinWorkflow, currentUserCanValidate, currentUserApproval } = useInvoiceValidationActions(
        organizationId,
        invoice.id
    )

    const isCurrentUserReviewer = withinWorkflow && currentUserCanValidate
    const hasApprovedOrRefused = currentUserApproval !== undefined
    const isReviewable = isCurrentUserReviewer && !hasApprovedOrRefused

    /* const isPennylaneSyncFailure = pennylaneStatus?.pennylaneSyncOutcome === PennylaneSyncOutcome.FAILURE */

    const handleEdit = () => {
        if (!invoice) return

        if (invoice.version === InvoiceBackendVersion.V0) {
            navigate(generatePath(EDIT_INVOICE_BUYER_ROUTE, { invoiceId: invoice.id }))
        } else {
            navigate(generatePath(INVOICE_EDIT_ROUTE, { invoiceId: invoice.id }))
        }
    }

    const openModal = () => setDisplayModal(true)

    const canImport = canDoAction(invoice, "import")

    const canReview =
        invoice.version !== InvoiceBackendVersion.V1 && organizationId && isReviewable && canDoAction(invoice, "review")

    const canDelete = handleMoveToTrash && canDoAction(invoice, "delete")

    const canRequest = canDoAction(invoice, "request")

    const canResolveAllRequestsInvoice = canDoAction(invoice, "resolveAllRequests")

    const canUnmarkAsPaid = canDoAction(invoice, "unmarkAsPaid")

    const canValidate = hasInvoicesApprovePermission && canDoAction(invoice, "validate")

    const canRemoveApproval =
        handleRemoveApproval && hasInvoicesApprovePermission && canDoAction(invoice, "removeApproval")

    const actions = [
        { render: <EditInvoice handleEdit={handleEdit} display="menu-item" /> },
        ...(canRequest ? [{ render: <RequestChangeInvoice handleRequest={openModal} /> }] : []),
        {
            render: <DownloadPdfInvoice pdfLink={invoice.fileLinks.pdfLink} display="menu-item" />,
        },
        ...(canDelete ? [{ render: <DeleteInvoice handleDelete={handleMoveToTrash} display="menu-item" /> }] : []),
    ]

    return (
        <Stack direction="row" spacing={1}>
            <ActionsMenu isActionIcon={false} actions={actions} />

            {/* TMP Removed */}
            {/* {isPennylaneSyncFailure && isInvoiceConfirmed && ( */}
            {/* )} */}

            {canImport && <ImportInvoice invoice={invoice} />}

            {canRemoveApproval && <RemoveApprovalInvoice handleRemoveApproval={handleRemoveApproval} />}

            {canUnmarkAsPaid && (
                <Button onClick={handleUnmarkAsPaid} text={formatMessage(messages.unmarkAsPaid)} type="grey" />
            )}

            {canResolveAllRequestsInvoice && <ResolveAllRequestsInvoice invoice={invoice} />}

            {invoice.version === InvoiceBackendVersion.V1 && <InvoiceLifeCycleNextButton />}

            {canReview && (
                <>
                    <RejectInvoice organizationId={organizationId} invoiceId={invoice.id} />
                    <ApproveInvoice organizationId={organizationId} invoiceId={invoice.id} />
                </>
            )}

            {canValidate && <ValidateInvoice invoice={invoice} involvedUsers={involvedUsers} withinWorkflow={false} />}

            <InvoiceAuditLogs invoiceId={invoice.id} version={invoice.version} />
        </Stack>
    )
}
