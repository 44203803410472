import { HandleProps, Position } from "@xyflow/react"
import { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/locale"
import { Event, EventTriggerNode as EventTriggerNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

const handles: HandleProps[] = [
    {
        type: "source",
        position: Position.Bottom,
    },
]

export const EventTriggerNode: FC<NodeProps<NodeType<EventTriggerNodeType>>> = ({ data, selected }) => {
    const { formatMessage } = useIntl()

    const information = messages.event[data.event as Event]
        ? formatMessage(messages.event[data.event as Event]).toLowerCase()
        : ""

    return (
        <Node
            type={data.type}
            name={data.name}
            information={information}
            handles={handles}
            selected={selected}
            error={Boolean(data.error)}
            slug={data.slug}
        ></Node>
    )
}
