import { PauseCircle } from "react-feather"

import { SuspendUntilSurveyCompletedConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    SuspendUntilSurveyCompletedNode as SuspendUntilSurveyCompletedNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const suspendUntilSurveyCompletedNode: Omit<SuspendUntilSurveyCompletedNodeType, keyof Node> = {
    surveyId: "",
    type: NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE,
    nextNode: null,
}

const advancedFields: (keyof typeof suspendUntilSurveyCompletedNode)[] = [AllAdvancedFieldsUnion.SURVEY_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE) return false
    return Boolean(node.surveyId)
}

const suspendUntilSurveyCompletedConfig: Configuration<NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE> = {
    type: NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE,
    baseSlug: "suspend-until-survey-completed",
    Icon: PauseCircle,
    Configuration: SuspendUntilSurveyCompletedConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): SuspendUntilSurveyCompletedNodeType => ({
        ...suspendUntilSurveyCompletedNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { suspendUntilSurveyCompletedConfig, advancedFields as suspendUntilSurveyCompletedAdvancedFields }
