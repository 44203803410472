import { Stack, Tooltip } from "@mui/material"
import { defineMessages } from "react-intl"

import { Chip, ProgressBar, SafeFormattedMessage } from "~/components"
import { CustomFormSurveyStatus } from "~/domains/identity/custom-forms/types/CustomForms"

const messages = defineMessages({
    inProgressTooltip: {
        id: "smartForms.inProgressTooltip",
        defaultMessage: "In progress: {percentage}%",
    },
    statusComplete: {
        id: "common.status.completed",
        defaultMessage: "Completed",
    },
    statusCreated: {
        id: "common.status.created",
        defaultMessage: "Created",
    },
})

export const ProgressCell = ({ status }: { status: CustomFormSurveyStatus }) => {
    if ("InProgress" in status) {
        const percentage = status.InProgress?.percentage ?? 0
        return (
            <Tooltip title={<SafeFormattedMessage {...messages.inProgressTooltip} values={{ percentage }} />} arrow>
                <Stack className="w-full h-full" alignItems="center" direction="row">
                    <ProgressBar
                        values={[
                            {
                                percentage: percentage / 100,
                                color: "var(--color-green)",
                            },
                        ]}
                        className="w-full"
                    />
                </Stack>
            </Tooltip>
        )
    }

    if ("Completed" in status) {
        return (
            <Chip variant="success">
                <SafeFormattedMessage {...messages.statusComplete} />
            </Chip>
        )
    }

    return (
        <Chip variant="neutral">
            <SafeFormattedMessage {...messages.statusCreated} />
        </Chip>
    )
}
