import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"

export type StatusesLinePurchaseOrder = {
    position: number
    actions: ("receive" | "edit" | "approve_client" | "approve_supplier" | "send" | "delete" | "communicate")[]
}

export const statusesLinePurchaseOrder: Record<FulfillmentStatus, StatusesLinePurchaseOrder> = {
    PENDING: {
        actions: ["receive"],
        position: 0,
    },
    IN_PREPARATION: {
        actions: ["receive"],
        position: 1,
    },
    OUT_FOR_DELIVERY: {
        actions: ["receive"],
        position: 2,
    },
    DELIVERED: {
        actions: ["receive"],
        position: 3,
    },
    CANCELED: {
        actions: ["receive"],
        position: 4,
    },
}
