import { Alert, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { CompanyAutocomplete, CompanyResult } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { ManualCompanyInputTrigger } from "~/domains/identity/organization/components/CreateOrganizationContainer/ManualInputContainer"
import { usePartnersAsOrganizations } from "~/domains/identity/partners/store/hooks"
import { PartnershipType } from "~/domains/identity/partners/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { CountryCode, EstablishmentI, ImportInvoiceCompanyInfoI } from "~/types"

const messages = defineMessages({
    // TODO: improve the text displayed in the label: "siret" if FR code, else should be "establishments"
    alreadyPartnership: {
        id: "partners.bookofrelations.modal.create.alreadyPartnership",
        defaultMessage: "This organization is already in your partners list",
    },
})

interface PartnerOrganizationSelectorProps {
    companyResult: CompanyResult | undefined
    setCompanyResult: (companyResult: CompanyResult | undefined) => void
    partnerOrganization: ImportInvoiceCompanyInfoI
    onManualImportClick: () => void
    updateData: (data: any | undefined) => void
}

export const PartnerOrganizationSelector: React.FC<PartnerOrganizationSelectorProps> = ({
    companyResult,
    setCompanyResult,
    partnerOrganization,
    onManualImportClick,
    updateData,
}) => {
    const [countryCode, setCountryCode] = useState(CountryCode.FR)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { organizations: partnersOrganizations } = usePartnersAsOrganizations(
        PartnershipType.SUPPLIER,
        currentOrganizationId || ""
    )

    useEffect(() => {
        const cc = partnerOrganization?.countryCode
        if (cc) {
            setCountryCode(cc)
        }
    }, [partnerOrganization])

    const onCountryCodeChange = (cc: CountryCode) => setCountryCode(cc)

    const onCompanyChange = useCallback(
        (company: CompanyResult | undefined, establishmentsToSelect?: EstablishmentI) => {
            setCompanyResult(company)
            if (!company) return updateData(undefined)
            if (company.type === CompanyAutocompleteType.Organization) {
                updateData({
                    organizationId: company.value.id,
                    countryCode: company.value.registration.countryCode,
                    name: company.value.name,
                    registrationNumber:
                        company.value.registration.dunsNumber ??
                        company.value.registration.preferredRegistrationNumber?.registrationNumber,
                })
            } else if (company.type === CompanyAutocompleteType.WhitePagesResult) {
                const registrationNumber =
                    establishmentsToSelect?.id ?? (company.value.establishments?.length ? undefined : company.value.id)
                updateData({
                    organizationId: undefined,
                    countryCode: company.value.countryCode,
                    name: company.value.name,
                    registrationNumber,
                    establishments: company.value.establishments,
                })
            }
        },
        []
    )

    const isSelectedOrganizationAlreadyPartnership = partnersOrganizations.some(
        (organization) =>
            partnerOrganization.registrationNumber ===
            organization.registration.preferredRegistrationNumber?.registrationNumber
    )

    return (
        <Stack gap={0}>
            <Stack gap={2}>
                <Stack>
                    <CompanyAutocomplete
                        company={companyResult}
                        setCompany={onCompanyChange}
                        countryCode={countryCode}
                        setCountryCode={onCountryCodeChange}
                        organizations={partnersOrganizations}
                        suggestedCompanies={[]}
                    />
                </Stack>
                {isSelectedOrganizationAlreadyPartnership && (
                    <Alert severity="info">
                        <SafeFormattedMessage {...messages.alreadyPartnership} />
                    </Alert>
                )}
            </Stack>
            <ManualCompanyInputTrigger onClick={onManualImportClick} />
        </Stack>
    )
}
