import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { InvoicesTab, OrganizationId, ReceivedDocumentI, SentDocumentI, UserI, UserId } from "~/types"

export interface AccountState {
    loading: boolean
    loadingLogin: boolean
    error: boolean
    redirect: boolean
    redirectTo: string | null
    userId: UserId
    displayModal: boolean

    receivedDocuments: ReceivedDocumentI[]
    receivedDocumentsTags: TagObjectRecordI | undefined
    receivedDocumentsTagsLoading: boolean
    receivedDocumentsUserId?: UserId
    receivedDocumentsOrganizationId?: OrganizationId | undefined
    fetchingReceivedDocuments: boolean
    fetchingReceivedDocumentsError?: string

    sentDocuments: SentDocumentI[]
    sentDocumentsTags: TagObjectRecordI | undefined
    sentDocumentsTagsLoading: boolean
    sentDocumentsUserId?: UserId
    sentDocumentsOrganizationId?: OrganizationId
    fetchingSentDocuments: boolean
    fetchingSentDocumentsError?: string
    data: UserI
    hasSignedUp: boolean
    bulkSuccess: boolean
    selectedDocuments: string[]
    paymentFileDownloaded: boolean
    paymentFileDownloadedError: boolean
    currentInvoicesTab: InvoicesTab
}

export const accountState: AccountState = {
    loading: true,
    loadingLogin: false,
    bulkSuccess: false,
    error: false,
    displayModal: false,
    redirect: false,
    redirectTo: null,
    receivedDocuments: [],
    receivedDocumentsTags: undefined,
    receivedDocumentsTagsLoading: false,
    fetchingReceivedDocuments: false,
    sentDocuments: [],
    sentDocumentsTags: undefined,
    sentDocumentsTagsLoading: false,
    fetchingSentDocuments: false,
    userId: "",
    hasSignedUp: false,
    selectedDocuments: [],
    paymentFileDownloaded: false,
    paymentFileDownloadedError: false,
    currentInvoicesTab: InvoicesTab.ALL,
    data: {
        id: "" as UserId,
        email: "",
        fullName: "",
        //position: "",
        phoneNumber: "",
        emailVerified: false,
        organizations: [],
        invitations: [],
    },
}
