import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { sleep } from "~/utils"
import { isDefined } from "~/utils/isDefined"

import { purchaseOrdersApi } from "../api"
import { getPurchaseOrderId } from "../core/purchaseOrder"
import { purchaseOrdersActions } from "../store/purchaseOrdersSlice"
import { ListPurchaseOrders } from "../types/PurchaseOrders"

const messages = defineMessages({
    purchaseOrderApproved: {
        id: "purchase.orders.purchaseOrderApproved",
        defaultMessage:
            "Your purchase {count, plural, =0 {} one {order} other {orders}} has been successfully approved.",
    },
    unableToApprovePurchaseOrder: {
        id: "purchase.orders.unableToApprovePurchaseOrder",
        defaultMessage:
            "An error occured: your purchase {count, plural, =0 {} one {order} other {orders}} could not be approved.",
    },
    unableToApproveSomePurchaseOrder: {
        id: "purchase.orders.unableToApproveSomePurchaseOrder",
        defaultMessage:
            "An error occured: {count} purchase {count, plural, =0 {} one {order} other {orders}} could not be approved.",
    },
})

export const useApprovePurchaseOrders = () => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    return async (purchaseOrders: ListPurchaseOrders, organizationId: OrganizationId) => {
        const results = await Promise.allSettled(
            purchaseOrders.map((purchaseOrder) => {
                const lineIds = purchaseOrder.lines.map((line) => line.id ?? "")
                purchaseOrdersApi.updateApprovalStatus(organizationId, purchaseOrder.id, lineIds, "APPROVED")
            })
        )

        // We have to wait for the PO updates to be processed, cause we fetch them right after to get updated statuses
        await sleep(200)

        const resultPOs = await Promise.allSettled(
            results.map((r, index) => {
                const purchaseOrderIds = purchaseOrders.map((po) => po.id)
                const purchaseOrderId = getPurchaseOrderId(r, index, purchaseOrderIds)
                if (purchaseOrderId) {
                    return purchaseOrdersApi.findById(organizationId, purchaseOrderId)
                }
                return undefined
            })
        )

        const successApprovedPOs = resultPOs
            .map((r) => (r.status === "fulfilled" && r.value ? r.value : null))
            .filter(isDefined)

        const failApprovedLength = results.length - successApprovedPOs.length
        dispatch(purchaseOrdersActions.approvePurchaseOrders(successApprovedPOs))
        if (failApprovedLength > 0) {
            toast.error(
                formatMessage(
                    failApprovedLength === results.length
                        ? messages.unableToApprovePurchaseOrder
                        : messages.unableToApproveSomePurchaseOrder,
                    { count: failApprovedLength }
                )
            )
        } else {
            toast.success(formatMessage(messages.purchaseOrderApproved, { count: purchaseOrders.length }))
        }
        return true
    }
}
