import * as Sentry from "@sentry/react"

import {
    AuthorizationName,
    SpiceDBPermissionsResult,
} from "~/domains/identity/roles-permissions/types/PermissionsTypes"

// Combine the keys of AuthorizationName into a Set for fast lookup
const validKeys = new Set<AuthorizationName>([...Object.values(AuthorizationName)])

export const adaptSpiceDBPermissionsResult = (result: Record<string, unknown>): SpiceDBPermissionsResult => {
    return Object.entries(result).reduce((adaptedResult, [key, value]) => {
        if (validKeys.has(key as AuthorizationName)) {
            adaptedResult[key] = value as boolean
        } else {
            Sentry.captureException(`SpiceDB permissions result: Invalid authorization name: ${key}`, {
                extra: { result },
            })
            adaptedResult[key] = false
        }
        return adaptedResult
    }, {} as SpiceDBPermissionsResult)
}
