import { SpiceDBPermissionsResult } from "~/types"

export const purchaseRequestPermissionAdapter = (permissions?: SpiceDBPermissionsResult) => {
    return {
        approve: permissions?.approve || true,
        convertToPurchaseOrder: permissions?.convert_to_purchase_order || permissions?.convertToPurchaseOrder || true,
        delete: permissions?.delete || true,
        edit: permissions?.edit || true,
        submit: permissions?.submit || true,
    }
}
