import { Stack, styled } from "@mui/material"
import React, { FC } from "react"
import { ArrowLeft } from "react-feather"

import { SafeFormattedMessage } from "~/components"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { ToggleSideBarButton } from "~/domains/orchestration/flows/components/ToggleSidebarButton"
import { SIDE_BAR_TOP_POSITION, SIDE_BAR_WIDTH } from "~/domains/orchestration/flows/constants"
import { nodeConfig } from "~/domains/orchestration/flows/core/"
import { messages } from "~/domains/orchestration/flows/locale"
import { AdvancedFieldsValues, Configuration, type EditorNode, NodeType } from "~/domains/orchestration/flows/types"

import { NodeList } from "./NodeList"
import "./SideBar.scss"

export interface SideBarProps {
    isOpen: boolean
    selectedNode?: EditorNode
    onDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    onNodeClick: (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => void
    onUnselectNode: () => void
    onToggleSideBar: () => void
}

const SideBarWrapper = styled("div")<{ width: number }>(({ width }) => ({
    width: `${width}px`,
    height: `calc(100vh - ${SIDE_BAR_TOP_POSITION}px)`,
    display: width === 0 ? "none" : "block",
}))

const getConfigurationComponent = (selectedNode: EditorNode) => {
    // Map node types to their configuration components

    const configuration: Configuration<NodeType> = nodeConfig[selectedNode.type]

    const ConfigComponent = configuration.Configuration
    if (!ConfigComponent) return null

    const advancedFields = configuration.advancedFields

    return (
        <ConfigComponent
            key={selectedNode.slug}
            selectedNode={selectedNode}
            advancedFields={advancedFields as AdvancedFieldsValues<EditorNode>}
            validateNode={configuration.validateNode}
        />
    )
}

export const SideBar: FC<SideBarProps> = ({
    isOpen,
    onDragStart,
    selectedNode,
    onNodeClick,
    onUnselectNode,
    onToggleSideBar,
}) => {
    const sideBarWidth = isOpen ? SIDE_BAR_WIDTH : 0
    return (
        <>
            <ToggleSideBarButton onClick={onToggleSideBar} width={sideBarWidth} isOpen={isOpen} />

            <SideBarWrapper className="flows-editor-sideBar" width={sideBarWidth}>
                <Stack
                    className="flows-editor-sideBar-header"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <SafeFormattedMessage
                        tagName="h4"
                        {...(selectedNode ? messages.sideBar.configurationTitle : messages.sideBar.listTitle)}
                    />
                    {selectedNode && (
                        <IconButton type="close" onClick={onUnselectNode}>
                            <ArrowLeft size={14} />
                        </IconButton>
                    )}
                </Stack>

                {selectedNode ? (
                    getConfigurationComponent(selectedNode)
                ) : (
                    <NodeList onDragStart={onDragStart} onNodeClick={onNodeClick} showLabels direction="column" />
                )}
            </SideBarWrapper>
        </>
    )
}
