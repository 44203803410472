import { OrganizationAddressI } from "~/domains/identity/organization/types/OrganizationTypes"
import { Address } from "~/domains/transactions/purchase-orders/types"

export const organizationAddressToAddress = (address: OrganizationAddressI): Address => ({
    city: address.city,
    country: address.country,
    street: address.addressLine,
    street2: address.secondaryAddressLine,
    zipCode: address.zipCode,
})
