import { useIntl } from "react-intl"

import { ProgressBarItem } from "~/components"
import { globalStatus } from "~/constants/globalStatus"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes } from "~/types"

const colorOrder = {
    "var(--color-green)": 0,
    "var(--color-grey-light)": 5,
    "var(--color-yellow)": 10,
    "var(--color-orange)": 50,
    "var(--color-gray)": 100,
    "var(--color-red)": 1000,
    "var(--color-blue)": 200,
}

export const sortProgressBarItemByColor = (a: ProgressBarItem, b: ProgressBarItem) => {
    const colorRankA = colorOrder[a.color] ?? 50
    const colorRankB = colorOrder[b.color] ?? 50
    return colorRankA - colorRankB
}

export type ItemsByStatus = Record<CurrencyCodes, Record<string, ProgressBarItem>>

export const useLineItemsByStatusComparedToBudget = (
    transaction: PurchaseOrders | PurchaseRequestDetails
): {
    groupedItemsByStatus: ItemsByStatus
    lineItemsByStatusAndCurrencyValuesWithoutRejected: ProgressBarItem[]
    totalPercentageWithoutRejected: number
} => {
    const { formatMessage } = useIntl()

    const groupedItemsByStatus = (transaction?.lines || []).reduce(
        (acc, item) => {
            const currency = transaction.currency
            const total = item.totalAmountExcludingTax
            const approvalStatus = item.buyerApprovalStatus ?? item.approvalStatus
            if (!approvalStatus) return acc

            if (!acc?.[currency]) {
                acc[currency] = {}
            }

            if (!acc?.[currency][approvalStatus]) {
                acc[currency][approvalStatus] = {
                    label: formatMessage(globalStatus[approvalStatus]),
                    value: 0,
                    percentage: 0,
                    color: globalStatus[approvalStatus]?.color || "var(--color-grey-light)",
                }
            }
            const budgetForCurrency = transaction?.budgets?.find(
                (budget) => budget?.budget?.currency === currency
            )?.budget

            acc[currency][approvalStatus].value += parseFloat((+total).toFixed(2))

            if (budgetForCurrency?.currentAmount) {
                acc[currency][approvalStatus].percentage =
                    acc[currency][approvalStatus].value / budgetForCurrency.currentAmount
            }

            return acc
        },
        {} as Record<CurrencyCodes, { [key: string]: ProgressBarItem }>
    )

    const allCurrencies = Object.keys(groupedItemsByStatus)
    const firstCurrency = allCurrencies.length > 0 ? allCurrencies[0] : null

    const lineItemsByStatusAndCurrencyValuesWithoutRejected: ProgressBarItem[] = firstCurrency
        ? (Object.values(groupedItemsByStatus[firstCurrency]) as ProgressBarItem[])
              .filter((item: ProgressBarItem) => item.label !== formatMessage(globalStatus.REJECTED))
              .sort(sortProgressBarItemByColor)
        : []

    const totalPercentageWithoutRejected = lineItemsByStatusAndCurrencyValuesWithoutRejected.reduce(
        (acc, item) => acc + +(item.percentage || 0),
        0
    )

    return {
        groupedItemsByStatus,
        lineItemsByStatusAndCurrencyValuesWithoutRejected,
        totalPercentageWithoutRejected,
    }
}
