import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorization as prepareHeaders } from "~/api/prepareHeaders"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"
import { purchaseOrderFromApiAdapter } from "~/domains/transactions/purchase-orders/api/adapters/purchaseOrderFromApiAdapter"
import { PurchaseOrderI } from "~/domains/transactions/purchase-orders/types"
import { PurchaseOrderAPI } from "~/domains/transactions/purchase-orders/types"

const errors = defineMessages({
    updateFulfillmentStatus: {
        id: "purchase.orders.order.statusUpdate.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
})

const BASE_URL_V3 = import.meta.env.VITE_API_PURCHASE_URL_V3 + "organizations/"

export const purchaseOrderApi = createApi({
    reducerPath: "purchaseOrderApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL_V3,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        updateFulfillmentStatus: builder.mutation<
            PurchaseOrderI,
            {
                organizationId: string
                purchaseOrderId: string
                lines: { id: string; quantity: number }[]
                status: FulfillmentStatus
            }
        >({
            query: ({ organizationId, purchaseOrderId, lines, status }) => ({
                url: `${organizationId}/purchase-orders/${purchaseOrderId}/lines/fulfillment`,
                method: "POST",
                body: { lines, status },
            }),
            transformResponse: (response: PurchaseOrderAPI) => purchaseOrderFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.updateFulfillmentStatus,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
    }),
})

export const { useUpdateFulfillmentStatusMutation } = purchaseOrderApi
