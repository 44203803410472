import {
    Background,
    BackgroundVariant,
    MiniMap,
    ReactFlow,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import React, { FC, useEffect, useRef, useState } from "react"

import { ButtonEdge } from "~/domains/orchestration/flows/components/ButtonEdge"
import { ButtonEdgeWithLabel } from "~/domains/orchestration/flows/components/ButtonEdgeWithLabel"
import { EdgeWithLabel } from "~/domains/orchestration/flows/components/EdgeWithLabel"
import { FlowWrapper } from "~/domains/orchestration/flows/components/FlowWrapper"
import { SIDE_BAR_WIDTH_RUN_EXPLORER } from "~/domains/orchestration/flows/constants"
import { FLOW_NODE_TYPES, adaptFlowToEdges, adaptFlowToNodes } from "~/domains/orchestration/flows/core"
import { type Flow } from "~/domains/orchestration/flows/types"

import { RunExplorerSideBar } from "./RunExplorerSideBar"

interface Props {
    flow: Flow
}

const EDGE_TYPES = {
    button: ButtonEdge,
    buttonWithLabel: ButtonEdgeWithLabel,
    withLabel: EdgeWithLabel,
}

export const RunExplorerPanel: FC<Props> = ({ flow }) => {
    const reactFlowWrapper = useRef(null)
    const [nodes, setNodes, onNodesChange] = useNodesState(adaptFlowToNodes(flow))
    const [edges, setEdges, onEdgesChange] = useEdgesState(adaptFlowToEdges(flow))

    const [showSidebar, setShowSidebar] = useState(true)
    const { fitView } = useReactFlow()

    const fitViewDelayed = (delay = 0) => {
        const timeoutId = setTimeout(async () => {
            await fitView()
        }, delay)
        return () => clearTimeout(timeoutId)
    }

    useEffect(() => {
        fitViewDelayed(100)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggleSideBar = () => {
        setShowSidebar((prev) => !prev)
    }
    useEffect(() => {
        setNodes(adaptFlowToNodes(flow))
        setEdges(
            adaptFlowToEdges(flow, {
                readOnly: true,
            })
        )
    }, [flow, setEdges, setNodes])

    const sideBarWidth = showSidebar ? SIDE_BAR_WIDTH_RUN_EXPLORER : 0

    return (
        <>
            <FlowWrapper ref={reactFlowWrapper} $sidebarWidth={sideBarWidth}>
                <ReactFlow
                    key={flow.id}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    edgeTypes={EDGE_TYPES}
                    nodeTypes={FLOW_NODE_TYPES}
                    snapToGrid
                    snapGrid={[50, 50]}
                    fitView
                    minZoom={0.1}
                    fitViewOptions={{
                        padding: 50,
                    }}
                    proOptions={{
                        hideAttribution: true,
                    }}
                >
                    <MiniMap nodeStrokeWidth={1} bgColor="transparent" position="bottom-left" />
                    <Background color="var(--primary-color)" variant={BackgroundVariant.Dots} />
                </ReactFlow>
            </FlowWrapper>
            <RunExplorerSideBar handleToggleSideBar={handleToggleSideBar} flowId={flow.id} isOpen={showSidebar} />
        </>
    )
}
