import { Stack, Typography } from "@mui/material"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card } from "~/components"
import { PartyPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"
import { PaymentTermInfo } from "~/domains/payment/payment-terms/components"
import { Payment, PaymentStatus } from "~/domains/payment/payment/types"
import { useFetchUsers } from "~/store/organization/hooks/useFetchUsers"

const messages = defineMessages({
    buyerPaymentMethod: {
        id: "payment.document.paymentDetails.buyerPaymentMethod",
        defaultMessage: "My payment method",
    },
    vendorPaymentMethod: {
        id: "payment.document.paymentDetails.vendorPaymentMethod",
        defaultMessage: "Vendor payment method",
    },
    generalPaymentMethod: {
        id: "payment.document.paymentDetails.generalPaymentMethod",
        defaultMessage: "Payment method",
    },
    amount: {
        id: "payment.document.paymentDetails.amount",
        defaultMessage: "Amount",
    },
    status: {
        id: "payment.document.paymentDetails.status",
        defaultMessage: "Status",
    },
    dateScheduled: {
        id: "payment.document.paymentDetails.dateScheduled",
        defaultMessage: "Scheduled date",
    },
    paymentDueDate: {
        id: "payment.document.paymentDetails.duePaymentDate",
        defaultMessage: "Payment due date",
    },
    paymentDate: {
        id: "payment.document.paymentDetails.paymentDate",
        defaultMessage: "Payment date",
    },
})

const paymentStatuses: Partial<Record<PaymentStatus, MessageDescriptor>> = {
    [PaymentStatus.PENDING]: {
        id: "common.status.pending",
        defaultMessage: "Pending",
    },
    [PaymentStatus.EXECUTED]: {
        id: "common.status.executed",
        defaultMessage: "Executed",
    },
    [PaymentStatus.CANCELED]: {
        id: "common.status.canceled",
        defaultMessage: "Canceled",
    },
    [PaymentStatus.SCHEDULED]: {
        id: "common.status.scheduled",
        defaultMessage: "Scheduled",
    },
    [PaymentStatus.DRAFT]: {
        id: "common.status.draft",
        defaultMessage: "Draft",
    },
}

interface ActivePaymentProps {
    payment: Payment
}

interface DataItem {
    label: string
    value: string
}

export const ActivePayment = ({ payment }: ActivePaymentProps) => {
    const { formatMessage, formatNumber, formatDate } = useIntl()

    const payload = payment.executed_by_id ? [payment.executed_by_id] : []
    const {
        destination_cash_id,
        origin_cash_id,
        payment_method_id,
        status,
        amount_sent_data,
        date_validated,
        date_scheduled,
        value_date_expected,
    } = payment
    const { users } = useFetchUsers(payload)
    const executedBy = users[0]?.fullName ?? "-"

    const data: DataItem[] = [
        {
            label: formatMessage(commonMessages.createdBy),
            value: executedBy,
        },
        {
            label: formatMessage(messages.status),
            value: paymentStatuses[status] ? formatMessage(paymentStatuses[status]!) : "-",
        },
        ...(date_scheduled
            ? [{ label: formatMessage(messages.dateScheduled), value: formatDate(date_scheduled) }]
            : []),
        ...(value_date_expected
            ? [{ label: formatMessage(messages.paymentDueDate), value: formatDate(value_date_expected) }]
            : []),
        ...(date_validated ? [{ label: formatMessage(messages.paymentDate), value: formatDate(date_validated) }] : []),
        {
            label: formatMessage(messages.amount),
            value: amount_sent_data?.amount
                ? formatNumber(amount_sent_data?.amount, {
                      style: "currency",
                      currency: amount_sent_data?.currency,
                  })
                : "-",
        },
    ]

    if (!payment?.id) {
        return null
    }

    return (
        <>
            <Stack flexDirection="row" columnGap={5} rowGap={2} flexWrap="wrap">
                {data.map(({ label, value }) => (
                    <Stack key={label} spacing={1}>
                        <Typography component="div" fontSize={12} color="var(--color-grey-light)">
                            {label}
                        </Typography>
                        <Typography component="div" fontSize={14} color="var(--color-grey)">
                            {value}
                        </Typography>
                    </Stack>
                ))}
                <PaymentTermInfo paymentId={payment?.id} />
            </Stack>
            {payment_method_id && (
                <Card title={formatMessage(messages.generalPaymentMethod)}>
                    <PartyPaymentMethodDetails paymentMethodId={payment_method_id} />
                </Card>
            )}
            {origin_cash_id && (
                <Card title={formatMessage(messages.buyerPaymentMethod)}>
                    <PartyPaymentMethodDetails paymentMethodDetailsId={origin_cash_id} />
                </Card>
            )}
            {destination_cash_id && (
                <Card title={formatMessage(messages.vendorPaymentMethod)}>
                    <PartyPaymentMethodDetails paymentMethodDetailsId={destination_cash_id} />
                </Card>
            )}
        </>
    )
}
