import { FormControlLabel, FormGroup, Typography } from "@mui/material"
import Linkify from "linkify-react"
import { useCallback, useEffect, useMemo, useState } from "react"

import {
    CustomFormQuestion,
    QuestionFieldTypeEnum,
    QuestionValue,
} from "~/domains/identity/custom-forms/types/CustomForms"

import { CustomFormsDateInput } from "./CustomFormsDateInput"
import { CustomFormsFileInput } from "./CustomFormsFileInput"
import { CustomFormsInput } from "./CustomFormsInput"
import { CustomFormsRadioInput } from "./CustomFormsRadioInput"
import { useFormViewerContext } from "./FormViewerProvider"

interface FormItemProps {
    question: CustomFormQuestion
    disabled?: boolean
}
export const FormItem = ({ question, disabled }: FormItemProps) => {
    const { handleSaveAnswer, focusQuestionId, hiddenQuestions, uploadFileAnswerLoading } = useFormViewerContext()
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        // Scroll to the question
        if (focusQuestionId === question.id) {
            setFocused(true)
        } else {
            setFocused(false)
        }
    }, [focusQuestionId, question])

    const isHidden = hiddenQuestions.includes(question.id)

    // Memoized callback for handling value changes
    const handleValueChanged = useCallback(
        (value: QuestionValue) => {
            handleSaveAnswer(question, value)
        },
        [handleSaveAnswer, question]
    )

    // Extract common properties for inputs
    const savedValue = useMemo(() => {
        return typeof question.savedValue === "boolean" ? String(question.savedValue) : question.savedValue
    }, [question.savedValue])

    const commonProps = {
        savedValue,
        onValueChanged: handleValueChanged,
        description: question.questionContent,
        required: question.required,
        isInvalid: question.invalid,
        disabled,
        isFocused: focused,
        uploadFileAnswerLoading,
    }

    // Render input based on field type
    const renderInput = () => {
        switch (true) {
            case QuestionFieldTypeEnum.TextField in question.fieldType:
                return <CustomFormsInput {...commonProps} />
            case QuestionFieldTypeEnum.TextArea in question.fieldType:
                return <CustomFormsInput {...commonProps} isMultiline />
            case QuestionFieldTypeEnum.NumericField in question.fieldType: {
                const numberProps = {
                    ...commonProps,
                    minValue: question.fieldType.NumericField.minValue,
                    maxValue: question.fieldType.NumericField.maxValue,
                    unit: question.fieldType.NumericField.unit,
                    isNumber: true,
                }
                return <CustomFormsInput {...numberProps} />
            }
            case QuestionFieldTypeEnum.MultiChoice in question.fieldType:
                return (
                    <CustomFormsInput
                        {...commonProps}
                        isSelect
                        isMultichoice
                        options={question.fieldType.MultiChoice.choices}
                    />
                )
            case QuestionFieldTypeEnum.SingleChoice in question.fieldType:
                return <CustomFormsInput {...commonProps} isSelect options={question.fieldType.SingleChoice.choices} />
            case QuestionFieldTypeEnum.YesNo in question.fieldType:
                return <CustomFormsRadioInput {...commonProps} />
            case QuestionFieldTypeEnum.DateField in question.fieldType:
                return (
                    <CustomFormsDateInput
                        {...commonProps}
                        minDate={question.fieldType.DateField.minDate}
                        maxDate={question.fieldType.DateField.maxDate}
                    />
                )
            case QuestionFieldTypeEnum.FileField in question.fieldType:
                return <CustomFormsFileInput {...commonProps} />
            default:
                return <></>
        }
    }

    if (isHidden) {
        return null
    }

    return (
        <FormGroup sx={{ padding: "var(--spacing-lg)" }}>
            <FormControlLabel
                labelPlacement="top"
                sx={{ alignItems: "start" }}
                label={
                    <Typography variant="h6" component="span" className="mb-12 pre-line">
                        <Linkify options={{ target: "_blank" }}>{question.questionContent}</Linkify>
                    </Typography>
                }
                control={renderInput()}
            />
        </FormGroup>
    )
}
