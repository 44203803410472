import { Tooltip } from "@mui/material"
import { Check } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { useProcessBulkReviewItems } from "~/domains/orchestration/flows-v0/hooks/useProcessBulkReviewItems"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks/useFetchPurchaseOrder"
import { useUpdatePurchaseOrderApprovalStatus } from "~/domains/transactions/purchase-orders/store/hooks/useUpdatePurchaseOrderApprovalStatus"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    approve: {
        id: "purchase.orders.order.action.approve",
        defaultMessage: "Mark all as approved",
    },
})

interface ApprovePOProps {
    PO: PurchaseOrders
    needsNewApproval: boolean
}

export const ApprovePO = ({ PO, needsNewApproval }: ApprovePOProps) => {
    const { formatMessage } = useIntl()

    const organization = useAppSelector(selectCurrentOrganization)
    const { processBulkReviewItems } = useProcessBulkReviewItems()
    const { updatePOApprovalStatus } = useUpdatePurchaseOrderApprovalStatus(PO.id)

    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)

    const handleApprove = async () => {
        if (!organization?.id) return

        const lineIds = PO.lines.map((line) => line.id ?? "")

        // if the PO needs approval with the new approval service:
        if (needsNewApproval) {
            await processBulkReviewItems(lineIds, true, ApprovalObjectType.PURCHASE_ORDER_LINE)
        }
        await updatePOApprovalStatus(organization.id, lineIds, "APPROVED")
        await fetchPurchaseOrder(PO.id)
    }

    return (
        <Tooltip title={formatMessage(messages.approve)}>
            <span>
                <Button type="primary-light" onClick={handleApprove}>
                    <Check size={18} />
                    <SafeFormattedMessage {...messages.approve} />
                </Button>
            </span>
        </Tooltip>
    )
}
