import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { TagObjectI } from "~/domains/analytics/tags/types"
import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { purchaseOrdersState } from "~/domains/transactions/purchase-orders/store/purchaseOrdersState"
import {
    ListPurchaseOrders,
    PurchaseOrderLine,
    PurchaseOrders,
    PurchaseOrdersTab,
} from "~/domains/transactions/purchase-orders/types"
import { RootState } from "~/store"
import { ViewTypeI } from "~/types"

const initialState = purchaseOrdersState

interface UpdateDataPayload {
    field: keyof PurchaseOrders
    value: PurchaseOrders[keyof PurchaseOrders]
}

const purchaseOrdersSlice = createSlice({
    name: "purchase-orders",
    initialState: initialState,
    reducers: {
        fetchPOs(state) {
            state.loading = true
            state.error = null
        },
        fetchPOsSuccess(state, action: PayloadAction<ListPurchaseOrders>) {
            state.loading = false
            state.list = action.payload
            state.error = null
        },
        fetchPOsFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchPO(state, action: PayloadAction<string>) {
            state.loading = true
            state.error = null
        },
        fetchPOSuccess(state, action: PayloadAction<PurchaseOrders>) {
            state.loading = false
            state.data = action.payload
            state.error = null
        },
        fetchPOFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        updatePO(state) {
            state.loading = true
        },
        updatePOSuccess(state) {
            state.loading = false
        },
        updatePOFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        updateData(state, action: PayloadAction<UpdateDataPayload>) {
            ;(state.data[action.payload.field] as PurchaseOrders[keyof PurchaseOrders]) = action.payload.value
        },
        setLines(state, action: PayloadAction<PurchaseOrderLine[]>) {
            state.data.lines = action.payload
        },
        createDraftPurchaseOrder(state, action: PayloadAction<PurchaseOrders>) {
            state.data = action.payload
        },
        createPO(state) {
            state.loading = true
            state.error = null
        },
        createPOSuccess(state, action: PayloadAction<PurchaseOrders>) {
            state.list = [...state.list, action.payload]
        },
        createPOError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        setFilter(state, action: PayloadAction<string>) {
            state.filter = action.payload
        },
        setViewType(state, action: PayloadAction<ViewTypeI>) {
            state.viewType = action.payload
        },
        resetData(state) {
            state.data = initialState.data
        },
        reset() {
            return initialState
        },
        setTagsLoading(state, action: PayloadAction<boolean>) {
            state.tagsLoading = action.payload
        },
        setTags(state, action: PayloadAction<TagObjectRecordI | undefined>) {
            state.tags = action.payload
            state.tagsLoading = false
        },
        setCurrentPurchaseOrdersTab(state, action: PayloadAction<PurchaseOrdersTab>) {
            state.currentPurchaseOrdersTab = action.payload
        },
        needSaveShippingAndBillingAddress(state, action: PayloadAction<boolean>) {
            state.needSaveShippingAndBillingAddress = action.payload
        },
        approvePurchaseOrders(state, action: PayloadAction<PurchaseOrders[]>) {
            state.list = state.list.map((po) => {
                const purchaseOrder = action.payload.find((p) => p.id === po.id)
                return purchaseOrder ?? po
            })
        },
        sendPurchaseOrders(state, action: PayloadAction<PurchaseOrders[]>) {
            state.list = state.list.map((po) => {
                const purchaseOrder = action.payload.find((p) => p.id === po.id)
                return purchaseOrder ?? po
            })
        },
        addTagsToPurchaseOrders(
            state,
            action: PayloadAction<{
                selected: string[]
                tagsToAdd: TagObjectI[]
                tagsToRemove: TagObjectI[]
            }>
        ) {
            const list = [...state.list]
            action.payload.selected.forEach((id) => {
                const index = list.findIndex((pr) => pr.id === id)
                const currentTags = list[index]?.tags ?? []
                list[index].tags = [
                    ...new Set([
                        ...currentTags.filter(
                            (currentTag) =>
                                !action.payload.tagsToRemove.some(
                                    (tagToRemove) =>
                                        tagToRemove.objectId === id && tagToRemove.tagId === currentTag.tagId
                                )
                        ),
                        ...action.payload.tagsToAdd.filter(
                            (tagObject) =>
                                tagObject.objectId === id && !currentTags.find((t) => t.tagId === tagObject.tagId)
                        ),
                    ]),
                ]
            })
            state.list = list
        },
    },
})

export const purchaseOrdersActions = purchaseOrdersSlice.actions

// // Selectors
export const selectPurchaseOrdersLoading = (state: RootState) => state.purchaseOrders.loading
export const selectPurchaseOrdersTags = (state: RootState) => state.purchaseOrders.tags
export const selectPurchaseOrdersTagsLoading = (state: RootState) => state.purchaseOrders.tagsLoading
export const selectPurchaseOrdersError = (state: RootState) => state.purchaseOrders.error
export const selectPurchaseOrders = (state: RootState) => state.purchaseOrders.list
export const selectPurchaseOrder = (state: RootState) => state.purchaseOrders.data
export const selectPurchaseOrderFilter = (state: RootState) => state.purchaseOrders.filter
export const selectPurchaseViewType = (state: RootState) => state.purchaseOrders.viewType
export const selectNeedSaveShippingAndBillingAddress = (state: RootState) =>
    state.purchaseOrders.needSaveShippingAndBillingAddress

// // Reducer
const purchaseOrdersReducer = purchaseOrdersSlice.reducer
export default purchaseOrdersReducer
