import * as Sentry from "@sentry/react"

export const AppReload = () => {
    // Quick fix:
    // We now just automatically refresh the page instead of showing the App reload page
    document.location.reload()

    // TO REMOVE. Not supposed to be in production
    const timestamp = new Date().toISOString()
    Sentry.captureException(`App reloaded at ${timestamp}`, {
        extra: {
            timestamp,
        },
    })

    return null
}
