import { Tooltip } from "@mui/material"
import { CheckCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { Button, SafeFormattedMessage } from "~/components"
import { INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import { INVOICE_SUPPLIER_ROUTE } from "~/domains/transactions/invoices/_views/supplier/routes"
import { useSegment } from "~/hooks/useSegment"
import { useAddBuyerInvoice } from "~/store/invoice/hooks/useAddBuyerInvoice"
import { InvoiceI, InvoiceUserType } from "~/types"

const messages = defineMessages({
    import: {
        id: "buyer.invoice.actions.button.import",
        defaultMessage: "Confirm",
    },
    importButtonTooltip: {
        id: "buyer.invoice.actions.button.import.tooltip",
        defaultMessage: "Confirm the import of this invoice !",
    },
})

interface ImportInvoiceProps {
    invoice: InvoiceI
    display?: "menu-item" | "button"
}

export const ImportInvoice = ({ invoice, display = "button" }: ImportInvoiceProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { segmentTrack } = useSegment()

    const addInvoice = useAddBuyerInvoice(invoice.id)

    const handleImport = async () => {
        await addInvoice()

        segmentTrack("Imported Invoice Confirmed", {
            invoice_id: invoice.id,
            source: invoice.source,
        })

        const ROUTE = invoice.initiator === InvoiceUserType.SUPPLIER ? INVOICE_SUPPLIER_ROUTE : INVOICE_BUYER_ROUTE

        navigate(generatePath(ROUTE, { invoiceId: invoice.id }))
    }

    return (
        <>
            {display === "button" ? (
                <Tooltip title={formatMessage(messages.importButtonTooltip)} arrow>
                    <div>
                        <Button onClick={handleImport} type="primary-light">
                            <CheckCircle size={18} />
                            <SafeFormattedMessage {...messages.import} />
                        </Button>
                    </div>
                </Tooltip>
            ) : (
                <Button onClick={handleImport} type="menu-item">
                    <CheckCircle size={18} />
                    <SafeFormattedMessage {...messages.import} />
                </Button>
            )}
        </>
    )
}
