import { FormControl, Grid, Switch, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC, useState } from "react"
import { generatePath, useLocation, useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"

import { HeaderNavigation, HeaderNavigationItem, SafeFormattedMessage } from "~/components"
import { LeftChevronIcon } from "~/components/Icons"
import { PublishButton } from "~/domains/orchestration/flows/components/PublishButton"
import { SwitchLabel } from "~/domains/orchestration/flows/components/SwitchLabel"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { useFlowActions } from "~/domains/orchestration/flows/hooks/useFlowActions"
import { messages } from "~/domains/orchestration/flows/locale"
import { EDITOR, FLOWS_LIST, RUN_EXPLORER } from "~/domains/orchestration/flows/routes"
import { Flow, View } from "~/domains/orchestration/flows/types"

import "./ActionBar.scss"
import { DeleteAction } from "./DeleteAction"

interface Props {
    allowDelete: boolean
    allowEdit: boolean
    flow: Flow
}

export const ActionBar: FC<Props> = ({ flow, allowDelete, allowEdit }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { view } = location.state || { view: View.FLOW }
    const dispatch = useEditorDispatch()
    const state = useEditor()
    const { archiveFlow } = useFlowActions()

    const currentFlow = state.flow || flow

    const [enabled, setEnabled] = useState(flow.enabled)

    const updateFlow = (payload: Flow) => {
        dispatch({
            type: "SET_FLOW",
            payload: payload,
        })
    }

    const handleToggleEnabledFlow = () => {
        const toggleEnabled = !enabled
        setEnabled(toggleEnabled)

        updateFlow({ ...currentFlow, enabled: toggleEnabled })
    }

    const handleEditFlowName = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value
        updateFlow({ ...currentFlow, name })
    }

    const handleArchiveFlow = () => {
        archiveFlow({ id: currentFlow.id, version: flow.version })
        updateFlow({ ...currentFlow, archived: true })
        navigate(generatePath(FLOWS_LIST))
    }

    const handleViewChange = (v: string) => () => {
        const path = generatePath(v === View.FLOW ? EDITOR : RUN_EXPLORER, { flowId: flow.id })
        navigate(path, {
            state: { view: v, version: currentFlow.version },
        })
    }

    const navItems: HeaderNavigationItem[] = [
        { value: View.FLOW, label: messages.editor.editorTab, onClick: handleViewChange(View.FLOW) },
        { value: View.RUN_EXPLORER, label: messages.page.runsTab, onClick: handleViewChange(View.RUN_EXPLORER) },
    ]

    const canEdit = allowEdit && !currentFlow.archived
    const canDelete = allowDelete && !currentFlow.archived

    const selectedIndex = view === View.FLOW ? 0 : 1

    return (
        <Grid container className="flows-actionBar" alignItems="center">
            <Grid item xs={5} display="flex" alignItems="center" gap={1}>
                <Link to={generatePath(FLOWS_LIST)} className="flows-actionBar-left-backLink">
                    <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
                </Link>
                <FormControl fullWidth>
                    <TextField
                        className="flows-actionBar-left-title"
                        value={currentFlow.name}
                        disabled={!canEdit}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                        }}
                        onChange={handleEditFlowName}
                    />
                </FormControl>
                {view === View.FLOW && (
                    <Typography className="flows-actionBar-left-version" variant="caption">
                        <SafeFormattedMessage {...messages.editor.version} values={{ version: currentFlow.version }} />
                    </Typography>
                )}
            </Grid>
            <Grid item xs={2}>
                <HeaderNavigation items={navItems} selectedIndex={selectedIndex} onChange={handleViewChange} />
            </Grid>
            {canEdit && canDelete && (
                <Grid item xs={5} display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                    <Switch checked={enabled} onChange={handleToggleEnabledFlow} disabled={!canEdit} />
                    <SwitchLabel toggle={enabled.toString()}>
                        <SafeFormattedMessage
                            {...(currentFlow.enabled ? messages.editor.enabled : messages.editor.disabled)}
                        />{" "}
                    </SwitchLabel>
                    <PublishButton />
                    <DeleteAction
                        flow={currentFlow}
                        deleteCallback={handleArchiveFlow}
                        hasFlowDeletePermission={canDelete}
                    />
                </Grid>
            )}
        </Grid>
    )
}
