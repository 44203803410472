import "dayjs/locale/fr"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormatDateOptions, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useGetBulkObjectsTags } from "~/domains/analytics/tags/hooks/useGetBulkObjectsTags"
import { selectSelectedTagsForFilter } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI } from "~/domains/analytics/tags/types/Tag"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { ListPurchaseOrders, PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId, ViewTypeI } from "~/types"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

import { purchaseOrdersActions, selectPurchaseOrderFilter } from "../purchaseOrdersSlice"
import { useFetchOrganizationPurchaseOrders } from "./useFetchOrganizationPurchaseOrders"

const noFilter = () => true

const getPurchaseOrdersFilter = (
    filter: string,
    formatDate: (value: string | number | Date | undefined, opts?: FormatDateOptions | undefined) => string,
    brandNames: Record<OrganizationId, string>,
    selectedTagsForFilter: SelectedTagI[]
) => {
    if ((!filter || !filter.length) && !selectedTagsForFilter.length) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)

    return (purchaseOrder: PurchaseOrders) => {
        const supplierNameWords = purchaseOrder.supplierName
            ? purchaseOrder.supplierName.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const buyerNameWords = purchaseOrder.buyerName
            ? purchaseOrder.buyerName.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const supplierBrandNameWords = brandNames[purchaseOrder.supplierId]
            ? brandNames[purchaseOrder.supplierId].toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const buyerBrandNameWords = brandNames[purchaseOrder.supplierId]
            ? brandNames[purchaseOrder.supplierId].toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const descriptionWords = purchaseOrder.description
            ? purchaseOrder.description.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const amountStr = purchaseOrder.totalAmount ? purchaseOrder.totalAmount.toString() : ""
        const requesterStr = purchaseOrder.requesterName
            ? purchaseOrder.requesterName.toString().toLocaleLowerCase()
            : ""
        const deliveryDateStr = purchaseOrder.expectedDeliveryDate
            ? formatDate(purchaseOrder.expectedDeliveryDate).toString().toLocaleLowerCase()
            : ""
        const POId = purchaseOrder.shortId ? purchaseOrder.shortId.toString().toLocaleLowerCase() : ""
        const statusStr = purchaseOrder.status ? purchaseOrder.status.toString().toLocaleLowerCase() : ""

        const searchArray = [
            ...descriptionWords,
            ...supplierNameWords,
            ...buyerNameWords,
            ...supplierBrandNameWords,
            ...buyerBrandNameWords,
            amountStr,
            requesterStr,
            deliveryDateStr,
            POId,
            statusStr,
        ]

        const matchesSearchWords = filterWords.every((word) =>
            searchArray.some((purchaseOrderWord) => purchaseOrderWord.indexOf(word) >= 0)
        )

        const hasSelectedTags =
            !selectedTagsForFilter.length ||
            selectedTagsForFilter.every((selectedTag) =>
                purchaseOrder.tags?.some((tag) => tag.tagId === selectedTag.tagId)
            )

        return matchesSearchWords && hasSelectedTags
    }
}

export const useFetchPurchaseOrders = (
    view?: ViewTypeI | string,
    organizationId?: OrganizationId
): { purchaseOrders: ListPurchaseOrders; loading: boolean; refetchPurchaseOrders: () => Promise<null | undefined> } => {
    const dispatch = useDispatch()
    const { formatDate } = useIntl()

    const [loading, setLoading] = useState<boolean>(false)
    const filter = useAppSelector(selectPurchaseOrderFilter)
    const brandNames = useAppSelector(selectPartnersBrandNames)
    const selectedTagsForFilter = useAppSelector(selectSelectedTagsForFilter)

    const { getBulkObjectsTags } = useGetBulkObjectsTags(organizationId)
    const { purchaseOrders, fetchPurchaseOrders } = useFetchOrganizationPurchaseOrders(view, organizationId)

    const fetchPurchaseOrdersAndTags = useCallback(async () => {
        if (!organizationId) {
            return null
        }
        try {
            setLoading(true)
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const purchaseOrders = await fetchPurchaseOrders()
            if (purchaseOrders && purchaseOrders.length) {
                dispatch(purchaseOrdersActions.setTagsLoading(true))
                try {
                    const prsObjectsTags = await getBulkObjectsTags(
                        purchaseOrders.map((d) => d.id),
                        true
                    )
                    dispatch(purchaseOrdersActions.setTags(prsObjectsTags))
                    if (prsObjectsTags) {
                        dispatch(
                            purchaseOrdersActions.fetchPOsSuccess(
                                purchaseOrders.map((doc) => ({
                                    ...doc,
                                    tags: prsObjectsTags[doc.id] ?? [],
                                }))
                            )
                        )
                    }
                } catch (e) {
                    dispatch(
                        purchaseOrdersActions.fetchPOsSuccess(
                            purchaseOrders.map((doc) => ({
                                ...doc,
                            }))
                        )
                    )
                }
            }
        } catch (error) {
            dispatch(purchaseOrdersActions.reset())
        } finally {
            setLoading(false)
        }
    }, [organizationId, view, fetchPurchaseOrders, getBulkObjectsTags])

    useEffect(() => {
        fetchPurchaseOrdersAndTags()
    }, [fetchPurchaseOrdersAndTags, view])

    return useMemo(() => {
        return {
            purchaseOrders: purchaseOrders.filter(
                getPurchaseOrdersFilter(filter, formatDate, brandNames, selectedTagsForFilter)
            ),
            loading,
            refetchPurchaseOrders: fetchPurchaseOrdersAndTags,
        }
    }, [purchaseOrders, loading, filter, fetchPurchaseOrdersAndTags, selectedTagsForFilter])
}
