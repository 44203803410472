import { styled } from "@mui/material"

export const Separator = styled("div")(({ theme, children }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: children ? "8px" : "0px",
    color: theme.palette.grey[900],
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "left",

    "&::before": {
        content: '""',
        display: "block",
        height: "1px",
        flex: "1 1 auto",
        backgroundColor: "#E4E7ED",
    },
    "&::after": {
        content: '""',
        display: "block",
        height: "1px",
        flex: "1 1 auto",
        backgroundColor: "#E4E7ED",
    },
}))
