import { UserCheck } from "react-feather"

import { CreateTaskConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    CreateTaskNode as CreateTaskNodeType,
    EditorNode,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const createTaskNode: Omit<CreateTaskNodeType, keyof Node> = {
    title: "",
    description: "",
    dueDate: "",
    priority: undefined,
    status: undefined,
    assignee: "",
    parentTaskId: "",
    public: false,
    followers: [],
    parties: [],
    type: NodeType.CREATE_TASK_NODE,
    nextNode: null,
}

const advancedFields = []

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CREATE_TASK_NODE) return false
    return Boolean(node.title)
}

const createTaskConfig: Configuration<NodeType.CREATE_TASK_NODE> = {
    type: NodeType.CREATE_TASK_NODE,
    baseSlug: "create-task",
    Icon: UserCheck,
    Configuration: CreateTaskConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): CreateTaskNodeType => ({
        ...createTaskNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { createTaskConfig, advancedFields as createTaskAdvancedFields }
