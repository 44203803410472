import React, { ChangeEvent, useEffect } from "react"
import { defineMessages, useIntl } from "react-intl"

import { DatatableFilters, DatatableFiltersActions } from "~/components"
import {
    purchaseOrdersActions,
    selectPurchaseOrderFilter,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    newPO: { id: "purchase.orders.newPO", defaultMessage: "Create a purchase order" },
})

interface Props {
    actions: DatatableFiltersActions[]
    hasPermission?: boolean
}

export const Filters: React.FC<Props> = ({ actions, hasPermission }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const filter = useAppSelector(selectPurchaseOrderFilter)

    const handleChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(purchaseOrdersActions.setFilter(e.target.value))
    }

    useEffect(() => {
        return () => {
            dispatch(purchaseOrdersActions.setFilter(""))
        }
    }, [])

    return (
        <DatatableFilters
            onChangeFilter={handleChangeFilter}
            valueFilter={filter}
            labelButton={formatMessage(messages.newPO)}
            actionsMenu={true}
            actions={actions}
            hasPermission={hasPermission ?? false}
        />
    )
}
