import {
    Book,
    CheckSquare,
    Command,
    Folder,
    Home,
    LogIn,
    Mail,
    MessageCircle,
    Play,
    Send,
    Settings,
    ShoppingBag,
    User,
} from "react-feather"
import { BarChart, CreditCard, DollarSign, Feather, FileText, Users } from "react-feather"
import { Upload } from "react-feather"
import { Icon } from "react-feather"
import { MessageDescriptor } from "react-intl"
import { generatePath } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { METRICS_ROUTE } from "~/domains/analytics/metrics/routes"
import { COMMUNICATION_ROUTE } from "~/domains/communication/chat/routes/routes"
import { INBOX_ROUTE } from "~/domains/communication/emails/routes"
import { TASKS_LIST_ROUTE } from "~/domains/communication/tasks/routes"
import {
    ORGANIZATIONS_ROUTE,
    ORGANIZATION_ROUTE,
    PROFILE_ROUTE,
    PROFILE_ROUTE_DEFAULT,
} from "~/domains/identity/account/routes"
import { CUSTOM_FORMS_ROUTE } from "~/domains/identity/custom-forms/routes"
import { DOCUMENTS_ROUTE } from "~/domains/identity/documents/routes"
import { PARTNERS_ROUTE, PARTNERS_ROUTE_DEFAULT } from "~/domains/identity/partners/routes"
import { COPILOT_PROMPT_ROUTE } from "~/domains/orchestration/copilot/routes"
import { WORKFLOWS_ROUTE } from "~/domains/orchestration/flows-v0/routes"
import { FLOWS_ROUTE } from "~/domains/orchestration/flows/routes"
import { PAYMENTS_ROUTE } from "~/domains/payment/payment/routes"
import { BUDGETS_ROUTE } from "~/domains/transactions/budget/routes"
import { CATALOG_V1_ROUTE } from "~/domains/transactions/catalog-v1/routes"
import { INVOICES_V1_ROUTE, INVOICE_V1_ROUTE_DEFAULT } from "~/domains/transactions/invoices-v1/routes"
import {
    INVOICES_ROUTE,
    INVOICES_ROUTE_DEFAULT,
    INVOICE_BUYER_ROUTE_DEFAULT,
    INVOICE_SUPPLIER_ROUTE_DEFAULT,
} from "~/domains/transactions/invoices/routes"
import { PURCHASE_ORDERS_ROUTE, PURCHASE_ORDERS_ROUTE_DEFAULT } from "~/domains/transactions/purchase-orders/routes"
import { PURCHASE_REQUESTS_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { DASHBOARD_ROUTE } from "~/routes/routes"
import { ViewTypeI } from "~/types"

export enum MenuItem {
    DASHBOARD = "dashboard",
    INBOX = "inbox",
    PAYMENTS = "payments",
    BUDGETS = "budget",
    METRICS = "metrics",
    SURVEY = "customForms",
    PARTNERS = "partners",
    ACCOUNT = "account",
    LOGIN = "login",
    NEW_UPLOAD = "newUpload",
    INVOICES = "invoices",
    INVOICES_V1 = "invoicesV1",
    DOCUMENTS = "documents",
    PURCHASE_ORDERS = "purchaseOrders",
    PURCHASE_REQUESTS = "purchaseRequests",
    WORKFLOWS = "workflow",
    FLOWS = "flows",
    COPILOT = "copilot",
    CATALOG = "catalog",
    SETTINGS = "settings",
    COMMUNICATION = "communication",
    TASKS = "tasks",
}

export type SideBarMenuItemI = {
    message: MessageDescriptor
    route: string
    routesActive?: string[]
    routeWithNoOrganization?: string
    icon: Icon
    showBetaFlag?: boolean
}

export const sideBarMenuItems: Record<MenuItem, SideBarMenuItemI> = {
    [MenuItem.NEW_UPLOAD]: {
        message: { id: "navbar.newUpload", defaultMessage: "New upload" },
        route: "#",
        icon: Upload,
    },
    [MenuItem.INVOICES]: {
        message: { id: "common.invoices", defaultMessage: "Invoices" },
        route: generatePath(INVOICES_ROUTE, { view: ViewTypeI.buyer }),
        routesActive: [INVOICES_ROUTE_DEFAULT, INVOICE_BUYER_ROUTE_DEFAULT, INVOICE_SUPPLIER_ROUTE_DEFAULT],
        icon: FileText,
    },
    [MenuItem.INVOICES_V1]: {
        message: { id: "common.invoices", defaultMessage: "Invoices" },
        route: INVOICES_V1_ROUTE,
        routesActive: [INVOICE_V1_ROUTE_DEFAULT],
        icon: FileText,
    },
    [MenuItem.DOCUMENTS]: {
        message: { id: "navbar.documents", defaultMessage: "Documents" },
        route: DOCUMENTS_ROUTE,
        icon: Folder,
    },
    [MenuItem.SURVEY]: {
        message: { id: "navbar.customForms", defaultMessage: "Smart forms" },
        route: CUSTOM_FORMS_ROUTE,
        icon: Feather,
    },
    [MenuItem.PARTNERS]: {
        message: { id: "navbar.partners", defaultMessage: "Partners network" },
        route: generatePath(PARTNERS_ROUTE, { view: ViewTypeI.supplier }),
        routesActive: [PARTNERS_ROUTE_DEFAULT],
        icon: Users,
    },
    [MenuItem.PAYMENTS]: {
        message: commonMessages.cashflow,
        route: PAYMENTS_ROUTE,
        icon: CreditCard,
    },
    [MenuItem.BUDGETS]: {
        message: { id: "navbar.budgets", defaultMessage: "Budgets" },
        route: BUDGETS_ROUTE,
        icon: DollarSign,
    },
    [MenuItem.METRICS]: {
        message: { id: "navbar.metrics", defaultMessage: "Metrics" },
        route: METRICS_ROUTE,
        icon: BarChart,
    },
    [MenuItem.PURCHASE_ORDERS]: {
        message: { id: "navbar.purchases.orders", defaultMessage: "Payments" },
        route: generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }),
        routesActive: [PURCHASE_ORDERS_ROUTE_DEFAULT],
        icon: ShoppingBag,
    },
    [MenuItem.PURCHASE_REQUESTS]: {
        message: { id: "navbar.purchases.requests", defaultMessage: "Payments" },
        route: PURCHASE_REQUESTS_ROUTE,
        icon: Send,
    },
    [MenuItem.INBOX]: { message: { id: "navbar.inbox", defaultMessage: "Inbox" }, route: INBOX_ROUTE, icon: Mail },
    [MenuItem.DASHBOARD]: {
        message: { id: "navbar.dashboard", defaultMessage: "Dashboard" },
        route: DASHBOARD_ROUTE,
        icon: Home,
    },
    [MenuItem.ACCOUNT]: {
        message: { id: "navbar.account", defaultMessage: "My account" },
        route: PROFILE_ROUTE,
        routesActive: [PROFILE_ROUTE_DEFAULT],
        icon: User,
    },
    [MenuItem.LOGIN]: { message: { id: "navbar.login", defaultMessage: "Login" }, route: "#", icon: LogIn },
    [MenuItem.WORKFLOWS]: {
        message: { id: "navbar.workflow", defaultMessage: "Workflows" },
        route: WORKFLOWS_ROUTE,
        icon: Play,
    },
    [MenuItem.FLOWS]: {
        message: { id: "navbar.flows", defaultMessage: "Flows (beta)" },
        route: FLOWS_ROUTE,
        icon: Play,
    },
    [MenuItem.COPILOT]: {
        message: { id: "navbar.copilot", defaultMessage: "Copilot" },
        route: COPILOT_PROMPT_ROUTE,
        icon: Command,
    },
    [MenuItem.CATALOG]: {
        message: { id: "navbar.catalog", defaultMessage: "Catalog" },
        route: CATALOG_V1_ROUTE,
        icon: Book,
    },
    [MenuItem.SETTINGS]: {
        message: { id: "navbar.settings", defaultMessage: "Settings" },
        route: ORGANIZATION_ROUTE,
        routeWithNoOrganization: ORGANIZATIONS_ROUTE,
        routesActive: [ORGANIZATIONS_ROUTE],
        icon: Settings,
    },
    [MenuItem.COMMUNICATION]: {
        message: { id: "navbar.communication", defaultMessage: "Communication" },
        route: COMMUNICATION_ROUTE,
        icon: MessageCircle,
    },
    [MenuItem.TASKS]: {
        message: { id: "navbar.tasks", defaultMessage: "Tasks" },
        route: TASKS_LIST_ROUTE,
        icon: CheckSquare,
    },
}
