/* eslint-disable max-lines */
import * as t from "io-ts"

import { InvoicePennylaneSynchronizationStatusExtendedI } from "~/domains/_shared/ocr/types/pennylaneSynchronizationStatus"
import { TagObjectI } from "~/domains/analytics/tags/types"
import { UserI, UserId } from "~/domains/identity/account/types/UserTypes"
import {
    CompanyI,
    CompanyIO,
    CompanyRegistrationNumberI,
    EstablishmentI,
} from "~/domains/identity/organization/types/CompanyTypes"
import { OrganizationI, OrganizationId } from "~/domains/identity/organization/types/OrganizationTypes"
import { PaymentI } from "~/domains/payment/types/PaymentTypes"
import { BudgetDataWithMetricsI } from "~/domains/transactions/budget/types"
import { InvoiceAPI, InvoiceLineItemAPI } from "~/domains/transactions/invoices-v1/types/Invoice"
import { LineStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes, MonetaryInfo } from "~/types"
import { CountryCode } from "~/types/CountryCode"
import { Opaque } from "~/utils"
import { optional } from "~/utils/io-ts"

export type InvoiceId = Opaque<string, { readonly T: unique symbol }>

export enum InvoiceBackendVersion {
    V0 = "V0",
    V1 = "V1",
}

export enum InvoiceStatus {
    DRAFT = "DRAFT",
    DUPLICATES = "DUPLICATES",
    CONFIRMED = "CONFIRMED",
    INFO_REQUESTED = "INFO_REQUESTED",
    VALIDATED = "VALIDATED",
    PAYMENT_SCHEDULED = "PAYMENT_SCHEDULED",
    MARKED_AS_PAID = "MARKED_AS_PAID",
    PAID = "PAID",
    DRAFT_supplier = "DRAFT_supplier",
    CONFIRMED_supplier = "CONFIRMED_supplier",
    INFO_REQUESTED_supplier = "INFO_REQUESTED_supplier",
    VALIDATED_supplier = "VALIDATED_supplier",
    PAYMENT_SCHEDULED_supplier = "PAYMENT_SCHEDULED_supplier",
    MARKED_AS_PAID_supplier = "MARKED_AS_PAID_supplier",
    PAID_supplier = "PAID_supplier",
    REJECTED = "REJECTED",
    REJECTED_supplier = "REJECTED_supplier",
}

export enum InvoicesTab {
    ALL = "ALL",
    DUPLICATES = "DUPLICATES",
    DRAFT = "DRAFT",
    PENDING_VALIDATION = "PENDING_VALIDATION",
    CONFIRMED = "CONFIRMED",
    INFO_REQUESTED = "INFO_REQUESTED",
    PENDING_PAYMENT = "PENDING_PAYMENT",
    PAID = "PAID",
    MARKED_AS_PAID = "MARKED_AS_PAID",
    REJECTED = "REJECTED",
    VALIDATED = "VALIDATED",
}

export enum SourceType {
    OCR = "OCR",
    ERP = "ERP",
    MAIL = "MAIL",
}

export enum ImportInvoiceError {
    BUYER_ORGANIZATION_FOUND_IN_SUPPLIER = "BUYER_ORGANIZATION_FOUND_IN_SUPPLIER",
    SUPPLIER_ORGANIZATION_FOUND_IN_BUYER = "SUPPLIER_ORGANIZATION_FOUND_IN_BUYER",
    UNKNOWN = "UNKNOWN",
}

export const InvoiceSourceIO = t.type({
    type: t.string,
    name: t.string,
})

export const InvoiceTaxLineIO = t.type({
    taxRateId: t.string,
    taxRate: t.number,
    taxAmount: t.number,
})

export const ImportInvoiceCompanyInfoIO = t.intersection([
    t.type({
        countryCode: t.string,
        name: t.string,
    }),
    t.partial({
        organizationId: optional(t.string),
        email: optional(t.string),
        contactName: optional(t.string),
        dunsNumber: optional(t.string),
        taxId: optional(t.string),
        registrationNumber: optional(t.string),
    }),
])

export const InvoicePaymentDetailsIO = t.partial({
    iban: optional(t.string),
    accountNumber: optional(t.string),
})

export const InvoicePaymentsIO = t.intersection([
    t.type({
        id: t.string,
        status: t.string,
        updateTimestamp: t.string,
    }),
    t.partial({
        scheduledDate: optional(t.string),
        errorMessage: optional(t.string),
    }),
])

export const InvoiceSubLineIO = t.intersection([
    t.type({
        linePosition: t.number,
        quantity: t.number,
        unitPrice: t.number,
        totalExcludedTaxes: t.number,
        totalTax: t.number,
        total: t.number,
    }),
    t.partial({
        description: optional(t.string),
        productReference: optional(t.string),
        discountTotal: optional(t.number),
        unitOfMeasure: optional(t.string),
        taxRateId: optional(t.string),
        accountingCode: optional(t.string),
    }),
])

export const InvoiceLineIO = t.intersection([
    InvoiceSubLineIO,
    t.partial({
        subLines: t.array(InvoiceSubLineIO),
    }),
])

const InvoiceCommonBasisIO = t.type({
    id: t.string,
    status: t.string,
    buyerTaxes: t.array(InvoiceTaxLineIO),
    supplierTaxes: t.array(InvoiceTaxLineIO),
})

const InvoiceCommonOptionalsIO = t.partial({
    reference: optional(t.string),
    description: optional(t.string),
    issueDate: optional(t.string),
    dueDate: optional(t.string),
    purchaseOrderNumber: optional(t.string),
    total: optional(t.number),
    totalExcludedTaxes: optional(t.number),
    totalDiscount: optional(t.number),
    scheduledPaymentDate: optional(t.string),
    paidAt: optional(t.string),
    source: optional(InvoiceSourceIO),
    paymentDetails: optional(InvoicePaymentDetailsIO),
    payments: optional(t.array(InvoicePaymentsIO)),
    signed: optional(t.boolean),
    lines: optional(t.array(InvoiceLineIO)),
    invoiceType: optional(t.string),
    possibleDuplicates: optional(t.array(t.string)),
})

export const ImportingInvoiceIO = t.intersection([
    InvoiceCommonBasisIO,
    t.type({
        buyer: ImportInvoiceCompanyInfoIO,
        supplier: ImportInvoiceCompanyInfoIO,
    }),
    InvoiceCommonOptionalsIO,
])

export const InvoiceCompanyRegistrationIO = t.partial({
    countryCode: optional(t.string),
    registrationNumber: optional(t.string),
    legalName: optional(t.string),
    vatNumber: optional(t.string),
})

export const InvoiceCompanyIO = t.partial({
    organizationId: optional(t.string),
    name: optional(t.string),
    contactName: optional(t.string),
    contactEmail: optional(t.string),
    registrations: InvoiceCompanyRegistrationIO,
    iban: optional(t.string),
})

const InvoiceFileLinksIO = t.partial({
    pdfLink: optional(t.string),
    bboxLink: optional(t.string),
    imageLinks: optional(t.array(t.string)),
    originalImageLinks: optional(t.array(t.string)),
})

const InvolvedPeopleIO = t.type({
    userId: t.string,
})

export const InvoiceIO = t.intersection([
    InvoiceCommonBasisIO,
    t.type({
        supplier: optional(InvoiceCompanyIO),
        buyer: optional(InvoiceCompanyIO),
        fileLinks: InvoiceFileLinksIO,
        involvedPeople: t.array(InvolvedPeopleIO), // TODO: rename buyerInvolvedPeople
        supplierInvolvedPeople: t.array(InvolvedPeopleIO),
        version: optional(t.string),
    }),
    InvoiceCommonOptionalsIO,
    t.partial({
        buyerTags: optional(t.array(t.string)),
        supplierTags: optional(t.array(t.string)),
    }),
])

export const CreateInvoiceErrorIO = t.intersection([
    t.type({
        type: t.string,
    }),
    t.partial({
        organizationId: optional(t.string),
    }),
])

export const CreateInvoiceResponseIO = t.intersection([
    t.type({
        invoice: ImportingInvoiceIO,
    }),
    t.partial({
        suggestedSupplierCompanies: optional(t.array(CompanyIO)),
        suggestedBuyerCompanies: optional(t.array(CompanyIO)),
        error: optional(CreateInvoiceErrorIO),
    }),
])

export type CreateInvoiceErrorI = t.TypeOf<typeof CreateInvoiceErrorIO> & {
    type: ImportInvoiceError
    organizationId?: OrganizationId | null
}
export type InvoiceLineI = t.TypeOf<typeof InvoiceLineIO> &
    InvoiceLineItemAPI & {
        approvalStatus?: LineStatus
        status?: string
        customField?: Record<string, string>
    }
export type InvoicePaymentDetailsI = t.TypeOf<typeof InvoicePaymentDetailsIO> | null
export type ImportInvoiceCompanyInfoI = t.TypeOf<typeof ImportInvoiceCompanyInfoIO> & {
    countryCode: CountryCode
    contactName: string
    organizationId: OrganizationId | null
    establishments?: EstablishmentI[]
}
export type InvoiceFileLinksI = t.TypeOf<typeof InvoiceFileLinksIO>
export type ImportingInvoiceI = t.TypeOf<typeof ImportingInvoiceIO> &
    DocumentWithTags &
    DocumentWithTaxes &
    DocumentWithPurchaseOrderNumber &
    DocumentWithReference &
    DocumentWithDueDate &
    DocumentWithLines &
    InvoicePaymentDetailsI &
    DocumentWithPrice &
    InvoiceWithId &
    DocumentWithVersion & {
        loaded: boolean
        status: InvoiceStatus
        buyer: ImportInvoiceCompanyInfoI
        supplier: ImportInvoiceCompanyInfoI
        fileLinks?: InvoiceFileLinksI
    }

export type InvoiceCompanyRegistrationI = t.TypeOf<typeof InvoiceCompanyRegistrationIO> & {
    countryCode: CountryCode
}
export type InvoiceCompanyI = t.TypeOf<typeof InvoiceCompanyIO> & {
    registrations: InvoiceCompanyRegistrationI
}

export type InvoiceI = t.TypeOf<typeof InvoiceIO> & {
    status: InvoiceStatus
    buyer: ImportInvoiceCompanyInfoI
    supplier: ImportInvoiceCompanyInfoI
    notification?: NotificationI
    signed: boolean
    currency: CurrencyCodes
    buyerId: string
    supplierId: string
    initiator: InvoiceUserType
} & InvoiceWithId &
    DocumentWithTags &
    DocumentWithTaxes &
    DocumentWithReference &
    DocumentWithPrice &
    DocumentWithPurchaseOrderNumber &
    DocumentWithDueDate &
    DocumentWithLines &
    DocumentWithBudgets &
    DocumentWithPossibleDuplicates &
    InvoiceAPI &
    DocumentWithVersion

export type CreateInvoiceResponseI = t.TypeOf<typeof CreateInvoiceResponseIO> & {
    invoice: ImportingInvoiceI
    suggestedSupplierCompanies?: CompanyI[] | null
    suggestedBuyerCompanies?: CompanyI[] | null
    ocrBuyer?: ImportInvoiceCompanyInfoI
    ocrSupplier?: ImportInvoiceCompanyInfoI
    paymentDetails?: InvoicePaymentDetailsI
    error: CreateInvoiceErrorI | null
    otherError: string | null // error is actually more a warning, this is a reel error, might want to change this in this future
}

export enum InvoiceUserType {
    SUPPLIER = "SUPPLIER",
    BUYER = "BUYER",
}

export interface DocumentWithDescription {
    description: string | null
}

export interface DocumentWithReference {
    reference: string
}

export interface DocumentWithVersion {
    version: InvoiceBackendVersion
}

export interface DocumentWithPurchaseOrderNumber {
    purchaseOrderNumber?: string
}

export interface DocumentWithLines {
    lines?: InvoiceLineI[] | null | undefined
}

export interface DocumentWithPossibleDuplicates {
    possibleDuplicates?: InvoiceId[]
}

export interface InvoiceWithId {
    id: InvoiceId
}

export interface InvoiceWithStatus {
    status: InvoiceStatus
}

export interface DocumentWithTags {
    buyerTags?: string[]
    supplierTags?: string[]
}

export interface DocumentWithPrice {
    total: number | null
    totalExcludedTaxes: number | null
    totalDiscount: number | null
    totalAmountDue?: MonetaryInfo[] | null
    flatDiscount?: Record<CurrencyCodes, string>
    discountRate?: string
}

export interface DocumentWithBudgets {
    budgets?: BudgetDataWithMetricsI[] | null
}

export interface InvoiceTaxLine {
    taxRateId: string
    taxRate: number
    taxAmount: number
}

export interface DocumentWithTaxes {
    buyerTaxes: InvoiceTaxLine[]
    supplierTaxes: InvoiceTaxLine[]
}

export interface DocumentWithDueDate {
    dueDate?: string
    issueDate?: string
}

export interface NewOcrCompanyDetails {
    countryCode: CountryCode
    name: string
    email?: string
    taxId?: string
    registrationNumber?: string
}

export interface NewOcrInvoiceI {
    id: string | null
    reference: string | null
    description: string | null
    dueDate?: string
    purchaseOrderNumber?: string
    total: number | null
    totalExcludedTaxes: number | null
    buyerTaxes: InvoiceTaxLine[]
    supplierTaxes: InvoiceTaxLine[]
    buyer: NewOcrCompanyDetails
    supplier: NewOcrCompanyDetails
    status: InvoiceStatus
    payments: PaymentI[]
    paymentDetails?: PaymentDetailsI
}

export interface OcrInvoiceI
    extends DocumentWithReference,
        DocumentWithDescription,
        DocumentWithDueDate,
        DocumentWithPurchaseOrderNumber,
        DocumentWithTags,
        DocumentWithPrice,
        DocumentWithTaxes {
    buyer: CompanyDetailsI
    supplier: CompanyDetailsI
    paymentDetails?: PaymentDetailsI
    notification: NotificationI

    // TODO: remove this
    [key: string]: unknown
}

export interface InvolvedOrganization {
    organizationId: string
    involvedUserIds: string[]
}

export interface UpdateInvoicePayload {
    version: InvoiceBackendVersion
    reference: string
    description: string
    total: number | null
    totalDiscount?: number | null
    totalExcludedTaxes: number | null
    issueDate: string
    dueDate: string
    purchaseOrderNumber?: string
    buyer: InvolvedOrganization
    supplier: InvolvedOrganization
    paymentDetails: PaymentDetailsI
    lines?: InvoiceLineI[]
}

export interface UpdateCompanyInfoRegistrationsPayload {
    registrationNumber?: string | null
    vatNumber?: string | null
}

export interface UpdateCompanyInfoPayload {
    organizationId?: OrganizationId | null
    involvedUserIds?: string[]
    name?: string
    contactName?: string
    registrations?: UpdateCompanyInfoRegistrationsPayload
}

export interface UpdateDraftInvoicePayload {
    version: InvoiceBackendVersion
    initiator: InvoiceUserType
    description?: string
    reference?: string
    purchaseOrderNumber?: string
    total?: number
    totalDiscount?: number
    totalExcludedTaxes?: number
    dueDate?: string
    issueDate?: string
    buyer?: UpdateCompanyInfoPayload
    supplier?: UpdateCompanyInfoPayload
    paymentDetails?: PaymentDetailsI
    lines?: InvoiceLineI[]
}

export interface ConfirmInvoicePayload extends UpdateInvoicePayload {
    notification?: NotificationI
}

export interface DocumentSourceI {
    type: SourceType
    name: string
}

export interface DocumentI {
    invoiceId: string
    status: InvoiceStatus
    dueDate: string
    paidDate: string | null
    updateDate: string
    reference: string
    purchaseOrderNumber?: string
    totalExcludedTaxes: number
    total: number
    description?: string
    pdfLink?: string
    supplierId?: string
    supplierName?: string
    buyerId?: string
    buyerName?: string
    source?: DocumentSourceI
    tags?: TagObjectI[]
    signed?: boolean
    issueDate?: string
    possibleDuplicates?: string[]
    pennylaneStatus?: InvoicePennylaneSynchronizationStatusExtendedI
    paymentDetails?: PaymentDetailsI | string
    currency?: CurrencyCodes

    [x: string]: unknown
}

export interface DocumentIWithTotalTax extends DocumentI {
    totalTax: number
}

export interface ReceivedDocumentI extends DocumentI {
    supplierId: string
    supplierName: string
    receptionDate: string
    budgets?: BudgetDataWithMetricsI[] | null
}

export interface SentDocumentI extends DocumentI {
    buyerId: string
    buyerName: string
    creationDate: string
}

export interface InvoiceRequestI {
    subject: string
    body: string
    date?: string
    invoiceId?: string | null
    userId?: string
}

export interface InvolvedPeopleI {
    userId: string
}

export interface OcrResultI {
    invoiceId: string
    ocrResultLink: string
    ocrImagesLink: string
}

export interface NotificationI {
    subject: string
    body: string
}

export interface FileLinksI {
    pdfLink: string
    imageLinks: string[]
    originalImageLinks: string[]
}

export interface CompanyDetailsI {
    name: string
    dunsNumber?: string | null
    email?: string | null
    contactName: string
    registrations: CompanyRegistrationI
    userId?: string
}

export interface CompanyRegistrationI {
    countryCode: CountryCode
    dunsNumber?: string | null
    legalName?: string
    registrationNumber?: CompanyRegistrationNumberI
    vatNumber?: string
}

export interface APICompanyRegistrationI extends CompanyRegistrationI {
    SIREN?: string
    SIRET?: string
    siret?: string
    siren?: string
    "VAT NUMBER"?: string
}

export interface PaymentDetailsI {
    iban?: string | null
}

export interface AlreadyPaidPaymentI {
    alreadyPaid?: boolean | false
    executionDate?: string | Date | null
    requestIBAN?: boolean | false
}

export interface DocumentsSortByI {
    sortBy: string
    direction: OrderDirection
}

export enum OrderDirection {
    ASC = "asc",
    DESC = "desc",
}

export interface FileI {
    name: string
    file: File
    description: string
    type: string
    url?: string
}

export interface AttachmentI {
    description?: string
    id: string
    link: string
    originalFilename: string
    type?: string
}

export interface PaymentFileI {
    invoiceIds: string[]
    fileName?: string
    scheduleDate?: string | Date
    version: XMLVersionsEnum
}

export interface MessageOrEventI {
    userId: string
    user?: UserI
    timestamp: Date | string
}

export interface MessageI extends MessageOrEventI {
    message: string
    file?: AttachmentI
}

export interface EventI extends MessageOrEventI {
    type: string
}

export interface PayloadValidationI {
    id: string
    comment: string
    tags: string[]
}

export interface OcrImagesI {
    imageLinks: string[]
}

export interface AddInvolvedPersonI {
    email: string
}
type UserWithOptOrganization = {
    userId: UserId
    organizationId: OrganizationId | undefined
}

export type ConfirmInvoiceOptions =
    | {
          supplier: UserWithOptOrganization
          buyer?: undefined
      }
    | {
          supplier?: undefined
          buyer: UserWithOptOrganization
      }

export type OrganizationOptions = {
    buyerOrganization?: OrganizationI
    supplierOrganization?: OrganizationI
}

export const complianceBusinessDocumentIO = t.type({
    invoiceId: t.string,
})

export type complianceBusinessDocumentI = t.TypeOf<typeof complianceBusinessDocumentIO> & {
    invoiceId: InvoiceId
}

export enum ComplianceContextOptions {
    CONTINUOUS_TRANSACTION_CONTROL_PT = "CONTINUOUS_TRANSACTION_CONTROL_PT",
    FRENCH_PUBLIC_PROCUREMENT = "FRENCH_PUBLIC_PROCUREMENT",
    POST_AUDIT = "POST_AUDIT",
}

const ComplianceContextOptionsIO = t.union([
    t.literal(ComplianceContextOptions.CONTINUOUS_TRANSACTION_CONTROL_PT),
    t.literal(ComplianceContextOptions.FRENCH_PUBLIC_PROCUREMENT),
    t.literal(ComplianceContextOptions.POST_AUDIT),
])

export enum ComplianceStatusOptions {
    COMPLIANT = "COMPLIANT",
    NON_COMPLIANT = "NON_COMPLIANT",
    PENDING = "PENDING",
}

const ComplianceStatusOptionsIO = t.union([
    t.literal(ComplianceStatusOptions.COMPLIANT),
    t.literal(ComplianceStatusOptions.NON_COMPLIANT),
    t.literal(ComplianceStatusOptions.PENDING),
])

export const ExternalStatusMetadataIO = t.partial({
    userMail: optional(t.string),
})

export const ComplianceExternalLifecycleIO = t.partial({
    externalStatus: optional(t.string),
    externalStatusDate: optional(t.string),
    externalStatusReason: optional(t.string),
    externalStatusMetadata: t.union([ExternalStatusMetadataIO, t.null, t.undefined]),
})

type ComplianceExternalLifecycleI = t.TypeOf<typeof ComplianceExternalLifecycleIO>

export enum ChorusExternalStatus {
    IN_RECU = "IN_RECU",
    IN_TRAITE_SE_CPP = "IN_TRAITE_SE_CPP",
    IN_EN_ATTENTE_TRAIT = "IN_EN_ATTENTE_TRAIT",
    IN_EN_COURS_TRAITE = "IN_EN_COURS_TRAITE",
    IN_INCIDENTE = "IN_INCIDENTE",
    IN_REJETE = "IN_REJETE",
    IN_EN_ATTENTE_RET = "IN_EN_ATTENTE_RET",
    IN_INTEGRE = "IN_INTEGRE",
    IN_DEPOT_PORTAIL_EN_ATTENTE_TRAITEMENT_SE_CPP = "IN_DEPOT_PORTAIL_EN_ATTENTE_TRAITEMENT_SE_CPP",
    IN_INTEGRE_PARTIEL = "IN_INTEGRE_PARTIEL",
    SUSPENDUE = "SUSPENDUE",
    A_RECYCLER = "A_RECYCLER",
    COMPLETEE = "COMPLETEE",
    COMPTABILISEE = "COMPTABILISEE",
    MISE_A_DISPOSITION = "MISE_A_DISPOSITION",
    MISE_A_DISPOSITION_COMPTABLE = "MISE_A_DISPOSITION_COMPTABLE",
    MISE_EN_PAIEMENT = "MISE_EN_PAIEMENT",
    REJETEE = "REJETEE",
    SERVICE_FAIT = "SERVICE_FAIT",
    MANDATEE = "MANDATEE",
    DEPOSEE = "DEPOSEE",
}

const ChorusExternalStatusIO = t.union([
    t.literal(ChorusExternalStatus.IN_RECU),
    t.literal(ChorusExternalStatus.IN_TRAITE_SE_CPP),
    t.literal(ChorusExternalStatus.IN_EN_ATTENTE_TRAIT),
    t.literal(ChorusExternalStatus.IN_EN_COURS_TRAITE),
    t.literal(ChorusExternalStatus.IN_INCIDENTE),
    t.literal(ChorusExternalStatus.IN_REJETE),
    t.literal(ChorusExternalStatus.IN_EN_ATTENTE_RET),
    t.literal(ChorusExternalStatus.IN_INTEGRE),
    t.literal(ChorusExternalStatus.IN_DEPOT_PORTAIL_EN_ATTENTE_TRAITEMENT_SE_CPP),
    t.literal(ChorusExternalStatus.IN_INTEGRE_PARTIEL),
    t.literal(ChorusExternalStatus.SUSPENDUE),
    t.literal(ChorusExternalStatus.A_RECYCLER),
    t.literal(ChorusExternalStatus.COMPLETEE),
    t.literal(ChorusExternalStatus.COMPTABILISEE),
    t.literal(ChorusExternalStatus.MISE_A_DISPOSITION),
    t.literal(ChorusExternalStatus.MISE_A_DISPOSITION_COMPTABLE),
    t.literal(ChorusExternalStatus.MISE_EN_PAIEMENT),
    t.literal(ChorusExternalStatus.REJETEE),
    t.literal(ChorusExternalStatus.SERVICE_FAIT),
    t.literal(ChorusExternalStatus.MANDATEE),
    t.literal(ChorusExternalStatus.DEPOSEE),
])

const KnownStatusReasons = t.partial({
    currentExternalStatus: ChorusExternalStatusIO,
    detail: t.string,
    error: t.string,
    NIN: t.string,
})

const DynamicStatusReasons = t.record(t.string, t.string)

const ComplianceStatusReasonsIO = t.intersection([KnownStatusReasons, DynamicStatusReasons])

export type ComplianceStatusReasonsI = t.TypeOf<typeof ComplianceStatusReasonsIO> & {
    currentExternalStatus?: ChorusExternalStatus
}

export const ComplianceStatusIO = t.intersection([
    t.type({
        id: t.string,
        businessDocument: complianceBusinessDocumentIO,
        complianceContext: ComplianceContextOptionsIO,
        complianceStatus: ComplianceStatusOptionsIO,
        complianceStatusDate: t.string,
    }),
    t.partial({
        complianceStatusReason: ComplianceStatusReasonsIO,
        complianceExternalLifecycle: t.union([t.array(ComplianceExternalLifecycleIO), t.null]),
    }),
])

export const ComplianceStatusResponseIO = t.array(ComplianceStatusIO)

export type ComplianceStatusI = t.TypeOf<typeof ComplianceStatusIO> & {
    complianceContext: ComplianceContextOptions
    complianceStatus: ComplianceStatusOptions
    complianceStatusReason?: ComplianceStatusReasonsI
    complianceExternalLifecycle?: ComplianceExternalLifecycleI[] | null
}

export enum InvoiceExportScheduleType {
    EVERY_DAY = "EVERY_DAY",
    ON_MONDAY = "ON_MONDAY",
    FIRST_DAY_OF_THE_MONTH = "FIRST_DAY_OF_THE_MONTH",
    CUSTOM_SCHEDULE = "CUSTOM_SCHEDULE",
    UNKNOWN = "UNKNOWN",
}

export const InvoiceExportCommonScheduleIO = t.intersection([
    t.type({
        type: t.string,
        hourOfTheDay: t.number,
        timezone: t.string,
    }),
    t.partial({
        periodInDays: optional(t.number),
    }),
])

export type InvoiceExportCommonSchedule = {
    hourOfTheDay: number
    timezone: string
}

export type InvoiceExportCommonScheduleI = InvoiceExportCommonSchedule & {
    type:
        | InvoiceExportScheduleType.EVERY_DAY
        | InvoiceExportScheduleType.ON_MONDAY
        | InvoiceExportScheduleType.FIRST_DAY_OF_THE_MONTH
}

export type InvoiceExportCustomScheduleI = InvoiceExportCommonSchedule & {
    type: InvoiceExportScheduleType.CUSTOM_SCHEDULE
    periodInDays: number
}

export type InvoiceExportUnknownScheduleI = {
    type: InvoiceExportScheduleType.UNKNOWN
}

export type InvoiceExportScheduleI =
    | InvoiceExportCommonScheduleI
    | InvoiceExportCustomScheduleI
    | InvoiceExportUnknownScheduleI

const NextRunIO = t.type({
    nextRunAt: t.string,
    exportRangeStart: t.string,
    exportRangeEnd: t.string,
})

type NextRunI = t.TypeOf<typeof NextRunIO>

export const InvoiceExportIO = t.type({
    id: t.string,
    enabled: t.boolean,
    schedule: InvoiceExportCommonScheduleIO,
    recipientEmailAddresses: t.array(t.string),
    nextRun: NextRunIO,
})

export type CreateInvoiceExportI = {
    enabled: boolean
    schedule: InvoiceExportScheduleI
    recipientEmailAddresses: string[]
}

export type InvoiceExportI = CreateInvoiceExportI & {
    id: string
    nextRun: NextRunI
}

export const XMLVersions = ["V03", "V11"] as const

export type XMLVersionsEnum = (typeof XMLVersions)[number]
