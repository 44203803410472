import { TagObjectI } from "~/domains/analytics/tags/types"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"
import { BudgetDataWithMetricsI } from "~/domains/transactions/budget/types"
import { ProductVersionId } from "~/domains/transactions/catalog/types"
import { Address, Contact, PurchaseOrderApprovalStatus } from "~/domains/transactions/purchase-orders/types"
import {
    AmountsMonetaryData,
    CurrencyCodes,
    MonetaryInfo,
    OrganizationId,
    SpiceDBPermissionsResult,
    TotalAmountsMonetaryData,
} from "~/types"

export enum PurchaseRequestStatus {
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    CONVERTED = "CONVERTED",
}

export enum LineStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}

export interface CreatePurchaseRequestDTO {
    supplierId: string | null
    expectedDeliveryDate?: string
    totalAmountExcludingTax: number
    description: string
    lines: CreateLineDTO[]
    shippingAddress?: Address | null
}

export interface CreatePurchaseRequestDTOV2 {
    supplierId: string | null
    expectedDeliveryDate?: string
    description: string
    lines: CreateLineDTOV2[]
    shippingAddress?: Address | null
}

export interface UpdatePurchaseRequestDTO {
    supplierId?: string | null
    description: string
    lines: PurchaseRequestLine[]
    expectedDeliveryDate?: string
    shippingAddress?: Address | null
    // recurrence: Recurrence
}

export interface UpdatePurchaseRequestDTOV2 {
    supplierId?: string | null
    description: string
    lines: CreateLineDTOV2[]
    expectedDeliveryDate?: string
    shippingAddress?: Address | null
}

export interface PurchaseRequestLineV2 extends PurchaseRequestLineSummary {
    unitPrice: MonetaryInfo
    unitPriceExcludingTax: MonetaryInfo
    totals: AmountsMonetaryData
    description: string
    expectedDeliveryDate?: string | null
    quantity: number
    taxRate: number // double
    temporaryId?: string
    itemId?: ProductVersionId
    units?: string
}

export interface PurchaseRequestV2SummaryAPI extends PurchaseRequestSummaryAPI {
    totals: TotalAmountsMonetaryData
}

export interface PurchaseRequestSummaryAPI {
    id: string
    organizationId: OrganizationId
    supplierId: OrganizationId
    requesterUserId: string
    status: PurchaseRequestStatus
    description: string
    expectedDeliveryDate: string
    totalAmount?: number
    totalAmountExcludingTax: number
    currency: CurrencyCodes
    taxRate?: number
    number?: number
    recurrence?: OneShotRecurrence | CustomRecurrence
    creationDate: string
    purchaseOrder: PurchaseRequestDetailsPurchaseOrder | null
    lines: PurchaseRequestLineSummary[]
    submittedDate: string | null
    permissions?: SpiceDBPermissionsResult
}

export interface PurchaseRequestDetailsPurchaseOrder {
    id: string
    shortId: string
}

export interface PurchaseRequestV2 {
    id: string
    organizationId: OrganizationId
    supplierId: OrganizationId
    requesterUserId: string
    status: PurchaseRequestStatus
    description: string
    expectedDeliveryDate: string
    number?: number
    recurrence?: OneShotRecurrence | CustomRecurrence
    creationDate: string
    purchaseOrder: PurchaseRequestDetailsPurchaseOrder | null
    lines: PurchaseRequestLineV2[]
    submittedDate: string | null
    documents?: Document[]
    requesterName?: string
    supplierName?: string
    supplier?: { email?: string }
    shippingAddress?: Address | null
    budgets?: BudgetDataWithMetricsI[] | null
    totals: TotalAmountsMonetaryData
    permissions?: SpiceDBPermissionsResult
}

export interface PurchaseRequestDetails extends PurchaseRequestSummary {
    number?: number
    description: string
    documents?: Document[]
    lines: PurchaseRequestLine[]
    requesterName?: string
    supplierName?: string
    supplier?: { email?: string }
    purchaseOrder: PurchaseRequestDetailsPurchaseOrder | null
    shippingAddress?: Address | null
    budgets?: BudgetDataWithMetricsI[] | null
    contact?: Contact
}

export interface PurchaseRequestSummary extends PurchaseRequestSummaryAPI {
    requesterName?: string
    supplierName?: string
    tags?: TagObjectI[] | undefined
}

export interface Document {
    id: string
    name: string
}

export interface PurchaseRequestLine extends PurchaseRequestLineSummary {
    description: string
    expectedDeliveryDate?: string | null
    quantity: number
    unitPrice: number
    unitPriceExcludingTax: number
    taxRate: number // double
    totalAmount: number
    totalAmountExcludingTax: number
    temporaryId?: string
    itemId?: ProductVersionId
    units?: string

    // useful to disambiguate between a purchase order line and a purchase request line
    buyerApprovalStatus?: never
}

export interface PurchaseRequestLineSummary {
    id: string | null
    approvalStatus: LineStatus
}

export interface CreateLineDTO {
    id?: string | null
    description: string
    quantity: number
    unitPrice: number
    unitPriceExcludingTax: number
    taxRate: number
    totalAmount: number
    totalAmountExcludingTax: number
    temporaryId?: string
    hasChanged?: boolean
    buyerFulfillment?: FulfillmentStatus
    supplierFulfillment?: FulfillmentStatus
    buyerApprovalStatus?: PurchaseOrderApprovalStatus
    supplierApprovalStatus?: PurchaseOrderApprovalStatus
    itemId?: ProductVersionId
    units?: string
}

export interface CreateLineDTOV2 {
    id?: string | null
    description: string
    quantity: number
    unitPrice: MonetaryInfo
    unitPriceExcludingTax: MonetaryInfo
    taxRate: string
    temporaryId?: string
    hasChanged?: boolean
    buyerApprovalStatus?: PurchaseOrderApprovalStatus
    supplierApprovalStatus?: PurchaseOrderApprovalStatus
    itemId?: ProductVersionId
    buyerItemId?: ProductVersionId
    supplierItemId?: ProductVersionId
    units?: string
}

export interface CreatePOLineDTO extends CreateLineDTO {
    totalTax?: number
    buyerItemId?: ProductVersionId
    supplierItemId?: ProductVersionId
}

export interface Recurrence {
    type: string
    repeatEvery?: number
    recurrenceType?: RecurrenceType
}

export interface OneShotRecurrence extends Recurrence {
    type: "OneShot"
}

export interface CustomRecurrence extends Recurrence {
    type: "Custom"
    repeatEvery: number
    recurrenceType: RecurrenceType
}

export enum RecurrenceType {
    DAILY,
    WEEKLY,
    MONTHLY,
    YEARLY,
}

export enum PurchaseRequestConversionStatus {
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    SENT = "SENT",
}

export enum PurchaseRequestsTab {
    ALL = "ALL",
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    CONVERTED = "CONVERTED",
}
