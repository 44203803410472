import { Breadcrumbs, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { ChevronRight } from "react-feather"

import { Card } from "~/components"
import { Step } from "~/domains/transactions/_shared/components/Steps/Step"

const MAX_MOBILE_STEPS = 3
const MAX_TABLET_STEPS = 5

export interface StepI {
    key: string
    name: string
    value?: string
    isCurrentLifecycle?: boolean
    done?: boolean
    status?: "done" | "next" | string
    rejected?: boolean
    description?: string
    onClick?: (status: string) => void
    icon?: React.ReactNode
    linesInProgress?: number
    totalLines?: number
    color?: "error" | "success" | "warning" | "info" | "default"
}

interface StepsProps {
    steps: StepI[]
}

export const Steps = ({ steps }: StepsProps) => {
    const [maxItems, setMaxItems] = useState(steps.length)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const isTablet = useMediaQuery(theme.breakpoints.between("md", "xl"))

    useEffect(() => {
        const maxMobile = Math.min(steps.length, MAX_MOBILE_STEPS)
        const maxTablet = Math.min(steps.length, MAX_TABLET_STEPS)

        if (isMobile) {
            setMaxItems(maxMobile)
        } else if (isTablet) {
            setMaxItems(maxTablet)
        } else {
            setMaxItems(steps.length)
        }
    }, [isMobile, isTablet, steps.length])

    return (
        <Card className="w-full p-0">
            <Breadcrumbs separator={<ChevronRight color="var(--color-grey)" size={14} />} maxItems={maxItems}>
                {steps.map((step) => (
                    <Step
                        key={step.key}
                        name={step.name}
                        icon={step.icon}
                        status={step.status || (step.isCurrentLifecycle ? "next" : "done")}
                        description={step.description}
                        totalLines={step.totalLines}
                        linesInProgress={step.linesInProgress}
                        color={step.color}
                        onClick={step.onClick}
                    />
                ))}
            </Breadcrumbs>
        </Card>
    )
}
