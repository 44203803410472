import { Alert, Autocomplete, Checkbox, FormControl, Stack, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { Copy } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Modal, SafeFormattedMessage } from "~/components"
import { useSendFormToPartnerMutation } from "~/domains/identity/custom-forms/api/customFormsApi"
import { CustomForm } from "~/domains/identity/custom-forms/types/CustomForms"
import { useFetchPartnerContacts, useFetchPartnersData } from "~/domains/identity/partners/store/hooks"
import { PartnerProfileContactI, PartnersDataI } from "~/domains/identity/partners/types"

const SURVEY_URL = `${import.meta.env.VITE_APP_URL}custom-forms/survey/`

interface Props {
    open: boolean
    close: () => void
    organizationId: string
    form?: CustomForm
}

const messages = defineMessages({
    sendForm: {
        id: "smartForms.sendForm",
        defaultMessage: "Send form {formName}",
    },
    selectContact: {
        id: "smartForms.selectContact",
        defaultMessage: "Select contact",
    },
    contact: {
        id: "smartForms.contact",
        defaultMessage: "Contact",
    },
    partner: {
        id: "smartForms.partner",
        defaultMessage: "Partner",
    },
    buttonSend: {
        id: "smartForms.buttonSend",
        defaultMessage: "Send",
    },
    formSent: {
        id: "smartForms.formSent",
        defaultMessage: "Form sent",
    },
    viewFormInstructions: {
        id: "smartForms.viewFormInstructions",
        defaultMessage: "Use this link to view the form:",
    },
    copyLink: {
        id: "smartForms.copyLink",
        defaultMessage: "Copy link",
    },
})

export const SendFormPartnerSelect = ({ open, close, organizationId, form }: Props) => {
    const [selectedPartner, setSelectedPartner] = useState<PartnersDataI | null>(null)
    const [selectedContact, setSelectedContact] = useState<PartnerProfileContactI | null>(null)
    const [formSent, setFormSent] = useState(false)
    const [formLink, setFormLink] = useState("")

    const { partnersData } = useFetchPartnersData(organizationId, true)
    const [sendFormToPartner, { isLoading: loadingSendToPartner, data: sendToPartnerResponse }] =
        // @ts-expect-error - for some reason organisationId is not recognized as a prop
        useSendFormToPartnerMutation({ organisationId: organizationId, formId: form?.id })

    const { contacts } = useFetchPartnerContacts(organizationId, selectedPartner?.organizationId)

    const { formatMessage } = useIntl()

    const handlePartnerSelect = (event, value) => {
        setSelectedPartner(value)
    }

    const handleContactSelect = (event, value) => {
        contacts && setSelectedContact(value)
    }

    useEffect(() => {
        if (open) {
            setFormSent(false)
            setSelectedContact(null)
            setSelectedPartner(null)
        }
    }, [open])

    useEffect(() => {
        setSelectedContact(null)
    }, [partnersData])

    useEffect(() => {
        if (contacts?.length === 1) {
            setSelectedContact(contacts[0])
        }
    }, [contacts])

    useEffect(() => {
        if (sendToPartnerResponse) {
            setFormSent(true)
            setSelectedContact(null)
            setSelectedPartner(null)
            setFormLink(`${SURVEY_URL}${sendToPartnerResponse.token}`)
        }
    }, [sendToPartnerResponse])

    const handleSendForm = async () => {
        if (selectedPartner && selectedContact && form) {
            sendFormToPartner({
                respondentContacts: [
                    {
                        userId: selectedContact.id,
                        email: selectedContact.id ? undefined : selectedContact.email || "", // if userId is specified, do not send the email
                    },
                ],
                respondentOrganizationId: selectedPartner.organizationId,
                organisationId: organizationId,
                formId: form?.id || "",
            })
        }
    }

    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <SafeFormattedMessage {...messages.sendForm} values={{ formName: form?.name || "" }} />
                </Typography>
            </Modal.Header>
            <Stack>
                {formSent ? (
                    <Stack display="flex" minHeight={200}>
                        <Alert severity="success">
                            <p>
                                <strong>
                                    <SafeFormattedMessage {...messages.formSent} />
                                </strong>
                            </p>
                            <p className="mt-12">
                                <SafeFormattedMessage {...messages.viewFormInstructions} />
                            </p>
                            <p>
                                <strong>{formLink}</strong>
                            </p>
                        </Alert>
                    </Stack>
                ) : (
                    <>
                        <FormControl className="mt-12 mb-12">
                            <h4 className="mb-12">
                                <SafeFormattedMessage {...messages.partner} />
                            </h4>
                            {partnersData && (
                                <Autocomplete
                                    onChange={handlePartnerSelect}
                                    options={partnersData}
                                    fullWidth
                                    getOptionKey={(option) => option.organizationId}
                                    getOptionLabel={(option) => option.organizationName}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.organizationId}>
                                            {option.organizationName}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={formatMessage(messages.partner)} />
                                    )}
                                />
                            )}
                        </FormControl>
                        {selectedPartner && (
                            <FormControl className="mt-12 mb-12">
                                <h4 className="mb-12">
                                    <SafeFormattedMessage {...messages.contact} />
                                </h4>
                                {contacts && (
                                    <Autocomplete
                                        onChange={handleContactSelect}
                                        options={contacts}
                                        fullWidth
                                        contentEditable={false}
                                        getOptionKey={(option) => option.id}
                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                        renderOption={(props, option, { selected }) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                                    {`${option.firstName} ${option.lastName} (${option.email})`}
                                                </li>
                                            )
                                        }}
                                        renderInput={(params) => <TextField {...params} label="partner" disabled />}
                                    />
                                )}
                            </FormControl>
                        )}
                    </>
                )}
            </Stack>
            <Modal.Footer className="mt-12">
                {selectedPartner && selectedContact && (
                    <FormControl>
                        <Button type="primary" onClick={handleSendForm} disabled={loadingSendToPartner}>
                            <SafeFormattedMessage {...messages.buttonSend} />
                        </Button>
                    </FormControl>
                )}
                {formSent && (
                    <CopyToClipboard text={formLink}>
                        <Button type="primary">
                            <Copy size={14} />
                            <SafeFormattedMessage {...messages.copyLink} />
                        </Button>
                    </CopyToClipboard>
                )}
                <Button onClick={close} type="neutral">
                    <SafeFormattedMessage id="common.close" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
