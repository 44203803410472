import { Box, IconButton, Stack, Tooltip } from "@mui/material"
import { FC } from "react"
import { Check, Info, RotateCcw, X } from "react-feather"
import { useIntl } from "react-intl"

import { Loader, TooltipWhite } from "~/components"
import { useApprovalReview } from "~/domains/orchestration/flows-v0/hooks/useApprovalReview"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"

import { messages } from "./ApprovalBlock"
import { ApprovalCheck } from "./ApprovalCheck"

interface Props {
    organisationId: string
    objectId: string
    objectType: ApprovalObjectType
    className?: string
    showRetract: boolean
    showRetractAll: boolean
    onApproved: () => void
    onRefused: () => void
    onRetract: () => void
    onRetractAll: () => void
    onReviewed?: (result: boolean) => void
    onApprovalRequired?: () => void
}

export const UserReviewLine: FC<Props> = ({
    organisationId,
    objectId,
    objectType,
    onReviewed,
    onApproved,
    onRefused,
    onRetract,
    onApprovalRequired,
    onRetractAll,
    showRetract = true,
    showRetractAll = false,
}) => {
    const { formatMessage } = useIntl()

    const {
        loading,
        isReviewing,
        reviewed,
        progressResponse,
        users,
        teams,
        retractLoading,
        retractAllLoading,
        handleRefuse,
        handleApprove,
        handleUpdateApproval,
        handleRetractAll,
        isApprovalRequired,
        isCurrentUserApprovalRequired,
    } = useApprovalReview({
        organisationId,
        objectId,
        objectType,
        onReviewed,
        onApproved,
        onRefused,
        onRetract,
        onRetractAll,
        onApprovalRequired,
    })

    const sendRefusal = () => {
        handleRefuse()
    }

    if (loading) return <Loader small />

    if (!progressResponse || !isApprovalRequired) return "-"

    return (
        <Stack flexDirection="row" alignItems="center" gap={1}>
            <TooltipWhite
                arrow
                title={
                    <Box width={250}>
                        {progressResponse.checks.map((check, index) => (
                            <ApprovalCheck
                                isOpen
                                check={check}
                                key={`${check.name}-${index}`}
                                users={users}
                                teams={teams}
                            />
                        ))}
                    </Box>
                }
            >
                <Info size={16} color="var(--color-grey)" />
            </TooltipWhite>
            {isCurrentUserApprovalRequired &&
                (reviewed ? (
                    <>
                        {showRetract && (
                            <Tooltip arrow title={formatMessage(messages.retractReview)}>
                                <IconButton color="info" onClick={handleUpdateApproval} disabled={retractLoading}>
                                    <X size={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {showRetractAll && (
                            <Tooltip arrow title={formatMessage(messages.retractAllReviews)}>
                                <IconButton color="error" onClick={handleRetractAll} disabled={retractAllLoading}>
                                    <RotateCcw size={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <>
                        <Tooltip arrow title={formatMessage(messages.approve)}>
                            <IconButton color="success" onClick={handleApprove} disabled={isReviewing}>
                                <Check size={20} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title={formatMessage(messages.refuse)}>
                            <IconButton color="error" onClick={sendRefusal} disabled={isReviewing}>
                                <X size={20} />
                            </IconButton>
                        </Tooltip>
                    </>
                ))}
        </Stack>
    )
}
