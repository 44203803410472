import { Stack, Typography } from "@mui/material"
import { FileText } from "react-feather"
import { Link, generatePath } from "react-router-dom"
import { validate as isUUID } from "uuid"

import { Loader } from "~/components"
import { PREFIX_INVOICE } from "~/constants"
import { useGetPaymentObjectsQuery } from "~/domains/payment/payment/api/paymentApi"
import { INVOICE_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"

interface PaymentLinkedTransactionProps {
    paymentId: string
    transactionNumber?: string
}

export const PaymentLinkedTransaction = ({ paymentId, transactionNumber }: PaymentLinkedTransactionProps) => {
    const { data, isLoading } = useGetPaymentObjectsQuery({ payment_id: paymentId }, { skip: !paymentId })
    const paymentObject = data?.items?.[0] ?? null
    const transactionId = paymentObject?.object_id ?? null
    const label = `${PREFIX_INVOICE} ${transactionNumber ? `-${transactionNumber}` : ""}`

    if (isLoading) {
        return <Loader small />
    }

    if (!paymentObject || !transactionId) {
        return "-"
    }

    const path = isUUID(transactionId)
        ? generatePath(INVOICE_ROUTE, { invoiceId: transactionId })
        : generatePath(INVOICE_BUYER_ROUTE, { invoiceId: transactionId })

    return (
        <Link to={path} target="_blank">
            <Stack direction="row" gap={1} alignItems="center">
                <FileText color="var(--color-primary)" />
                <Typography variant="body2" component="span" color="var(--color-primary)">
                    {label}
                </Typography>
            </Stack>
        </Link>
    )
}
