import { MessageDescriptor, defineMessages } from "react-intl"

import {
    CreateRole,
    DomainName,
    RoleAssignedToType,
    RoleOrTemplate,
    RoleTemplate,
    RoleWithEntityIds,
    ScopeName,
    permissionErrorType,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { AuthorizationName, TeamId, UserId } from "~/types"

export const authorizationMessages: Record<AuthorizationName, MessageDescriptor> = {
    [AuthorizationName.READ]: {
        id: "common.permission.read",
        defaultMessage: "read",
    },
    [AuthorizationName.VIEW]: {
        id: "common.permission.view",
        defaultMessage: "view",
    },
    [AuthorizationName.CREATE]: {
        id: "common.permission.create",
        defaultMessage: "create",
    },
    [AuthorizationName.UPDATE]: {
        id: "common.permission.update",
        defaultMessage: "update",
    },
    [AuthorizationName.DELETE]: {
        id: "common.permission.delete",
        defaultMessage: "delete",
    },
    [AuthorizationName.EDIT]: {
        id: "common.permission.update",
        defaultMessage: "update",
    },
    [AuthorizationName.APPROVE]: {
        id: "common.permission.approve",
        defaultMessage: "approve",
    },
    [AuthorizationName.CONVERT]: {
        id: "common.permission.convert",
        defaultMessage: "convert",
    },
    [AuthorizationName.CONVERT_TO_PO]: {
        id: "common.permission.convert",
        defaultMessage: "convert",
    },
    [AuthorizationName.CONVERT_TO_PO_API]: {
        id: "common.permission.convert",
        defaultMessage: "convert",
    },
    [AuthorizationName.CHECK]: {
        id: "common.permission.check",
        defaultMessage: "check",
    },
    [AuthorizationName.OWNER]: {
        id: "common.permission.owner",
        defaultMessage: "owner",
    },
    [AuthorizationName.SUBMIT]: {
        id: "common.permission.submit",
        defaultMessage: "submit",
    },
    [AuthorizationName.SEND]: {
        id: "common.permission.send",
        defaultMessage: "send",
    },

    [AuthorizationName.CLOSE]: {
        id: "common.permission.close",
        defaultMessage: "close",
    },
    [AuthorizationName.REOPEN]: {
        id: "common.permission.reopen",
        defaultMessage: "reopen",
    },
    [AuthorizationName.MARK_LINE_IN_PREPARATION]: {
        id: "common.permission.markLineInPreparation",
        defaultMessage: "mark line in preparation",
    },
    [AuthorizationName.MARK_LINE_IN_TRANSIT]: {
        id: "common.permission.markLineInTransit",
        defaultMessage: "mark line in transit",
    },
    [AuthorizationName.MARK_LINE_DELIVERED_OR_RECEIVED]: {
        id: "common.permission.markLineDeliveredOrReceived",
        defaultMessage: "mark line delivered or received",
    },
    [AuthorizationName.MARK_LINE_CANCELED]: {
        id: "common.permission.markLineCanceled",
        defaultMessage: "mark line canceled",
    },
}

export const scopeMessages: Record<ScopeName, MessageDescriptor> = {
    [ScopeName.ORGANIZATIONS]: {
        id: "rolesPermissions.permission.scope.organizations",
        defaultMessage: "Organizations",
    },
    [ScopeName.USERS]: {
        id: "rolesPermissions.permission.scope.users",
        defaultMessage: "Users",
    },
    [ScopeName.TEAMS]: {
        id: "rolesPermissions.permission.scope.teams",
        defaultMessage: "Teams",
    },
    [ScopeName.TAGS]: {
        id: "rolesPermissions.permission.scope.tags",
        defaultMessage: "Tags",
    },
    [ScopeName.ROLES_PERMISSIONS]: {
        id: "rolesPermissions.permission.scope.rolesPermissions",
        defaultMessage: "Roles & permissions",
    },
    [ScopeName.BOOK_OF_RELATIONS]: {
        id: "rolesPermissions.permission.scope.bookOfRelations",
        defaultMessage: "Partners network",
    },
    [ScopeName.PURCHASE_REQUESTS]: {
        id: "rolesPermissions.permission.scope.purchaseRequests",
        defaultMessage: "Purchase requests",
    },
    [ScopeName.INVOICES]: {
        id: "rolesPermissions.permission.scope.invoices",
        defaultMessage: "Invoices",
    },
    [ScopeName.INVOICE_LIFECYCLE]: {
        id: "rolesPermissions.permission.scope.invoiceLifecycle",
        defaultMessage: "Invoice lifecycle",
    },
    [ScopeName.WORKFLOWS]: {
        id: "rolesPermissions.permission.scope.workflows",
        defaultMessage: "Workflows",
    },
    [ScopeName.PURCHASE_ORDERS]: {
        id: "rolesPermissions.permission.scope.purchaseOrders",
        defaultMessage: "Purchase orders",
    },
    [ScopeName.BUDGETS]: {
        id: "rolesPermissions.permission.scope.budgets",
        defaultMessage: "Budgets",
    },
    [ScopeName.PAYMENTS]: {
        id: "rolesPermissions.permission.scope.payments",
        defaultMessage: "Payments",
    },
    [ScopeName.CATALOGS]: {
        id: "rolesPermissions.permission.scope.catalogs",
        defaultMessage: "Catalogs",
    },
}

export const errorMessages: Record<permissionErrorType, MessageDescriptor> = {
    [permissionErrorType.PERMISSION_ID_ALREADY_USED]: {
        id: "rolesPermissions.permission.error.PERMISSION_ID_ALREADY_USED",
        defaultMessage: "This permission ID is already used",
    },
    [permissionErrorType.PERMISSION_ENTITY_NOT_VALID]: {
        id: "rolesPermissions.permission.error.PERMISSION_ENTITY_NOT_VALID",
        defaultMessage: "The permission entity provided is not valid",
    },
    [permissionErrorType.ORGANIZATION_ID_NOT_VALID]: {
        id: "rolesPermissions.permission.error.ORGANIZATION_ID_NOT_VALID",
        defaultMessage: "The organizationId provided is not valid",
    },
    [permissionErrorType.PERMISSION_ID_NOT_WELL_FORMATED]: {
        id: "rolesPermissions.permission.error.PERMISSION_ID_NOT_WELL_FORMATED",
        defaultMessage: "The ID is not well formated. It must be: <authorization>:<domain>:<scope>",
    },
    [permissionErrorType.AUTHORIZATION_NAME_UNKNOWN]: {
        id: "rolesPermissions.permission.error.AUTHORIZATION_NAME_UNKNOWN",
        defaultMessage: "The authorization in name is unknown.",
    },
    [permissionErrorType.DOMAIN_NAME_UNKNOWN]: {
        id: "rolesPermissions.permission.error.DOMAIN_NAME_UNKNOWN",
        defaultMessage: "The domain in name is unknown.",
    },
    [permissionErrorType.CANT_UPDATE_PREDEFINED_ROLE]: {
        id: "rolesPermissions.permission.error.CANT_UPDATE_PREDEFINED_ROLE",
        defaultMessage: "This role is predefined by Flowie. You can't update it.",
    },
    [permissionErrorType.CANT_DELETE_PREDEFINED_ROLE]: {
        id: "rolesPermissions.permission.error.CANT_DELETE_PREDEFINED_ROLE",
        defaultMessage: "This role is predefined by Flowie. You can't delete it.",
    },
    [permissionErrorType.UPDATE_ROLE_MAX_RETRY]: {
        id: "rolesPermissions.permission.error.UPDATE_ROLE_MAX_RETRY",
        defaultMessage: "Failed to update role after maximum retries.",
    },
    [permissionErrorType.CREATE_ROLE_MAX_RETRY]: {
        id: "rolesPermissions.permission.error.CREATE_ROLE_MAX_RETRY",
        defaultMessage: "Failed to add role after maximum retries.",
    },
    [permissionErrorType.ROLE_ENTITY_NOT_VALID]: {
        id: "rolesPermissions.permission.error.ROLE_ENTITY_NOT_VALID",
        defaultMessage: "The role entity provided is not valid",
    },
    [permissionErrorType.USER_ROLE_ASSOCIATION_NOT_FOUND]: {
        id: "rolesPermissions.permission.error.USER_ROLE_ASSOCIATION_NOT_FOUND",
        defaultMessage: "User/Role association not found",
    },
    [permissionErrorType.TEAM_ROLE_ASSOCIATION_NOT_FOUND]: {
        id: "rolesPermissions.permission.error.TEAM_ROLE_ASSOCIATION_NOT_FOUND",
        defaultMessage: "Team/Role association not found",
    },
    [permissionErrorType.ROLE_NOT_FOUND]: {
        id: "rolesPermissions.permission.error.ROLE_NOT_FOUND",
        defaultMessage: "Role not found",
    },
    [permissionErrorType.ADD_ROLE_PERMISSION_MAX_RETRY]: {
        id: "rolesPermissions.permission.error.ADD_ROLE_PERMISSION_MAX_RETRY",
        defaultMessage: "Failed to add permission to role after maximum retries.",
    },
    [permissionErrorType.PERMISSION_DATA_PROVIDED_NOT_VALID]: {
        id: "rolesPermissions.permission.error.PERMISSION_DATA_PROVIDED_NOT_VALID",
        defaultMessage: "The permission data provided is not valid",
    },
    [permissionErrorType.ROLE_DATA_PROVIDED_NOT_VALID]: {
        id: "rolesPermissions.permission.error.ROLE_DATA_PROVIDED_NOT_VALID",
        defaultMessage: "The role data provided is not valid",
    },
}

export const permissionMessages = defineMessages({
    permissionDescription: {
        id: "rolesPermissions.permission.scope.permissionDescription",
        defaultMessage: "Can {permissionString} {scope}",
    },
    errorPermission: {
        id: "rolesPermissions.permission.scope.errorPermission",
        defaultMessage: "You can't {permissionString} {scope}. Ask for the access to your administrator.",
    },
    errorNoAccess: {
        id: "rolesPermissions.permission.scope.errorNoAccess",
        defaultMessage: "You don't have access to this section.",
    },
    errorNoAccessAdministrator: {
        id: "rolesPermissions.permission.scope.errorNoAccessAdministrator",
        defaultMessage: "You don't have access to this section. Please contact your administrator and ask for access.",
    },
})

export const getPermissionIdString = (
    permission: string,
    domainName: DomainName,
    scope: ScopeName,
    formatMessage: (message: MessageDescriptor) => string
): string => {
    const regExp = new RegExp(`(^${Object.values(AuthorizationName).join("|")}):${domainName}:${scope}`, "")
    const match = permission.match(regExp)
    return match && match[1] ? formatMessage(authorizationMessages[match[1]]) : ""
}

export const isCreateRole = (role: RoleOrTemplate | CreateRole): role is CreateRole => {
    return !isRoleOrTemplate(role)
}

export const isRoleWithEntityIds = (role: RoleOrTemplate | CreateRole): role is RoleWithEntityIds => {
    return "id" in role && "roleTemplateId" in role && "userIds" in role
}

export const isRoleTemplate = (role: RoleOrTemplate | CreateRole): role is RoleTemplate => {
    return "id" in role && "isRoleTemplate" in role && role.isRoleTemplate
}

export const isRoleOrTemplate = (role: RoleOrTemplate | CreateRole): role is RoleOrTemplate => {
    return "id" in role && "isRoleTemplate" in role
}

export const getRoleFromRoleTemplate = (
    roleTemplate: RoleTemplate,
    roles: RoleWithEntityIds[]
): RoleWithEntityIds | undefined => {
    return roles.find((r) => r.roleTemplateId === roleTemplate.id)
}

export const getUserIdsOfRoleTemplate = (roleTemplate: RoleTemplate, roles: RoleWithEntityIds[]): UserId[] => {
    const role = getRoleFromRoleTemplate(roleTemplate, roles)
    return role?.userIds ?? []
}

export const getTeamIdsOfRoleTemplate = (roleTemplate: RoleTemplate, roles: RoleWithEntityIds[]): TeamId[] => {
    const role = getRoleFromRoleTemplate(roleTemplate, roles)
    return role?.teamIds ?? []
}

export const getRoleEntityIds = (
    entityType: RoleAssignedToType,
    role: RoleOrTemplate | CreateRole,
    roles: RoleWithEntityIds[],
    pendingObjects: string[]
): string[] => {
    if (entityType === RoleAssignedToType.USER) {
        if (isRoleTemplate(role)) return getUserIdsOfRoleTemplate(role, roles)
        if (isRoleWithEntityIds(role)) return role.userIds ?? []
    } else {
        if (isRoleTemplate(role)) return getTeamIdsOfRoleTemplate(role, roles)
        if (isRoleWithEntityIds(role)) return role.teamIds ?? []
    }
    return pendingObjects
}

export const separateRoleIdsAndTemplateIds = (roles: RoleOrTemplate[]) => {
    return roles.reduce(
        (acc, role) => {
            role.isRoleTemplate ? acc.templateIds.push(role.id) : acc.roleIds.push(role.id)
            return acc
        },
        { roleIds: [] as string[], templateIds: [] as string[] }
    )
}

export const getRolesToAddAndRemove = (
    newRoles: RoleOrTemplate[],
    originalRoles: RoleOrTemplate[],
    organizationRoles: RoleWithEntityIds[]
) => {
    const isRolePresent = (roleId: string, roles: RoleOrTemplate[]) => roles.some((role) => role.id === roleId)
    const getRoleBasedOnTemplate = (templateId: string) =>
        organizationRoles.find((r) => r.roleTemplateId === templateId)

    const rolesToAdd = newRoles.filter((roleOrTemplate) => {
        if (isRolePresent(roleOrTemplate.id, originalRoles)) return false

        if (isRoleTemplate(roleOrTemplate)) {
            const roleBasedOnTemplate = getRoleBasedOnTemplate(roleOrTemplate.id)
            if (roleBasedOnTemplate && isRolePresent(roleBasedOnTemplate.id, originalRoles)) {
                return false
            }
        }

        return true
    })

    const rolesToRemove = originalRoles.filter((originalRole) => {
        return !newRoles.some((role) => {
            if (isRoleWithEntityIds(role)) {
                return originalRole.id === role.id
            }

            const roleBasedOnTemplate = getRoleBasedOnTemplate(role.id)
            return originalRole.id === roleBasedOnTemplate?.id
        })
    })

    return { rolesToAdd, rolesToRemove }
}
