import { TextField } from "@mui/material"
import { Stack } from "@mui/material"
import { MenuItem } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"
import { statusMessages } from "~/constants/globalStatus"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, SetPoFulfillmentStatusNode } from "~/domains/orchestration/flows/types"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const SetPoFulfillmentStatusConfiguration: FC<ConfigurationProps<SetPoFulfillmentStatusNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const { formatMessage } = useIntl()

    const handleChangeValueToUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const statusToSet = e.target.value as FulfillmentStatus
        setCurrentNode({ ...currentNode, statusToSet: statusToSet })
    }
    const renderEnumOptionItems = () => {
        return FulfillmentStatus.filter((key) => key !== "PENDING").map((key) => {
            return (
                <MenuItem key={key} value={key}>
                    <SafeFormattedMessage {...statusMessages[key]} />
                </MenuItem>
            )
        })
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.setPoFulfillmentStatusConfiguration.fieldTitle} />

                <TextField
                    select
                    required
                    label={formatMessage(messages.setPoFulfillmentStatusConfiguration.valueLabel)}
                    value={currentNode.statusToSet || ""}
                    onChange={handleChangeValueToUpdate}
                    fullWidth
                >
                    {renderEnumOptionItems()}
                </TextField>
            </Stack>
            <AdvancedFields<SetPoFulfillmentStatusNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
