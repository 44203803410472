import { GridDensity, GridInitialState } from "@mui/x-data-grid-premium"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PersistConfig, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { ResizableGridState } from "~/components/Layout/ResizableGridContext"
import { TagGroupI } from "~/domains/analytics/tags/types/TagGroup"
import { VatRateI } from "~/domains/transactions/invoices-v1/types/VatRate"
import { RootState } from "~/store"

export enum SupportedLanguages {
    FR = "fr",
    EN = "en",
    AR = "ar",
    CH = "ch",
    DE = "de",
    NO = "no",
    PL = "pl",
    BR = "br",
}

export enum GlobalFilterType {
    TAGS = "TAGS",
}

export interface TagFilterProps {
    tagGroups: TagGroupI[]
}

export interface GlobalFilterProps {
    [GlobalFilterType.TAGS]: TagFilterProps
}

export const SUPPORTED_LANGUAGES = [
    SupportedLanguages.FR,
    SupportedLanguages.EN,
    SupportedLanguages.AR,
    SupportedLanguages.CH,
    SupportedLanguages.DE,
    SupportedLanguages.NO,
    SupportedLanguages.PL,
    SupportedLanguages.BR,
]

interface DataGridCommonStateInterface {
    density: GridDensity
}

interface StateInterface {
    displayMenu: boolean
    displayModalTerms: boolean
    lang: SupportedLanguages
    keepSidebarOpened: boolean
    vatRates?: VatRateI[]
    fetchingVatRates: boolean
    darkMode: boolean
    filterTypes: GlobalFilterType[]
    dataGridState: {
        [key: string]: GridInitialState
    }
    dataGridCommonState: DataGridCommonStateInterface
    resizableGridState: {
        [key: string]: {
            [index: number]: number
        }
    }
}

const globalPersistConfig: PersistConfig<StateInterface> = {
    key: "global",
    storage: storage,
}

const initialState: StateInterface = {
    displayMenu: false,
    displayModalTerms: false,
    lang: SupportedLanguages.FR,
    keepSidebarOpened: true,
    fetchingVatRates: false,
    darkMode: false,
    filterTypes: [],
    dataGridState: {},
    dataGridCommonState: {
        density: "standard",
    },
    resizableGridState: {},
}

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setDisplayMenu(state) {
            state.displayMenu = !state.displayMenu
        },
        setLang(state, action: PayloadAction<SupportedLanguages>) {
            state.lang = action.payload
        },
        setDisplayModalTerms(state, action: PayloadAction<boolean>) {
            state.displayModalTerms = action.payload
        },
        toggleKeepSidebarOpened(state) {
            state.keepSidebarOpened = !state.keepSidebarOpened
        },
        fetchVatRates(state) {
            state.fetchingVatRates = true
        },
        fetchVatRatesSuccess(state, action: PayloadAction<VatRateI[]>) {
            state.fetchingVatRates = false
            state.vatRates = action.payload
        },
        fetchVatRatesFailure(state) {
            state.fetchingVatRates = false
        },
        setDarkMode(state, action: PayloadAction<boolean>) {
            state.darkMode = action.payload
        },
        setFilterTypes(state, action: PayloadAction<GlobalFilterType[]>) {
            state.filterTypes = action.payload
        },
        resetFilterTypes(state) {
            state.filterTypes = []
        },
        setDataGridState(state, action: PayloadAction<{ stateKey: string; state: GridInitialState }>) {
            state.dataGridState[action.payload.stateKey] = action.payload.state
        },
        setDataGridCommonState(state, action: PayloadAction<DataGridCommonStateInterface>) {
            state.dataGridCommonState = action.payload
        },
        setResizableGridState(state, action: PayloadAction<{ stateKey: string; index: number; size: number }>) {
            state.resizableGridState[action.payload.stateKey] = {
                ...(state.resizableGridState[action.payload.stateKey] || {}),
                [action.payload.index]: action.payload.size,
            }
        },
    },
})

export const globalActions = globalSlice.actions

export const selectDisplayMenu = (state: RootState) => state.global.displayMenu
export const selectDisplayModalTerms = (state: RootState) => state.global.displayModalTerms
export const selectLang = (state: RootState) => state.global.lang
export const selectKeepSidebarOpened = (state: RootState) => state.global.keepSidebarOpened
export const selectVatRates = (state: RootState) => state.global.vatRates
export const selectFetchingVatRates = (state: RootState) => state.global.fetchingVatRates

export const selectDarkMode = (state: RootState) => state.global.darkMode

export const selectFilterTypes = (state: RootState) => state.global.filterTypes

export const selectDataGridStateByStateKey = (state: RootState, stateKey: string) =>
    state.global.dataGridState[stateKey]

export const selectDataGridCommonState = (state: RootState) => state.global.dataGridCommonState

export const selectResizableGridState = (state: RootState, stateKey: string): ResizableGridState =>
    state.global.resizableGridState[stateKey]

export const globalReducer = globalSlice.reducer

export default persistReducer(globalPersistConfig, globalReducer)
