import { Grid, Stack } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useEffect, useMemo } from "react"
import { AlertTriangle, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { batch } from "react-redux"
import { toast } from "react-toastify"

import { Button, Card, Modal, SafeFormattedMessage, Size } from "~/components"
import { useDocumentById } from "~/domains/identity/documents/hooks/useDocumentById"
import {
    useCreatePaymentMethodDetailsMutation,
    useRequestManualVerificationMutation,
    useUpdatePaymentMethodDetailsMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import {
    PaymentMethodFormBankTransfer,
    PaymentMethodFormCard,
    PaymentMethodFormDirectDebit,
    PaymentMethodUpload,
} from "~/domains/payment/payment-method-details/components"
import {
    selectSelectedItem,
    selectSelectedPaymentMethod,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { paymentMethodDetailsActions } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import {
    BankTransferFormState,
    CardFormState,
    CreatePaymentMethodDetails,
    DirectDebitFormState,
    FormState,
    ManualVerification,
    PaymentMethodDetails,
    PaymentMethodDetailsFormType,
    VerificationMethod,
} from "~/domains/payment/payment-method-details/types"
import { mapToSave } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { useGetPaymentMethodsQuery } from "~/domains/payment/payment-methods/api/paymentMethodsApi"
import { PaymentMethodsSelector } from "~/domains/payment/payment-methods/components"
import { PaymentMethod, PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    addTitle: {
        id: "payment.paymentMethodDetailsManagement.addTitle",
        defaultMessage: "Add payment method",
    },
    editTitle: {
        id: "payment.paymentMethodDetailsManagement.editTitle",
        defaultMessage: "Edit payment method",
    },
    selectMessage: {
        id: "payment.paymentMethodDetailsManagement.selectMessage",
        defaultMessage: "Please select a payment method",
    },
    notSupportedMessage: {
        id: "payment.paymentMethodDetailsManagement.notSupportedMessage",
        defaultMessage: "This payment method is not supported yet",
    },
    successMessage: {
        id: "payment.paymentMethodDetailsManagement.successMessage",
        defaultMessage: "Payment method saved successfully",
    },
    errorMessage: {
        id: "payment.paymentMethodDetailsManagement.errorMessage",
        defaultMessage: "An error occurred while saving the payment method",
    },
    paymentMethod: {
        id: "payment.paymentMethodDetailsManagement.paymentMethod",
        defaultMessage: "Payment method",
    },
})

const supportedPaymentMethods = [
    PaymentMethodType.CARD,
    PaymentMethodType.BANK_TRANSFER,
    PaymentMethodType.DIRECT_DEBIT,
]

interface PaymentMethodDetailsCreateOrEditModalProps {
    open: boolean
    activeTab: PaymentMethodType
    organizationId: string
    formType?: PaymentMethodDetailsFormType
    onPaymentMethodDetailsCreated?: (data: PaymentMethodDetails) => void
    onClose: () => void
    onOpen?: () => void
}

export const PaymentMethodDetailsCreateOrEditModal = ({
    open,
    organizationId,
    activeTab,
    onPaymentMethodDetailsCreated,
    onClose,
    onOpen,
    formType = PaymentMethodDetailsFormType.MODAL,
}: PaymentMethodDetailsCreateOrEditModalProps) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [createPaymentMethodDetails, { isLoading: createLoading }] = useCreatePaymentMethodDetailsMutation()
    const [updatePaymentMethodDetails, { isLoading: updateLoading }] = useUpdatePaymentMethodDetailsMutation()

    const { data: paymentMethods, isLoading: paymentMethodsLoading } = useGetPaymentMethodsQuery(null)
    const [requestManualVerification] = useRequestManualVerificationMutation()

    const selectedPaymentMethodDetails = useAppSelector(selectSelectedItem)
    const selectedPaymentMethod = useAppSelector(selectSelectedPaymentMethod)
    const paymentMethodType = selectedPaymentMethod?.payment_method_type ?? PaymentMethodType.BANK_TRANSFER
    const paymentMethodBasedOnTab = paymentMethods?.items.find((item) => item.payment_method_type === activeTab)

    const { document } = useDocumentById(selectedPaymentMethodDetails?.documentId)

    const selectedPaymentMethodType =
        selectedPaymentMethod?.id ||
        selectedPaymentMethodDetails?.paymentMethodId ||
        paymentMethodBasedOnTab?.id ||
        null

    const handleClose = (_: React.SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason && reason === "backdropClick") return
        clearStates()
    }

    const clearStates = () => {
        batch(() => {
            dispatch(paymentMethodDetailsActions.setSelectedItem(null))
            dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(null))
        })
        onClose()
    }

    const handleSave = async (values: FormState, resetForm: () => void) => {
        const paymentMethodDetailsId = selectedPaymentMethodDetails?.id
        const payload = mapToSave(
            {
                ...values,
                paymentMethodId: (selectedPaymentMethod?.id || paymentMethodBasedOnTab?.id) ?? null,
                paymentMethodType: selectedPaymentMethod?.payment_method_type ?? activeTab,
            },
            organizationId,
            paymentMethodDetailsId
        )

        try {
            if (paymentMethodDetailsId) {
                await updatePaymentMethodDetails(payload as PaymentMethodDetails).unwrap()
            } else {
                const newPaymentMethodDetails = await createPaymentMethodDetails(
                    payload as CreatePaymentMethodDetails
                ).unwrap()

                if (newPaymentMethodDetails?.id && selectedPaymentMethodDetails?.documentId && document) {
                    const { id, name, latestFileVersion } = document
                    const verificationPayload: ManualVerification = {
                        payment_method_details_id: newPaymentMethodDetails.id,
                        verification_method: VerificationMethod.DOCUMENT,
                        verification_documents: {
                            name,
                            url: latestFileVersion,
                            document_id: id,
                        },
                    }

                    await requestManualVerification(verificationPayload)
                }

                if (onPaymentMethodDetailsCreated) {
                    onPaymentMethodDetailsCreated(newPaymentMethodDetails)
                }
            }

            clearStates()
            resetForm()
            toast.success(formatMessage(messages.successMessage))
        } catch (error) {
            toast.error(formatMessage(messages.errorMessage))
            Sentry.captureException(error, {
                extra: {
                    organizationId,
                    payload,
                },
            })
        }
    }

    const handlePaymentMethodChange = (paymentMethod: PaymentMethod | null) => {
        dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(paymentMethod))
    }

    const handleCancel = (resetForm: () => void) => {
        clearStates()
        resetForm()
    }

    const renderForm = useMemo(
        () => (
            <Stack flexGrow={1} gap={2}>
                <PaymentMethodsSelector
                    loading={paymentMethodsLoading}
                    items={paymentMethods?.items ?? []}
                    value={selectedPaymentMethodType}
                    disabled={updateLoading || createLoading}
                    label={formatMessage(messages.paymentMethod)}
                    onChange={handlePaymentMethodChange}
                />
                {(paymentMethodType || activeTab) === PaymentMethodType.CARD && (
                    <PaymentMethodFormCard
                        loading={updateLoading || createLoading}
                        selectedItem={selectedPaymentMethodDetails as CardFormState}
                        onSubmit={handleSave}
                        onCancel={handleCancel}
                    />
                )}
                {(paymentMethodType || activeTab) === PaymentMethodType.BANK_TRANSFER && (
                    <PaymentMethodFormBankTransfer
                        loading={updateLoading || createLoading}
                        selectedItem={selectedPaymentMethodDetails as BankTransferFormState}
                        onSubmit={handleSave}
                        onCancel={handleCancel}
                    />
                )}
                {(paymentMethodType || activeTab) === PaymentMethodType.DIRECT_DEBIT && (
                    <PaymentMethodFormDirectDebit
                        loading={updateLoading || createLoading}
                        selectedItem={selectedPaymentMethodDetails as DirectDebitFormState}
                        onSubmit={handleSave}
                        onCancel={handleCancel}
                    />
                )}
                {!!selectedPaymentMethod &&
                    !supportedPaymentMethods.includes(selectedPaymentMethod.payment_method_type) && (
                        <>
                            <AlertTriangle size={48} color="var(--color-grey-lighter)" />
                            <SafeFormattedMessage {...messages.notSupportedMessage} />
                        </>
                    )}
                <input type="hidden" name="documentId" value={selectedPaymentMethodDetails?.documentId} />
            </Stack>
        ),
        [
            paymentMethodsLoading,
            paymentMethods,
            selectedPaymentMethod,
            selectedPaymentMethodDetails,
            paymentMethodBasedOnTab,
            updateLoading,
            createLoading,
            formatMessage,
            paymentMethodType,
            activeTab,
            handlePaymentMethodChange,
            handleSave,
            handleCancel,
        ]
    )

    useEffect(() => {
        if (selectedPaymentMethodDetails && !open) {
            const paymentMethod =
                paymentMethods?.items.find((item) => item.id === selectedPaymentMethodDetails.paymentMethodId) ?? null

            dispatch(paymentMethodDetailsActions.setSelectedPaymentMethod(paymentMethod))
            if (onOpen) {
                onOpen()
            }
        }
    }, [open, paymentMethods, selectedPaymentMethodDetails, dispatch, onOpen])

    return formType === PaymentMethodDetailsFormType.MODAL ? (
        <Modal size={Size.XXL} open={open} onClose={handleClose}>
            <Modal.Header>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <h4>
                        <SafeFormattedMessage
                            {...(selectedPaymentMethodDetails ? messages.editTitle : messages.addTitle)}
                        />
                    </h4>
                    <Button type="title" onClick={clearStates}>
                        <X size={24} color="var(--color-grey-light)" />
                    </Button>
                </Stack>
            </Modal.Header>
            <Modal.Content>
                <Grid container spacing={2}>
                    <Grid item sm={12} lg={6}>
                        {paymentMethodType === PaymentMethodType.BANK_TRANSFER && (
                            <PaymentMethodUpload organizationId={organizationId} />
                        )}
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        {renderForm}
                    </Grid>
                </Grid>
            </Modal.Content>
        </Modal>
    ) : (
        <Card>{renderForm}</Card>
    )
}
