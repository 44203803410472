import { Tooltip } from "@mui/material"
import { UserCheck } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { useUpdateFulfillmentStatusMutation } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"

const messages = defineMessages({
    delivered: {
        id: "purchase.orders.order.action.delivered",
        defaultMessage: "Mark all as delivered",
    },
    received: {
        id: "purchase.orders.order.action.received",
        defaultMessage: "Mark all as received",
    },
})

interface ReceiveOrDeliverPOProps {
    PO: PurchaseOrders
    viewType: ViewTypeI
}

export const ReceiveOrDeliverPO = ({ PO, viewType }: ReceiveOrDeliverPOProps) => {
    const { formatMessage } = useIntl()

    const organization = useAppSelector(selectCurrentOrganization)
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)
    const [updateFulfillmentStatus] = useUpdateFulfillmentStatusMutation()

    const isBuyer = viewType === ViewTypeI.buyer

    const handleDeliverOrReceive = async () => {
        if (!organization?.id) return

        const lines = PO.lines.map((line) => ({ id: line.id ?? "", quantity: line.quantity ?? 1 }))

        await updateFulfillmentStatus({
            organizationId: organization.id,
            purchaseOrderId: PO.id,
            lines,
            status: "DELIVERED",
        })
        await fetchPurchaseOrder(PO.id)
    }

    return (
        <Tooltip title={formatMessage(isBuyer ? messages.received : messages.delivered)}>
            <span>
                <Button type="primary-light" onClick={handleDeliverOrReceive}>
                    <UserCheck size={18} />
                    <SafeFormattedMessage {...(isBuyer ? messages.received : messages.delivered)} />
                </Button>
            </span>
        </Tooltip>
    )
}
