import { Edit } from "react-feather"

import { SetPoFulfillmentStatusConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    SetPoFulfillmentStatusNode as SetPoFulfillmentStatusNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setPoFulfillmentStatusNode: Omit<SetPoFulfillmentStatusNodeType, keyof Node> = {
    purchaseOrderId: `{{ trigger_event.id }}`,
    statusToSet: null,
    type: NodeType.SET_PO_FULFILLMENT_STATUS_NODE,
    nextNode: null,
}

const advancedFields: (keyof typeof setPoFulfillmentStatusNode)[] = [
    AllAdvancedFieldsUnion.PURCHASE_ORDER_ID,
    AllAdvancedFieldsUnion.STATUS_TO_SET,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.SET_PO_FULFILLMENT_STATUS_NODE) return false
    return Boolean(node.purchaseOrderId && node.statusToSet)
}

const setPurchaseOrderFulfillmentStatusConfig: Configuration<NodeType.SET_PO_FULFILLMENT_STATUS_NODE> = {
    type: NodeType.SET_PO_FULFILLMENT_STATUS_NODE,
    baseSlug: "set-po-fulfillment-status",
    Icon: Edit,
    Configuration: SetPoFulfillmentStatusConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): SetPoFulfillmentStatusNodeType => ({
        ...setPoFulfillmentStatusNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { setPurchaseOrderFulfillmentStatusConfig, advancedFields as setPurchaseOrderFulfillmentStatusAdvancedFields }
