import { isRejectedWithValue } from "@reduxjs/toolkit"
import type { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { createIntl } from "react-intl"
import { toast } from "react-toastify"

import { messages } from "~/i18n/messages"
import { ErrorMeta, RootState, ServerError } from "~/store"

export const errorLoggerMiddleware: Middleware =
    (middlewareApi: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => (next) => (action: unknown) => {
        // We need to type check before using action properties

        if (!isRejectedWithValue(action)) {
            return next(action as AnyAction)
        }

        const locale = middlewareApi.getState()?.global?.lang || "en"
        const intl = createIntl({ locale, messages: messages[locale] })

        const payload = action.payload as {
            meta?: ErrorMeta
            error?: Error
            originalStatus?: number
            data?: ServerError
        }
        const meta = payload?.meta
        const serverError = payload?.data

        // For some url, the originalStatus is 200, even if the request is rejected
        const hasErrorStatus = (payload?.originalStatus || 200) >= 400

        if (meta?.errorMessage && hasErrorStatus) {
            toast.error(intl.formatMessage(meta.errorMessage))
        } else if (serverError?.message) {
            toast.error(serverError.message)
        }

        if (meta?.catchSentryException) {
            Sentry.captureException(action.type, {
                extra: {
                    error: payload,
                },
            })
        }

        return next(action)
    }
