import { DollarSign } from "react-feather"

import { AddToBudgetConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEventTypeToTransactionType } from "~/domains/orchestration/flows/core"
import { AddToBudgetNode as AddToBudgetNodeType, Event, Node, NodeType } from "~/domains/orchestration/flows/types/"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { EditorNode } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const addToBudgetNode = (eventTriggerEventType: Event | null): Omit<AddToBudgetNodeType, keyof Node> => ({
    amount: `{{ trigger_event.totalAmountDue.EUR }}`,
    budgetId: ` `,
    transactionId: `{{ trigger_event.id }}`,
    transactionType: eventTriggerEventType ? adaptEventTypeToTransactionType(eventTriggerEventType) : null,
    failIfOverbudget: false,
    currency: `'EUR'`,
    nextNode: null,
    type: NodeType.ADD_TO_BUDGET_NODE,
})

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.AMOUNT,
    AllAdvancedFieldsUnion.TRANSACTION_ID,
    AllAdvancedFieldsUnion.TRANSACTION_TYPE,
    AllAdvancedFieldsUnion.CURRENCY,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.ADD_TO_BUDGET_NODE) return false
    return Boolean(node.budgetId && node.transactionId && node.transactionType && node.amount && node.currency)
}

const addToBudgetConfig: Configuration<NodeType.ADD_TO_BUDGET_NODE> = {
    type: NodeType.ADD_TO_BUDGET_NODE,
    baseSlug: "add_to_budget",
    Icon: DollarSign,
    Configuration: AddToBudgetConfiguration,
    Node: RegularNode,
    advancedFields,
    validateNode,
    factory: (props: FactoryProps): AddToBudgetNodeType => ({
        ...addToBudgetNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props),
        error: false,
    }),
}

export { addToBudgetConfig, advancedFields as addToBudgetAdvancedFields }
