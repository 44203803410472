import { defineMessages } from "react-intl"

export const notificationDefaultMessages = defineMessages({
    subject: {
        id: "supplier.ocrInvoice.notification.subject",
        defaultMessage: "New Invoice: {reference}",
    },
    body: {
        id: "supplier.ocrInvoice.notification.body",
        defaultMessage: `Hi {contactName} 👋🏼\n
Always a pleasure to make business together!\n
I attached the invoice 🧾`,
    },
})
