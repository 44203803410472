import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, PoStatus, SetPoStatusNode, isPoStatus } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const SetPoStatusConfiguration: FC<ConfigurationProps<SetPoStatusNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const { formatMessage } = useIntl()

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const handleStatusChange = (event: SelectChangeEvent<PoStatus>) => {
        const status = event.target.value
        if (!isPoStatus(status)) {
            return
        }
        setCurrentNode((prev) => ({
            ...prev,
            statusToSet: status,
            metadata: {
                ...prev.metadata,
                additionalInformation: formatMessage(messages.poStatus[status]),
            },
        }))
    }

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <FormControl fullWidth>
                    <InputLabel>{formatMessage(messages.setPoStatusConfiguration.statusLabel)}</InputLabel>
                    <Select
                        value={currentNode.statusToSet ?? ""}
                        onChange={handleStatusChange}
                        label={formatMessage(messages.setPoStatusConfiguration.statusLabel)}
                    >
                        {Object.values(PoStatus).map((status) => (
                            <MenuItem key={status} value={status}>
                                <SafeFormattedMessage {...messages.poStatus[status]} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>

            <AdvancedFields<SetPoStatusNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
