import { Grid } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { DocumentObjectType } from "~/domains/identity/documents/types"
import { SavePayloadType } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/types"
import { useGetPartnerApprovalStatus } from "~/domains/orchestration/flows-v0/hooks/useGetPartnerApprovalStatus"
import { Organization } from "~/domains/transactions/_shared/components/Organizations/Organization"
import { Address } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types"

const messages = defineMessages({
    from: {
        id: "purchase.organization.from",
        defaultMessage: "From",
    },
    to: {
        id: "purchase.organization.to",
        defaultMessage: "To",
    },
    supplier: {
        id: "purchase.organization.supplier",
        defaultMessage: "Supplier",
    },
    buyer: {
        id: "purchase.organization.buyer",
        defaultMessage: "Buyer",
    },
    selectOrganization: {
        id: "purchase.organization.select",
        defaultMessage: "Select an organization",
    },
})

export interface OrganizationSummary {
    name: string
    id: string | null
    type?: ViewTypeI
}

interface Props {
    from: OrganizationSummary
    to: OrganizationSummary
    updateAddressesOrContact?: (payload: SavePayloadType) => void
    billingAddress?: Address | null
    shippingAddress?: Address | null
    readOnly?: boolean
    showOrganizationSelector?: boolean
    objectType: DocumentObjectType
}

export function Organizations({
    from,
    to,
    updateAddressesOrContact,
    billingAddress,
    shippingAddress,
    readOnly,
    showOrganizationSelector,
    objectType,
}: Props) {
    const { formatMessage } = useIntl()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const fromTitleLabel = formatMessage(from.type ? messages[from.type] : messages.from)
    const toTitleLabel = formatMessage(to.type ? messages[to.type] : messages.to)

    // get approval status of the partner
    const { approvalStatus: partnerApprovalStatus } = useGetPartnerApprovalStatus({
        currentOrganizationId: currentOrganizationId ?? "",
        partnerId: to.id ?? "",
    })

    const handleUpdateAddressesOrContact = (organizationId: string | null) => (payload: SavePayloadType) => {
        if (!updateAddressesOrContact) return

        if (payload.contact && organizationId) {
            updateAddressesOrContact({ ...payload, contact: { ...payload.contact, organizationId } })
        } else {
            updateAddressesOrContact(payload)
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <Organization
                    title={fromTitleLabel}
                    name={from.name}
                    id={from.id}
                    withSendingAddress
                    billingAddress={billingAddress}
                    shippingAddress={shippingAddress}
                    onSave={handleUpdateAddressesOrContact(from.id)}
                    isCurrentUserSide={currentOrganizationId === from.id}
                    readOnly={readOnly}
                    objectType={objectType}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Organization
                    title={toTitleLabel}
                    name={to.name}
                    id={to.id}
                    showOrganizationSelector={showOrganizationSelector}
                    selectOrganizationLabel={formatMessage(messages.selectOrganization)}
                    onSave={handleUpdateAddressesOrContact(to.id)}
                    isCurrentUserSide={currentOrganizationId === to.id}
                    readOnly={readOnly}
                    objectType={objectType}
                    partnerApprovalStatus={partnerApprovalStatus}
                />
            </Grid>
        </Grid>
    )
}
