import { Connection } from "@xyflow/react"

import {
    AssignTagGroupNode,
    BranchNode,
    EditorNode,
    isAssignTagGroupNode,
    isBranchNode,
    isRegularNode,
} from "~/domains/orchestration/flows/types"

interface ConnectNodesProps {
    nodes: EditorNode[]
    params: Connection
}

// Helper function to update node with next target
const updateNodeWithTarget = (node: EditorNode, target: string) => ({
    ...node,
    nextNode: target,
})

// Helper function to update branch node
const updateBranchNode = (node: BranchNode, target: string, isDefault: boolean, handleIndex?: number) => {
    if (isDefault) {
        return {
            ...node,
            default: target,
        }
    }

    return {
        ...node,
        default: node.default,
        branches: node.branches.map((branch, index) => ({
            ...branch,
            nextNode: handleIndex === index ? target : branch.nextNode,
        })),
    }
}

// Helper function to update conditional node
const updateConditionalNode = (node: EditorNode, target: string, isSuccess: boolean) => ({
    ...node,
    ...(isSuccess ? { nextIfSuccess: target } : { nextIfFailure: target }),
})

const updateAssignTagGroupNode = (node: AssignTagGroupNode, target: string) => ({
    ...node,
    nextNode: target,
    nodes: node.nodes.map((n, index, array) => ({
        ...n,
        nextNode: index === array.length - 1 ? target : n.nextNode,
    })),
})

const updateSourceNode = (node: EditorNode, params: Connection) => {
    if (isBranchNode(node)) {
        const isDefaultBranchHandle = isBranchNode(node) && params.sourceHandle === `${node.slug}-default`
        const handleIndex = isDefaultBranchHandle
            ? undefined
            : parseInt(params.sourceHandle?.match(/\{(\d+)\}/)?.[1] || "0")

        return updateBranchNode(node, params.target, isDefaultBranchHandle, handleIndex)
    }

    if (isAssignTagGroupNode(node)) {
        return updateAssignTagGroupNode(node, params.target)
    }

    if (isRegularNode(node)) {
        return updateNodeWithTarget(node, params.target)
    }

    // Handle conditional nodes
    const isSuccess = params.sourceHandle?.includes("success") || false
    return updateConditionalNode(node, params.target, isSuccess)
}

export const connectNodes = ({ nodes, params }: ConnectNodesProps) => {
    const sourceNodeIndex = nodes.findIndex((node) => node.slug === params.source)
    if (sourceNodeIndex === -1) return nodes

    const sourceNode = nodes[sourceNodeIndex]

    const updatedSourceNode = updateSourceNode(sourceNode, params)

    const updatedNodes = nodes.map((node) => {
        if (node.slug === params.source) {
            return updatedSourceNode
        }
        return node
    })

    return updatedNodes
}
