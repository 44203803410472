import { Autocomplete, Chip, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks/useOrganizationTagGroups"
import { TagI } from "~/domains/analytics/tags/types"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { ConfigurationNode } from "~/domains/orchestration/flows/components/configuration/ConfigurationNode"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    AssignTagGroupNode,
    AssignTagNode,
    ConfigurationProps,
    EditorNode,
    NodeType,
} from "~/domains/orchestration/flows/types"

const createTagNodes = (currentNode: AssignTagGroupNode, tags: TagI[]): AssignTagNode[] => {
    if (!tags.length) return []

    const { objectId, objectType, slug, metadata, nextNode } = currentNode
    const { position } = metadata

    return tags.map((tag, index) => ({
        tagId: tag.tagId,
        objectId,
        objectType,
        name: tag.name,
        slug: index === 0 ? slug : `${slug}-${index}`,
        type: NodeType.ASSIGN_TAG_NODE,
        metadata: {
            position,
            groupId: slug,
        },
        nextNode: index === tags.length - 1 ? nextNode : `${slug}-${index + 1}`,
        error: false,
    }))
}

const updateAdvancedFields = (currentNode: AssignTagGroupNode) => {
    const { objectId, objectType } = currentNode

    return currentNode.nodes.map((node) => ({
        ...node,
        objectId,
        objectType,
    }))
}

export const AssignTagGroupConfiguration: FC<ConfigurationProps<AssignTagGroupNode & EditorNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const organizationId = useOrganizationId()

    // Handle the current node
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const tags = useMemo(() => tagGroups?.flatMap((tagGroup) => tagGroup.tags) || [], [tagGroups])
    const tagIdsFromGroup = useMemo(() => currentNode.nodes.map((node) => node.tagId) || [], [currentNode])

    const [selectedTags, setSelectedTags] = useState<TagI[]>([])

    const handleChangeTag = (_, value: TagI[]) => {
        if (!value) return

        setSelectedTags(value)

        setCurrentNode((prev) => ({
            ...prev,
            nodes: createTagNodes(prev, value),
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value.map((tag) => tag.name).join(", "),
            },
        }))
    }

    const handleChangeAdvancedFields = (node: AssignTagGroupNode) => {
        setCurrentNode({
            ...node,
            nodes: updateAdvancedFields(node),
        })
    }

    useEffect(() => {
        const currentTags = tags.filter((tag) => tagIdsFromGroup.includes(tag.tagId))
        setSelectedTags(currentTags)
    }, [tagIdsFromGroup, tags])

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const isLoading = tagGroups === null

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Autocomplete
                    multiple
                    autoComplete
                    options={tags}
                    value={selectedTags}
                    renderTags={(value, getTagProps) => (
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            {value.map((option, index) => {
                                const { onDelete } = getTagProps({ index })
                                const name = option?.name ?? ""
                                return <Chip key={option.tagId} label={name} variant="outlined" onDelete={onDelete} />
                            })}
                        </Stack>
                    )}
                    getOptionKey={(option) => option.tagId}
                    getOptionLabel={(option) => option?.name ?? ""}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
                    onChange={handleChangeTag}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.assignTagConfiguration.tagLabel)} />
                    )}
                    loading={isLoading}
                />
            </Stack>

            <AdvancedFields<AssignTagGroupNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={handleChangeAdvancedFields}
            />
        </ConfigurationNode>
    )
}
