import { styled } from "@mui/material"

import { AttachmentStatusType, ReceivedEmailStatusType } from "~/domains/_shared/ocr/types"

// FIXME: This should be moved to a shared place with StatusChip component
// Commented lines are duplicated statuses
const colorByStatus: Record<ReceivedEmailStatusType | AttachmentStatusType, string> = {
    [ReceivedEmailStatusType.FAILED]: "var(--color-red-darker)",
    [ReceivedEmailStatusType.NOTHING_TO_PROCESS]: "var(--color-grey-light)",
    [ReceivedEmailStatusType.PENDING]: "var(--color-grey)",
    [ReceivedEmailStatusType.PROCESSED]: "var(--color-green-darker)",
    [ReceivedEmailStatusType.UNKNOWN]: "var(--color-grey-light)",
    //[AttachmentStatusType.FAILED]: "var(--color-red-darker)",
    [AttachmentStatusType.ERROR_STORING]: "var(--color-red-darker)",
    [AttachmentStatusType.NOT_PROCESSABLE]: "var(--color-grey-light)",
    //[AttachmentStatusType.PENDING]: "var(--color-grey)",
    [AttachmentStatusType.SUCCESS]: "var(--color-green-darker)",
    //[AttachmentStatusType.UNKNOWN]: "var(--color-grey-light)",
}

interface LabelProps {
    status?: ReceivedEmailStatusType | AttachmentStatusType
    noMargin?: boolean
    color?: "error" | "success" | "warning" | "info" | "default"
}

export const ItemLabel = styled("div", {
    shouldForwardProp: (prop) => !["status", "noMargin", "color"].includes(prop as string),
})<LabelProps>(({ status, color, noMargin }) => ({
    fontSize: "var(--font-size-sm)",
    lineHeight: "18px",
    color: (status && colorByStatus[status]) || color || "var(--color-grey-light)",
    marginBottom: noMargin ? 0 : "var(--spacing-xxs)",
}))
