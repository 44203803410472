import { Autocomplete, Chip, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { TagGroupI } from "~/domains/analytics/tags/types"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, GetTagByGroupNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const GetTagByGroupConfiguration: FC<ConfigurationProps<GetTagByGroupNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const organizationId = useOrganizationId()

    const [selectedTagGroups, setSelectedTagGroups] = useState<TagGroupI | null>(null)

    const { tagGroups } = useOrganizationTagGroups(organizationId)

    const handleChangeTagGroups = (_, value: TagGroupI | null) => {
        if (!value) return

        setSelectedTagGroups(value)

        setCurrentNode((prev) => ({
            ...prev,
            tagGroupId: value.tagGroupId,
            metadata: {
                ...prev.metadata,
                additionalInformation: value.name,
            },
        }))
    }

    useEffect(() => {
        if (tagGroups) {
            setSelectedTagGroups(tagGroups.find((tagGroup) => tagGroup.tagGroupId === currentNode.tagGroupId) || null)
        }
    }, [currentNode.tagGroupId, tagGroups])

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const isLoading = tagGroups === null

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Autocomplete
                    autoComplete
                    options={tagGroups || []}
                    value={selectedTagGroups}
                    renderTags={(value, getTagProps) => (
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            {value.map((option, index) => {
                                const { onDelete } = getTagProps({ index })
                                const name = option?.name ?? ""
                                return (
                                    <Chip key={option.tagGroupId} label={name} variant="outlined" onDelete={onDelete} />
                                )
                            })}
                        </Stack>
                    )}
                    getOptionKey={(option) => option.tagGroupId}
                    getOptionLabel={(option) => option?.name ?? ""}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.tagGroupId === value.tagGroupId}
                    onChange={handleChangeTagGroups}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.getTagByGroupConfiguration.tagLabel)} />
                    )}
                    loading={isLoading}
                />
            </Stack>

            <AdvancedFields<GetTagByGroupNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
