import { MenuItem, Stack, SxProps, TextField, Theme, Typography } from "@mui/material"
import React, { ChangeEvent, ReactNode, useCallback } from "react"
import { Home } from "react-feather"

import { SafeFormattedMessage, StatusChip } from "~/components"
import { Chip } from "~/components/Chip"
import { CrossIcon } from "~/components/Icons"
import {
    chipStyles,
    companyStatusMessages,
} from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import {
    PartnerOrganizationI,
    partnershipCollaborationStatusMessages,
} from "~/domains/identity/partners/types/Partners"
import { EstablishmentI, OrganizationI } from "~/types"

import "./EstablishmentSelect.scss"

interface Props {
    options: EstablishmentI[]
    value: EstablishmentI | undefined
    onChange: (value: EstablishmentI | undefined) => void
    className?: string
    disabled?: boolean
    sx?: SxProps<Theme>
    organizations?: (OrganizationI | PartnerOrganizationI)[]
}

const isPartnerOrganization = (
    organization: OrganizationI | PartnerOrganizationI
): organization is PartnerOrganizationI => "collaborationStatus" in organization

export const EstablishmentSelect: React.FC<Props> = ({
    options,
    value,
    organizations,
    onChange,
    className,
    disabled,
    sx,
}) => {
    const onValueChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const selectedOption = options.find((option) => option.id === event.target.value)
            onChange(selectedOption)
        },
        [options, onChange]
    )

    const renderEstablishmentChip = useCallback(
        (establishment: EstablishmentI) => {
            if (!establishment.active)
                return (
                    <Chip size="small" variant="error" sx={chipStyles}>
                        <SafeFormattedMessage {...companyStatusMessages.closed} />
                    </Chip>
                )

            const organization = organizations?.find(
                (org) => org.registration.preferredRegistrationNumber?.registrationNumber === establishment.id
            )
            if (organization && isPartnerOrganization(organization) && organization.collaborationStatus) {
                return (
                    <StatusChip size="small" status={organization.collaborationStatus}>
                        <SafeFormattedMessage
                            {...partnershipCollaborationStatusMessages[organization.collaborationStatus]}
                        />
                    </StatusChip>
                )
            }
            return null
        },
        [organizations]
    )

    const renderSelectedEstablishment = useCallback(
        (v: unknown): ReactNode => {
            const selectedEstablishment = options.find((opt) => opt.id === v)
            if (!selectedEstablishment) return ""

            return (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>
                        {selectedEstablishment.id} ({selectedEstablishment.city})
                    </Typography>
                    {renderEstablishmentChip(selectedEstablishment)}
                </Stack>
            )
        },
        [options]
    )

    const clearSelection = useCallback(() => {
        onChange(undefined)
    }, [onChange])

    return (
        <TextField
            select
            className={className}
            sx={sx}
            value={value ? value.id : ""}
            InputProps={{
                endAdornment: value ? (
                    <CrossIcon
                        size={20}
                        onClick={clearSelection}
                        color="var(--color-grey-light)"
                        className="establishment-selector-clearer"
                    />
                ) : undefined,
            }}
            SelectProps={{
                renderValue: renderSelectedEstablishment,
            }}
            label={<SafeFormattedMessage id="supplier.buyerInfo.siret" defaultMessage="SIRET" />}
            onChange={onValueChange}
            disabled={disabled}
        >
            {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography fontWeight={option.isHeadOffice ? "bold" : "normal"}>
                            {option.id} ({option.city})
                        </Typography>
                        {option.isHeadOffice && <Home size={14} />}
                    </Stack>
                    {renderEstablishmentChip(option)}
                </MenuItem>
            ))}
        </TextField>
    )
}
