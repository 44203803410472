import { Card, Grid, Stack, Typography } from "@mui/material"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage, TooltipWhite } from "~/components"
import { copilotSuggestions, scopeMessages } from "~/domains/orchestration/copilot/constants/copilotSuggestions"

const messages = defineMessages({
    suggestions: {
        id: "copilot.suggestions",
        defaultMessage: "Suggestions",
    },
    disclaimer: {
        id: "copilot.suggestions.disclaimer",
        defaultMessage: "You can click on a suggestion to copy/paste it into the prompt.",
    },
})

interface CopilotPromptSuggestionsProps {
    setMessage: (message: string) => void
}

export const CopilotPromptSuggestions: React.FC<CopilotPromptSuggestionsProps> = ({ setMessage }) => {
    const { formatMessage } = useIntl()

    const onClick = (suggestion: MessageDescriptor) => {
        setMessage(formatMessage(suggestion))
    }

    return (
        <Stack direction="column" alignItems="center">
            <Typography variant="h6">
                <SafeFormattedMessage {...messages.suggestions} />
            </Typography>
            <Typography variant="body1" color="text.secondary" marginBottom={2}>
                <SafeFormattedMessage {...messages.disclaimer} />
            </Typography>
            <Grid container spacing={2} marginBottom={5} justifyContent="center">
                {Object.keys(copilotSuggestions).map((scope) => (
                    <Grid item key={scope} md={4} xs={12}>
                        <Card sx={{ height: "100%" }}>
                            <Stack direction="column" gap={2} padding={3} justifyContent="flex-start">
                                <Typography variant="h3">
                                    <SafeFormattedMessage {...scopeMessages[scope]} />
                                </Typography>
                                <Stack direction="column" gap={2}>
                                    {copilotSuggestions[scope].map((suggestion: MessageDescriptor) => (
                                        <TooltipWhite
                                            title={<SafeFormattedMessage {...suggestion} />}
                                            placement="top"
                                            arrow
                                            key={suggestion.id}
                                        >
                                            <div>
                                                <Button type="menu-item" onClick={() => onClick(suggestion)}>
                                                    <span className="text-ellipsis">
                                                        <SafeFormattedMessage {...suggestion} />
                                                    </span>
                                                </Button>
                                            </div>
                                        </TooltipWhite>
                                    ))}
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}
