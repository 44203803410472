import {
    Autocomplete,
    AutocompleteRenderGroupParams,
    AutocompleteRenderInputParams,
    FilterOptionsState,
    Grid,
    Popper,
    SxProps,
    TextField,
    Theme,
    createFilterOptions,
    styled,
} from "@mui/material"
import React, { Dispatch, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Users } from "react-feather"
import { MessageDescriptor, PrimitiveType, defineMessages, useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { StatusChip } from "~/components"
import { useWhitePagesApi } from "~/domains/identity/organization/api/whitePagesApi"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { PartnerOrganizationI, partnershipCollaborationStatusMessages } from "~/domains/identity/partners/types"
import { SaveStatus, useSaveBuffer } from "~/hooks"
import { useAppSelector } from "~/store/hooks"
import { CountryCode, OrganizationI, OrganizationId, WhitePagesIdTypes, WhitePagesResultI } from "~/types"

import { CompanyResultWithCreation } from "./CompanyAutocomplete"

export enum CompanyAutocompleteType {
    Organization = "org",
    WhitePagesResult = "wp",
    Creation = "create",
}

export const companyStatusMessages = defineMessages({
    new: {
        id: "company.autocomplete.sources.new",
        defaultMessage: "New",
    },
    closed: {
        id: "company.autocomplete.sources.closed",
        defaultMessage: "Closed",
    },
})

const CompanyAutocompleteTypeLabel: Record<CompanyAutocompleteType, MessageDescriptor> = defineMessages({
    [CompanyAutocompleteType.Organization]: {
        id: "company.autocomplete.myPartners",
        defaultMessage: "My partners",
    },
    [CompanyAutocompleteType.WhitePagesResult]: {
        id: "company.autocomplete.legalBase",
        defaultMessage: "Legal base",
    },
    [CompanyAutocompleteType.Creation]: {
        id: "company.autocomplete.customCreation",
        defaultMessage: "Custom creation",
    },
})

const getCompanyAutocompleteTypeLabel = (companyType: CompanyAutocompleteType): MessageDescriptor =>
    CompanyAutocompleteTypeLabel[companyType]

export type CompanyCreation = {
    countryCode: CountryCode
    name: string
    label: string
}

export type CompanyAutocompleteResult =
    | {
          type: CompanyAutocompleteType.Organization
          value: OrganizationI | PartnerOrganizationI
      }
    | {
          type: CompanyAutocompleteType.WhitePagesResult
          value: WhitePagesResultI
      }
    | {
          type: CompanyAutocompleteType.Creation
          value: CompanyCreation
      }

export const chipStyles: SxProps<Theme> = {
    margin: "-8px 0 -8px 2px",
    textAlign: "center",
    lineHeight: "12px",
}

interface Props {
    company: CompanyResultWithCreation | undefined
    countryCode: CountryCode
    inputValue: string
    setInputValue: Dispatch<string>
    value: CompanyAutocompleteResult | undefined
    onChange: (value: CompanyAutocompleteResult | undefined, inputValue: string) => void
    label?: React.ReactNode
    required?: boolean
    organizations: (OrganizationI | PartnerOrganizationI)[]
    enableCreation?: boolean
    groupLabel?: Partial<Record<CompanyAutocompleteType, ReactNode>>
}

const createAutocompleteOptionFromOrganization = (
    organization: OrganizationI | PartnerOrganizationI,
    brandNames: Record<OrganizationId, string>
): CompanyAutocompleteResult => {
    const organizationName = brandNames[organization.id] ?? organization.name

    return {
        type: CompanyAutocompleteType.Organization,
        value: {
            ...organization,
            name: organizationName,
        },
    }
}

const createAutocompleteOptionFromWhitePagesResult = (
    whitePagesResult: WhitePagesResultI
): CompanyAutocompleteResult => ({
    type: CompanyAutocompleteType.WhitePagesResult,
    value: whitePagesResult,
})

const getCityAndPostalCode = (companyResult: CompanyAutocompleteResult): string => {
    const { type, value } = companyResult

    if (type === CompanyAutocompleteType.Organization) {
        return value.address?.city ?? ""
    }

    if (type === CompanyAutocompleteType.Creation) {
        return ""
    }

    const city = value.city ?? ""
    const postalCode = value.postalCode ?? ""
    return `${city} ${postalCode}`.trim()
}

const WHITE_PAGES_ID_TYPES_WITH_SIREN = [WhitePagesIdTypes.FRENCH_SIRET, WhitePagesIdTypes.FRENCH_SIREN]
const hasWhitePagesIdTypeSIREN = (type: WhitePagesIdTypes) => {
    if (WHITE_PAGES_ID_TYPES_WITH_SIREN.includes(type)) {
        return true
    }
    return WHITE_PAGES_ID_TYPES_WITH_SIREN.some((t) => type.startsWith(t))
}
const formatFrenchSiretOrSiren = (id: string, type: WhitePagesIdTypes) => {
    if (hasWhitePagesIdTypeSIREN(type)) {
        return `${id.substring(0, 3)} ${id.substring(3, 6)} ${id.substring(6, 9)} ${id.substring(9, 14)}`
    }
    return id
}

const getRegistrationNumber = (option: CompanyAutocompleteResult) => {
    if (option.type === CompanyAutocompleteType.WhitePagesResult) {
        return formatFrenchSiretOrSiren(option.value.id, option.value.idType)
    }
    if (option.type === CompanyAutocompleteType.Organization) {
        return formatFrenchSiretOrSiren(
            option.value.registration?.preferredRegistrationNumber?.registrationNumber ?? "",
            option.value.registration?.preferredRegistrationNumber?.registrationType as WhitePagesIdTypes
        )
    }
    return ""
}

const groupBy = (option: CompanyAutocompleteResult) => option.type

const GroupHeader = styled("div")(() => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: "var(--color-grey-light)",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
    backgroundColor: "white",
}))

const GroupItems = styled("ul")({
    padding: 0,
})

const IconAndStatusContainer = styled("div")(() => ({
    display: "flex",
    gap: "8px",
    alignItems: "center",
}))

const StyledCity = styled("span")(() => ({
    color: "var(--color-grey-light)",
    marginLeft: "4px",
}))

const StyledRegistrationNumber = styled("div")(() => ({
    color: "var(--color-grey-light)",
}))

const filterOptions = createFilterOptions({
    stringify: (option: CompanyAutocompleteResult) =>
        option.type === CompanyAutocompleteType.Organization
            ? `${option.value.name} ${option.value.registration?.preferredRegistrationNumber?.registrationNumber}`
            : option.value.name,
})

const noFilter = (options: CompanyAutocompleteResult[]) => options

const filterOptionsWithCreation =
    (
        formatMessage: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string,
        countryCode: CountryCode
    ) =>
    (options: CompanyAutocompleteResult[], params: FilterOptionsState<CompanyAutocompleteResult>) => {
        const { inputValue } = params

        const isExistingInOrganizations = options.some(
            (option) => option.type === CompanyAutocompleteType.Organization && inputValue === option.value.name
        )
        const companyCreation: CompanyAutocompleteResult = {
            type: CompanyAutocompleteType.Creation,
            value: {
                countryCode,
                name: inputValue,
                label: formatMessage(
                    {
                        id: "account.organization.companyAutocomplete.CreateCompany",
                        defaultMessage: `Add: "{inputValue}"`,
                    },
                    { inputValue }
                ),
            },
        }

        if (inputValue) {
            if (isExistingInOrganizations) {
                options.push(companyCreation)
            } else {
                options.unshift(companyCreation)
            }
        }

        return options
    }

const getOptionLabel = (option: string | CompanyAutocompleteResult) => {
    if (typeof option === "string") return option
    return option.value.name
}

const hasCollaborationStatus = (
    option: CompanyAutocompleteResult
): option is CompanyAutocompleteResult & { value: { collaborationStatus: string } } => {
    return option.type === CompanyAutocompleteType.Organization && option.value && "collaborationStatus" in option.value
}

export const CompanyAutocompleteField: React.FC<Props> = ({
    countryCode,
    company,
    value,
    inputValue,
    setInputValue,
    label,
    required,
    onChange,
    organizations,
    enableCreation,
    groupLabel,
}) => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<CompanyAutocompleteResult[]>([])
    const inputValueRef = useRef<string>("")
    const inputRef = useRef<HTMLInputElement>()
    const brandNames = useAppSelector(selectPartnersBrandNames)

    const whitePagesApi = useWhitePagesApi()

    const openPopper = useCallback(() => setOpen(true), [setOpen])
    const closePopper = useCallback(() => setOpen(false), [setOpen])

    useEffect(() => {
        if (company?.type === CompanyAutocompleteType.Organization) {
            setInputValue(company.value.name)
            inputValueRef.current = company.value.name
        } else if (
            company?.type === CompanyAutocompleteType.WhitePagesResult &&
            company.value.name !== inputValueRef.current
        ) {
            setInputValue(company.value.name)
            inputValueRef.current = company.value.name
        }
    }, [company, setInputValue])

    const organizationsOptions = useMemo(
        () =>
            filterOptions(
                organizations.map((org) => createAutocompleteOptionFromOrganization(org, brandNames)),
                {
                    inputValue,
                    getOptionLabel,
                }
            ),
        [organizations, inputValue, brandNames]
    )

    useEffect(() => {
        setOptions(organizationsOptions)
    }, [setOptions, organizationsOptions])

    const searchCompanies = useCallback(async () => {
        if (inputValue.length > 0) {
            const whitePagesResults = await whitePagesApi.autocomplete(countryCode, inputValue)
            const options = [
                ...organizationsOptions,
                ...whitePagesResults.map(createAutocompleteOptionFromWhitePagesResult),
            ]
            setOptions(options)
            return options
        }
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const options = organizationsOptions
        setOptions(options)
        return options
    }, [whitePagesApi, countryCode, organizationsOptions, inputValue, setOptions])

    useEffect(() => {
        if (inputValue && inputValueRef.current !== inputValue) {
            if (inputRef.current) {
                inputRef.current.click()
            }
            openPopper()
        }
    }, [inputValue, openPopper])

    const { status, setHasChanges } = useSaveBuffer(searchCompanies, {
        savingTime: 350,
        displaySavedTime: 0,
    })

    const onInputChange = useCallback(
        (_event: React.SyntheticEvent, newInputValue: string) => {
            setInputValue(newInputValue)
            inputValueRef.current = newInputValue
            setHasChanges(true)
            if (!newInputValue || newInputValue.length === 0) {
                onChange(undefined, "")
            }
        },
        [setInputValue, setHasChanges, onChange]
    )

    const onAucompleteChange = useCallback(
        (_event: React.SyntheticEvent, aucompleteResult: CompanyAutocompleteResult | string | null) => {
            if (typeof aucompleteResult === "string") {
                console.warn(`Unexpeced string in OrganizationAutocomplete>onAucompleteChange: ${aucompleteResult}`)
                return
            }
            onChange(aucompleteResult || undefined, inputValue)
        },
        [onChange, inputValue]
    )

    const renderGroup = useCallback(
        (params: AutocompleteRenderGroupParams) => {
            const group = params.group as CompanyAutocompleteType
            return (
                <li key={params.key}>
                    <GroupHeader>
                        {groupLabel && groupLabel[group] ? (
                            groupLabel[group]
                        ) : (
                            <SafeFormattedMessage {...getCompanyAutocompleteTypeLabel(group)} />
                        )}
                    </GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )
        },
        [groupLabel]
    )

    const renderOptionType = useCallback(
        (option: CompanyAutocompleteResult) => {
            if (option.type === CompanyAutocompleteType.Organization) {
                return (
                    <>
                        {hasCollaborationStatus(option) && (
                            <StatusChip status={option.value.collaborationStatus}>
                                <IconAndStatusContainer>
                                    <Users size={16} />
                                    {formatMessage(
                                        partnershipCollaborationStatusMessages[option.value.collaborationStatus]
                                    )}
                                </IconAndStatusContainer>
                            </StatusChip>
                        )}
                    </>
                )
            }
            if (option.type === CompanyAutocompleteType.WhitePagesResult) {
                if (option.value.isOutOfBusiness) {
                    return (
                        <StatusChip status={companyStatusMessages.closed.defaultMessage}>
                            {formatMessage(companyStatusMessages.closed)}
                        </StatusChip>
                    )
                }
                return (
                    <StatusChip status={companyStatusMessages.new.defaultMessage}>
                        {formatMessage(companyStatusMessages.new)}
                    </StatusChip>
                )
            }
            return null
        },
        [formatMessage]
    )

    const renderOption = useCallback(
        (params: React.HTMLAttributes<HTMLLIElement>, option: CompanyAutocompleteResult) => {
            if (!option) return null
            const city = getCityAndPostalCode(option)
            return (
                <li {...params} key={option.type === CompanyAutocompleteType.Creation ? "creation" : option.value.id}>
                    <Grid container justifyContent="space-between" direction="row" alignItems="center" gap={1}>
                        <Grid item xs={8}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: option.value.name || "",
                                }}
                            />
                            {city && <StyledCity>({city})</StyledCity>}
                            {option.type !== CompanyAutocompleteType.Creation && (
                                <StyledRegistrationNumber>{getRegistrationNumber(option)}</StyledRegistrationNumber>
                            )}
                        </Grid>
                        <Grid item>{renderOptionType(option)}</Grid>
                    </Grid>
                </li>
            )
        },
        [renderOptionType]
    )

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                InputLabelProps={params.InputLabelProps}
                InputProps={params.InputProps}
                inputProps={params.inputProps}
                id={params.id}
                disabled={params.disabled}
                fullWidth={params.fullWidth}
                size={params.size}
                label={
                    label ?? (
                        <SafeFormattedMessage
                            id="account.organization.companyAutocomplete.defaultLabel"
                            defaultMessage="Search company"
                        />
                    )
                }
                required={required}
            />
        ),
        [required, label]
    )

    const loading = status === SaveStatus.SAVING

    if (countryCode === CountryCode.UNKNOWN) {
        return null
    }

    return (
        <Autocomplete
            freeSolo
            open={open}
            onOpen={openPopper}
            onClose={closePopper}
            inputValue={inputValue}
            onInputChange={onInputChange}
            loading={loading}
            options={loading ? [] : options}
            getOptionLabel={getOptionLabel}
            value={value ?? ""}
            filterOptions={enableCreation ? filterOptionsWithCreation(formatMessage, countryCode) : noFilter}
            renderOption={renderOption}
            renderInput={renderInput}
            onChange={onAucompleteChange}
            groupBy={groupBy}
            renderGroup={renderGroup}
            fullWidth
            PopperComponent={Popper}
            ref={inputRef}
        />
    )
}
