import { Alert, IconButton, Snackbar, SnackbarCloseReason, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { X } from "react-feather"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { useInvoicesByIds } from "~/store/invoice/hooks/useInvoicesByIds"
import { InvoiceStatus } from "~/types"
import { isDefined } from "~/utils/isDefined"

import { InvoiceDuplicateWarningInvoice } from "./InvoiceDuplicateWarningInvoice"

const AUTO_HIDE_DURATION = 7000
const messages = defineMessages({
    warning: {
        id: "invoice.duplicate.warning",
        defaultMessage: "This invoice has already been imported",
    },
})

interface Props {
    duplicates: string[]
}

export const InvoiceDuplicateWarning: React.FC<Props> = ({ duplicates }) => {
    const [open, setOpen] = useState(true)
    const { invoices } = useInvoicesByIds(duplicates)

    const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === "clickaway") return

        setOpen(false)
    }

    const filteredInvoices = Object.keys(invoices)
        .map((invoiceId) => invoices[invoiceId]?.invoice)
        .filter(isDefined)
        .filter((invoice) => invoice.status !== InvoiceStatus.REJECTED)

    if (filteredInvoices.length === 0) {
        return null
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                onClose={handleClose}
                autoHideDuration={AUTO_HIDE_DURATION}
            >
                <Alert severity="warning" variant="filled" color="warning" icon={false}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <Typography variant="h5">
                            <SafeFormattedMessage {...messages.warning} />
                        </Typography>
                        <IconButton size="small" color="inherit" onClick={handleClose}>
                            <X size={16} />
                        </IconButton>
                    </Stack>

                    <Stack gap={0.5} marginTop={2}>
                        {filteredInvoices.map((invoice) => (
                            <InvoiceDuplicateWarningInvoice key={invoice.id} invoice={invoice} />
                        ))}
                    </Stack>
                </Alert>
            </Snackbar>
        </>
    )
}
