import { Box, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction, useState } from "react"
import { DollarSign } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Card } from "~/components"
import { BudgetLink } from "~/domains/transactions/_shared/components/BudgetLink/BudgetLink"
import { ModalAddToBudget } from "~/domains/transactions/_shared/components/ModalAddToBudget/ModalAddToBudget"
import { TransactionBudgetGauge } from "~/domains/transactions/_shared/components/TransactionInBudgets/TransactionBudgetGauge"
import { AddTransactionPayloadI, BudgetDataWithMetricsI, TransactionType } from "~/domains/transactions/budget/types"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useFetchBudgetsData } from "~/store/budget/hooks"
import { InvoiceI, ReceivedDocumentI } from "~/types"

const messages = defineMessages({
    budgets: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
    addToBudget: {
        id: "transactions.in.addToBudget",
        defaultMessage: "Add to budget",
    },
})

interface TransactionInBudgetsProps {
    transaction: ReceivedDocumentI | InvoiceI | PurchaseRequestDetails | PurchaseOrders
    objectType: TransactionType
    draftBudgets: BudgetDataWithMetricsI[]
    setDraftBudgets: Dispatch<SetStateAction<BudgetDataWithMetricsI[]>>
    organizationId: string
    withGauge?: boolean
    handleSuccess: () => void
}

export const TransactionInBudgets: React.FC<TransactionInBudgetsProps> = ({
    transaction,
    objectType,
    withGauge = false,
    organizationId,
    draftBudgets,
    setDraftBudgets,
    handleSuccess,
}) => {
    const { formatMessage } = useIntl()
    const [displayModal, setDisplayModal] = useState(false)

    const { budgetsData } = useFetchBudgetsData({ organizationId, withMetrics: false })

    const budgets = draftBudgets?.length ? draftBudgets : transaction.budgets

    const transactionPayload: AddTransactionPayloadI = {
        transactionRefId: transaction.id as string,
        transactionStatus: transaction.status,
        transactionType: objectType,
        supplierOrgId: transaction.supplierId,
        buyerOrgId: organizationId ?? "",
        description: transaction.description ?? "",
        amount: 0,
        amountWithoutTaxes: 0,
        amountRemainingToPay: 0,
        partialAmount: 0,
        partialRate: 0,
    }

    const handleOpenModal = () => {
        setDisplayModal(true)
    }

    const handleCloseModal = () => {
        setDisplayModal(false)
    }

    return (
        <>
            <Card
                title={formatMessage(messages.budgets)}
                header={
                    <Box display="flex" gap={1} alignItems="center">
                        <Typography variant="h4">{formatMessage(messages.budgets)}</Typography>
                        <Button size="x-small" type="title" onClick={handleOpenModal}>
                            {formatMessage(commonMessages.edit)}
                        </Button>
                    </Box>
                }
                expandable
            >
                {budgets?.length ? (
                    <>
                        <BudgetLink budgets={budgets} />
                        {withGauge && (
                            <TransactionBudgetGauge
                                transaction={transaction as PurchaseOrders | PurchaseRequestDetails}
                            />
                        )}
                    </>
                ) : (
                    <Box>
                        <Button type="tertiary" onClick={handleOpenModal}>
                            <DollarSign size={16} />
                            {formatMessage(messages.addToBudget)}
                        </Button>
                    </Box>
                )}
            </Card>

            <ModalAddToBudget
                open={displayModal}
                close={handleCloseModal}
                organizationId={organizationId}
                budgets={budgetsData}
                transaction={transaction}
                transactionPayload={transactionPayload}
                setDraftBudgets={setDraftBudgets}
                handleSuccess={handleSuccess}
            />
        </>
    )
}
