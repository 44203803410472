import { Route } from "react-router-dom"

import { OrganisationCustomForms } from "~/domains/identity/custom-forms/pages/OrganisationCustomForms"

import { CustomForm } from "./pages/CustomForm"
import { CustomFormEditor } from "./pages/CustomFormEditor"
import { CustomFormSurveyResults } from "./pages/CustomFormSurveyResults"
import { CustomFormViewer } from "./pages/CustomFormViewer"
import {
    CUSTOM_FORMS,
    CUSTOM_FORMS_EDITOR,
    CUSTOM_FORMS_FORM,
    CUSTOM_FORMS_FORM_VIEWER,
    CUSTOM_FORMS_SURVEY_RESULTS,
} from "./routes"

export default {
    routes: [
        <Route key="custom_forms_route">
            <Route path={CUSTOM_FORMS} element={<OrganisationCustomForms />} />
        </Route>,
        <Route key="custom_forms_viewer_route">
            <Route path={CUSTOM_FORMS_FORM_VIEWER} element={<CustomFormViewer />} />
        </Route>,
        <Route key="custom_form_route">
            <Route path={CUSTOM_FORMS_FORM} element={<CustomForm />} />
        </Route>,
        <Route key="custom_form_editor_route">
            <Route path={CUSTOM_FORMS_EDITOR} element={<CustomFormEditor />} />
        </Route>,
        <Route key="custom_form_survey_results_route">
            <Route path={CUSTOM_FORMS_SURVEY_RESULTS} element={<CustomFormSurveyResults />} />
        </Route>,
    ],
}
