import { Alert, AlertTitle, Box, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { AlertTriangle, Info } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Modal, OneTimePassword, SafeFormattedMessage } from "~/components"
import UserAvatar from "~/components/Avatar/UserAvatar"
import TrimmedTextField from "~/components/Form/TrimmedTextField"
import { selectUser } from "~/store/account/accountSlice"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectSendValidation, selectValidationSent } from "~/store/invoice/invoiceSlice"
import { useCurrentOrganization } from "~/store/organization/hooks"
import { AddInvolvedPersonI, UserPartialI } from "~/types"
import { getJWTCookie } from "~/utils"

const messages = defineMessages({
    modalTitle: { id: "buyer.modalValidation.title", defaultMessage: "Validate Invoice" },
    modalCancel: { id: "common.cancel", defaultMessage: "Cancel" },
    modalConfirm: { id: "buyer.modalValidation.button.confirm", defaultMessage: "Validate" },
    emailVerificationTitle: {
        id: "buyer.modalValidation.emailVerification.title",
        defaultMessage: "Email verification",
    },
    email: { id: "buyer.modalValidation.emailVerification.labelEmail", defaultMessage: "Or add your email" },
    emailAdd: { id: "buyer.modalValidation.emailVerification.addEmail", defaultMessage: "Add email" },
    stepCommentTitle: { id: "buyer.modalValidation.stepComment.title", defaultMessage: "Comment" },
    stepAddTags: { id: "buyer.modalValidation.stepTags.title", defaultMessage: "Tag this invoice" },
    stepCommentLabel: { id: "buyer.modalValidation.stepComment.label", defaultMessage: "Add a comment" },
    step1Title: { id: "buyer.modalValidation.step1.title", defaultMessage: "Select who you are" },
    step1Content: { id: "buyer.modalValidation.step1.content", defaultMessage: "You can also just {login}" },
    step2Title: { id: "buyer.modalValidation.step2.title", defaultMessage: "Authentication method" },
    emailVerificationText: {
        id: "buyer.modalValidation.emailVerification.text",
        defaultMessage:
            "Before validating your invoice, we need to verify if the email address {email} belongs to you.",
    },
    reconnect: {
        id: "buyer.emailVerification.reconnect",
        defaultMessage: "Your session has expired, please sign up again or verify your email",
    },
    alertTitle: { id: "buyer.modalValidation.alertTitle", defaultMessage: "Warning" },
    alertMessage: {
        id: "buyer.modalValidation.alertMessage",
        defaultMessage: "Your approval is going to bypass the Approval workflow.{br}Are you sure you want to proceed?",
    },
    alertPennylaneSyncTitle: { id: "buyer.modalValidation.alertPennylaneSyncTitle", defaultMessage: "Information" },
    alertPennylaneSyncMessage: {
        id: "buyer.modalValidation.alertPennylaneSyncMessage",
        defaultMessage:
            "Once the invoice is validated, the Pennylane synchronization will begin. Please allow a moment before refreshing the page to see the updated changes.",
    },
})

interface ModalValidationProps {
    displayModal: boolean
    onClose: Dispatch<SetStateAction<boolean>>
    onConfirm: (comment: string, tags: string[]) => void
    involvedPeople: UserPartialI[]
    inWorkflow?: boolean
    isPennylaneSyncFailure?: boolean
}

export function ModalValidation({
    displayModal,
    onClose,
    onConfirm,
    involvedPeople,
    inWorkflow,
    isPennylaneSyncFailure,
}: ModalValidationProps) {
    const [isAuthorized, setIsAuthorized] = useState(false)
    const [email, setEmail] = useState("")
    const [newPerson, setNewPerson] = useState("")
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const isConnected = useAppSelector(selectIsConnected)

    const formRef = useRef<HTMLFormElement>(null)
    const sendValidation = useAppSelector(selectSendValidation)
    const validationSent = useAppSelector(selectValidationSent)

    const [emailList, setEmailList] = useState<AddInvolvedPersonI[]>([])
    const [comment, setComment] = useState<string>("")
    const user = useAppSelector(selectUser)
    const organization = useCurrentOrganization(user.organizations)

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail((event.target as HTMLInputElement).value)
    }

    useEffect(() => {
        setIsAuthorized(isConnected || !!getJWTCookie())
    }, [isConnected])

    useEffect(() => {
        if (sendValidation && validationSent) {
            handleClose()
        }
    }, [sendValidation, validationSent])

    const handleConfirm = () => {
        onConfirm(comment, [])
        handleClose()
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewPerson(event.target.value)
    }

    const handleAddInvolvedPeople = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        formRef.current?.requestSubmit()
        if (formRef.current?.checkValidity()) {
            setEmailList(emailList.concat({ email: newPerson }))
            setEmail(newPerson)
            setNewPerson("")
        }
    }

    const handleClose = () => {
        setEmail("")
        onClose(false)
    }

    const handleUpdateComment = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(event.target.value)
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const handleClickLogin = () => {
        dispatch(authActions.openPopupSignIn())
    }

    return (
        <Modal open={displayModal} onClose={handleClose} aria-labelledby="modal-validation" className="modal-otp">
            <Modal.Header>
                <h4>
                    <SafeFormattedMessage {...messages.modalTitle} />
                </h4>
            </Modal.Header>
            <Modal.Content>
                <Grid className="add-comment">
                    <Grid className="OTP-step">
                        <div className="OTP-step-num">1</div>
                        <div className="OTP-step-content">
                            <h5>
                                <SafeFormattedMessage {...messages.stepCommentTitle} />
                            </h5>
                        </div>
                    </Grid>
                    <TextField
                        id="comment"
                        name="comment"
                        className="bg-grey"
                        label={formatMessage(messages.stepCommentLabel)}
                        placeholder={formatMessage(messages.stepCommentLabel)}
                        onChange={handleUpdateComment}
                        value={comment ?? ""}
                        fullWidth
                    />
                </Grid>
                {isAuthorized && organization && user.id ? null : (
                    <Grid className="validation-auth">
                        <Grid className="OTP-step">
                            <div className="OTP-step-num">2</div>
                            <div className="OTP-step-content">
                                <h5>
                                    <SafeFormattedMessage {...messages.step1Title} />
                                </h5>
                                <p>
                                    <SafeFormattedMessage
                                        {...messages.step1Content}
                                        values={{
                                            login: <span onClick={handleClickLogin}>login</span>,
                                        }}
                                    />
                                </p>
                            </div>
                        </Grid>
                        <Grid className="involved-people-list">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                                value={email}
                            >
                                {[...involvedPeople, ...emailList].map((person, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={person.email}
                                        labelPlacement="start"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <UserAvatar email={person.email} />
                                                {person.email}
                                            </>
                                        }
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                        <form onSubmit={handleSubmit} ref={formRef}>
                            <Grid className="add-email">
                                <TrimmedTextField
                                    required
                                    id="supplier.email"
                                    name="supplier.email"
                                    type="email"
                                    className="bg-grey"
                                    label={formatMessage(messages.email)}
                                    placeholder={formatMessage(messages.email)}
                                    onChange={handleChange}
                                    value={newPerson}
                                    fullWidth
                                />
                                <Button onClick={handleAddInvolvedPeople}>
                                    <SafeFormattedMessage {...messages.emailAdd} />
                                </Button>
                            </Grid>
                        </form>
                        {email === "" ? null : (
                            <>
                                <Grid className="OTP-step step2">
                                    <div className="OTP-step-num">3</div>
                                    <div className="OTP-step-content">
                                        <h5>
                                            <SafeFormattedMessage {...messages.step2Title} />
                                        </h5>
                                    </div>
                                </Grid>
                                <Box className="otp-container">
                                    <p>
                                        <SafeFormattedMessage
                                            {...messages.emailVerificationText}
                                            values={{
                                                email: <strong>{email}</strong>,
                                            }}
                                        />
                                    </p>
                                    <OneTimePassword senderEmail={email} setIsAuthorized={setIsAuthorized} />
                                </Box>
                            </>
                        )}
                    </Grid>
                )}
                {inWorkflow && (
                    <Alert severity="warning" className="alert-validate" icon={<AlertTriangle />}>
                        <AlertTitle>
                            <SafeFormattedMessage {...messages.alertTitle} />
                        </AlertTitle>
                        <SafeFormattedMessage {...messages.alertMessage} values={{ br: <br /> }} />
                    </Alert>
                )}
                {isPennylaneSyncFailure && (
                    <Alert severity="info" className="alert-pennylane-sync" icon={<Info />}>
                        <AlertTitle>
                            <SafeFormattedMessage {...messages.alertPennylaneSyncTitle} />
                        </AlertTitle>
                        <SafeFormattedMessage {...messages.alertPennylaneSyncMessage} />
                    </Alert>
                )}
            </Modal.Content>
            <Modal.Footer>
                <Button onClick={handleClose} type="transparent">
                    <SafeFormattedMessage {...messages.modalCancel} />
                </Button>
                <Button buttonType="submit" onClick={handleConfirm} disabled={!isAuthorized}>
                    <SafeFormattedMessage {...messages.modalConfirm} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
