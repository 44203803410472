import { Stack, TextField } from "@mui/material"
import React, { useEffect, useMemo } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Loader, SkeletonInput } from "~/components"
import {
    CompanyRegistrationFields,
    organizationBlockType,
} from "~/domains/identity/organization/components/CompanyRegistrationFields"
import { usePartnersAsOrganizations } from "~/domains/identity/partners/store/hooks"
import { PartnerProfileContactI, PartnershipType } from "~/domains/identity/partners/types"
import { InvoiceContact } from "~/domains/transactions/invoices/components/InvoiceContact"
import { selectUser } from "~/store/account/accountSlice"
import { selectIsConnected } from "~/store/auth/authSlice"
import { useAppSelector } from "~/store/hooks"
import { selectSuggestedBuyerCompagnies } from "~/store/invoice/invoiceSlice"
import { useCurrentOrganization, useOrganizations } from "~/store/organization/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { ImportInvoiceCompanyInfoI, InvoiceUserType, OrganizationItemI } from "~/types"

const messages = defineMessages({
    companyName: {
        id: "supplier.buyerInfo.companyName",
        defaultMessage: "Company Name",
    },
    contactName: {
        id: "supplier.buyerInfo.contactName",
        defaultMessage: "Contact Name",
    },
    email: {
        id: "supplier.buyerInfo.email",
        defaultMessage: "Email",
    },
    taxNumber: {
        id: "supplier.buyerInfo.taxNumber",
        defaultMessage: "VAT NUMBER",
    },
    siren: {
        id: "supplier.buyerInfo.siren",
        defaultMessage: "SIREN",
    },
    siret: {
        id: "supplier.buyerInfo.siret",
        defaultMessage: "SIRET",
    },
})

interface Props {
    initiator: InvoiceUserType
    buyer: ImportInvoiceCompanyInfoI
    ocrBuyerData?: ImportInvoiceCompanyInfoI
    updateData: (data: Partial<ImportInvoiceCompanyInfoI>) => void
    dataLoaded: boolean
    buyerContactId?: string | undefined
    disabled?: boolean
}

const NO_ORGANIZATIONS: OrganizationItemI[] = []

export const InvoiceBuyerInfo: React.FC<Props> = ({
    initiator,
    buyer,
    ocrBuyerData,
    updateData,
    dataLoaded,
    buyerContactId,
    disabled,
}) => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const isConnected = useAppSelector(selectIsConnected)
    const buyerUser = initiator === InvoiceUserType.BUYER ? user : undefined
    const userIsConnected = buyerUser !== undefined && isConnected
    const { organizations: buyerOrganizations, loading: loadingOrganizations } = useOrganizations(
        buyerUser?.organizations ?? NO_ORGANIZATIONS
    )
    const suggestedCompanies = useAppSelector(selectSuggestedBuyerCompagnies)

    const currentUserOrganization = useCurrentOrganization(buyerUser?.organizations ?? NO_ORGANIZATIONS)
    const currentUserOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { organizations: currentUserOrganizationPartners } = usePartnersAsOrganizations(
        PartnershipType.BUYER,
        currentUserOrganizationId || ""
    )
    const organizations = initiator === InvoiceUserType.BUYER ? buyerOrganizations : currentUserOrganizationPartners

    const updateInvoiceContact = (contact: PartnerProfileContactI | null) => {
        if (!contact) {
            updateData({ contactName: "", email: "" })
            return
        }

        const contactName = `${contact.lastName || ""} ${contact.firstName || ""}`.trim()
        updateData({ contactName: contactName, email: contact.email })
    }

    const buyerUserIds = useMemo(() => (buyerContactId ? [buyerContactId] : []), [buyerContactId])
    const { users } = useGetAllUsersQuery(buyerUserIds)
    const buyerContact = users.length > 0 ? users[0] : undefined

    useEffect(() => {
        if (!buyerContact) return

        updateData({
            email: buyerContact.email,
            contactName: buyerContact.fullName ?? "",
        })
    }, [buyerContact, updateData])

    return (
        <>
            {!dataLoaded ? (
                <>
                    <SkeletonInput />
                    <SkeletonInput />
                    <SkeletonInput />
                </>
            ) : (
                <Stack gap={2}>
                    {loadingOrganizations ? (
                        <div>
                            <Loader small />
                        </div>
                    ) : (
                        <CompanyRegistrationFields
                            initiator={initiator}
                            currentOrganization={currentUserOrganization}
                            organizations={organizations}
                            suggestedCompanies={suggestedCompanies}
                            buyerOrSupplier={buyer}
                            ocrCompanyDetails={ocrBuyerData}
                            updateData={updateData}
                            disabled={disabled}
                            organizationType={organizationBlockType.BUYER}
                        />
                    )}

                    {!userIsConnected && (
                        <Stack gap={2}>
                            {disabled ? (
                                <Stack gap={2}>
                                    {buyer?.contactName && (
                                        <TextField
                                            id="supplier.contactName"
                                            name="supplier.contactName"
                                            label={formatMessage(messages.contactName)}
                                            placeholder={formatMessage(messages.contactName)}
                                            value={buyer?.contactName ?? ""}
                                            aria-readonly={disabled}
                                            inputProps={{ readOnly: disabled }}
                                            fullWidth
                                        />
                                    )}
                                    {buyer?.email && (
                                        <TextField
                                            id="supplier.email"
                                            name="supplier.email"
                                            type="email"
                                            label={formatMessage(messages.email)}
                                            placeholder={formatMessage(messages.email)}
                                            value={buyer?.email ?? ""}
                                            aria-readonly={disabled}
                                            inputProps={{ readOnly: disabled }}
                                            fullWidth
                                        />
                                    )}
                                </Stack>
                            ) : (
                                <InvoiceContact
                                    currentOrganizationId={currentUserOrganization?.id}
                                    partnerId={buyer.organizationId}
                                    updateInvoiceContact={updateInvoiceContact}
                                    suggestedOcrContact={ocrBuyerData}
                                    invoiceContact={buyer}
                                />
                            )}
                        </Stack>
                    )}
                </Stack>
            )}
            {/* <CheckOrganizationCanBeSet
                updateData={updateData}
                user={userIsConnected && buyerUser?.id ? buyerUser : undefined}
                isInitiator={initiator === InvoiceUserType.BUYER}
                organizationIdentifier={getImportCompanyIdentifierOrEmptyString(buyer)}
                organizationId={buyer.organizationId}
            /> */}
        </>
    )
}
