import { Stack } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useMemo, useState } from "react"
import { CheckCircle, Edit, ThumbsUp, Trash } from "react-feather"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, ConfirmModal, Loader, SafeFormattedMessage } from "~/components"
import {
    useDeletePaymentMethodDetailsMutation,
    useUpdatePaymentMethodDetailsObjectMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { ManualVerificationRequestModal } from "~/domains/payment/payment-method-details/components"
import {
    paymentMethodDetailsActions,
    selectActiveTab,
    selectSelectedOrganizationId,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails, PaymentMethodDetailsObject } from "~/domains/payment/payment-method-details/types"
import { mapToEdit } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    addVerification: {
        id: "payment.paymentMethodDetailsList.manualVerification.addVerification",
        defaultMessage: "Add verification",
    },
    successMessage: {
        id: "payment.paymentMethodDetailsList.successMessage",
        defaultMessage: "Payment method deleted successfully",
    },
    errorMessage: {
        id: "payment.paymentMethodDetailsList.errorMessage",
        defaultMessage: "An error occurred while deleting the payment method",
    },
    deleteMessage: {
        id: "payment.paymentMethodDetailsList.deleteMessage",
        defaultMessage: "Are you sure you want to delete this payment method?",
    },
})

interface LineActionsProps {
    row: PaymentMethodDetails
}

export const LineActions = ({ row }: LineActionsProps) => {
    const [deletePaymentMethodDetails, { isLoading }] = useDeletePaymentMethodDetailsMutation()
    const [updatePaymentDetailsObject] = useUpdatePaymentMethodDetailsObjectMutation()
    const organizationId = useAppSelector(selectSelectedOrganizationId)
    const activeTab = useAppSelector(selectActiveTab)
    const linkedObject = row.associated_objects?.find(
        (object: PaymentMethodDetailsObject) => object?.payment_method_details_id === row.id
    )

    const [open, setOpen] = useState(false)
    const [openManualVerification, setOpenManualVerification] = useState(false)
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    const handleEdit = () => {
        const lineToEdit = mapToEdit(row)
        dispatch(paymentMethodDetailsActions.setSelectedItem(lineToEdit))
    }

    const handleDelete = async () => {
        try {
            await deletePaymentMethodDetails(row.id).unwrap()
            setOpen(false)
            toast.success(formatMessage(messages.successMessage))
            return true
        } catch (error) {
            toast.error(formatMessage(messages.errorMessage))
            Sentry.captureException(error, {
                extra: {
                    paymentMethodId: row.id,
                },
            })
            return false
        }
    }

    const handleMarkAsDefault = async () => {
        if (!linkedObject) return

        await updatePaymentDetailsObject({ object: { default: true }, id: linkedObject.id })

        toast.success(
            formatMessage(commonMessages.successUpdateMessage, {
                instance: formatMessage(commonMessages.paymentMethodDetails),
            })
        )
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpenDeleteConfirmation = () => {
        setOpen(true)
    }

    const handleOpenManualVerification = () => {
        setOpenManualVerification(true)
    }

    const handleCloseManualVerification = () => {
        setOpenManualVerification(false)
    }

    const actions = useMemo(
        () => [
            ...(linkedObject && !linkedObject.default
                ? [
                      {
                          label: <FormattedMessage {...commonMessages.setAsDefault} />,
                          icon: <CheckCircle size={14} />,
                          action: handleMarkAsDefault,
                      },
                  ]
                : []),
            {
                label: <FormattedMessage {...messages.addVerification} />,
                icon: <ThumbsUp size={14} />,
                action: handleOpenManualVerification,
            },
            {
                label: <SafeFormattedMessage {...commonMessages.edit} />,
                icon: <Edit size={14} />,
                action: handleEdit,
            },
            {
                label: <SafeFormattedMessage {...commonMessages.delete} />,
                icon: <Trash size={14} />,
                action: handleOpenDeleteConfirmation,
            },
        ],
        [row]
    )

    if (!organizationId) {
        return null
    }

    return (
        <Stack direction="row" width="100%" alignItems="center" justifyContent="flex-end" gap={1}>
            {activeTab !== PaymentMethodType.TO_APPROVE && <ActionsMenu actions={actions} />}

            <ConfirmModal
                open={open}
                title={<SafeFormattedMessage {...messages.deleteMessage} />}
                close={handleClose}
                onConfirm={handleDelete}
                {...(isLoading ? { confirmButtonText: <Loader small /> } : {})}
            />

            <ManualVerificationRequestModal
                open={openManualVerification}
                paymentMethodDetailsId={row.id}
                onClose={handleCloseManualVerification}
            />
        </Stack>
    )
}
