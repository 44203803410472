import { CreditCard } from "react-feather"

import { FitsToBudgetConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { FitsToBudgetNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEventTypeToTransactionType } from "~/domains/orchestration/flows/core"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types"
import {
    EditorNode,
    Event,
    FitsToBudgetNode as FitsToBudgetNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/"

import { createCommonNodeProperties } from "./commonConfig"

const fitsToBudgetNode = (eventTriggerEventType: Event | null): Omit<FitsToBudgetNodeType, keyof Node> => ({
    amount: `{{ trigger_event.totalAmountDue.EUR }}`,
    budgetId: ` `,
    transactionId: `{{ trigger_event.id }}`,
    transactionType: eventTriggerEventType ? adaptEventTypeToTransactionType(eventTriggerEventType) : null,
    currency: `'EUR'`,
    nextIfSuccess: null,
    nextIfFailure: null,
    type: NodeType.FITS_TO_BUDGET_NODE,
})

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.AMOUNT,
    AllAdvancedFieldsUnion.TRANSACTION_ID,
    AllAdvancedFieldsUnion.TRANSACTION_TYPE,
    AllAdvancedFieldsUnion.CURRENCY,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.FITS_TO_BUDGET_NODE) return false
    return Boolean(node.budgetId && node.transactionType)
}

const fitsToBudgetConfig: Configuration<NodeType.FITS_TO_BUDGET_NODE> = {
    type: NodeType.FITS_TO_BUDGET_NODE,
    baseSlug: "fits_to_budget",
    Icon: CreditCard,
    Configuration: FitsToBudgetConfiguration,
    advancedFields,
    Node: FitsToBudgetNode,
    factory: (props: FactoryProps): FitsToBudgetNodeType => ({
        ...fitsToBudgetNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}
export { fitsToBudgetConfig, advancedFields as fitsToBudgetAdvancedFields }
