import { Mail } from "react-feather"

import { SendEmailConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { EmailMailType } from "~/domains/orchestration/flows/types"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    SendEmailNode as SendEmailNodeType,
} from "~/domains/orchestration/flows/types/Flows"
import { getEmailMailType } from "~/domains/orchestration/flows/utils"

import { createCommonNodeProperties } from "./commonConfig"

const sendEmailNode: Omit<SendEmailNodeType, keyof Node> = {
    subject: ``,
    recipientAddresses: [],
    body: ``,
    nextNode: null,
    type: NodeType.SEND_EMAIL_NODE,
}

const advancedFields: (keyof typeof sendEmailNode)[] = []

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.SEND_EMAIL_NODE) return false
    const hasInvalidEmail = node.recipientAddresses.some((email) => getEmailMailType(email) === EmailMailType.ERROR)

    return Boolean(node.subject && node.body && node.recipientAddresses?.length && !hasInvalidEmail)
}

const sendEmailConfig: Configuration<NodeType.SEND_EMAIL_NODE> = {
    type: NodeType.SEND_EMAIL_NODE,
    baseSlug: "send-email",
    Icon: Mail,
    Configuration: SendEmailConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): SendEmailNodeType => ({
        ...sendEmailNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { sendEmailConfig, advancedFields as sendEmailAdvancedFields }
