import { IconButton, styled } from "@mui/material"
import React from "react"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { LeftChevronIcon } from "~/components/Icons"
import { CompanyResult } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { PartnerOrganizationI } from "~/domains/identity/partners/types"
import { CompanyData } from "~/domains/transactions/invoices/_views/ocr/components/OrganizationPickerFromOCR"
import { CreateOrganizationForm } from "~/domains/transactions/invoices/_views/ocr/components/OrganizationPickerFromOCR/CreateOrganizationForm"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { CompanyI, CountryCode, OrganizationI } from "~/types"

const getCompanyData = (data: CompanyResult): CompanyI | OrganizationI | PartnerOrganizationI | undefined => {
    switch (data?.type) {
        case CompanyAutocompleteType.WhitePagesResult:
            return data.value as CompanyI
        case CompanyAutocompleteType.Organization:
            return data.value as OrganizationI | PartnerOrganizationI
        default:
            return undefined
    }
}

const createNewCompanyData = (
    companyResult: CompanyResult | undefined,
    defaultCountryCode: CountryCode = CountryCode.UNKNOWN
): CompanyData => {
    const defaultCompanyData: CompanyData = {
        name: "",
        countryCode: defaultCountryCode,
        registrationNumber: "",
        taxNumber: "",
    }

    if (!companyResult) {
        return defaultCompanyData
    }

    const value = getCompanyData(companyResult)
    if (value) {
        const isOrganization = "registration" in value
        return {
            name: value.name,
            countryCode: isOrganization
                ? (value.registration.countryCode ?? defaultCountryCode)
                : (value.countryCode ?? defaultCountryCode),
            registrationNumber: isOrganization
                ? ((value as OrganizationI).identifier ?? value.registration.dunsNumber)
                : (value.registrationNumbers?.[0]?.registrationNumber ?? ""),
            taxNumber: isOrganization ? (value.registration.vatNumber ?? "") : "",
        }
    }

    return defaultCompanyData
}

const messages = defineMessages({
    editButton: {
        id: "company.autocomplete.EditOrganization",
        defaultMessage: "Edit",
    },
    dontFindIt: {
        id: "transactions.invoices.ocr.OrganizationPicker.dontFindCompany",
        defaultMessage: "You don’t find it? ",
    },
    inputManuallyCTA: {
        id: "transactions.invoices.ocr.OrganizationPicker.manuallyInputCompany",
        defaultMessage: "Input manually a new company +",
    },
    createCompanyManually: {
        id: "transactions.invoices.ocr.OrganizationPicker.createCompanyManuallyTitle",
        defaultMessage: "Create a new company manually",
    },
})

const MainContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
})

interface Props {
    cancelManualImport: () => void
    companyResult?: CompanyResult
    onOrganizationCreated: (organization: OrganizationI, isFoundation?: boolean) => void
    open?: boolean
    close?: () => void
    isFoundation?: boolean
}

export const CreateOrganizationContainer: React.FC<Props> = ({
    cancelManualImport,
    companyResult,
    onOrganizationCreated,
    isFoundation,
}) => {
    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const companyData = createNewCompanyData(companyResult, currentOrganization?.registration?.countryCode)

    return (
        <MainContainer>
            <h4>
                <IconButton onClick={cancelManualImport}>
                    <LeftChevronIcon />
                </IconButton>
                <SafeFormattedMessage {...messages.createCompanyManually} />
            </h4>
            <CreateOrganizationForm
                companyData={companyData}
                onOrganizationCreated={onOrganizationCreated}
                cancel={cancelManualImport}
                isFoundation={isFoundation}
            />
        </MainContainer>
    )
}
