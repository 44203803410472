import "dayjs/locale/fr"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormatDateOptions, defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useGetBulkObjectsTags } from "~/domains/analytics/tags/hooks/useGetBulkObjectsTags"
import { selectSelectedTagsForFilter } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI } from "~/domains/analytics/tags/types/Tag"
import { userApi } from "~/domains/identity/account/api/userApi"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import {
    purchaseRequestsActions,
    selectPurchaseRequestFilter,
    selectPurchaseRequests,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { PurchaseRequestSummary } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

const messages = defineMessages({
    error: {
        id: "purchase.requests.list.errorFetch",
        defaultMessage: "We can't retrieve the purchase requests. Please contact your administrator",
    },
})

const noFilter = () => true

const getPurchaseRequestsFilter = (
    filter: string,
    formatDate: (value: string | number | Date | undefined, opts?: FormatDateOptions | undefined) => string,
    brandNames: Record<OrganizationId, string>,
    selectedTagsForFilter: SelectedTagI[]
) => {
    if ((!filter || !filter.length) && !selectedTagsForFilter.length) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)

    return (purchaseRequest: PurchaseRequestSummary) => {
        const descriptionWords = purchaseRequest.description
            ? purchaseRequest.description.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const supplierNameWords = purchaseRequest.supplierName
            ? purchaseRequest.supplierName.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const brandNameWords = brandNames[purchaseRequest.supplierId]
            ? brandNames[purchaseRequest.supplierId].toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
            : []
        const amountStr = purchaseRequest.totalAmount ? purchaseRequest.totalAmount.toString() : ""
        const requesterStr = purchaseRequest.requesterName
            ? purchaseRequest.requesterName.toString().toLocaleLowerCase()
            : ""
        const deliveryDateStr = purchaseRequest.expectedDeliveryDate
            ? formatDate(purchaseRequest.expectedDeliveryDate).toString().toLocaleLowerCase()
            : ""

        const searchArray = [
            ...descriptionWords,
            ...supplierNameWords,
            ...brandNameWords,
            amountStr,
            requesterStr,
            deliveryDateStr,
        ]

        const matchesSearchWords = filterWords.every((word) =>
            searchArray.some((purchaseRequestWord) => purchaseRequestWord.indexOf(word) >= 0)
        )

        const hasSelectedTags =
            !selectedTagsForFilter.length ||
            selectedTagsForFilter.every((selectedTag) =>
                purchaseRequest.tags?.some((tag) => tag.tagId === selectedTag.tagId)
            )

        return matchesSearchWords && hasSelectedTags
    }
}

export const useFetchPurchaseRequests = (organizationId?: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage, formatDate } = useIntl()

    const { getBulkObjectsTags } = useGetBulkObjectsTags(organizationId)
    const purchaseRequests: PurchaseRequestSummary[] = useAppSelector(selectPurchaseRequests)
    const filter = useAppSelector(selectPurchaseRequestFilter)
    const brandNames = useAppSelector(selectPartnersBrandNames)
    const selectedTagsForFilter = useAppSelector(selectSelectedTagsForFilter)

    const [loading, setLoading] = useState<boolean>(false)

    const fetchPurchaseRequests = useCallback(async () => {
        if (!organizationId) {
            return null
        }
        try {
            setLoading(true)
            dispatch(purchaseRequestsActions.fetchPRs())
            const prs = await purchaseRequestApi.findAll(organizationId)
            const organizationIds: OrganizationId[] = prs.map((purchaseItem) => purchaseItem.supplierId)
            organizationIds.push(organizationId)
            const userIds = prs.map((purchaseItem) => purchaseItem.requesterUserId)

            const organizations = organizationIds.length
                ? await organizationApi.fetchOrganizationsByIds([...new Set(organizationIds)])
                : []
            const users = userIds.length ? await userApi.findUsersByIds([...new Set(userIds)]) : []

            const pRWithNames = prs.map((pr) => {
                const supplier = organizations.find((organization) => organization.id === pr.supplierId)
                const user = users.find((u) => u.id === pr.requesterUserId)

                return {
                    ...pr,
                    supplierName: supplier?.name,
                    requesterName: user?.fullName,
                }
            })
            dispatch(purchaseRequestsActions.fetchPRsSuccess(pRWithNames))

            if (pRWithNames.length) {
                dispatch(purchaseRequestsActions.setTagsLoading(true))
                const prsObjectsTags = await getBulkObjectsTags(
                    pRWithNames.map((d) => d.id),
                    true
                )

                dispatch(purchaseRequestsActions.setTags(prsObjectsTags))
                if (prsObjectsTags) {
                    dispatch(
                        purchaseRequestsActions.fetchPRsSuccess(
                            pRWithNames.map((doc) => ({
                                ...doc,
                                tags:
                                    prsObjectsTags[doc.id]?.filter((tag) => tag.organizationId === organizationId) ??
                                    [],
                            }))
                        )
                    )
                }
            }
            return prs
        } catch (error) {
            dispatch(purchaseRequestsActions.reset())
            dispatch(purchaseRequestsActions.fetchPRsFailed(formatMessage(messages.error)))
        } finally {
            setLoading(false)
        }
    }, [organizationId, getBulkObjectsTags, dispatch, formatMessage])

    useEffect(() => {
        fetchPurchaseRequests()
    }, [fetchPurchaseRequests])

    return useMemo(() => {
        return {
            purchaseRequests: purchaseRequests.filter(
                getPurchaseRequestsFilter(filter, formatDate, brandNames, selectedTagsForFilter)
            ),
            loading,
            refetchPurchaseRequests: fetchPurchaseRequests,
        }
    }, [purchaseRequests, loading, fetchPurchaseRequests, filter, selectedTagsForFilter])
}
