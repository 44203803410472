import { ChevronsRight } from "react-feather"

import { ConvertPrToPoConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    ConvertPrToPoNode as ConvertPrToPoNodeType,
    EditorNode,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const convertPrToPoNode: Omit<ConvertPrToPoNodeType, keyof Node> = {
    purchaseRequestId: `{{ trigger_event.id }}`,
    nextNode: null,
    type: NodeType.CONVERT_PR_TO_PO_NODE,
}

const advancedFields: (keyof typeof convertPrToPoNode)[] = [AllAdvancedFieldsUnion.PURCHASE_REQUEST_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CONVERT_PR_TO_PO_NODE) return false
    return Boolean(node.purchaseRequestId)
}

const convertPrToPoConfig: Configuration<NodeType.CONVERT_PR_TO_PO_NODE> = {
    type: NodeType.CONVERT_PR_TO_PO_NODE,
    baseSlug: "convert-pr-to-po",
    Icon: ChevronsRight,
    Configuration: ConvertPrToPoConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): ConvertPrToPoNodeType => ({
        ...convertPrToPoNode,
        ...createCommonNodeProperties({ ...props, error: false }),
    }),
    validateNode,
}

export { convertPrToPoConfig, advancedFields as convertPrToPoAdvancedFields }
