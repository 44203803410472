import { MessageDescriptor, defineMessages } from "react-intl"

export const CopilotSuggestionScopesEnum = [
    "PURCHASE_REQUESTS",
    "PURCHASE_ORDERS",
    "INVOICES",
    "DOCUMENTS",
    "REGULATIONS",
] as const
export type CopilotSuggestionScope = (typeof CopilotSuggestionScopesEnum)[number]

export const suggestionMessages = defineMessages({
    purchaseRequest1: {
        id: "copilot.suggestion.purchaseRequest1",
        defaultMessage:
            "I would like to buy [x] [item] from [supplier] and delivered the [deliveryDate] for a total price of [price]",
    },
    purchaseRequest2: {
        id: "copilot.suggestion.purchaseRequest2",
        defaultMessage:
            "I would like to flight from [origin] to [destination] on [date], using [airline], for a maximum price of [price]",
    },
    purchaseRequest3: {
        id: "copilot.suggestion.purchaseRequest3",
        defaultMessage:
            "I would like to create a new campaign for [product] to [target], with a budget of [budget] and a start date of [startDate]",
    },
    document1: {
        id: "copilot.suggestion.document1",
        defaultMessage: "Can you analyse and describe the document I just uploaded?",
    },
    regulations1: {
        id: "copilot.suggestion.regulations1",
        defaultMessage: "Can you check if the regulations match the document I just uploaded?",
    },
    regulations2: {
        id: "copilot.suggestion.regulations2",
        defaultMessage: "What is CSRD?",
    },
})

export const scopeMessages: Partial<Record<CopilotSuggestionScope, MessageDescriptor>> = {
    PURCHASE_REQUESTS: {
        id: "copilot.suggestion.purchaseRequests",
        defaultMessage: "Purchase Requests",
    },
    DOCUMENTS: {
        id: "copilot.suggestion.documents",
        defaultMessage: "Documents",
    },
    REGULATIONS: {
        id: "copilot.suggestion.regulations",
        defaultMessage: "Regulations",
    },
}

export const copilotSuggestions: Partial<Record<CopilotSuggestionScope, MessageDescriptor[]>> = {
    PURCHASE_REQUESTS: [
        suggestionMessages.purchaseRequest1,
        suggestionMessages.purchaseRequest2,
        suggestionMessages.purchaseRequest3,
    ],
    DOCUMENTS: [suggestionMessages.document1],
    REGULATIONS: [suggestionMessages.regulations1, suggestionMessages.regulations2],
}
