import { Stack } from "@mui/material"
import { Link, generatePath } from "react-router-dom"

import { Card } from "~/components"
import { Chip } from "~/components/Chip"
import { PurchaseOrderPurchaseRequest } from "~/domains/transactions/purchase-orders/types"
import { PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"

interface Props {
    purchaseRequest?: PurchaseOrderPurchaseRequest
    label: string
}

export function PurchaseRequestLink({ purchaseRequest, label }: Props) {
    return (
        <Card title={label} expandable>
            <Stack direction="row" spacing={1}>
                <Chip variant="primary">
                    {!purchaseRequest ? (
                        "-"
                    ) : (
                        <Link to={generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: purchaseRequest.id })}>
                            #{purchaseRequest.number}
                        </Link>
                    )}
                </Chip>
            </Stack>
        </Card>
    )
}
