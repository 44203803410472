import { TextFieldVariants } from "@mui/material"
import { AutocompleteRenderInputParams } from "@mui/material"
import { TextField } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { getCountryFlag } from "~/components/CountrySelector/CountrySelector"
import { CountryCode } from "~/types/CountryCode"

const messages = defineMessages({
    selectCountry: {
        id: "company.selectCountry",
        defaultMessage: "Select country",
    },
})

interface CountrySelectorAutoCompleteTextFieldProps {
    params: AutocompleteRenderInputParams
    variant: TextFieldVariants
    error?: boolean
    helperText: React.ReactNode
    label?: string
    required: boolean
    disabled: boolean
    renderedValue: CountryCode | null
}

export const CountrySelectorAutoCompleteTextField = ({
    params,
    variant,
    error,
    helperText,
    label,
    required,
    disabled,
    renderedValue,
}: CountrySelectorAutoCompleteTextFieldProps) => {
    const { formatMessage } = useIntl()
    const { value: code } = params.inputProps
    const isUnknownCountry = code === CountryCode.UNKNOWN

    return (
        <TextField
            {...params}
            className="company-autocomplete-country-selector"
            variant={variant}
            error={error}
            helperText={helperText}
            InputProps={{
                ...params.InputProps,
                startAdornment: isUnknownCountry ? undefined : getCountryFlag(renderedValue as CountryCode),
                autoComplete: "new-password",
            }}
            inputProps={{
                ...params.inputProps,
            }}
            label={isUnknownCountry ? formatMessage(messages.selectCountry) : label}
            required={required}
            disabled={disabled}
        />
    )
}
