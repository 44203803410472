import { FulfillmentStatus } from "~/domains/transactions/_shared/types"
import { statusesPurchaseOrder } from "~/domains/transactions/purchase-orders/core/statuses"
import {
    PurchaseOrderFulfillmentPresentation,
    PurchaseOrderStatus,
    PurchaseOrderStatusPresentation,
} from "~/domains/transactions/purchase-orders/types"
import { ViewTypeI } from "~/types"

export type PurchaseOrderStatusTypes =
    | PurchaseOrderStatus
    | FulfillmentStatus
    | PurchaseOrderFulfillmentPresentation
    | PurchaseOrderStatusPresentation

interface LifecyclePurchaseOrder {
    position: number
    actions: string[]
}

export const getLifecyclePurchaseOrder = (status: PurchaseOrderStatusTypes): LifecyclePurchaseOrder | undefined => {
    return statusesPurchaseOrder[status] as LifecyclePurchaseOrder | undefined
}

export const isStatusCurrent = (status: PurchaseOrderStatusTypes, statusToCompare: PurchaseOrderStatusTypes) =>
    (getLifecyclePurchaseOrder(status)?.position || 0) === (getLifecyclePurchaseOrder(statusToCompare)?.position || 0)

export const isStatusNotCurrent = (status: PurchaseOrderStatusTypes, statusToCompare: PurchaseOrderStatusTypes) =>
    getLifecyclePurchaseOrder(status) !== getLifecyclePurchaseOrder(statusToCompare)

export const isStatusCompleted = (status: PurchaseOrderStatusTypes, statusToCompare: PurchaseOrderStatusTypes) =>
    (getLifecyclePurchaseOrder(status)?.position || 0) > (getLifecyclePurchaseOrder(statusToCompare)?.position || 0)

export const isStatusIncompleted = (status: PurchaseOrderStatusTypes, statusToCompare: PurchaseOrderStatusTypes) =>
    (getLifecyclePurchaseOrder(status)?.position || 0) < (getLifecyclePurchaseOrder(statusToCompare)?.position || 0)

export const isStatusNotCurrentAndIncompleted = (
    status: PurchaseOrderStatusTypes,
    statusToCompare: PurchaseOrderStatusTypes
) => isStatusNotCurrent(status, statusToCompare) && isStatusIncompleted(status, statusToCompare)

export const isStatusCurrentOrCompleted = (
    status: PurchaseOrderStatusTypes,
    statusToCompare: PurchaseOrderStatusTypes
) => isStatusCurrent(status, statusToCompare) || isStatusCompleted(status, statusToCompare)

export const isStatusCurrentOrIncompleted = (
    status: PurchaseOrderStatusTypes,
    statusToCompare: PurchaseOrderStatusTypes
) => isStatusCurrent(status, statusToCompare) || isStatusIncompleted(status, statusToCompare)

export const isStatusReadonly = (status: PurchaseOrderStatusTypes) =>
    !getLifecyclePurchaseOrder(status)?.actions.includes("edit")

export const isStatusWithoutCommunication = (status: PurchaseOrderStatusTypes) =>
    !getLifecyclePurchaseOrder(status)?.actions.includes("communicate")

// Use type assertions or checks to ensure safe access
export const isStatusReceivable = (status: PurchaseOrderStatusTypes) => {
    const lifecycle = getLifecyclePurchaseOrder(status)
    return lifecycle?.actions.includes("receive")
}

export const isStatusApprovable = (status: PurchaseOrderStatusTypes, view: ViewTypeI) => {
    const lifecycle = getLifecyclePurchaseOrder(status)

    return (
        (view === ViewTypeI.buyer && lifecycle?.actions.includes("approve_client")) ||
        (view === ViewTypeI.supplier && lifecycle?.actions.includes("approve_supplier"))
    )
}

export const isStatusSendable = (status: PurchaseOrderStatusTypes, view: ViewTypeI) => {
    const lifecycle = getLifecyclePurchaseOrder(status)

    return (
        (view === ViewTypeI.buyer && lifecycle?.actions.includes("send")) ||
        (view === ViewTypeI.supplier && lifecycle?.actions.includes("send"))
    )
}

export const isStatusDeletable = (status: PurchaseOrderStatusTypes) => {
    const lifecycle = getLifecyclePurchaseOrder(status)

    return lifecycle?.actions.includes("delete")
}

export const canSubmitPurchaseOrder = (status: PurchaseOrderStatusTypes) => isStatusCurrent(status, "DRAFT")

export const canSendPurchaseOrder = (status: PurchaseOrderStatusTypes, view: ViewTypeI) =>
    isStatusSendable(status, view)

export const canEditPurchaseOrder = (status: PurchaseOrderStatusTypes, hasPOEditPermission: boolean) =>
    isStatusNotCurrent(status, "CLOSED") && hasPOEditPermission

export const canDownloadPurchaseOrder = (status: PurchaseOrderStatusTypes) => isStatusNotCurrent(status, "DRAFT")

export const canDeletePurchaseOrder = (status: PurchaseOrderStatusTypes) => isStatusDeletable(status)

export const canClosePurchaseOrder = (status: PurchaseOrderStatusTypes) =>
    isStatusCurrentOrCompleted(status, "OPEN") && isStatusNotCurrent(status, "CLOSED")

export const canReOpenPurchaseOrder = (status: PurchaseOrderStatusTypes) => isStatusCurrentOrCompleted(status, "CLOSED")

export const canMarkAllInPreparationPurchaseOrder = (
    status: PurchaseOrderStatusTypes,
    fulfillment: PurchaseOrderFulfillmentPresentation,
    view: ViewTypeI
) =>
    isStatusApprovable(status, view) &&
    isStatusCurrent(status, "OPEN") &&
    isStatusNotCurrentAndIncompleted(fulfillment, "IN_PREPARATION")

export const canMarkOutForDeliveryPurchaseOrder = (
    status: PurchaseOrderStatusTypes,
    fulfillment: PurchaseOrderFulfillmentPresentation,
    view: ViewTypeI
) => view === ViewTypeI.supplier && isStatusApprovable(status, view) && isStatusCurrent(fulfillment, "IN_PREPARATION")

export const canReceiveOrDeliverPurchaseOrder = (
    status: PurchaseOrderStatusTypes,
    fulfillment: PurchaseOrderFulfillmentPresentation,
    view: ViewTypeI
) =>
    isStatusCurrentOrCompleted(status, "OPEN") &&
    ((view === ViewTypeI.buyer && isStatusIncompleted(fulfillment, "RECEIVED")) ||
        (view === ViewTypeI.supplier && isStatusCurrent(fulfillment, "SHIPPED")))
