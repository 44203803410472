import { FlowId } from "~/domains/orchestration/flows/types"
import { Opaque, PaginationOffsetLimit } from "~/utils"

export type RunId = Opaque<string, { readonly T: unique symbol }>

export enum RunStatus {
    CANCELED = "Canceled",
    FAILED = "Failed",
    FINISHED = "Finished",
    RUNNING = "Running",
    SUSPENDED = "Suspended",
}

export type RunItem = {
    id: RunId
    status: RunStatus
    startedAt: string
    finishedAt: string | null
}

export type Runs = {
    runs: RunItem[]
}

export type Run = {
    id: RunId
    flowId: FlowId
    flowVersion: number
    status: RunStatus
    state: {
        pathTaken: string[]
        storage: Record<string, unknown>
    }
    error?: string
    startedAt: string
    finishedAt: string | null
}

export type GetRunQuery = {
    runId: RunId
}

export interface GetRunsQuery extends PaginationOffsetLimit {
    flowId?: FlowId
}
