import { Tooltip } from "@mui/material"
import { X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { useInvoiceValidationActions } from "~/store/invoice/hooks/useInvoiceValidationActions"

const messages = defineMessages({
    refuse: { id: "buyer.invoice.actions.button.refuse", defaultMessage: "Refuse" },
    refuseButtonTooltip: {
        id: "buyer.invoice.actions.button.refuse.tooltip",
        defaultMessage: "The invoice does not correspond to what you expected? Refuse it.",
    },
})

interface RejectInvoiceProps {
    handleReject?: () => void
    organizationId: string
    invoiceId: string
}

export const RejectInvoice = ({ handleReject, organizationId, invoiceId }: RejectInvoiceProps) => {
    const { formatMessage } = useIntl()

    const { refuse } = useInvoiceValidationActions(organizationId, invoiceId)

    const handleRejectInvoice = () => {
        refuse?.()
        handleReject?.()
    }

    return (
        <Tooltip title={formatMessage(messages.refuseButtonTooltip)} arrow>
            <div>
                <Button onClick={handleRejectInvoice} type="error-light">
                    <X size={18} />
                    <SafeFormattedMessage {...messages.refuse} />
                </Button>
            </div>
        </Tooltip>
    )
}
