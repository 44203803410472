import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { ReactNode, forwardRef } from "react"
import { CheckSquare } from "react-feather"

interface CheckItemProps {
    primary: ReactNode
    secondary?: ReactNode
}

export const AknowledgeCheckItem = forwardRef<HTMLLIElement | null, CheckItemProps>(
    ({ primary, secondary }: CheckItemProps, ref) => (
        <ListItem ref={ref}>
            <ListItemAvatar>
                <Avatar>
                    <CheckSquare />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={primary} secondary={secondary} secondaryTypographyProps={{ component: "div" }} />
        </ListItem>
    )
)
AknowledgeCheckItem.displayName = "AknowledgeCheckItem"
