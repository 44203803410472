import { Tooltip } from "@mui/material"
import { useState } from "react"
import { Check } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { PennylaneSyncOutcome } from "~/domains/_shared/ocr/types/pennylaneSynchronizationStatus"
import { ModalValidation } from "~/domains/transactions/invoices/_views/buyer/components/ModalValidation/ModalValidation"
import { INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import { useInvoicePennylaneSynchronizationStatus } from "~/domains/transactions/invoices/hooks/useInvoicePennylaneSynchronizationStatus"
import { useSegment } from "~/hooks/useSegment"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { InvoiceI, UserI } from "~/types"

const messages = defineMessages({
    tooltip: {
        id: "buyer.invoice.actions.button.validate.tooltip",
        defaultMessage: "Are all the details on this invoice correct? Please validate it!",
    },
})

interface ValidateInvoiceProps {
    invoice: InvoiceI
    involvedUsers: UserI[]
    withinWorkflow: boolean
    display?: "menu-item" | "button"
}

export const ValidateInvoice = ({
    invoice,
    involvedUsers,
    withinWorkflow,
    display = "button",
}: ValidateInvoiceProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { segmentTrack } = useSegment()

    const [displayModalValidation, setDisplayModalValidation] = useState(false)

    const { status: pennylaneStatus } = useInvoicePennylaneSynchronizationStatus(invoice)

    const isPennylaneSyncFailure = pennylaneStatus?.pennylaneSyncOutcome === PennylaneSyncOutcome.FAILURE

    const openModalValidation = () => setDisplayModalValidation(true)

    const closeModalValidation = () => setDisplayModalValidation(false)

    const handleValidate = async (comment: string) => {
        segmentTrack("Invoice Validated", {
            invoice_id: invoice.id,
        })
        dispatch(
            invoiceActions.validateInvoice({
                comment,
                tags: [],
            })
        )
        navigate(generatePath(INVOICE_BUYER_ROUTE, { invoiceId: invoice.id }))
    }

    return (
        <>
            {display === "button" ? (
                <Tooltip title={formatMessage(messages.tooltip)} arrow>
                    <div>
                        <Button onClick={openModalValidation} type="primary-light">
                            <Check size={18} />
                            <SafeFormattedMessage {...commonMessages.validate} />
                        </Button>
                    </div>
                </Tooltip>
            ) : (
                <Button onClick={openModalValidation} type="menu-item">
                    <Check size={18} />
                    <SafeFormattedMessage {...commonMessages.validate} />
                </Button>
            )}

            <ModalValidation
                displayModal={displayModalValidation}
                onClose={closeModalValidation}
                onConfirm={handleValidate}
                involvedPeople={involvedUsers}
                inWorkflow={withinWorkflow}
                isPennylaneSyncFailure={isPennylaneSyncFailure}
            />
        </>
    )
}
