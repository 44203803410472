import { XYPosition } from "@xyflow/react"

import { Node } from "~/domains/orchestration/flows/types/"

type Props = {
    slug: string
    position: XYPosition
    name: string
    error?: boolean
}
export const createCommonNodeProperties = ({ slug, position, name, error = true }: Props): Node => ({
    name,
    slug,
    metadata: {
        position,
    },
    error,
})
