import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material"
import { FormEvent, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Loader, SafeFormattedMessage } from "~/components"
import { useGetPaymentBatchesByOrganizationQuery } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    paymentBatch: {
        id: "payment.batches.paymentBatch",
        defaultMessage: "Payment batch",
    },
    addToBatch: {
        id: "payment.batches.addToBatch",
        defaultMessage: "Add to batch",
    },
    paymentBatchRequired: {
        id: "payment.batches.paymentBatchRequired",
        defaultMessage: "Please select a payment batch",
    },
})

interface ObjectAssignFormProps {
    loading: boolean
    onSubmit: (batchId: string) => void
}

export const ObjectAssignForm = ({ loading = false, onSubmit }: ObjectAssignFormProps) => {
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [selectedBatchId, setSelectedBatchId] = useState<string>("")

    const { data: batchesData, isLoading: isLoadingBatches } = useGetPaymentBatchesByOrganizationQuery(
        { organizationId },
        {
            skip: !organizationId,
        }
    )
    const batches = batchesData?.items ?? []

    const handleBatchChange = (event: SelectChangeEvent) => {
        setSelectedBatchId(event.target.value)
    }

    const handleAddToBatch = (event: FormEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (!selectedBatchId) {
            toast.warning(formatMessage(messages.paymentBatchRequired))
            return
        }

        onSubmit(selectedBatchId)
    }

    if (isLoadingBatches) {
        return <Loader small />
    }

    return (
        <Box component="form" noValidate>
            <Stack gap={2}>
                <FormControl>
                    <InputLabel>
                        <SafeFormattedMessage {...messages.paymentBatch} />
                    </InputLabel>
                    <Select required disabled={loading} value={selectedBatchId} onChange={handleBatchChange}>
                        {batches.map((batch) => (
                            <MenuItem key={batch.id} value={batch.id}>
                                {batch.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button disabled={loading} buttonType="button" type="primary-light" block onClick={handleAddToBatch}>
                    <SafeFormattedMessage {...messages.addToBatch} />
                </Button>
            </Stack>
        </Box>
    )
}
