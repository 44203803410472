import { CheckCircle } from "react-feather"

import { CheckNodeConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { CheckNode } from "~/domains/orchestration/flows/components/nodes"
import { adaptEventTypeToObjectType } from "~/domains/orchestration/flows/core"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    CheckNode as CheckNodeType,
    EditorNode,
    Event,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const checkNode = (eventTriggerEventType: Event | null): Omit<CheckNodeType, keyof Node> => ({
    objectId: `{{ trigger_event.id }}`,
    objectType: eventTriggerEventType ? adaptEventTypeToObjectType(eventTriggerEventType) : null,
    reviewers: [],
    passThreshold: 1,
    refuseThreshold: 1,
    nextIfSuccess: null,
    nextIfFailure: null,
    type: NodeType.CHECK_NODE,
})

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID, AllAdvancedFieldsUnion.OBJECT_TYPE]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CHECK_NODE) return false

    return Boolean(node.passThreshold && node.refuseThreshold && node.objectId && node.reviewers?.length)
}

const checkConfig: Configuration<NodeType.CHECK_NODE> = {
    type: NodeType.CHECK_NODE,
    baseSlug: "check",
    Icon: CheckCircle,
    Configuration: CheckNodeConfiguration,
    advancedFields,
    Node: CheckNode,
    validateNode,
    factory: (props: FactoryProps): CheckNodeType => ({
        ...checkNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props),
    }),
}

export { checkConfig, advancedFields as checkAdvancedFields }
