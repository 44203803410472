import {
    Box,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useCallback, useEffect, useState } from "react"
import { Trash } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Modal, Size, UploadDocumentBox } from "~/components"
import { useUploadDocument } from "~/domains/identity/documents/hooks"
import { DocumentType } from "~/domains/identity/documents/types"
import {
    useRequestManualVerificationMutation,
    useUpdatePaymentMethodDetailsMutation,
} from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { selectSelectedOrganizationId } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { ManualVerification, VerificationMethod } from "~/domains/payment/payment-method-details/types"
import { toggleButtonsStyles } from "~/domains/payment/payment/utils/customStyles"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    title: {
        id: "payment.paymentMethodDetailsList.manualVerification.title",
        defaultMessage: "Payment method manual verification",
    },
    cancel: {
        id: "common.cancel",
        defaultMessage: "Cancel",
    },
    addVerification: {
        id: "payment.paymentMethodDetailsList.manualVerification.addVerification",
        defaultMessage: "Add verification",
    },
    uploadMessage: {
        id: "payment.paymentMethodDetailsList.manualVerification.uploadMessage",
        defaultMessage:
            "To proceed with the manual verification of your payment method, please upload the required document. Once uploaded, your document will be reviewed as part of the verification process.",
    },
    fileRequired: {
        id: "payment.paymentMethodDetailsList.manualVerification.fileRequired",
        defaultMessage: "Please upload a document",
    },
    verificationDone: {
        id: "payment.paymentMethodDetailsList.manualVerification.verificationDone",
        defaultMessage: "The manual payment method verification was successfully registered.",
    },
    phone: {
        id: "payment.paymentMethodDetailsList.manualVerification.phone",
        defaultMessage: "Phone",
    },
    email: {
        id: "payment.paymentMethodDetailsList.manualVerification.email",
        defaultMessage: "Email",
    },
    document: {
        id: "payment.paymentMethodDetailsList.manualVerification.document",
        defaultMessage: "Document",
    },
    note: {
        id: "payment.paymentMethodDetailsList.manualVerification.note",
        defaultMessage: "Note",
    },
})

const initialState = {
    file: null,
    note: "",
}

interface ManualVerificationRequestModalProps {
    open: boolean
    paymentMethodDetailsId: string
    onClose?: () => void
}

interface FormData {
    note: string
    file: File | null
}

export const ManualVerificationRequestModal = ({
    open,
    paymentMethodDetailsId,
    onClose,
}: ManualVerificationRequestModalProps) => {
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectSelectedOrganizationId)
    const [formData, setFormData] = useState<FormData>(initialState)
    const [fileError, setFileError] = useState<string>("")
    const [verificationType, setVerificationType] = useState<VerificationMethod>(VerificationMethod.DOCUMENT)

    const { uploadDocument, loading } = useUploadDocument(organizationId ?? "", null)
    const [requestManualVerification, { isLoading: isLoadingRequest }] = useRequestManualVerificationMutation()
    const [updatePaymentMethodDetails, { isLoading: isLoadingUpdate }] = useUpdatePaymentMethodDetailsMutation()
    const isLoadingState = loading || isLoadingUpdate || isLoadingRequest

    const resetFields = () => {
        setFormData(initialState)
        setFileError("")
    }

    const handleClose = () => {
        if (onClose) {
            onClose()
        }
        resetFields()
        setVerificationType(VerificationMethod.DOCUMENT)
    }

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            event.stopPropagation()

            const { note, file } = formData

            if (verificationType === VerificationMethod.DOCUMENT && !file) {
                setFileError(formatMessage(messages.fileRequired))
                return
            }

            const handleVerification = async (verificationPayload: ManualVerification) => {
                try {
                    const manualVerification = await requestManualVerification(verificationPayload).unwrap()

                    if (manualVerification?.id) {
                        toast.success(formatMessage(messages.verificationDone))
                        handleClose()
                    }
                } catch (error) {
                    Sentry.captureException(error, {
                        extra: {
                            organizationId,
                            paymentMethodDetailsId,
                        },
                    })
                }
            }

            try {
                if (verificationType === VerificationMethod.DOCUMENT && file) {
                    const response = await uploadDocument(
                        file,
                        DocumentType.BANK_ACCOUNT_DETAILS,
                        paymentMethodDetailsId
                    )

                    if (response?.result?.id) {
                        const { id, name, latestFileVersion } = response.result
                        const verificationPayload: ManualVerification = {
                            payment_method_details_id: paymentMethodDetailsId,
                            verification_method: VerificationMethod.DOCUMENT,
                            verification_documents: {
                                name,
                                url: latestFileVersion,
                                document_id: id,
                            },
                            ...(note && { verification_notes: note }),
                        }

                        await handleVerification(verificationPayload)
                    }
                } else if (
                    verificationType === VerificationMethod.PHONE ||
                    verificationType === VerificationMethod.EMAIL
                ) {
                    const verificationPayload: ManualVerification = {
                        payment_method_details_id: paymentMethodDetailsId,
                        verification_method: verificationType,
                        ...(note && { verification_notes: note }),
                    }

                    await handleVerification(verificationPayload)
                }
            } catch (error) {
                Sentry.captureException(error, {
                    extra: {
                        organizationId,
                        paymentMethodDetailsId,
                    },
                })
            }
        },
        [
            formData,
            formatMessage,
            organizationId,
            paymentMethodDetailsId,
            requestManualVerification,
            updatePaymentMethodDetails,
            uploadDocument,
        ]
    )

    const handleSetFile = (file: File) => {
        setFormData((prevState) => ({ ...prevState, file }))
        setFileError("")
    }

    const handleClearFile = () => {
        setFormData((prevState) => ({ ...prevState, file: null }))
    }

    const handleVerificationTypeChanged = (
        _: React.MouseEvent<HTMLElement>,
        newVerificationType: VerificationMethod
    ) => {
        if (!newVerificationType || newVerificationType === verificationType) return
        setVerificationType(newVerificationType)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setFormData((prevState) => ({ ...prevState, [name]: value }))
    }

    useEffect(() => {
        resetFields()
    }, [verificationType])

    return (
        <Modal open={open} size={Size.MD} onClose={handleClose}>
            <Modal.Header>
                <h4>{formatMessage(messages.title)}</h4>
            </Modal.Header>
            <Modal.Content>
                <Box
                    component="form"
                    id="manual-verification-form"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    onSubmit={handleSubmit}
                >
                    <ToggleButtonGroup
                        color="primary"
                        fullWidth
                        sx={toggleButtonsStyles}
                        value={verificationType}
                        exclusive
                        disabled={isLoadingState}
                        onChange={handleVerificationTypeChanged}
                    >
                        <ToggleButton value={VerificationMethod.PHONE}>{formatMessage(messages.phone)}</ToggleButton>
                        <ToggleButton value={VerificationMethod.EMAIL}>{formatMessage(messages.email)}</ToggleButton>
                        <ToggleButton value={VerificationMethod.DOCUMENT}>
                            {formatMessage(messages.document)}
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {verificationType === VerificationMethod.DOCUMENT && (
                        <>
                            <Typography variant="body1" color="var(--color-grey-light)">
                                {formatMessage(messages.uploadMessage)}
                            </Typography>
                            <Box>
                                {formData.file ? (
                                    <Stack
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        color="var(--color-grey)"
                                    >
                                        {formData.file.name}
                                        {!isLoadingState && (
                                            <IconButton onClick={handleClearFile}>
                                                <Trash size={16} />
                                            </IconButton>
                                        )}
                                    </Stack>
                                ) : (
                                    <UploadDocumentBox handleFile={handleSetFile} />
                                )}
                            </Box>
                            {fileError && <Box color="var(--color-red)">{fileError}</Box>}
                        </>
                    )}
                    <TextField
                        label={formatMessage(messages.note)}
                        fullWidth
                        multiline
                        rows={4}
                        name="note"
                        value={formData.note}
                        disabled={isLoadingState}
                        onChange={handleChange}
                    />
                    {isLoadingState && <LinearProgress />}
                </Box>
            </Modal.Content>
            <Modal.Footer>
                <Button type="transparent" disabled={isLoadingState} onClick={handleClose}>
                    {formatMessage(messages.cancel)}
                </Button>
                <Button buttonType="submit" form="manual-verification-form" disabled={isLoadingState}>
                    {formatMessage(messages.addVerification)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
