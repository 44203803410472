import { Divider, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { adaptExpressionToText } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, SendEmailNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"
import { SharedEmailConfiguration } from "./SharedEmailConfiguration"

export const SendEmailConfiguration: FC<ConfigurationProps<SendEmailNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            subject: e.target.value,
            metadata: {
                ...prev.metadata,
                additionalInformation: e.target.value,
            },
        }))
    }

    const handleChangeBody = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            body: e.target.value,
        }))
    }

    const handleChangeEmails = (recipientAddresses: string[]) => {
        setCurrentNode((prev) => ({
            ...prev,
            recipientAddresses,
        }))
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.sendEmailConfiguration.title} />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.subjectLabel)}
                    value={adaptExpressionToText(currentNode.subject)}
                    size="small"
                    onChange={handleChangeSubject}
                />

                <SharedEmailConfiguration onChangeEmails={handleChangeEmails} emails={currentNode.recipientAddresses} />

                <Divider />
                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.bodyLabel)}
                    value={currentNode.body}
                    multiline
                    rows={10}
                    onChange={handleChangeBody}
                />
            </Stack>

            <AdvancedFields<SendEmailNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
