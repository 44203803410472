import { Stack, Typography, styled } from "@mui/material"
import React, { FC } from "react"

import { Card, SafeFormattedMessage } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"

interface Props {
    storage: Record<string, unknown>
}

const JsonValue = styled(Typography)`
    font-family: monospace;
    padding: var(--spacing-md);
    color: var(--color-grey);
    border-radius: var(--border-radius-sm);
    background-color: var(--color-slate);
`

const CardTitle = ({ name }: { name: string }) => {
    return <Typography variant="subtitle2">{name}</Typography>
}

export const RunStorage: FC<Props> = ({ storage }) => {
    if (!storage || Object.keys(storage).length === 0) {
        return null
    }

    return (
        <Stack gap={2}>
            <Typography variant="body2">
                <SafeFormattedMessage {...messages.runInformation.storage} />
            </Typography>
            <Stack gap={2}>
                {Object.entries(storage).map(([key, value]) => (
                    <Card key={key} header={<CardTitle name={key} />} expandable>
                        <JsonValue whiteSpace="pre-wrap">{JSON.stringify(value, null, 2)}</JsonValue>
                    </Card>
                ))}
            </Stack>
        </Stack>
    )
}
