export enum AuthorizationName {
    READ = "read",
    VIEW = "view",
    CREATE = "create",
    UPDATE = "update",
    EDIT = "edit",
    DELETE = "delete",
    APPROVE = "approve",
    CONVERT = "convert",
    CONVERT_TO_PO_API = "convert_to_purchase_order",
    CONVERT_TO_PO = "convertToPurchaseOrder",
    OWNER = "owner",
    CHECK = "check",
    SUBMIT = "submit",
    SEND = "send",
    CLOSE = "close",
    REOPEN = "reopen",
    MARK_LINE_IN_PREPARATION = "mark_line_in_preparation",
    MARK_LINE_IN_TRANSIT = "mark_line_in_transit",
    MARK_LINE_DELIVERED_OR_RECEIVED = "mark_line_delivered_or_received",
    MARK_LINE_CANCELED = "mark_line_canceled",
}

export type SpiceDBPermissionsResult = {
    [key in AuthorizationName]?: boolean
}
