import { useCallback } from "react"

import { useCustomFieldsApi } from "~/domains/identity/custom-fields/customFieldsApi"
import { customFieldsActions } from "~/domains/identity/custom-fields/store"
import { CreateCustomFieldPayload } from "~/domains/identity/custom-fields/types/CreateCustomFieldPayload"
import { CreateCustomFieldObjectPayload } from "~/domains/identity/custom-fields/types/CustomFieldValue"
import { useAppDispatch } from "~/store/hooks"

export const useCreateCustomField = () => {
    const customFieldsApi = useCustomFieldsApi()
    const dispatch = useAppDispatch()

    return useCallback(
        async (payload: CreateCustomFieldPayload | CreateCustomFieldObjectPayload, allowCustom?: boolean) => {
            const customField = await customFieldsApi.createCustomField(payload, allowCustom)

            if (customField.result) {
                dispatch(
                    customFieldsActions.createCustomFieldObjectSuccess({
                        objectId: payload.objectId,
                        customField: customField.result,
                        value: payload.value,
                    })
                )
            }
            return customField.result
        },
        [customFieldsApi]
    )
}
