import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"

import {
    useCompleteFormMutation,
    useGetFormByTokenQuery,
    useGetSurveyStateQuery,
    useSaveUserAnswerMutation,
    useUploadFileAnswerMutation,
} from "~/domains/identity/custom-forms/api/customFormsApi"
import {
    CustomFormQuestion,
    FormViewerContextType,
    QuestionFieldTypeEnum,
    UserSurveyForm,
} from "~/domains/identity/custom-forms/types/CustomForms"

const FormViewerContext = createContext<FormViewerContextType | null>(null)

interface FormViewerProviderProps {
    children: React.ReactNode
    formToken: string
    viewMode?: string
}

// Context Provider for Form Data
export const FormViewerProvider = ({ children, formToken, viewMode }: FormViewerProviderProps) => {
    const [currentSection, setCurrentSection] = useState(0)
    const [formSent, setFormSent] = useState(false)
    const [formData, setFormData] = useState<UserSurveyForm | null>(null)
    const [currentSectionInvalid, setCurrentSectionInvalid] = useState(false)
    const [hiddenQuestions, setHiddenQuestions] = useState<string[]>([])
    const [focusQuestionId, setFocusQuestionId] = useState<string | null>(null)
    const { data: form, refetch } = useGetFormByTokenQuery({ formToken })
    const { data: surveyState, isFetching: surveyStateLoading } = useGetSurveyStateQuery({
        surveyToken: formToken,
    })
    const [saveUserAnswer] = useSaveUserAnswerMutation()
    const [uploadFileAnswer, { isLoading: uploadFileAnswerLoading }] = useUploadFileAnswerMutation()
    const [completeForm] = useCompleteFormMutation()
    useEffect(() => {
        setFormSent(Boolean(form?.status.Completed && !viewMode))
        if (form && form?.answers) {
            const enrichedSections = form.sections.map((section) => ({
                ...section,
                questions: section.questions
                    .map((q) => {
                        const answerObject = form.answers.find((a) => a.questionId === q.id)
                        if (!answerObject) {
                            return {
                                ...q,
                                invalid: false,
                            }
                        }

                        const savedValue = answerObject.value

                        return { ...q, savedValue, invalid: false }
                    })
                    .sort((a, b) => a.order - b.order),
            }))
            setFormData({ ...form, sections: enrichedSections })
        }
    }, [form])

    useEffect(() => {
        // need to fetch the form data when section changes
        refetch()
    }, [currentSection, refetch])

    useEffect(() => {
        if (surveyState) {
            setHiddenQuestions(surveyState.questions.filter((q) => !q.visible).map((q) => q.questionId))
        }
    }, [surveyState])

    const updateQuestion = useCallback(
        (updatedQuestion: CustomFormQuestion) => {
            setFormData((prev) => {
                if (!prev) return null
                const updatedSections = prev.sections.map((section, idx) => {
                    if (idx === currentSection) {
                        return {
                            ...section,
                            questions: section.questions.map((q) =>
                                q.id === updatedQuestion.id ? updatedQuestion : q
                            ),
                        }
                    }
                    return section
                })
                return { ...prev, sections: updatedSections }
            })
        },
        [currentSection]
    )

    const validateSection = useCallback(() => {
        if (!formData) return false

        const checkQuestionInvalid = (question: CustomFormQuestion) => {
            if (!question.required || hiddenQuestions.includes(question.id)) return false

            // false is a valid value for radio buttons
            if (QuestionFieldTypeEnum.YesNo in question.fieldType) return question.savedValue === undefined

            // For single and multichoice we also need to check the length of the array
            if (
                QuestionFieldTypeEnum.MultiChoice in question.fieldType ||
                QuestionFieldTypeEnum.SingleChoice in question.fieldType
            ) {
                return !question.savedValue || (Array.isArray(question.savedValue) && question.savedValue.length === 0)
            }

            return !question.savedValue
        }

        let firstInvalidQuestionId = ""
        let isInvalid = false
        const updatedQuestions = formData.sections[currentSection].questions.map((q) => {
            const invalid = checkQuestionInvalid(q)
            if (invalid) {
                isInvalid = true
                if (!firstInvalidQuestionId) {
                    firstInvalidQuestionId = q.id
                }
            }
            return { ...q, invalid }
        })

        // Set the first invalid question to focus on the element
        // this is done this way to avoid stroring refs in the context
        if (firstInvalidQuestionId) {
            setFocusQuestionId(firstInvalidQuestionId)
            // reset focus after a short delay to enable the behaviour where user
            // can click on the submit button again without filling out the field
            setTimeout(() => {
                setFocusQuestionId(null)
            }, 500)
        }

        // update state
        setFormData((prev) => {
            if (!prev) return null
            const updatedSections = prev.sections.map((section, idx) => {
                if (idx === currentSection) {
                    return {
                        ...section,
                        questions: updatedQuestions,
                    }
                }
                return section
            })
            return { ...prev, sections: updatedSections }
        })
        setCurrentSectionInvalid(isInvalid)
        return isInvalid
    }, [formData, currentSection, hiddenQuestions])

    const handleSaveAnswer = useCallback(
        (question: CustomFormQuestion, value) => {
            const answerKey = `${Object.keys(question.fieldType)[0]}Answer`
            const formattedValue = "YesNo" in question.fieldType ? value === "true" : value

            if (QuestionFieldTypeEnum.FileField in question.fieldType) {
                uploadFileAnswer({
                    surveyToken: formToken,
                    questionId: question.id,
                    file: value,
                })
                updateQuestion({ ...question, savedValue: formattedValue })
            } else {
                saveUserAnswer({
                    surveyToken: formToken,
                    questionId: question.id,
                    answer: { [answerKey]: { value: formattedValue } },
                })

                updateQuestion({ ...question, savedValue: formattedValue })
            }
            setFocusQuestionId(null)
        },
        [saveUserAnswer, formToken, updateQuestion, uploadFileAnswer]
    )

    const sendForm = useCallback(() => {
        completeForm({ surveyToken: formToken })
        setFormSent(true)
    }, [formToken, completeForm])

    const contextValue = useMemo(
        () => ({
            formData,
            currentSection,
            setCurrentSection,
            formSent,
            viewMode,
            currentSectionInvalid,
            validateSection,
            handleSaveAnswer,
            focusQuestionId,
            hiddenQuestions,
            surveyStateLoading,
            sendForm,
            uploadFileAnswerLoading,
        }),
        [
            formData,
            currentSection,
            formSent,
            viewMode,
            currentSectionInvalid,
            validateSection,
            handleSaveAnswer,
            focusQuestionId,
            hiddenQuestions,
            surveyStateLoading,
            sendForm,
            uploadFileAnswerLoading,
        ]
    )

    return <FormViewerContext.Provider value={contextValue}>{children}</FormViewerContext.Provider>
}

// Custom Hook to Access Form Context
export const useFormViewerContext = () => {
    const context = useContext(FormViewerContext)
    if (!context) {
        throw new Error("useFormViewerContext must be used within FormViewerProvider")
    }
    return context
}
