import { toast } from "react-toastify"

import { NewInvitationsToastContent } from "~/components/Toasts/NewInvitationsToast/NewInvitationsToastContent"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"

export const NewInvitationsToast = () => {
    const currentUser = useAppSelector(selectUser)

    if (!currentUser?.invitations?.length) return null

    toast.info(<NewInvitationsToastContent invitations={currentUser?.invitations} />, {
        autoClose: false,
        closeOnClick: false,
    })

    return null
}
