import { Box, Stack, Tab, Tabs } from "@mui/material"
import { SyntheticEvent } from "react"
import { MessageDescriptor } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"

export type HeaderNavigationItem = {
    label: MessageDescriptor
    value: string
    onClick: () => void
    hidden?: boolean
    showBetaFlag?: boolean
}

interface HeaderNavigationProps {
    items: HeaderNavigationItem[]
    selectedIndex: number
    onChange: (value: string) => void
}

export const HeaderNavigation = ({ items, selectedIndex, onChange }: HeaderNavigationProps) => {
    const handleChange = (_: SyntheticEvent, index: number) => {
        const newValue = items[index].value
        onChange(newValue)
    }

    const getItemLabel = (item: HeaderNavigationItem) => (
        <Stack direction="row" alignItems="center" gap={1}>
            <SafeFormattedMessage {...item.label} />
            {item.showBetaFlag && (
                <span>
                    (<SafeFormattedMessage {...commonMessages.beta} />)
                </span>
            )}
        </Stack>
    )

    return (
        <Box justifyContent="center" paddingBlock={2} width="100%">
            <Tabs value={selectedIndex} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {items.map(
                    (item) => !item.hidden && <Tab key={item.value} label={getItemLabel(item)} onClick={item.onClick} />
                )}
            </Tabs>
        </Box>
    )
}
