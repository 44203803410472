import { Autocomplete, Checkbox, Divider, FormControlLabel, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { useGetOrganisationCustomFormsQuery } from "~/domains/identity/custom-forms/api/customFormsApi"
import { CustomForm } from "~/domains/identity/custom-forms/types/CustomForms"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/useOrganizationId"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    ConfigurationProps,
    CreateSurveyNode,
    EmailRespondent,
    RespondentType,
    UserRespondent,
} from "~/domains/orchestration/flows/types"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI } from "~/types"

import { ConfigurationNode } from "./ConfigurationNode"
import { SharedEmailConfiguration } from "./SharedEmailConfiguration"
import { SharedUserSelect } from "./SharedUserSelect"

export const CreateSurveyConfiguration: FC<ConfigurationProps<CreateSurveyNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)

    const [currentNode, setCurrentNode] = useState(selectedNode)

    const organisationId = useOrganizationId()
    const { data: customForms = [], isLoading } = useGetOrganisationCustomFormsQuery({ organisationId })

    const handleChangeForm = (_, value: CustomForm | null) => {
        if (!value) return

        setCurrentNode({
            ...currentNode,
            formId: value.id,
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value.name,
            },
        })
    }

    const handleNotificationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            sendNotifications: event.target.checked,
        })
    }

    const handleUserSelect = (_, values: OrganizationMemberUserI[]) => {
        const userRespondents: UserRespondent[] = values.map((member) => ({
            type: RespondentType.USER,
            userId: adaptTextToExpression(member.userId),
        }))

        const emailRespondents = currentNode.respondents.filter(
            (r): r is EmailRespondent => r.type === RespondentType.EMAIL
        )

        setCurrentNode({
            ...currentNode,
            respondents: [...emailRespondents, ...userRespondents],
        })
    }

    const handleChangeEmails = (emails: string[]) => {
        setCurrentNode((prev) => ({
            ...prev,
            respondents: emails.map((email) => ({
                type: RespondentType.EMAIL,
                emailAddress: email,
            })),
        }))
    }

    const form = customForms.find((f) => f.id === currentNode.formId) || null
    const selectedUsers = members.filter((member) =>
        currentNode.respondents.some(
            (r) => r.type === "UserRespondent" && adaptExpressionToText(r.userId) === member.userId
        )
    )

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const emails = currentNode.respondents
        .filter((r): r is EmailRespondent => r.type === RespondentType.EMAIL)
        .map((r) => r.emailAddress)

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <Autocomplete
                    options={customForms}
                    value={form}
                    onChange={handleChangeForm}
                    getOptionLabel={(f: CustomForm) => f.name}
                    isOptionEqualToValue={(option: CustomForm, value: CustomForm) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.advancedFields.formId)} />
                    )}
                    loading={isLoading}
                />

                <FormControlLabel
                    control={<Checkbox checked={currentNode.sendNotifications} onChange={handleNotificationsChange} />}
                    label={formatMessage(messages.createSurveyConfiguration.sendNotifications)}
                />

                <Divider />

                <Stack gap={1}>
                    <SafeFormattedMessage tagName="h5" {...messages.createSurveyConfiguration.emailRespondents} />
                    <SharedEmailConfiguration
                        onChangeEmails={handleChangeEmails}
                        emails={emails}
                        label={formatMessage(messages.createSurveyConfiguration.emailLabel)}
                    />
                </Stack>

                <Divider />

                <Stack gap={1}>
                    <SafeFormattedMessage tagName="h5" {...messages.createSurveyConfiguration.userRespondents} />
                    <SharedUserSelect
                        members={members}
                        selectedUsers={selectedUsers}
                        onSelectUsers={handleUserSelect}
                        label={formatMessage(messages.createSurveyConfiguration.userLabel)}
                    />
                </Stack>
            </Stack>

            <AdvancedFields<CreateSurveyNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
