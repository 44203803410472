import * as t from "io-ts"

import { CollaborationStatus, PartnershipType, SourceType } from "~/domains/identity/partners/types"
import { OrganizationAddressIO } from "~/types"
import { optional } from "~/utils"

export const SourceTypeIO = t.union([
    t.literal(SourceType.ERP),
    t.literal(SourceType.INVOICE),
    t.literal(SourceType.MANUAL),
    t.literal(SourceType.PURCHASE_ORDER),
    t.literal(SourceType.PURCHASE_REQUEST),
    t.literal(SourceType.NONE),
])

export const CollaborationStatusIO = t.union([
    t.literal(CollaborationStatus.ACTIVE),
    t.literal(CollaborationStatus.INACTIVE),
    t.literal(CollaborationStatus.REFUSED),
    t.literal(CollaborationStatus.IN_REVIEW),
    t.literal(CollaborationStatus.CLOTURED),
    t.literal(CollaborationStatus.EXCLUDED),
    t.literal(CollaborationStatus.DRAFT),
])

export const OneSideDataIO = t.partial({
    pendingAmount: optional(t.string),
    pendingAmountExcludedTaxes: optional(t.string),
    pendingNumber: optional(t.number),
    overdueAmount: optional(t.string),
    overdueAmountExcludedTaxes: optional(t.string),
    overdueNumber: optional(t.number),
    paidAmount: optional(t.string),
    paidAmountExcludedTaxes: optional(t.string),
    paidNumber: optional(t.number),
    aggregatedTimeToPayInDays: optional(t.string),
    averagePaymentDelayInDays: optional(t.string),
    partnershipId: optional(t.string),
    source: optional(SourceTypeIO),
    preferredStatus: optional(t.boolean),
    verifiedStatus: optional(t.boolean),
    collaborationStatus: optional(CollaborationStatusIO),
    x3InternalId: optional(t.string),
    ifsInternalId: optional(t.string),
    approvalStatus: optional(t.string),
})

export const preferredRegistrationNumberIO = t.type({
    registrationType: t.string,
    registrationNumber: t.string,
})

export const PartnerDataRegistrationIO = t.intersection([
    t.type({
        legalName: t.string,
        preferredRegistrationNumber: preferredRegistrationNumberIO,
    }),
    t.partial({
        countryCode: optional(t.string),
        dunsNumber: optional(t.string),
        vatNumber: optional(t.string),
    }),
])

export const PartnersDataIO = t.intersection([
    t.type({
        organizationId: t.string,
        organizationName: t.string,
        registration: PartnerDataRegistrationIO,
    }),
    t.partial({
        brandName: optional(t.string),
        lastAction: optional(t.string),
        supplier: optional(OneSideDataIO),
        buyer: optional(OneSideDataIO),
        address: optional(OrganizationAddressIO),
    }),
])

export const PartnerOrganizationIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        registration: PartnerDataRegistrationIO,
    }),
    t.partial({
        address: optional(OrganizationAddressIO),
    }),
])

export const PartnershipTypeIO = t.union([t.literal(PartnershipType.BUYER), t.literal(PartnershipType.SUPPLIER)])

export const PartnershipIO = t.type({
    id: t.string,
    initiatorId: t.string,
    partnerId: t.string,
    partnershipType: PartnershipTypeIO,
    source: SourceTypeIO,
    preferredStatus: t.boolean,
    verifiedStatus: t.boolean,
    collaborationStatus: t.string,
})

export const CreatePartnerProfileContactIO = t.partial({
    lastName: optional(t.string),
    firstName: optional(t.string),
    email: optional(t.string),
    phoneNumber: optional(t.string),
    position: optional(t.string),
    language: optional(t.string),
    timezone: optional(t.string),
    userId: optional(t.string),
})

export const PartnerProfileContactIO = t.intersection([
    t.type({
        id: t.string,
    }),
    CreatePartnerProfileContactIO,
])

export const PartnerProfileContactArrayIO = t.array(PartnerProfileContactIO)

export const CreatePartnerProfileAddressIO = t.intersection([
    t.type({
        addressLine1: t.string,
        zipCode: t.string,
        city: t.string,
        country: t.string,
    }),
    t.partial({
        label: optional(t.string),
        contactLine: optional(t.string),
        addressLine2: optional(t.string),
        region: optional(t.string),
        extra: optional(t.string),
    }),
])

export const PartnerProfileAddressIO = t.intersection([
    t.type({
        id: t.string,
    }),
    CreatePartnerProfileAddressIO,
])

export const PartnerProfileAddressArrayIO = t.array(PartnerProfileAddressIO)

export const CreatePartnerProfilePaymentDetailIO = t.partial({
    label: optional(t.string),
    iban: optional(t.string),
    bic: optional(t.string),
    bankName: optional(t.string),
    paymentMethod: optional(t.string),
    verifiedStatus: optional(t.boolean),
})

export const PartnerProfilePaymentDetailIO = t.intersection([
    t.type({
        id: t.string,
        source: SourceTypeIO,
        createdAt: t.string,
    }),
    CreatePartnerProfilePaymentDetailIO,
])

export const PartnerProfilePaymentDetailArrayIO = t.array(PartnerProfilePaymentDetailIO)

export const commonPartnerProfileIO = t.type({
    initiatorId: t.string,
    partnerId: t.string,
    brandName: optional(t.string),
    description: optional(t.string),
    paymentTerms: optional(t.string),
    responsibleUserId: optional(t.string),
})

export const CreatePartnerProfileIO = t.intersection([
    commonPartnerProfileIO,
    t.type({
        paymentDetails: t.array(CreatePartnerProfilePaymentDetailIO),
        contacts: t.array(CreatePartnerProfileContactIO),
        addresses: t.array(CreatePartnerProfileAddressIO),
    }),
])

export const PartnerProfileIO = t.intersection([
    commonPartnerProfileIO,
    t.type({
        paymentDetails: t.array(PartnerProfilePaymentDetailIO),
        contacts: t.array(PartnerProfileContactIO),
        addresses: t.array(PartnerProfileAddressIO),
    }),
])
