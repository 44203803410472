export enum PurchaseViewType {
    "ADD" = "ADD",
    "EDIT" = "EDIT",
    "VIEW" = "VIEW",
}

export interface CreatePurchaseResponse {
    id: string
}

export enum PurchaseType {
    REQUEST = "REQUEST",
    ORDER = "ORDER",
}

export const FulfillmentStatus = ["PENDING", "IN_PREPARATION", "OUT_FOR_DELIVERY", "DELIVERED", "CANCELED"] as const
export type FulfillmentStatus = (typeof FulfillmentStatus)[number]

export enum FulfillmentType {
    BUYER = "BUYER",
    SUPPLIER = "SUPPLIER",
}
