/* eslint-disable max-lines */
import {
    AddToBudgetNode,
    ApiAddToBudgetNode,
    ApiApprovePurchaseOrderLineNode,
    ApiApprovePurchaseOrderNode,
    ApiApprovePurchaseRequestLineNode,
    ApiApprovePurchaseRequestNode,
    ApiAssignTagNode,
    ApiBranch,
    ApiBranchNode,
    ApiCheckNode,
    ApiConvertPrToPoNode,
    ApiCreateCustomFieldNode,
    ApiCreateSurveyNode,
    ApiCreateTaskNode,
    ApiEvent,
    ApiEventTriggerNode,
    ApiFetchCustomFieldsNode,
    ApiFetchPartnershipNode,
    ApiFitsToBudgetNode,
    ApiFlowNode,
    ApiFlowUpdate,
    ApiFulfillmentStatus,
    ApiGetTagByGroupNode,
    ApiIfNode,
    ApiInvoiceToPurchaseOrderMatchNode,
    ApiMappingNode,
    ApiMappingNodeElement,
    ApiNewFlow,
    ApiNode,
    ApiNodeType,
    ApiObjectType,
    ApiPoStatus,
    ApiRefusePurchaseOrderNode,
    ApiRefusePurchaseRequestNode,
    ApiRetractReviewsNode,
    ApiReviewer,
    ApiSendEmailNode,
    ApiSetInvoiceLifecycleStatusNode,
    ApiSetPartnershipFieldNode,
    ApiSetPaymentMethodDetailsFieldNode,
    ApiSetPoFulfillmentStatusNode,
    ApiSetPoStatusNode,
    ApiSuspendUntilSurveyCompletedNode,
    ApiTaskPriority,
    ApiTaskStatus,
    ApiTeamReviewer,
    ApiUpdateCustomFieldNode,
    ApiUpdateTripletexLedgerNode,
    ApiUserReviewer,
    ApiUserType,
    ApprovePurchaseOrderLineNode,
    ApprovePurchaseOrderNode,
    ApprovePurchaseRequestLineNode,
    ApprovePurchaseRequestNode,
    AssignTagGroupNode,
    Branch,
    BranchNode,
    CheckNode,
    ConvertPrToPoNode,
    CreateCustomFieldNode,
    CreateFlowBody,
    CreateSurveyNode,
    CreateTaskNode,
    EditorNode,
    EventTriggerNode,
    FetchCustomFieldsNode,
    FetchPartnershipNode,
    FitsToBudgetNode,
    Flow,
    FlowNode,
    GetTagByGroupNode,
    IfNode,
    InvoiceToPurchaseOrderMatchNode,
    MappingNode,
    MappingNodeElement,
    NodeType,
    RefusePurchaseOrderNode,
    RefusePurchaseRequestNode,
    RetractReviewsNode,
    Reviewer,
    SendEmailNode,
    SetInvoiceLifecycleStatusNode,
    SetPartnershipFieldNode,
    SetPaymentMethodDetailsFieldNode,
    SetPoFulfillmentStatusNode,
    SetPoStatusNode,
    SuspendUntilSurveyCompletedNode,
    TaskPriority,
    TaskStatus,
    TransactionType,
    UpdateCustomFieldNode,
    UpdateTripletexLedgerNode,
    isApiFlowNode,
} from "~/domains/orchestration/flows/types"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types"

const adaptReviewerToApi = (reviewer: Reviewer): ApiReviewer => {
    if ("userId" in reviewer) {
        return {
            userId: reviewer.userId,
            type: ApiUserType.USER,
        } as ApiUserReviewer
    }
    return {
        teamId: reviewer.teamId,
        type: ApiUserType.TEAM,
    } as ApiTeamReviewer
}

const adaptCommonNodeProperties = (node: FlowNode): ApiNode => {
    return {
        slug: node.slug,
        name: node.name.trim() || null,
        metadata: node.metadata,
    }
}

const adaptTransactionTypeToApi = (value: TransactionType | null) => {
    return value || ""
}

const adaptExpressionToApi = (value: string) => `'${value}'`

const adaptBranchToApi = (branch: Branch): ApiBranch => {
    return {
        name: branch.name,
        conditions: branch.conditions,
        nextNode: branch.nextNode || undefined,
    }
}

const adaptEventTriggerNodeToApi = (node: EventTriggerNode): ApiEventTriggerNode => {
    const nodeToAdapt: ApiEventTriggerNode = {
        ...adaptCommonNodeProperties(node),
        event: node.event as unknown as ApiEvent,
        type: node.type as unknown as ApiNodeType.EVENT_TRIGGER_NODE,
        nextNode: node.nextNode || undefined,
    }
    if (node.filter.conditions.length > 0 && node.filter.conditions[0].length > 0) {
        nodeToAdapt.filter = node.filter
    }
    return nodeToAdapt
}

const adaptIfNodeToApi = (node: IfNode): ApiIfNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.IF_NODE,
        condition: node.condition,
        nextIfTrue: node.nextIfSuccess || undefined,
        nextIfFalse: node.nextIfFailure || undefined,
    }
}

const adaptSetPartnershipFieldNodeToApi = (node: SetPartnershipFieldNode): ApiSetPartnershipFieldNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.SET_PARTNERSHIP_FIELD_NODE,
        partnershipId: node.partnershipId,
        fieldToUpdate: node.fieldToUpdate,
        valueToSet: node.valueToSet,
        nextNode: node.nextNode || undefined,
    }
}

const adaptCheckNodeToApi = (node: CheckNode): ApiCheckNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.CHECK_NODE,
        objectId: node.objectId,
        objectType: node.objectType as unknown as ApiObjectType,
        reviewers: node.reviewers.map(adaptReviewerToApi),
        passThreshold: node.passThreshold,
        refuseThreshold: node.refuseThreshold,
        nextIfPassed: node.nextIfSuccess || undefined,
        nextIfRefused: node.nextIfFailure || undefined,
    }
}

const adaptSendEmailNodeToApi = (node: SendEmailNode): ApiSendEmailNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.SEND_EMAIL_NODE,
        subject: node.subject,
        recipientAddresses: node.recipientAddresses,
        body: adaptExpressionToApi(node.body),
        nextNode: node.nextNode || undefined,
    }
}

const adaptAddToBudgetNodeToApi = (node: AddToBudgetNode): ApiAddToBudgetNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.ADD_TO_BUDGET_NODE,
        amount: node.amount,
        budgetId: adaptExpressionToApi(node.budgetId),
        transactionId: node.transactionId,
        transactionType: adaptTransactionTypeToApi(node.transactionType),
        failIfOverbudget: node.failIfOverbudget,
        currency: node.currency,
        nextNode: node.nextNode || undefined,
    }
}

const adaptInvoiceToPurchaseOrderMatchNodeToApi = (
    node: InvoiceToPurchaseOrderMatchNode
): ApiInvoiceToPurchaseOrderMatchNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
        invoiceId: node.invoiceId,
        nextIfMatched: node.nextIfSuccess || undefined,
        nextIfNotMatched: node.nextIfFailure || undefined,
        metadata: node.metadata,
    }
}

const adaptSetInvoiceLifecycleStatusNodeToApi = (
    node: SetInvoiceLifecycleStatusNode
): ApiSetInvoiceLifecycleStatusNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
        invoiceId: node.invoiceId,
        statusToSet: node.statusToSet,
        nextNode: node.nextNode || undefined,
    }
}

const adaptFitsToBudgetNodeToApi = (node: FitsToBudgetNode): ApiFitsToBudgetNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.FITS_TO_BUDGET_NODE,
        budgetId: adaptExpressionToApi(node.budgetId),
        transactionId: node.transactionId,
        transactionType: adaptTransactionTypeToApi(node.transactionType),
        amount: node.amount,
        currency: node.currency,
        nextIfFits: node.nextIfSuccess || undefined,
        nextIfDoesNotFit: node.nextIfFailure || undefined,
    }
}

const adaptAssignTagGroupNodeToApi = (node: AssignTagGroupNode): ApiAssignTagNode[] => {
    return node.nodes.map((n) => ({
        ...adaptCommonNodeProperties(n),
        metadata: {
            ...node.metadata,
            group: {
                id: node.slug,
                name: node.name || "",
            },
            additionalInformation: node.metadata.additionalInformation,
        },
        type: n.type as unknown as ApiNodeType.ASSIGN_TAG_NODE,
        objectId: n.objectId,
        objectType: n.objectType as unknown as ApiObjectType,
        tagId: n.tagId,
        nextNode: n.nextNode || undefined,
    }))
}

const adaptUpdateTripletexLedgerNodeToApi = (node: UpdateTripletexLedgerNode): ApiUpdateTripletexLedgerNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.UPDATE_TRIPLETEX_LEDGER_NODE,
        ledgerId: node.ledgerId,
        ledgerDate: node.ledgerDate || null,
        ledgerDescription: node.ledgerDescription ? adaptExpressionToApi(node.ledgerDescription) : null,
        accountToCredit: node.accountToCredit,
        accountToDebit: node.accountToDebit,
        amount: node.amount,
        amountGross: node.amountGross,
        currency: node.currency,
        nextNode: node.nextNode || undefined,
    }
}

const adaptApprovePurchaseOrderNodeToApi = (node: ApprovePurchaseOrderNode): ApiApprovePurchaseOrderNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_ORDER_NODE,
        purchaseOrderId: node.purchaseOrderId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptApprovePurchaseOrderLineNodeToApi = (
    node: ApprovePurchaseOrderLineNode
): ApiApprovePurchaseOrderLineNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_ORDER_LINE_NODE,
        purchaseOrderLineId: node.purchaseOrderLineId,
        purchaseOrderId: node.purchaseOrderId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptConvertPrToPoNodeToApi = (node: ConvertPrToPoNode): ApiConvertPrToPoNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.CONVERT_PR_TO_PO_NODE,
        purchaseRequestId: node.purchaseRequestId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptApprovePurchaseRequestNodeToApi = (node: ApprovePurchaseRequestNode): ApiApprovePurchaseRequestNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_REQUEST_NODE,
        purchaseRequestId: node.purchaseRequestId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptApprovePurchaseRequestLineNodeToApi = (
    node: ApprovePurchaseRequestLineNode
): ApiApprovePurchaseRequestLineNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
        purchaseRequestId: node.purchaseRequestId,
        purchaseRequestLineId: node.purchaseRequestLineId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptBranchNodeToApi = (node: BranchNode): ApiBranchNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.BRANCH_NODE,
        branches: node.branches.filter((branch) => branch.conditions.length > 0).map(adaptBranchToApi),
        default: node.default,
    }
}

const adaptSetPaymentMethodDetailsFieldNodeToApi = (
    node: SetPaymentMethodDetailsFieldNode
): ApiSetPaymentMethodDetailsFieldNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
        paymentMethodDetailsId: node.paymentMethodDetailsId,
        fieldToUpdate: node.fieldToUpdate,
        valueToSet: node.valueToSet,
        nextNode: node.nextNode || undefined,
    }
}

const adaptCreateSurveyNodeToApi = (node: CreateSurveyNode): ApiCreateSurveyNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.CREATE_SURVEY_NODE,
        formId: adaptExpressionToApi(node.formId),
        respondentOrganizationId: node.respondentOrganizationId || undefined,
        sendNotifications: node.sendNotifications,
        respondents: node.respondents,
        nextNode: node.nextNode || undefined,
    }
}

const adaptRetractReviewsNodeToApi = (node: RetractReviewsNode): ApiRetractReviewsNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.RETRACT_REVIEWS_NODE,
        objectId: node.objectId,
        objectType: node.objectType as unknown as ApiObjectType,
        nextNode: node.nextNode || undefined,
    }
}

const adaptFetchCustomFieldsNodeToApi = (node: FetchCustomFieldsNode): ApiFetchCustomFieldsNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.FETCH_CUSTOM_FIELDS_NODE,
        objectId: node.objectId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptMappingNodeElementToApi = (element: MappingNodeElement): ApiMappingNodeElement => ({
    label: element.label,
    value: element.value || "''",
})

const adaptMappingNodeToApi = (node: MappingNode): ApiMappingNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.MAPPING_NODE,
        valueToMap: node.valueToMap,
        mappingTable: Object.fromEntries(
            Object.entries(node.mappingTable).map(([key, value]) => [key, value.map(adaptMappingNodeElementToApi)])
        ),
        defaultValues: node.defaultValues.map(adaptMappingNodeElementToApi),
        nextNode: node.nextNode || undefined,
    }
}

const adaptGetTagByGroupNodeToApi = (node: GetTagByGroupNode): ApiGetTagByGroupNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.GET_TAG_BY_GROUP_NODE,
        objectId: node.objectId,
        tagGroupId: adaptExpressionToApi(node.tagGroupId),
        nextNode: node.nextNode || undefined,
    }
}

const adaptUpdateCustomFieldNodeToApi = (node: UpdateCustomFieldNode): ApiUpdateCustomFieldNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.UPDATE_CUSTOM_FIELD_NODE,
        customFieldId: node.customFieldId,
        value: node.value,
        nextNode: node.nextNode || undefined,
    }
}

const adaptTaskPriorityToApi = (priority?: TaskPriority): ApiTaskPriority | undefined => {
    switch (priority) {
        case TaskPriority.LOW:
            return ApiTaskPriority.LOW
        case TaskPriority.MEDIUM:
            return ApiTaskPriority.MEDIUM
        case TaskPriority.HIGH:
            return ApiTaskPriority.HIGH
        case TaskPriority.URGENT:
            return ApiTaskPriority.URGENT
        default:
            return undefined
    }
}

const adaptTaskStatusToApi = (status?: TaskStatus): ApiTaskStatus | undefined => {
    switch (status) {
        case TaskStatus.PENDING:
            return ApiTaskStatus.PENDING
        case TaskStatus.IN_PROGRESS:
            return ApiTaskStatus.IN_PROGRESS
        case TaskStatus.COMPLETED:
            return ApiTaskStatus.COMPLETED
        default:
            return undefined
    }
}
const adaptCreateTaskNodeToApi = (node: CreateTaskNode): ApiCreateTaskNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.CREATE_TASK_NODE,
        title: node.title ? adaptExpressionToApi(node.title) : undefined,
        description: node.description ? adaptExpressionToApi(node.description) : undefined,
        dueDate: node.dueDate ? node.dueDate : undefined,
        priority: adaptTaskPriorityToApi(node.priority),
        status: adaptTaskStatusToApi(node.status),
        assignee: node.assignee ? adaptExpressionToApi(node.assignee) : undefined,
        parentTaskId: node.parentTaskId ? node.parentTaskId : undefined,
        public: node.public ? "true" : "false",
        followers: node.followers.length > 0 ? node.followers.map(adaptExpressionToApi) : undefined,
        parties: node.parties.length > 0 ? node.parties.filter(Boolean).map(adaptExpressionToApi) : undefined,
        nextNode: node.nextNode || undefined,
    }
}

const adaptFetchPartnershipNodeToApi = (node: FetchPartnershipNode): ApiFetchPartnershipNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.FETCH_PARTNERSHIP_NODE,
        partnerId: node.partnerId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptCreateCustomFieldNodeToApi = (node: CreateCustomFieldNode): ApiCreateCustomFieldNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.CREATE_CUSTOM_FIELD_NODE,
        objectId: node.objectId,
        customFieldName: node.customFieldName,
        customFieldValue: node.customFieldValue,
        nextNode: node.nextNode || undefined,
    }
}

const adaptFulfillmentStatusToApi = (status: FulfillmentStatus | null): ApiFulfillmentStatus => {
    switch (status) {
        case "IN_PREPARATION":
            return ApiFulfillmentStatus.IN_PREPARATION
        case "OUT_FOR_DELIVERY":
            return ApiFulfillmentStatus.OUT_FOR_DELIVERY
        case "DELIVERED":
            return ApiFulfillmentStatus.DELIVERED
        case "CANCELED":
            return ApiFulfillmentStatus.CANCELED
        default:
            throw new Error(`Unknown fulfillment status: ${status}`)
    }
}

const adaptSetPoFulfillmentStatusNodeToApi = (node: SetPoFulfillmentStatusNode): ApiSetPoFulfillmentStatusNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.SET_PO_FULFILLMENT_STATUS_NODE,
        purchaseOrderId: node.purchaseOrderId,
        statusToSet: adaptFulfillmentStatusToApi(node.statusToSet),
        nextNode: node.nextNode || undefined,
    }
}

const adaptSuspendUntilSurveyCompletedNodeToApi = (
    node: SuspendUntilSurveyCompletedNode
): ApiSuspendUntilSurveyCompletedNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE,
        surveyId: node.surveyId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptSetPoStatusNodeToApi = (node: SetPoStatusNode): ApiSetPoStatusNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.SET_PO_STATUS_NODE,
        purchaseOrderId: node.purchaseOrderId,
        statusToSet: node.statusToSet as unknown as ApiPoStatus,
        nextNode: node.nextNode || undefined,
    }
}

const adaptRefusePurchaseRequestNodeToApi = (node: RefusePurchaseRequestNode): ApiRefusePurchaseRequestNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.REFUSE_PURCHASE_REQUEST_NODE,
        purchaseRequestId: node.purchaseRequestId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptRefusePurchaseOrderNodeToApi = (node: RefusePurchaseOrderNode): ApiRefusePurchaseOrderNode => {
    return {
        ...adaptCommonNodeProperties(node),
        type: node.type as unknown as ApiNodeType.REFUSE_PURCHASE_ORDER_NODE,
        purchaseOrderId: node.purchaseOrderId,
        nextNode: node.nextNode || undefined,
    }
}

const adaptNodeToApi = (node: EditorNode): ApiFlowNode | ApiFlowNode[] | null => {
    const nodeTypeToAdapter: Record<NodeType, (node: never) => ApiFlowNode | ApiFlowNode[] | null> = {
        [NodeType.EVENT_TRIGGER_NODE]: adaptEventTriggerNodeToApi,
        [NodeType.IF_NODE]: adaptIfNodeToApi,
        [NodeType.SET_PARTNERSHIP_FIELD_NODE]: adaptSetPartnershipFieldNodeToApi,
        [NodeType.CHECK_NODE]: adaptCheckNodeToApi,
        [NodeType.SEND_EMAIL_NODE]: adaptSendEmailNodeToApi,
        [NodeType.ADD_TO_BUDGET_NODE]: adaptAddToBudgetNodeToApi,
        [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: adaptInvoiceToPurchaseOrderMatchNodeToApi,
        [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: adaptSetInvoiceLifecycleStatusNodeToApi,
        [NodeType.FITS_TO_BUDGET_NODE]: adaptFitsToBudgetNodeToApi,
        [NodeType.ASSIGN_TAG_GROUP_NODE]: adaptAssignTagGroupNodeToApi,
        [NodeType.UPDATE_TRIPLETEX_LEDGER_NODE]: adaptUpdateTripletexLedgerNodeToApi,
        [NodeType.APPROVE_PURCHASE_ORDER_NODE]: adaptApprovePurchaseOrderNodeToApi,
        [NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE]: adaptApprovePurchaseOrderLineNodeToApi,
        [NodeType.CONVERT_PR_TO_PO_NODE]: adaptConvertPrToPoNodeToApi,
        [NodeType.APPROVE_PURCHASE_REQUEST_NODE]: adaptApprovePurchaseRequestNodeToApi,
        [NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE]: adaptApprovePurchaseRequestLineNodeToApi,
        [NodeType.BRANCH_NODE]: adaptBranchNodeToApi,
        [NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE]: adaptSetPaymentMethodDetailsFieldNodeToApi,
        [NodeType.CREATE_SURVEY_NODE]: adaptCreateSurveyNodeToApi,
        [NodeType.RETRACT_REVIEWS_NODE]: adaptRetractReviewsNodeToApi,
        [NodeType.FETCH_CUSTOM_FIELDS_NODE]: adaptFetchCustomFieldsNodeToApi,
        [NodeType.MAPPING_NODE]: adaptMappingNodeToApi,
        [NodeType.GET_TAG_BY_GROUP_NODE]: adaptGetTagByGroupNodeToApi,
        [NodeType.UPDATE_CUSTOM_FIELD_NODE]: adaptUpdateCustomFieldNodeToApi,
        [NodeType.CREATE_TASK_NODE]: adaptCreateTaskNodeToApi,
        [NodeType.FETCH_PARTNERSHIP_NODE]: adaptFetchPartnershipNodeToApi,
        [NodeType.CREATE_CUSTOM_FIELD_NODE]: adaptCreateCustomFieldNodeToApi,
        [NodeType.SET_PO_FULFILLMENT_STATUS_NODE]: adaptSetPoFulfillmentStatusNodeToApi,
        [NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE]: adaptSuspendUntilSurveyCompletedNodeToApi,
        [NodeType.SET_PO_STATUS_NODE]: adaptSetPoStatusNodeToApi,
        [NodeType.REFUSE_PURCHASE_REQUEST_NODE]: adaptRefusePurchaseRequestNodeToApi,
        [NodeType.REFUSE_PURCHASE_ORDER_NODE]: adaptRefusePurchaseOrderNodeToApi,
        // Here handles nodes that are not used in the editor
        [NodeType.ASSIGN_TAG_NODE]: () => null,
    }

    const adapter = nodeTypeToAdapter[node.type]
    return adapter ? adapter(node as never) : null
}

export const adaptFlowToApi = (flow: Flow): ApiFlowUpdate => {
    const nodes = flow.nodes.flatMap(adaptNodeToApi).filter(isApiFlowNode)

    return {
        version: flow.version,
        name: flow.name,
        enabled: flow.enabled,
        archived: flow.archived,
        nodes,
    }
}

export const adaptNewFlowToApi = (flow: CreateFlowBody): ApiNewFlow => {
    return {
        name: flow.name,
        enabled: flow.enabled,
        nodes: flow.nodes.flatMap(adaptNodeToApi).filter(isApiFlowNode),
    }
}
