import { XYPosition } from "reactflow"

import {
    AssignTagGroupNode,
    BranchNode,
    ConditionalNode,
    EditorNode,
    Event,
    EventTriggerNode,
    FlowValidation,
    InvalidFlow,
    NodeType,
    ObjectType,
    PoStatus,
    RegularNode,
    Reviewer,
    TeamReviewer,
    UserReviewer,
    UserType,
    ValidFlow,
} from "./Flows"

// type guard to check if node is an EventTriggerNode
export const isEventTriggerNode = (node: EditorNode): node is EventTriggerNode =>
    node.type === NodeType.EVENT_TRIGGER_NODE

// type guard to check if string is Event
export const isEvent = (event: string): event is Event => Object.values(Event).includes(event as Event)

// type guard to check if string is ObjectType
export const isObjectType = (type: string): type is ObjectType => Object.values(ObjectType).includes(type as ObjectType)

// type guard to check if node is an AssignTagGroupNode
export const isAssignTagGroupNode = (node: EditorNode): node is AssignTagGroupNode =>
    node.type === NodeType.ASSIGN_TAG_GROUP_NODE

// type guard to check if node is an BranchNode
export const isBranchNode = (node: EditorNode): node is BranchNode => node.type === NodeType.BRANCH_NODE

// type guard to check if node has nextNode
export const hasNextNode = (node: EditorNode): node is EditorNode & RegularNode =>
    "nextNode" in node && node.nextNode !== null

// type guard to check if node is conditional
export const isConditionalNode = (node: EditorNode): node is EditorNode & ConditionalNode =>
    "nextIfSuccess" in node && "nextIfFailure" in node

// type guard to check if node is regular
export const isRegularNode = (node: EditorNode): node is EditorNode & RegularNode => "nextNode" in node
// type guard to check if metadata position is position
export const isPosition = (position: unknown): position is XYPosition => {
    if (typeof position !== "object" || position === null) return false
    if (!("x" in position) || !("y" in position)) return false
    if (typeof (position as XYPosition).x !== "number" || typeof (position as XYPosition).y !== "number") return false
    return true
}

// type guard to check if reviewer is a UserReviewer
export const isUserReviewer = (reviewer: Reviewer): reviewer is UserReviewer => reviewer.type === UserType.USER

// type guard to check if reviewer is a TeamReviewer
export const isTeamReviewer = (reviewer: Reviewer): reviewer is TeamReviewer => reviewer.type === UserType.TEAM

// type guard to check if flow is invalid
export const isFlowInvalid = (flowValidation: FlowValidation): flowValidation is InvalidFlow =>
    "error" in flowValidation

// type guard to check if flow is valid
export const isFlowValid = (flowValidation: FlowValidation): flowValidation is ValidFlow => "isValid" in flowValidation

// type guard to check if value is a PoStatus
export const isPoStatus = (value: unknown): value is PoStatus => Object.values(PoStatus).includes(value as PoStatus)
