import { MessageDescriptor } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { HeaderNavigation, HeaderNavigationItem } from "~/components"
import { PAYMENTS } from "~/domains/payment/payment-batches/routes"
import { PaymentsTabsI } from "~/domains/payment/payment-batches/types"

const menuMessages: Record<PaymentsTabsI, MessageDescriptor> = {
    [PaymentsTabsI.PAYMENTS]: commonMessages.payments,
    [PaymentsTabsI.PAYMENT_BATCHES]: {
        id: "payment.batches.paymentBatches",
        defaultMessage: "Batches",
    },
    [PaymentsTabsI.PAYMENT_BATCH_INSTANCES]: {
        id: "payment.batches.paymentBatchInstances",
        defaultMessage: "Batch instances",
    },
    [PaymentsTabsI.PAYMENT_METHOD_DETAILS]: {
        id: "payment.batches.myPaymentMethodDetails",
        defaultMessage: "My payment methods",
    },
    [PaymentsTabsI.PARTNERS_PAYMENT_METHOD_DETAILS]: {
        id: "payment.batches.partnersPaymentMethodDetails",
        defaultMessage: "Partners payment methods",
    },
}

interface PaymentsHeaderProps {
    currentPage: PaymentsTabsI
}

const menu: PaymentsTabsI[] = [
    PaymentsTabsI.PAYMENTS,
    PaymentsTabsI.PAYMENT_BATCHES,
    PaymentsTabsI.PAYMENT_BATCH_INSTANCES,
    PaymentsTabsI.PAYMENT_METHOD_DETAILS,
    PaymentsTabsI.PARTNERS_PAYMENT_METHOD_DETAILS,
]

export const PaymentsHeader = ({ currentPage }: PaymentsHeaderProps) => {
    const navigate = useNavigate()

    const menuItems: HeaderNavigationItem[] = menu.map((page) => ({
        label: menuMessages[page],
        value: page,
        onClick: () => handleSwitchView(page),
    }))

    const handleSwitchView = (page: string) => {
        const route = generatePath(`${PAYMENTS}/:page`, { page })
        navigate(route)
    }

    return (
        <HeaderNavigation
            items={menuItems}
            selectedIndex={menuItems.findIndex((item) => item.value === currentPage)}
            onChange={handleSwitchView}
        />
    )
}
