// FIXME: Refactor this file and the  onConnect function to reduce the complexity

/* eslint-disable complexity */
import { type Connection, type Node, NodeMouseHandler, XYPosition, useReactFlow } from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import React, { FC, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import Selecto, { OnDragStart } from "react-selecto"

import { FlowBuilder } from "~/domains/orchestration/flows/components/FlowBuilder"
import { FlowWrapper } from "~/domains/orchestration/flows/components/FlowWrapper"
import { SideBar } from "~/domains/orchestration/flows/components/editor/SideBar"
import { NODE_WIDTH, SIDE_BAR_WIDTH } from "~/domains/orchestration/flows/constants"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { connectNodes } from "~/domains/orchestration/flows/core"
import { createNode } from "~/domains/orchestration/flows/core"
import { useFlowValidator } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { EditorNode, type Flow, NodeType, isEventTriggerNode } from "~/domains/orchestration/flows/types"
import { generateReadableId } from "~/domains/orchestration/flows/utils"

interface Props {
    flow: Flow
    hasWorkflowUpdatePermission: boolean
}

export const EditorPanel: FC<Props> = ({ flow, hasWorkflowUpdatePermission }) => {
    const reactFlowWrapper = useRef(null)
    const { formatMessage } = useIntl()
    const state = useEditor()
    const currentFlow = state.flow || flow
    const { error } = state

    const [selectedNode, setSelectedNode] = useState<EditorNode>()
    const { screenToFlowPosition } = useReactFlow()
    const dispatch = useEditorDispatch()

    const [currentNodeType, setCurrentNodeType] = useState<NodeType | null>(null)

    const [showSidebar, setShowSidebar] = useState(true)

    useFlowValidator(currentFlow)

    const updateFlow = (f: Flow) => {
        dispatch({
            type: "SET_FLOW",
            payload: f,
        })
    }
    const handleUpdatePosition = (node: Node) => {
        const { nodes: flowNodes } = currentFlow
        const currentFlowNode = flowNodes.find((fn) => fn.slug === node.id)
        if (!currentFlowNode) return

        const position = {
            x: Math.floor(node.position.x),
            y: Math.floor(node.position.y),
        }

        const hasPositionChanged =
            currentFlowNode.metadata.position.x !== position.x || currentFlowNode.metadata.position.y !== position.y

        if (hasPositionChanged) {
            dispatch({
                type: "UPDATE_POSITION",
                payload: {
                    ...currentFlowNode,
                    metadata: { ...currentFlowNode.metadata, position },
                },
            })
        }
    }

    const handleConnect = (params: Connection) => {
        const updatedNodes = connectNodes({ nodes: currentFlow.nodes, params })
        updateFlow({ ...currentFlow, nodes: updatedNodes })
    }

    const handleCreateAndAddNode = (nodeType: NodeType, position: XYPosition) => {
        const id = generateReadableId(nodeType, currentFlow.nodes)

        const eventTriggerEventType = currentFlow.nodes.find(isEventTriggerNode)?.event || null

        const title = messages.nodeTitle[nodeType]
        const name = title ? formatMessage(title) : ""
        const node = createNode({ type: nodeType, slug: id, position, eventTriggerEventType, name })

        // TODO: Handle the case when node is null
        if (!node) return

        updateFlow({ ...currentFlow, nodes: [...currentFlow.nodes, node] })
    }

    const handlSelectNode: NodeMouseHandler<Node> = (_, node) => {
        const selected = currentFlow.nodes.find((n) => n.slug === node.id)

        if (!selected) return
        if (selectedNode && selected.slug === selectedNode.slug) return

        setSelectedNode(selected)
        setShowSidebar(true)
    }

    const handleUnselectNode = () => {
        setSelectedNode(undefined)
        setShowSidebar(true)
    }

    const handleSelect = (e: OnDragStart) => {
        // This prevent select box appearing when connecting nodes
        e.preventDrag()
    }

    const handleAutoLayout = async (layoutedNodes: Node[]) => {
        updateFlow({
            ...currentFlow,
            nodes: currentFlow.nodes.map((node) => {
                const layoutedNode = layoutedNodes.find((n) => n.id === node.slug)
                return {
                    ...node,
                    metadata: { ...node.metadata, position: layoutedNode?.position || node.metadata.position },
                }
            }),
        })
    }

    // Sidebar handlers

    const handleSideBarNodeClick = (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setCurrentNodeType(nodeType)

        // Add delta to avoid nodes stacking on top of each other
        const centerX = window.innerWidth / 2 + Math.random() * 100
        const centerY = window.innerHeight / 2 + Math.random() * 100

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(centerX - NODE_WIDTH / 2),
            y: Math.floor(centerY),
        })

        handleCreateAndAddNode(nodeType, position)
    }

    const handleSideBarDragStart =
        (nodeType: NodeType) =>
        (event: React.DragEvent<HTMLDivElement>): void => {
            setCurrentNodeType(nodeType)
            event.dataTransfer.effectAllowed = "move"
        }

    const handleToggleSideBar = () => {
        setShowSidebar((prev) => !prev)
    }

    useEffect(() => {
        if (!selectedNode) return

        const currentNode = currentFlow.nodes.find((node) => node.slug === selectedNode?.slug)

        if (!currentNode) handleUnselectNode()
    }, [currentFlow.nodes, flow, selectedNode])

    const hasError = error !== null && error.trim().length > 0
    const sideBarWidth = showSidebar ? SIDE_BAR_WIDTH : 0
    const hasSideBar = hasWorkflowUpdatePermission

    return (
        <>
            {reactFlowWrapper.current && (
                <Selecto
                    container={reactFlowWrapper.current}
                    dragContainer={reactFlowWrapper.current}
                    selectableTargets={[".react-flow__node"]}
                    boundContainer={reactFlowWrapper.current}
                    continueSelect={false}
                    keyContainer={reactFlowWrapper.current}
                    hitRate={100}
                    onDragStart={handleSelect}
                />
            )}
            <FlowWrapper ref={reactFlowWrapper} $sidebarWidth={sideBarWidth}>
                <FlowBuilder
                    flow={currentFlow}
                    currentNodeType={currentNodeType}
                    onConnect={handleConnect}
                    onNodeClick={handlSelectNode}
                    onPaneClick={handleUnselectNode}
                    hasError={hasError}
                    error={error}
                    onCreateNode={handleCreateAndAddNode}
                    onAutoLayout={handleAutoLayout}
                    onUpdatePosition={handleUpdatePosition}
                />
            </FlowWrapper>
            {hasSideBar && (
                <SideBar
                    isOpen={showSidebar}
                    selectedNode={selectedNode}
                    onDragStart={handleSideBarDragStart}
                    onNodeClick={handleSideBarNodeClick}
                    onUnselectNode={handleUnselectNode}
                    onToggleSideBar={handleToggleSideBar}
                />
            )}
        </>
    )
}
