const messages_ar = import.meta.glob("~/lang/ar/**/*.json", { eager: true })
const messages_ch = import.meta.glob("~/lang/ch/**/*.json", { eager: true })
const messages_de = import.meta.glob("~/lang/de/**/*.json", { eager: true })
const messages_en = import.meta.glob("~/lang/en/**/*.json", { eager: true })
const messages_fr = import.meta.glob("~/lang/fr/**/*.json", { eager: true })
const messages_no = import.meta.glob("~/lang/no/**/*.json", { eager: true })
const messages_pl = import.meta.glob("~/lang/pl/**/*.json", { eager: true })
const messages_br = import.meta.glob("~/lang/br/**/*.json", { eager: true })

export const messages = {
    fr: Object.values(messages_fr).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
    en: Object.values(messages_en).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
    ar: Object.values(messages_ar).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
    ch: Object.values(messages_ch).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
    de: Object.values(messages_de).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
    no: Object.values(messages_no).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
    pl: Object.values(messages_pl).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
    br: Object.values(messages_br).reduce((acc: any, module: any) => ({ ...acc, ...module.default }), {}) as any,
}
