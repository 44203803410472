import { useCallback } from "react"

import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { usePurchaseRequestActions, usePurchaseRequestWorkflowReviewers } from "~/store/workflow/hooks"

import { useUpdatePurchaseRequestStatus } from "./useUpdatePurchaseRequestStatus"

type PurchaseRequestActions = {
    withinWorkflow: boolean
    loading: boolean
    currentUserCanValidate: boolean
    currentUserApproval: boolean | undefined
    approve: () => void
    refuse: () => void
    statusLoading: boolean
    submit: () => void
}

type PurchaseRequestLoadingState = {
    loading: true
    withinWorkflow?: never
    currentUserCanValidate?: never
    approve?: never
    refuse?: never
    statusLoading?: never
    currentUserApproval?: never
    submit?: () => void
}

type PurchaseRequestValidationState = PurchaseRequestLoadingState | PurchaseRequestActions

export const usePurchaseRequestValidationActions = (
    purchaseRequest: PurchaseRequestDetails
): PurchaseRequestValidationState => {
    const currentUser = useAppSelector(selectUser)
    const { workflowCheck, loading } = usePurchaseRequestWorkflowReviewers(
        purchaseRequest.organizationId,
        purchaseRequest.id
    )
    const { updatePRStatus, statusLoading } = useUpdatePurchaseRequestStatus(
        purchaseRequest.organizationId,
        purchaseRequest.id
    )
    const {
        approvePurchaseRequest,
        refusePurchaseRequest,
        loading: validationLoading,
    } = usePurchaseRequestActions(purchaseRequest)

    const approveFromWorkflow = useCallback(async () => {
        await updatePRStatus(PurchaseRequestStatus.APPROVED)
        await approvePurchaseRequest()
    }, [approvePurchaseRequest, updatePRStatus])

    const refuseFromWorkflow = useCallback(async () => {
        await updatePRStatus(PurchaseRequestStatus.REJECTED)
        await refusePurchaseRequest()
    }, [refusePurchaseRequest, updatePRStatus])

    const rejectPurchaseRequest = useCallback(async () => {
        await updatePRStatus(PurchaseRequestStatus.REJECTED)
    }, [updatePRStatus])

    const validatePurchaseRequest = useCallback(async () => {
        await updatePRStatus(PurchaseRequestStatus.APPROVED)
    }, [updatePRStatus])

    const submitPurchaseRequest = useCallback(async () => {
        await updatePRStatus(PurchaseRequestStatus.SUBMITTED)
    }, [updatePRStatus])

    if (loading) {
        return { loading: true }
    }

    if (workflowCheck) {
        const hasApproved = workflowCheck.purchaseRequestCheckStatus.approvers.some(
            (userId) => userId === currentUser.id
        )
        const hasRefused = workflowCheck.purchaseRequestCheckStatus.refusers.some((userId) => userId === currentUser.id)
        return {
            loading: false,
            withinWorkflow: true,
            currentUserCanValidate: true,
            approve: approveFromWorkflow,
            refuse: refuseFromWorkflow,
            currentUserApproval: hasApproved || hasRefused || undefined,
            statusLoading: validationLoading,
            submit: submitPurchaseRequest,
        }
    }
    return {
        loading: false,
        withinWorkflow: false,
        currentUserCanValidate: true,
        approve: validatePurchaseRequest,
        refuse: rejectPurchaseRequest,
        currentUserApproval: undefined,
        statusLoading,
        submit: submitPurchaseRequest,
    }
}
