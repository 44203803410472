import { Typography, styled } from "@mui/material"
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from "@xyflow/react"
import React from "react"
import { useIntl } from "react-intl"

import { useEditor } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/locale"
import { isBranchNode } from "~/domains/orchestration/flows/types/"

const EdgeLabel = styled("div")<{ labelX: number; labelY: number }>(({ labelX, labelY }) => ({
    position: "absolute",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    // everything inside EdgeLabelRenderer has no pointer events by default
    // if you have an interactive element, set pointer-events: all
    pointerEvents: "all",
}))

const Label = styled(Typography)({
    backgroundColor: "var(--color-white)",
    padding: "var(--spacing-xs)",
    borderRadius: "var(--border-radius-sm)",
})

export const EdgeWithLabel = ({
    target,
    source,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const state = useEditor()
    const { formatMessage } = useIntl()
    const currentFlow = state.flow

    const node = currentFlow?.nodes.find((n) => n.slug === source)

    if (!node || !isBranchNode(node)) return null

    const isDefaultBranchEdge = node.default === target

    const edgeLabel = isDefaultBranchEdge
        ? formatMessage(messages.branchNode.defaultBranch)
        : node.branches.find((branch) => branch.nextNode === target)?.name || ""

    if (!node || !isBranchNode(node)) return null

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <EdgeLabel labelX={labelX} labelY={labelY} className="nodrag nopan">
                    <Label variant="body2">{edgeLabel}</Label>
                </EdgeLabel>
            </EdgeLabelRenderer>
        </>
    )
}
