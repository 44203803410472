import { Checkbox, InputAdornment, ListItemText, MenuItem, SelectProps, TextField } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { QuestionValue } from "~/domains/identity/custom-forms/types/CustomForms"

const messages = defineMessages({
    textInput: { id: "common.placeholder.textInput", defaultMessage: "Write your response here..." },
    numberInput: { id: "common.placeholder.numberInput", defaultMessage: "Enter a number..." },
    select: { id: "common.placeholder.select", defaultMessage: "Choose an option..." },
})

interface CustomFormsInputProps {
    onValueChanged: (value: QuestionValue) => void
    savedValue?: QuestionValue
    isMultiline?: boolean
    isSelect?: boolean
    isNumber?: boolean
    options?: string[]
    isMultichoice?: boolean
    required?: boolean
    disabled?: boolean
    isInvalid?: boolean
    isFocused?: boolean
    maxValue?: number
    minValue?: number
    unit?: string
}

const DEBOUNCE_TIME = 500

export const CustomFormsInput: React.FC<CustomFormsInputProps> = ({
    onValueChanged,
    savedValue = "",
    isMultiline = false,
    isSelect = false,
    isNumber = false,
    options = [],
    isMultichoice = false,
    required = false,
    disabled = false,
    isInvalid = false,
    isFocused = false,
    unit = "",
    minValue = undefined,
    maxValue = undefined,
}) => {
    const { formatMessage } = useIntl()
    const [value, setValue] = useState<QuestionValue>(isMultichoice ? [] : savedValue || "")
    const [invalid, setInvalid] = useState(isInvalid)
    const [focused, setFocused] = useState(isFocused)
    const [inputRef, setInputRef] = useState<HTMLElement | null>(null)

    const placeholderMessage = useMemo(() => {
        if (isSelect) return messages.select
        if (isNumber) return messages.numberInput
        return messages.textInput
    }, [isSelect, isNumber])

    const selectProps = useMemo<SelectProps | undefined>(() => {
        if (!isSelect) return undefined
        return {
            multiple: isMultichoice,
            renderValue: (selected) => (isMultichoice ? (selected as string[]).join(", ") : (selected as string)),
            SelectDisplayProps: {
                className: "text-wrap",
            },
        }
    }, [isSelect, isMultichoice])

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value: targetValue } = event.target
            let newValue
            if (isMultichoice) {
                if (Array.isArray(targetValue)) {
                    newValue = targetValue.filter(Boolean)
                } else {
                    newValue = []
                }
            } else {
                newValue = targetValue
            }

            // skip saving if the value is not in the min/max range
            if (
                isNumber &&
                ((maxValue !== undefined && newValue > maxValue) || (minValue !== undefined && newValue < minValue))
            ) {
                return
            }

            setValue(newValue)
            if (invalid && value) {
                setInvalid(false)
            }
            if (isSelect || isMultichoice) {
                onValueChanged(newValue)
            }
        },
        [invalid, isMultichoice, isSelect, onValueChanged, value]
    )

    const handleBlur = useCallback(() => {
        setFocused(false)

        if (isSelect || isMultichoice) {
            return
        }
        onValueChanged(value)
        if (invalid && value) {
            setInvalid(false)
        }
    }, [isSelect, isMultichoice, onValueChanged, value, invalid])

    useEffect(() => {
        setValue(savedValue || (isMultichoice ? [] : ""))
    }, [savedValue, isMultichoice])

    useEffect(() => {
        setInvalid(isInvalid)
        setFocused(isFocused)
    }, [isInvalid, isFocused])

    useEffect(() => {
        if (focused && inputRef) {
            // workaround for Select component that returns the inputRef as an Object instead of HTMLElement
            const input = "node" in inputRef ? inputRef.node : inputRef
            ;(input as HTMLElement).scrollIntoView({ behavior: "smooth", block: "center" })

            inputRef.focus()
        }
    }, [focused, inputRef])

    // debounce the value change to avoid multiple calls to the server
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            if (!isMultichoice && !isSelect && savedValue !== value) {
                onValueChanged(value)
            }
        }, DEBOUNCE_TIME)
        return () => clearTimeout(delayInputTimeoutId)
    }, [value, isMultichoice, isSelect, savedValue])

    // Number input props
    const numberProps = isNumber
        ? {
              InputProps: {
                  startAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
                  min: minValue,
                  max: maxValue,
              },
          }
        : {}

    return (
        <TextField
            error={invalid}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            color="secondary"
            fullWidth
            inputRef={setInputRef}
            placeholder={formatMessage(placeholderMessage)}
            disabled={disabled}
            required={required}
            multiline={isMultiline}
            type={isNumber ? "number" : "text"}
            select={isSelect}
            value={value}
            SelectProps={selectProps}
            FormHelperTextProps={{ sx: { height: "0px" } }}
            helperText={invalid && <SafeFormattedMessage {...commonMessages.errorRequiredField} />}
            {...numberProps}
        >
            {isSelect &&
                options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {isMultichoice ? (
                            <>
                                <Checkbox checked={(value as string[]).includes(option)} />
                                <ListItemText primary={option} />
                            </>
                        ) : (
                            option
                        )}
                    </MenuItem>
                ))}
        </TextField>
    )
}
