import dayjs from "dayjs"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { ObjectAssignPicker } from "~/domains/payment"
import { ObjectType } from "~/domains/payment/types"
import { InvoiceI } from "~/types"

const messages = defineMessages({
    paymentBatches: {
        id: "payment.batches.paymentBatch",
        defaultMessage: "Payment batch",
    },
})

interface InvoicePaymentBatchProps {
    invoice: InvoiceI
}

export const InvoicePaymentBatch = ({ invoice }: InvoicePaymentBatchProps) => {
    const { formatMessage } = useIntl()

    const payload = {
        objectId: invoice.id,
        objectType: ObjectType.INVOICE,
        amount: invoice.total as number,
        currency: invoice.currency,
        dateScheduled: invoice.dueDate ?? dayjs().format("YYYY-MM-DD"),
        payeeId: invoice.supplierId ?? invoice.supplier.organizationId,
    }

    return (
        <Card expandable title={formatMessage(messages.paymentBatches)}>
            <ObjectAssignPicker objectId={invoice.id} payload={payload} />
        </Card>
    )
}
