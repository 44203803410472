import { FC, Suspense, lazy, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import { Loader } from "~/components"
import { AknowledgePurchasePolicyInitialConfirmationModal } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgePurchasePolicyInitialConfirmationModal"
import { AknowledgePurchasePolicySummaryModal } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgePurchasePolicySummaryModal"
import { FinalPurchasePolicyAknowledgeModal } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/FinalPurchasePolicyAknowledgeModal"
import { OrganizationId, UserI, UserId } from "~/types"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

const ReadPurchasePolicyModal = lazy(() =>
    import("~/domains/identity/account/components/AknowledgePurchasePolicyWall/ReadPurchasePolicyModal").then(
        ({ ReadPurchasePolicyModal: ReadPurchasePolicyModalComponent }) => ({
            default: ReadPurchasePolicyModalComponent,
        })
    )
)

const STORAGE_KEY = "acknowledgePurchasePolicyWall"

const getStorageKey = (userId: UserId) => `${STORAGE_KEY}_${userId}`

enum AknowledgePurchasePolicyWallSteps {
    INITIAL_CONFIRMATION = "INITIAL_CONFIRMATION",
    READING_PURCHASE_POLICY = "READING_PURCHASE_POLICY",
    AKNOWLEDGE_SUMMARY = "AKNOWLEDGE_SUMMARY",
    AKNOWLEDGE_POLICIES = "AKNOWLEDGE_POLICIES",
    AKNOWLEDGE_COMPLETED = "AKNOWLEDGE_COMPLETED",
}

const followingStep: Record<AknowledgePurchasePolicyWallSteps, AknowledgePurchasePolicyWallSteps | null> = {
    [AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION]: AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY,
    [AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY]: AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY,
    [AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY]: AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES,
    [AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES]: AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_COMPLETED,
    [AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_COMPLETED]: null,
}

type AcknowledgePurchasePolicyWallState = {
    step: AknowledgePurchasePolicyWallSteps
    validations: Partial<Record<AknowledgePurchasePolicyWallSteps, string>>
}

const getInitialState = (userId: UserId): AcknowledgePurchasePolicyWallState => {
    if (window.localStorage?.getItem) {
        const stateData = window.localStorage.getItem(getStorageKey(userId))
        if (stateData) {
            try {
                return JSON.parse(stateData) as AcknowledgePurchasePolicyWallState
            } catch {
                window.localStorage.removeItem(getStorageKey(userId))
            }
        }
    }
    return { step: AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION, validations: {} }
}

const saveState = (state: AcknowledgePurchasePolicyWallState, userId: UserId) => {
    if (window.localStorage?.setItem && userId) {
        window.localStorage.setItem(getStorageKey(userId), JSON.stringify(state))
    }
}

interface Props {
    isConnected: boolean
    user: UserI
    organizationId: OrganizationId | undefined
}

export const AknowledgePurchasePolicyWall: FC<Props> = ({ isConnected, user, organizationId }) => {
    const [state, setState] = useState(getInitialState(user.id))
    const aknowledgePurchasePolicyWall = isFeatureEnabled(Features.AknowledgePurchasePolicyWall, organizationId)

    const getSetStateForStep = (step: AknowledgePurchasePolicyWallSteps) => (confirmDate: Date) => {
        const nextStep = followingStep[step]
        setState({
            step: nextStep ?? step,
            validations: {
                ...state.validations,
                [step]: confirmDate.toISOString(),
            },
        })
    }

    useEffect(() => {
        setState(getInitialState(user.id))
    }, [user.id])

    useEffect(() => {
        saveState(state, user.id)
    }, [state, user.id])

    if (
        !isConnected ||
        !aknowledgePurchasePolicyWall ||
        state.step === AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_COMPLETED
    )
        return <Outlet />

    if (state.step === AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION) {
        return (
            <AknowledgePurchasePolicyInitialConfirmationModal
                onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.INITIAL_CONFIRMATION)}
            />
        )
    }
    if (state.step === AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY) {
        return (
            <Suspense fallback={<Loader />}>
                <ReadPurchasePolicyModal
                    onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.READING_PURCHASE_POLICY)}
                />
            </Suspense>
        )
    }
    if (state.step === AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY) {
        return (
            <AknowledgePurchasePolicySummaryModal
                onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_SUMMARY)}
            />
        )
    }
    if (state.step === AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES) {
        return (
            <FinalPurchasePolicyAknowledgeModal
                onConfirm={getSetStateForStep(AknowledgePurchasePolicyWallSteps.AKNOWLEDGE_POLICIES)}
            />
        )
    }
    return <Outlet />
}
