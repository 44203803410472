import { FormControlLabel, Stack, Switch } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { SharedBudgetConfiguration } from "~/domains/orchestration/flows/components/configuration/SharedBudgetConfiguration"
import { messages } from "~/domains/orchestration/flows/locale"
import { AddToBudgetNode, BudgetNode, ConfigurationProps, NodeType } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const AddToBudgetConfiguration: FC<ConfigurationProps<AddToBudgetNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()

    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleToggleFailIfOverbudget = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            failIfOverbudget: e.target.checked,
        })
    }
    const handleChangeBudget = (node: BudgetNode) => {
        if (node.type === NodeType.ADD_TO_BUDGET_NODE) {
            setCurrentNode(node)
        }
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={3} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.addToBudgetConfiguration.title} />

                <SharedBudgetConfiguration currentNode={currentNode} handleChange={handleChangeBudget} />

                <FormControlLabel
                    control={<Switch checked={currentNode.failIfOverbudget} onChange={handleToggleFailIfOverbudget} />}
                    label={formatMessage(messages.addToBudgetConfiguration.failIfOverbudgetLabel)}
                />
            </Stack>

            <AdvancedFields<AddToBudgetNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
