import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
    IOrganisationEstablishmentResponse,
    IOrganizationLegalInfo,
} from "~/domains/identity/organization/types/OrganisationV2Types"

import { organisationEstablishmentFromApiAdapter } from "./adapters/organisationEstablishmentFromApiAdapter"

const BASE_URL = import.meta.env.VITE_API_ORG_V2_URL

export const organizationV2Api = createApi({
    reducerPath: "organizationV2Api",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ["Organization", "OrganizationLegalInfo"],
    endpoints: (builder) => ({
        getOrganizationEstablishmentBySiret: builder.query<IOrganisationEstablishmentResponse, { siret: string }>({
            query: ({ siret }) => ({
                url: `/organization-relationships/establishments/${siret}`,
            }),
            providesTags: ["Organization"],
            transformResponse: (response: IOrganisationEstablishmentResponse) => {
                return organisationEstablishmentFromApiAdapter(response)
            },
        }),
        getOrganizationLegalInfo: builder.query<IOrganizationLegalInfo, { organizationId: string }>({
            query: ({ organizationId }) => ({
                url: `/organizations/legal-info/${organizationId}`,
            }),
            providesTags: ["OrganizationLegalInfo"],
        }),
    }),
})

export const { useGetOrganizationEstablishmentBySiretQuery, useGetOrganizationLegalInfoQuery } = organizationV2Api
