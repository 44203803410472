import { Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { MessageDescriptor, useIntl } from "react-intl"

import { SafeFormattedMessage, StatusChip } from "~/components"
import { globalStatus } from "~/constants/globalStatus"
import {
    ConditionAcceptedBySupplier,
    DisplayContext,
    ObjectStrictDecision,
    SecheCustomFields,
    SecheErp,
    conditionAcceptedBySupplierEnumMessages,
    currencyCodeMessages,
    erpEnumMessages,
    secheCustomFieldsMessages,
    secheSupplierConditionsWarningMessages,
    strictDecisionEnumMessages,
} from "~/domains/identity/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import { CustomFieldObjectAsTextfield } from "~/domains/identity/custom-fields/components/CompanyCustomFields/CustomFieldObjectAsTextfield"
import { getOrCreateCustomField } from "~/domains/identity/custom-fields/components/CompanyCustomFields/CustomFieldObjectHelpers"
import {
    useFetchCustomFieldsByObjectId,
    usePendingCustomFieldsByFieldNames,
} from "~/domains/identity/custom-fields/hooks"
import { selectPendingCustomFieldObjects } from "~/domains/identity/custom-fields/store"
import { CustomFieldObjectType } from "~/domains/identity/custom-fields/types/CustomFieldObjectType"
import { CustomFieldValueI } from "~/domains/identity/custom-fields/types/CustomFieldValue"
import { PartnerEvaluationForms } from "~/domains/identity/partners/components/PartnerEvaluationForms/PartnerEvaluationForms"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

interface PartnerProfileSecheCustomFieldsProps {
    partnershipId: string | undefined
    currentOrganizationId: OrganizationId | undefined
    readOnly: boolean
    customFieldsList: string[]
    displayContext?: DisplayContext
}

const getOrCreateAllCustomFields = (
    customFields: CustomFieldValueI[] | null,
    partnershipId: string | undefined,
    currentOrganizationId: OrganizationId | undefined
) => {
    const isExistingEntityCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.IS_EXISTING_ENTITY,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const existingEntityIdCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.EXISTING_ENTITY_ID,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const isFactorCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.IS_FACTOR,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const ifsInternalIdCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.IFS_INTERNAL_ID,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const x3InternalIdCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.X3_INTERNAL_ID,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const factorIdCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.FACTOR_ID,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const erpCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.ERP,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const targetsCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.TARGETS,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const estimatedAnnualExpensesCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_AMOUNT,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const estimatedAnnualExpensesCurrencyCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_CURRENCY,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const conditionsAcceptedBySupplierCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const revenueYearN1CustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.REVENUE_YEAR_N_1,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const revenueYearN2CustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.REVENUE_YEAR_N_2,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const justificationForSupplierCreationCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.JUSTIFICATION_FOR_SUPPLIER_CREATION,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const parentCompanyCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.PARENT_COMPANY,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    const operationalPerformanceStatusCustomField = getOrCreateCustomField(
        customFields,
        SecheCustomFields.OPERATIONAL_PERFORMANCE_STATUS,
        partnershipId ?? "",
        CustomFieldObjectType.PARTNER_PROFILE,
        currentOrganizationId
    )

    return {
        isExistingEntityCustomField,
        existingEntityIdCustomField,
        isFactorCustomField,
        ifsInternalIdCustomField,
        x3InternalIdCustomField,
        factorIdCustomField,
        erpCustomField,
        targetsCustomField,
        estimatedAnnualExpensesCustomField,
        estimatedAnnualExpensesCurrencyCustomField,
        conditionsAcceptedBySupplierCustomField,
        revenueYearN1CustomField,
        revenueYearN2CustomField,
        justificationForSupplierCreationCustomField,
        parentCompanyCustomField,
        operationalPerformanceStatusCustomField,
    }
}

export const PartnerProfileSecheCustomFields: React.FC<PartnerProfileSecheCustomFieldsProps> = ({
    partnershipId,
    currentOrganizationId,
    readOnly,
    customFieldsList,
    displayContext = DisplayContext.PARTNER_PROFILE,
}) => {
    const { formatMessage } = useIntl()

    const [showExistingEntityIdField, setShowExistingEntityIdField] = useState<boolean>(false)
    const [showFactorIdField, setShowFactorIdField] = useState<boolean>(false)
    const [warningMessage, setWarningMessage] = useState<MessageDescriptor | undefined>(undefined)

    const [showX3InternalIdField, setShowX3InternalIdField] = useState<boolean>(false)
    const [showIfsInternalIdField, setShowIfsInternalIdField] = useState<boolean>(false)

    const { customFields } = useFetchCustomFieldsByObjectId(partnershipId)

    const pendingCustomFieldObject = useAppSelector(selectPendingCustomFieldObjects)
    const pendingCustomFields = usePendingCustomFieldsByFieldNames(customFieldsList, pendingCustomFieldObject)
    const isTemporaryId = !partnershipId

    const {
        isExistingEntityCustomField,
        existingEntityIdCustomField,
        isFactorCustomField,
        factorIdCustomField,
        erpCustomField,
        targetsCustomField,
        ifsInternalIdCustomField,
        x3InternalIdCustomField,
        estimatedAnnualExpensesCustomField,
        estimatedAnnualExpensesCurrencyCustomField,
        conditionsAcceptedBySupplierCustomField,
        revenueYearN1CustomField,
        revenueYearN2CustomField,
        justificationForSupplierCreationCustomField,
        parentCompanyCustomField,
        operationalPerformanceStatusCustomField,
    } = getOrCreateAllCustomFields(customFields, partnershipId, currentOrganizationId)

    const isExistingEntityPendingValue = pendingCustomFields.find(
        (field) => field?.name === SecheCustomFields.IS_EXISTING_ENTITY
    )?.value

    useEffect(() => {
        setShowExistingEntityIdField(
            isExistingEntityCustomField?.value === ObjectStrictDecision.YES ||
                isExistingEntityPendingValue === ObjectStrictDecision.YES
        )
    }, [isExistingEntityCustomField?.value, isExistingEntityPendingValue])

    const isFactorPendingValue = pendingCustomFields.find((field) => field?.name === SecheCustomFields.IS_FACTOR)?.value

    const erpPendingValue = pendingCustomFields.find((field) => field?.name === SecheCustomFields.ERP)?.value

    useEffect(() => {
        setShowFactorIdField(
            isFactorCustomField?.value === ObjectStrictDecision.YES || isFactorPendingValue === ObjectStrictDecision.YES
        )
    }, [isFactorCustomField?.value, isFactorPendingValue])

    useEffect(() => {
        const isX3 = [SecheErp.X3, SecheErp.IFSX3]
        const isIFS = [SecheErp.IFS, SecheErp.IFSX3]

        const currentValue = erpCustomField?.value as SecheErp
        const pendingValue = erpPendingValue as SecheErp

        setShowX3InternalIdField(
            displayContext !== DisplayContext.PARTNER_MODAL &&
                (isX3.includes(currentValue) || isX3.includes(pendingValue))
        )
        setShowIfsInternalIdField(
            displayContext !== DisplayContext.PARTNER_MODAL &&
                (isIFS.includes(currentValue) || isIFS.includes(pendingValue))
        )
    }, [erpCustomField?.value, erpPendingValue])

    const fixComplianceStatus = (status: string) => {
        return status.replace(/-/g, "_")
    }

    const conditionsAcceptedBySupplierPendingValue = pendingCustomFields.find(
        (field) => field?.name === SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER
    )?.value

    useEffect(() => {
        if (
            (conditionsAcceptedBySupplierCustomField?.value === ConditionAcceptedBySupplier.LME ||
                conditionsAcceptedBySupplierCustomField?.value === ConditionAcceptedBySupplier.NO ||
                conditionsAcceptedBySupplierPendingValue === ConditionAcceptedBySupplier.LME ||
                conditionsAcceptedBySupplierPendingValue === ConditionAcceptedBySupplier.NO) &&
            displayContext === DisplayContext.PARTNER_MODAL
        ) {
            setWarningMessage(
                secheSupplierConditionsWarningMessages[
                    (conditionsAcceptedBySupplierCustomField?.value as ConditionAcceptedBySupplier) ||
                        (conditionsAcceptedBySupplierPendingValue as ConditionAcceptedBySupplier)
                ]
            )
        } else {
            setWarningMessage(undefined)
        }
    }, [conditionsAcceptedBySupplierCustomField?.value, conditionsAcceptedBySupplierPendingValue, displayContext])

    if (!currentOrganizationId || !customFieldsList) {
        return
    }

    return (
        <Stack gap={2}>
            <CustomFieldObjectAsTextfield
                customField={isExistingEntityCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.IS_EXISTING_ENTITY])}
                enumMessages={strictDecisionEnumMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            {showExistingEntityIdField ? (
                <CustomFieldObjectAsTextfield
                    customField={existingEntityIdCustomField}
                    label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.EXISTING_ENTITY_ID])}
                    isTemporaryId={isTemporaryId}
                    isReadOnly={readOnly}
                    isRequired={showExistingEntityIdField ? true : false}
                />
            ) : null}
            <CustomFieldObjectAsTextfield
                customField={isFactorCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.IS_FACTOR])}
                enumMessages={strictDecisionEnumMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            {showFactorIdField ? (
                <CustomFieldObjectAsTextfield
                    customField={factorIdCustomField}
                    label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.FACTOR_ID])}
                    isTemporaryId={isTemporaryId}
                    isReadOnly={readOnly}
                    isRequired={showFactorIdField ? true : false}
                />
            ) : null}
            {displayContext === DisplayContext.PARTNER_PROFILE && (
                <>
                    <CustomFieldObjectAsTextfield
                        customField={erpCustomField}
                        label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.ERP])}
                        enumMessages={erpEnumMessages}
                        isTemporaryId={isTemporaryId}
                        isReadOnly={readOnly}
                    />
                    {showIfsInternalIdField ? (
                        <CustomFieldObjectAsTextfield
                            customField={ifsInternalIdCustomField}
                            label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.IFS_INTERNAL_ID])}
                            isTemporaryId={isTemporaryId}
                            isReadOnly={readOnly}
                            isRequired
                        />
                    ) : null}
                    {showX3InternalIdField ? (
                        <CustomFieldObjectAsTextfield
                            customField={x3InternalIdCustomField}
                            label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.X3_INTERNAL_ID])}
                            isTemporaryId={isTemporaryId}
                            isReadOnly={readOnly}
                            isRequired
                        />
                    ) : null}
                </>
            )}
            <CustomFieldObjectAsTextfield
                customField={targetsCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.TARGETS])}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            <CustomFieldObjectAsTextfield
                customField={estimatedAnnualExpensesCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_AMOUNT])}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            <CustomFieldObjectAsTextfield
                customField={estimatedAnnualExpensesCurrencyCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.ESTIMATED_ANUAL_EXPENSES_CURRENCY])}
                enumMessages={currencyCodeMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
            />
            <CustomFieldObjectAsTextfield
                customField={conditionsAcceptedBySupplierCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER])}
                enumMessages={conditionAcceptedBySupplierEnumMessages}
                isTemporaryId={isTemporaryId}
                isReadOnly={readOnly}
                isRequired={true}
                errorMessage={warningMessage ? formatMessage(warningMessage) : undefined}
            />
            {displayContext === DisplayContext.PARTNER_PROFILE && (
                <>
                    <CustomFieldObjectAsTextfield
                        customField={revenueYearN1CustomField}
                        label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.REVENUE_YEAR_N_1])}
                        isTemporaryId={isTemporaryId}
                        isReadOnly={readOnly}
                        isRequired={false}
                    />
                    <CustomFieldObjectAsTextfield
                        customField={revenueYearN2CustomField}
                        label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.REVENUE_YEAR_N_2])}
                        isTemporaryId={isTemporaryId}
                        isReadOnly={readOnly}
                        isRequired={false}
                    />
                </>
            )}
            <CustomFieldObjectAsTextfield
                customField={justificationForSupplierCreationCustomField}
                label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.JUSTIFICATION_FOR_SUPPLIER_CREATION])}
                isTemporaryId={isTemporaryId}
                placeholder={formatMessage(
                    secheCustomFieldsMessages[SecheCustomFields.JUSTIFICATION_FOR_SUPPLIER_CREATION_PLACEHOLDER]
                )}
                isReadOnly={readOnly}
                isRequired={true}
            />
            {displayContext === DisplayContext.PARTNER_PROFILE && (
                <CustomFieldObjectAsTextfield
                    customField={parentCompanyCustomField}
                    label={formatMessage(secheCustomFieldsMessages[SecheCustomFields.PARENT_COMPANY])}
                    isTemporaryId={isTemporaryId}
                    isReadOnly={readOnly}
                    isRequired={false}
                />
            )}
            {displayContext === DisplayContext.PARTNER_PROFILE && (
                <Stack gap={1}>
                    {operationalPerformanceStatusCustomField.value && (
                        <>
                            <Typography variant="body2">
                                <SafeFormattedMessage
                                    {...secheCustomFieldsMessages[SecheCustomFields.OPERATIONAL_PERFORMANCE_STATUS]}
                                />
                                :
                            </Typography>

                            <Stack direction="row" gap={1}>
                                <StatusChip status={fixComplianceStatus(operationalPerformanceStatusCustomField.value)}>
                                    <SafeFormattedMessage
                                        {...globalStatus[
                                            fixComplianceStatus(operationalPerformanceStatusCustomField.value)
                                        ]}
                                    />
                                </StatusChip>
                            </Stack>
                        </>
                    )}
                    <PartnerEvaluationForms
                        organizationId={currentOrganizationId}
                        partnershipId={partnershipId ?? ""}
                        existingStatus={operationalPerformanceStatusCustomField.value}
                    />
                </Stack>
            )}
        </Stack>
    )
}
