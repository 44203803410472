import { FileText } from "react-feather"

import { CreateSurveyConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import {
    CreateSurveyNode as CreateSurveyNodeType,
    EditorNode,
    Event,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"

import { createCommonNodeProperties } from "./commonConfig"

const createSurveyNode = (eventTriggerEventType: Event | null): Omit<CreateSurveyNodeType, keyof Node> => ({
    nextNode: null,
    formId: "",
    type: NodeType.CREATE_SURVEY_NODE,
    sendNotifications: false,
    respondents: [],
    respondentOrganizationId:
        eventTriggerEventType === Event.PARTNERSHIP_CREATED ? "{{ trigger_event.partnerId }}" : null,
})

const advancedFields: AllAdvancedFieldsUnion[] = [
    AllAdvancedFieldsUnion.FORM_ID,
    AllAdvancedFieldsUnion.RESPONDENT_ORGANIZATION_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CREATE_SURVEY_NODE) return false
    return Boolean(node.formId && node.respondentOrganizationId)
}

const createSurveyConfig: Configuration<NodeType.CREATE_SURVEY_NODE> = {
    type: NodeType.CREATE_SURVEY_NODE,
    baseSlug: "create_survey",
    Icon: FileText,
    Configuration: CreateSurveyConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): CreateSurveyNodeType => ({
        ...createSurveyNode(props.eventTriggerEventType),
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { createSurveyConfig, advancedFields as createSurveyAdvancedFields }
