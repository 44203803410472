import { LinearProgress } from "@mui/material"
import {
    DataGridPremiumProps,
    GridColDef,
    GridFilterModel,
    GridRowIdGetter,
    GridRowParams,
    GridSortModel,
    GridToolbar,
    useGridApiRef,
} from "@mui/x-data-grid-premium"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { generatePath, useNavigate } from "react-router-dom"

import { DataGridNoRows } from "~/components/DataGrid/DataGridNoRows"
import { DataGridPremiumWithState } from "~/components/DataGrid/DataGridPremiumWithState"
import { initialState } from "~/constants/dataGrid"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { useLazyGetPaymentByObjectIdQuery } from "~/domains/payment/payment/api/paymentApi"
import { ProductsSearchReset } from "~/domains/transactions/catalog-v1/components/list-products/ProductsSearchReset"
import { useGetInvoicesByIdsQuery, useGetInvoicesQuery } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { GetInvoicesQuerySortBy } from "~/domains/transactions/invoices-v1/api/types/invoiceApi.type"
import { InvoicesListTabs } from "~/domains/transactions/invoices-v1/components/list/InvoicesListTabs"
import { useGetInvoicesListTableColumns } from "~/domains/transactions/invoices-v1/components/list/useGetInvoicesListTableColumns"
import { INVOICE_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { useAppSelector } from "~/store/hooks"
import { InvoiceI, OrganizationId } from "~/types"

const getRowId: GridRowIdGetter<InvoiceI> = (invoice) => invoice.id

const STATE_KEY = "invoicesList"
const ROWS_LENGTH = 50
const SCROLL_END_THRESHOLD = 0

interface InvoicesInfiniteListTableProps {
    organizationId: string
    otherOrganizationIds?: string[]
    invoiceIds?: string[]
    hideSearch?: boolean
    hideToolbar?: boolean
    actionColumn?: GridColDef<InvoiceI>
}

export const InvoicesInfiniteListTable = ({
    organizationId,
    otherOrganizationIds = [],
    invoiceIds,
    hideSearch,
    hideToolbar,
    actionColumn,
}: InvoicesInfiniteListTableProps) => {
    const navigate = useNavigate()
    const [rows, setRows] = useState<InvoiceI[]>([])
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "issuedAt", sort: "desc" }])
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [{ field: "status", value: "", operator: "equals" }],
    })
    const [paidInvoices, setPaidInvoices] = useState<Record<string, string | null>>({})
    const [vendorNames, setVendorNames] = useState<Record<string, string>>({})

    const [currentCursor, setCurrentCursor] = useState<string | null>(null)
    const [activeTab, setActiveTab] = useState("")
    const [status, setStatus] = useState("")
    const [dueWithin7Days, setDueWithin7Days] = useState(false)
    const apiRef = useGridApiRef()

    const brandNames = useAppSelector(selectPartnersBrandNames)
    const [organizationNames, setOrganizationNames] = useState<Record<OrganizationId, string>>(brandNames)

    const {
        data,
        isFetching: isInvoicesLoading,
        refetch,
    } = useGetInvoicesQuery(
        {
            organizationIds: [organizationId, ...otherOrganizationIds],
            cursor: currentCursor || "",
            pageSize: ROWS_LENGTH,
            status,
            dueDateBefore: dueWithin7Days ? dayjs().add(7, "day").format("YYYY-MM-DD") : undefined,
            dueDateAfter: dueWithin7Days ? dayjs().format("YYYY-MM-DD") : undefined,
            sortBy: sortModel?.[0]?.field as GetInvoicesQuerySortBy,
            ascending: sortModel?.[0]?.sort === "asc",
        },
        {
            skip: !!invoiceIds,
        }
    )
    const { data: invoicesByIds } = useGetInvoicesByIdsQuery(
        {
            organizationId,
            ids: invoiceIds || [],
        },
        {
            skip: !invoiceIds,
        }
    )

    const [getPaymentByObjectId] = useLazyGetPaymentByObjectIdQuery()

    const invoices = useMemo(() => invoicesByIds || data?.items || [], [data, invoicesByIds])

    const columns = useGetInvoicesListTableColumns(organizationId, vendorNames, paidInvoices, actionColumn)

    useEffect(() => {
        if (!invoices?.length) return

        invoices.forEach(async (invoice) => {
            const { data: payments } = await getPaymentByObjectId(invoice?.invoiceId)

            const paidDate = payments?.items?.[0]?.date_created

            setPaidInvoices((prev) => ({
                ...prev,
                [invoice.invoiceId]: paidDate ?? null,
            }))
        })
    }, [invoices, getPaymentByObjectId])

    useEffect(() => {
        invoices.forEach(async (invoice) => {
            const vendorName = organizationNames[invoice.sellerId]
            if (vendorName) {
                setVendorNames((prev) => ({
                    ...prev,
                    [invoice.invoiceId]: vendorName,
                }))
                return
            }

            ;(async () => {
                try {
                    if (!invoice.sellerId) return

                    const { name } = await organizationApi.getOrganizationById(invoice.sellerId)

                    setOrganizationNames((prev) => ({
                        ...prev,
                        [invoice.sellerId]: name,
                    }))
                    setVendorNames((prev) => ({
                        ...prev,
                        [invoice.invoiceId]: name,
                    }))
                } catch (error) {
                    console.error(error)
                    setVendorNames((prev) => ({
                        ...prev,
                        [invoice.invoiceId]: "-",
                    }))
                }
            })()
        })
    }, [organizationNames, invoices])

    useEffect(() => {
        if (!invoices) return

        setRows((prevRows) => {
            const existingIds = new Set(prevRows.map((invoice) => invoice.id))
            const newInvoices = invoices.filter((invoice) => !existingIds.has(invoice.id))

            return [...prevRows, ...newInvoices]
        })
    }, [invoices])

    useEffect(() => {
        resetQuery()
        setTimeout(async () => {
            const result = await refetch()
            setRows(result.data?.items || [])
        }, 1000)
    }, [organizationId, refetch])

    const handleOnRowsScrollEnd = useCallback<NonNullable<DataGridPremiumProps["onRowsScrollEnd"]>>(async () => {
        const lastItem = invoices?.[invoices.length - 1]
        if (!lastItem?.nextCursor) return

        setCurrentCursor(lastItem.nextCursor)
    }, [invoices])

    const handleRowClick = (params: GridRowParams<InvoiceI>) => {
        navigate(generatePath(INVOICE_ROUTE, { invoiceId: params.row.invoiceId }))
    }

    const resetQuery = () => {
        setCurrentCursor("")
        setRows([])
    }

    const handleChangeTab = (property: string, value: string) => {
        if (property === "dueWithin7Days") {
            setDueWithin7Days(true)
            setStatus("")
        } else {
            setStatus(value)
            setDueWithin7Days(false)
        }
        setActiveTab(value)
        resetQuery()
    }

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model)
        resetQuery()
    }

    const handleFilterModelChange = (model: GridFilterModel) => {
        setFilterModel(model)
        resetQuery()
    }

    return (
        <div>
            {!hideSearch && <InvoicesListTabs activeTab={activeTab} onChange={handleChangeTab} />}
            <DataGridPremiumWithState
                stateKey={STATE_KEY}
                apiRef={apiRef}
                initialState={initialState}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
                rows={rows}
                columns={columns}
                getRowId={getRowId}
                onRowClick={handleRowClick}
                disableRowSelectionOnClick
                hideFooter
                onRowsScrollEnd={handleOnRowsScrollEnd}
                scrollEndThreshold={SCROLL_END_THRESHOLD}
                slots={{
                    toolbar: hideToolbar ? undefined : GridToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: DataGridNoRows,
                    noResultsOverlay: hideSearch ? undefined : ProductsSearchReset,
                }}
                loading={isInvoicesLoading}
                sx={{ height: "calc(100vh - 200px)" }}
            />
        </div>
    )
}
