import { Users } from "react-feather"

import { FetchPartnershipConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    FetchPartnershipNode as FetchPartnershipNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const fetchPartnershipNode: Omit<FetchPartnershipNodeType, keyof Node> = {
    partnerId: "",
    type: NodeType.FETCH_PARTNERSHIP_NODE,
    nextNode: null,
}

const advancedFields: (keyof typeof fetchPartnershipNode)[] = [AllAdvancedFieldsUnion.PARTNER_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.FETCH_PARTNERSHIP_NODE) return false
    return Boolean(node.partnerId)
}

const fetchPartnershipConfig: Configuration<NodeType.FETCH_PARTNERSHIP_NODE> = {
    type: NodeType.FETCH_PARTNERSHIP_NODE,
    baseSlug: "fetch-partnership",
    Icon: Users,
    Configuration: FetchPartnershipConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): FetchPartnershipNodeType => ({
        ...fetchPartnershipNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { fetchPartnershipConfig, advancedFields as fetchPartnershipAdvancedFields }
