import { Alert, Grid, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { Copy } from "react-feather"
import { useIntl } from "react-intl"
import { defineMessages } from "react-intl"

import { Button, Loader, Modal, SafeFormattedMessage } from "~/components"
import {
    useGetCustomFormSurveysForLinkedObjectQuery,
    useSendFormToPartnerMutation,
} from "~/domains/identity/custom-forms/api/customFormsApi"
import {
    CustomFormSurvey,
    CustomFormSurveyStatus,
    SurveyLinkedObjectType,
} from "~/domains/identity/custom-forms/types/CustomForms"

const SURVEY_URL = `${import.meta.env.VITE_APP_URL}custom-forms/survey/`

const OPE_PRODUCT_SURVEY_ID = "404ba363-d56b-437a-8e7d-d6e3cfb40a8b"
const OPE_SERVICES_SURVEY_ID = "1538426b-6ddc-49ae-b8ec-d802faa9f536"

const messages = defineMessages({
    title: {
        id: "evaluation.forms.title",
        defaultMessage: "Evaluation forms",
    },
    createOPEProductSurvey: {
        id: "evaluation.forms.createOPEProductSurvey",
        defaultMessage: "Operational Performance Product form",
    },
    createOPEServicesSurvey: {
        id: "evaluation.forms.createOPEServicesSurvey",
        defaultMessage: "Operational Performance Services form",
    },
    formSent: {
        id: "smartForms.formSent",
        defaultMessage: "Form sent",
    },
    viewFormInstructions: {
        id: "smartForms.viewFormInstructions",
        defaultMessage: "Use this link to view the form:",
    },
    copyLink: {
        id: "smartForms.copyLink",
        defaultMessage: "Copy link",
    },
    sendForm: {
        id: "smartForms.sendForm",
        defaultMessage: "Send {formName}",
    },
    close: {
        id: "common.close",
        defaultMessage: "Close",
    },
    send: {
        id: "common.send",
        defaultMessage: "Send",
    },
    sendAgain: {
        id: "evaluation.forms.sendAgain",
        defaultMessage: "Send again",
    },
    inProgress: {
        id: "common.status.inProgress",
        defaultMessage: "In progress",
    },
    completed: {
        id: "common.status.completed",
        defaultMessage: "Completed",
    },
    created: {
        id: "common.status.created",
        defaultMessage: "Created",
    },
    alreadySentForms: {
        id: "evaluation.forms.alreadySentForms",
        defaultMessage: "Sent forms",
    },
})

export const PartnerEvaluationForms = ({
    organizationId,
    partnershipId,
    existingStatus,
}: {
    organizationId: string
    partnershipId: string
    existingStatus: string
}) => {
    const { formatMessage } = useIntl()

    const [formId, setFormId] = useState<string | null>(null)
    const [formLink, setFormLink] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [sentEvaluationForms, setSentEvaluationForms] = useState<CustomFormSurvey[]>([])
    const [sendFormToPartner, { isLoading: loadingSendToPartner, data: sendFormToPartnerData }] =
        // @ts-expect-error - for some reason organisationId is not recognized as a prop
        useSendFormToPartnerMutation({ organisationId: organizationId, formId })

    const { data: customFormSurveysForLinkedObjectData, refetch: refetchForms } =
        useGetCustomFormSurveysForLinkedObjectQuery({
            organisationId: organizationId,
            formId: existingStatus,
            linkedObjectId: partnershipId,
        })

    useEffect(() => {
        if (sendFormToPartnerData) {
            setFormLink(`${SURVEY_URL}${sendFormToPartnerData.token}`)
        }
    }, [sendFormToPartnerData])

    useEffect(() => {
        setSentEvaluationForms(
            customFormSurveysForLinkedObjectData?.filter(
                (survey) => survey.formId === OPE_PRODUCT_SURVEY_ID || survey.formId === OPE_SERVICES_SURVEY_ID
            ) ?? []
        )
    }, [customFormSurveysForLinkedObjectData])

    useEffect(() => {
        if (showModal) {
            refetchForms()
        }
    }, [refetchForms, showModal])

    const handleCreateOPEProductSurvey = () => {
        setFormId(OPE_PRODUCT_SURVEY_ID)
        setShowModal(true)
    }

    const handleCreateOPEServicesSurvey = () => {
        setFormId(OPE_SERVICES_SURVEY_ID)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setFormId(null)
        setFormLink("")
        setShowModal(false)
    }

    const handleSendFormToPartner = () => {
        formId &&
            sendFormToPartner({
                organisationId: organizationId,
                formId: formId,
                respondentContacts: [],
                respondentOrganizationId: organizationId,
                linkedObjects: [{ objectId: partnershipId, objectType: SurveyLinkedObjectType.PARTNERSHIP }],
            })
    }

    const getSentEvaluationForms = () => {
        const getStatus = (status: CustomFormSurveyStatus) => {
            if ("InProgress" in status) {
                return formatMessage(messages.inProgress)
            }
            if ("Completed" in status) {
                return formatMessage(messages.completed)
            }
            if ("Created" in status) {
                return formatMessage(messages.created)
            }
            return formatMessage(messages.created)
        }
        return (
            <>
                {sentEvaluationForms?.map((survey) => {
                    const formName =
                        survey.formId === OPE_PRODUCT_SURVEY_ID
                            ? formatMessage(messages.createOPEProductSurvey)
                            : formatMessage(messages.createOPEServicesSurvey)
                    return (
                        <div key={survey.id}>
                            <a href={`${SURVEY_URL}${survey.token}`} target="_blank" rel="noopener noreferrer">
                                <u>
                                    {formName} - {dayjs(survey.createdAt).format("DD/MM/YYYY")} -{" "}
                                    {getStatus(survey.status)}
                                </u>
                            </a>
                        </div>
                    )
                })}
            </>
        )
    }

    const modalContent = () => {
        if (loadingSendToPartner) {
            return (
                <>
                    <Modal.Content>
                        <Loader />
                    </Modal.Content>
                </>
            )
        }
        if (formLink) {
            return (
                <>
                    <Modal.Content>
                        <Alert severity="success">
                            <p>
                                <strong>
                                    <SafeFormattedMessage {...messages.formSent} />
                                </strong>
                            </p>
                            <p className="mt-12">
                                <SafeFormattedMessage {...messages.viewFormInstructions} />
                            </p>
                            <p>
                                <strong>{formLink}</strong>
                            </p>
                        </Alert>
                    </Modal.Content>
                    <Modal.Footer>
                        <CopyToClipboard text={formLink}>
                            <Button type="primary">
                                <Copy size={14} />
                                <SafeFormattedMessage {...messages.copyLink} />
                            </Button>
                        </CopyToClipboard>

                        <Button onClick={handleCloseModal} type="neutral">
                            <SafeFormattedMessage id="common.close" defaultMessage="Close" />
                        </Button>
                    </Modal.Footer>
                </>
            )
        }

        if (!partnershipId) {
            return null
        }

        return (
            <>
                <Modal.Content className="confirm-modal">
                    {sentEvaluationForms?.length ? (
                        <>
                            <Typography variant="body2">
                                <SafeFormattedMessage {...messages.alreadySentForms} />
                            </Typography>
                            {getSentEvaluationForms()}
                        </>
                    ) : (
                        <SafeFormattedMessage
                            {...messages.sendForm}
                            values={{
                                formName:
                                    formId === OPE_PRODUCT_SURVEY_ID
                                        ? formatMessage(messages.createOPEProductSurvey)
                                        : formatMessage(messages.createOPEServicesSurvey),
                            }}
                        />
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button
                        type="primary"
                        buttonType="submit"
                        onClick={handleSendFormToPartner}
                        disabled={loadingSendToPartner}
                    >
                        <SafeFormattedMessage {...(sentEvaluationForms.length ? messages.sendAgain : messages.send)} />
                    </Button>
                    <Button type="neutral" onClick={handleCloseModal} disabled={loadingSendToPartner}>
                        {formatMessage(messages.close)}
                    </Button>
                </Modal.Footer>
            </>
        )
    }

    return (
        <>
            <Typography variant="body2">
                <SafeFormattedMessage {...messages.title} />
            </Typography>
            {!existingStatus ? (
                <Grid container direction="row" gap={2}>
                    <Button type="primary-light" onClick={handleCreateOPEProductSurvey} disabled={loadingSendToPartner}>
                        <SafeFormattedMessage {...messages.createOPEProductSurvey} />
                    </Button>
                    <Button
                        type="primary-light"
                        onClick={handleCreateOPEServicesSurvey}
                        disabled={loadingSendToPartner}
                    >
                        <SafeFormattedMessage {...messages.createOPEServicesSurvey} />
                    </Button>
                </Grid>
            ) : (
                <>{getSentEvaluationForms()}</>
            )}

            <Modal open={showModal} onClose={handleCloseModal}>
                <Modal.Header>
                    <SafeFormattedMessage {...messages.title} />
                </Modal.Header>
                {modalContent()}
            </Modal>
        </>
    )
}
