import { List, Stack } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Card, Loader, SafeFormattedMessage } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"
import { useUploadOCRDocument } from "~/domains/_shared/ocr/hooks/useUploadDocument"
import { OCR_IMPORT } from "~/domains/_shared/ocr/routes"
import { InvoicePicker } from "~/domains/transactions/invoices/components/InvoicePicker"
import { selectPurchaseViewType } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useAppSelector } from "~/store/hooks"
import { InvoiceUserType, OrganizationId } from "~/types"
import { useFetchErrorMessage } from "~/utils/apiClient/errors"
import { checkOneFileType } from "~/utils/upload"

import { useAddRelationToPurchaseOrder, usePurchaseOrderRelations } from "../../hooks"
import { UploadInvoiceBox } from "../UploadInvoiceBox/UploadInvoiceBox"
import { PurchaseOrderRelation } from "./PurchaseOrderRelation"

const messages = defineMessages({
    title: {
        id: "transactions.purchaseOrder.documentRelations.title",
        defaultMessage: "{count, plural, =0 {Invoice} one {Invoice} other {Invoices}}",
    },
    or: { id: "common.or", defaultMessage: "or" },
    errorWrongFileType: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept PDF files.",
    },
    errorUpload: {
        id: "common.error.errorUploadingDocument",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

interface Props {
    organizationId: OrganizationId
    purchaseOrder: PurchaseOrders
}

const ACCEPTED_FILE_TYPES = "application/pdf"

export const PurchaseOrderDocumentRelations: React.FC<Props> = ({ organizationId, purchaseOrder }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const [uploadingFile, setUploadingFile] = useState(false)

    const viewType = useAppSelector(selectPurchaseViewType)

    const { loading, error, purchaseOrderRelations } = usePurchaseOrderRelations(organizationId, purchaseOrder.id)
    const addRelationToPurchaseOrder = useAddRelationToPurchaseOrder(organizationId, purchaseOrder.id)
    const errorMessage = useFetchErrorMessage(error)

    const invoiceIds = useMemo(() => purchaseOrderRelations.map(({ invoiceId }) => invoiceId), [purchaseOrderRelations])

    const { uploadOCRDocument } = useUploadOCRDocument(InvoiceUserType.SUPPLIER)

    const handleFile = useCallback(
        async (file: File) => {
            setUploadingFile(true)
            const document = await uploadOCRDocument(file)
            setUploadingFile(false)

            if (document?.id) {
                const searchParams = new URLSearchParams({ purchaseOrderId: purchaseOrder.id }).toString()
                navigate(
                    `${generatePath(OCR_IMPORT, {
                        initiator: viewType,
                        documentId: document.id,
                    })}?${searchParams}`
                )
                return
            }

            toast.error(formatMessage(messages.errorUpload))
        },
        [uploadOCRDocument, viewType]
    )

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            return checkOneFileType(file, ACCEPTED_FILE_TYPES, formatMessage(messages.errorWrongFileType))
        },
        [formatMessage]
    )

    if (loading) {
        return <Loader small />
    }

    return (
        <Card title={formatMessage(messages.title, { count: purchaseOrderRelations.length })} expandable>
            <Stack spacing={1}>
                {loading ? (
                    <Loader small />
                ) : (
                    <List>
                        {purchaseOrderRelations.map((relation) => (
                            <PurchaseOrderRelation
                                key={relation.relationId}
                                purchaseOrderRelation={relation}
                                organizationId={organizationId}
                                purchaseOrderId={purchaseOrder.id}
                                viewType={viewType}
                            />
                        ))}
                    </List>
                )}

                <ErrorMessage>{errorMessage}</ErrorMessage>

                <InvoicePicker
                    organizationId={organizationId}
                    value={null}
                    onChange={addRelationToPurchaseOrder}
                    viewType={viewType}
                    blackListIds={invoiceIds}
                />

                <p className="text-center">
                    <SafeFormattedMessage {...messages.or} />
                </p>

                <UploadInvoiceBox
                    handleFile={handleFile}
                    checkFileType={checkFileType}
                    loading={uploadingFile}
                    acceptedFileTypes={ACCEPTED_FILE_TYPES}
                />
            </Stack>
        </Card>
    )
}
