import { HandleProps, Position } from "@xyflow/react"

import { RegularNodeProps } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const RegularNode: RegularNodeProps = ({ data, selected }) => {
    const handles: HandleProps[] = [
        {
            type: "target",
            isConnectableEnd: true,
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            isConnectableStart: true,
        },
    ]
    return (
        <Node
            type={data.type}
            name={data.name}
            information={data.metadata?.additionalInformation}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={data.error}
        ></Node>
    )
}
