import React, { FC, PropsWithChildren, createContext, useContext, useReducer } from "react"
import { Dispatch } from "react"

import { nodeConfig } from "~/domains/orchestration/flows/core"
import {
    BranchNode,
    ConditionalNode,
    EditorNode,
    type Flow,
    RegularNode,
    type Run,
    hasNextNode,
    isBranchNode,
    isConditionalNode,
} from "~/domains/orchestration/flows/types"
import { generateReadableId } from "~/domains/orchestration/flows/utils"

type EditorState = {
    flow: Flow | null
    history: Flow[]
    error: string | null
    run: Run | null
}

type EditorAction =
    | { type: "SET_FLOW"; payload: Flow }
    | { type: "UPDATE_NODE"; payload: EditorNode }
    | { type: "UPDATE_POSITION"; payload: EditorNode }
    | { type: "DELETE_NODE"; payload: string }
    | { type: "COPY_NODE"; payload: string }
    | { type: "SET_FLOW_ERROR"; payload: string | null }
    | { type: "SET_RUN"; payload: Run | null }
    | { type: "SET_HISTORY"; payload: Flow[] }
const initialState: EditorState = {
    flow: null,
    history: [],
    error: null,
    run: null,
}

const resetNextNode = (node: EditorNode): RegularNode | ConditionalNode | BranchNode => {
    if (hasNextNode(node)) {
        return { ...node, nextNode: null }
    } else if (isConditionalNode(node)) {
        return { ...node, nextIfSuccess: null, nextIfFailure: null }
    } else if (isBranchNode(node)) {
        return {
            ...node,
            branches: node.branches.map((branch) => ({
                ...branch,
                nextNode: null,
            })),
        }
    }
    return node
}

const editorReducer = (state: EditorState, action: EditorAction): EditorState => {
    switch (action.type) {
        case "SET_FLOW":
            return { ...state, flow: action.payload, history: [...state.history, action.payload] }

        case "SET_HISTORY":
            return { ...state, history: [...action.payload] }

        case "UPDATE_POSITION": {
            const { flow } = state
            const { payload } = action

            if (!flow) return state

            const updatedFlow = {
                ...flow,
                nodes: flow.nodes.map((node) => (node.slug === payload.slug ? payload : node)),
            }
            return { ...state, flow: updatedFlow, history: [...state.history, updatedFlow] }
        }

        case "UPDATE_NODE": {
            const { flow } = state
            const { payload } = action

            const config = nodeConfig[payload.type]
            const validateNode = config.validateNode

            if (!flow) return state
            const updatedFlow = {
                ...flow,
                nodes: flow.nodes.map((node) =>
                    node.slug === payload.slug
                        ? {
                              ...payload,
                              error: !validateNode(payload),
                              metadata: { ...payload.metadata, position: node.metadata.position },
                          }
                        : node
                ),
            }
            return { ...state, flow: updatedFlow, history: [...state.history, updatedFlow] }
        }
        case "COPY_NODE": {
            const { flow } = state
            const { payload: nodeSlug } = action

            if (!flow) return state

            const nodeToCopy = flow.nodes.find((node) => node.slug === nodeSlug)
            if (!nodeToCopy) return state

            // name example: "Send email
            // Next copy will be "Send email ~ (1)"
            // Next copy will be "Send email ~ (2)"

            const getBaseName = (name: string) => name.split("~")[0].trim()
            const baseName = getBaseName(nodeToCopy.name)
            const baseSlug = nodeSlug.split("_")[0]

            const isSameNodeType = (node: EditorNode) => node.slug.includes(baseSlug)

            // we filter and map to get the copy numbers
            const nameList = flow.nodes.filter(isSameNodeType).map((node) => node.name)

            let copyNumber = 1
            while (nameList.includes(`${baseName} ~ (${copyNumber})`)) {
                copyNumber++
            }

            const slug = generateReadableId(nodeToCopy.type, flow.nodes)

            const name = `${baseName || baseSlug} ~ (${copyNumber})`

            // Create copy with reset connections and random offset position between 200 and 300
            const randomX = Math.floor(Math.random() * 100) + 200
            const randomY = Math.floor(Math.random() * 100) + 200
            const copiedNode = {
                ...resetNextNode(nodeToCopy),
                slug,
                name,
                metadata: {
                    ...nodeToCopy.metadata,
                    position: {
                        x: nodeToCopy.metadata.position.x + randomX,
                        y: nodeToCopy.metadata.position.y + randomY,
                    },
                },
            } as EditorNode

            const updatedFlow = {
                ...flow,
                nodes: [...flow.nodes, copiedNode],
            }

            return {
                ...state,
                flow: updatedFlow,
            }
        }
        case "DELETE_NODE": {
            const { flow } = state
            const { payload } = action

            if (!flow) return state
            const newFlow = {
                ...flow,
                nodes: flow.nodes.filter((node) => node.slug !== payload),
            }
            // remove node from nextNode of all nodes that have nextNode as the deleted node
            const updatedNodes = newFlow.nodes.map((node) => {
                if (hasNextNode(node) && node.nextNode === payload) {
                    return { ...node, nextNode: null }
                } else if (isConditionalNode(node) && node.nextIfSuccess === payload) {
                    return { ...node, nextIfSuccess: null }
                } else if (isConditionalNode(node) && node.nextIfFailure === payload) {
                    return { ...node, nextIfFailure: null }
                } else if (isBranchNode(node) && node.branches.some((branch) => branch.nextNode === payload)) {
                    return {
                        ...node,
                        branches: node.branches.map((branch) => ({
                            ...branch,
                            nextNode: branch.nextNode === payload ? null : branch.nextNode,
                        })),
                    }
                }
                return node
            })
            const updatedFlow = {
                ...newFlow,
                nodes: updatedNodes,
            }
            return { ...state, flow: updatedFlow, history: [...state.history, updatedFlow] }
        }

        case "SET_FLOW_ERROR":
            return { ...state, error: action.payload }

        case "SET_RUN":
            return { ...state, run: action.payload }

        default:
            return state
    }
}

export const EditorContext = createContext<EditorState>({
    flow: null,
    history: [],
    error: null,
    run: null,
})

const EditorDispatchContext = createContext<Dispatch<EditorAction>>(() => {
    throw new Error("EditorDispatchContext not provided")
})

export const EditorProvider: FC<PropsWithChildren> = ({ children }) => {
    const [flow, dispatch] = useReducer(editorReducer, initialState)

    return (
        <EditorContext.Provider value={flow}>
            <EditorDispatchContext.Provider value={dispatch}>{children}</EditorDispatchContext.Provider>
        </EditorContext.Provider>
    )
}

export function useEditor() {
    return useContext(EditorContext)
}

export function useEditorDispatch() {
    return useContext(EditorDispatchContext)
}
