import { CurrencyCodes } from "~/types"

export enum PaymentStatus {
    DRAFT = "DRAFT",
    EXECUTED = "EXECUTED",
    PENDING = "PENDING",
    CANCELED = "CANCELED",
    SCHEDULED = "SCHEDULED",
    INITIATION = "INITIATION",
    AUTHORIZED = "AUTHORIZED",
    PROCESSING = "PROCESSING",
    CLEARING = "CLEARING",
}

export enum PaymentRole {
    PAYER = "payer",
    PAYEE = "payee",
    BOTH = "both",
}

export enum DocumentPaymentStatus {
    PAID = "PAID",
    UNPAID = "UNPAID",
    SCHEDULED = "SCHEDULED",
}

export interface DocumentData {
    number: string
    total: PaymentAmount
    dueDate?: string | null
    issueDate?: string | null
    iban?: string | null
    accountNumber?: string | null
}

export interface PaymentAmount {
    amount: number | null
    currency: CurrencyCodes | undefined
}

export interface PaymentMetadata {
    invoice_number: string
    customer_id?: string
}

export interface PaymentMetadataUI {
    invoiceNumber: string
    customerId?: string
}

export interface Payment {
    id?: string
    amount_sent_data: PaymentAmount | null
    amount_received_data?: PaymentAmount | null
    payer_id: string
    payee_id: string
    payment_method_id?: string | null
    origin_cash_id?: string | null
    date_validated?: string | null
    date_scheduled?: string | null
    date_created?: string | null
    value_date_expected?: string | null
    destination_cash_id?: string | null
    reference?: string
    comment?: string
    status: PaymentStatus
    executed_by_id?: string
    authorized_by_id?: string
    payment_metadata?: PaymentMetadata
}

export interface PaymentUI {
    id?: string
    amountSentData: PaymentAmount | null
    amountReceivedData?: PaymentAmount | null
    payerId: string
    payeeId: string
    paymentMethodId?: string | null
    originCashId?: string | null
    dateValidated?: string | null
    dateScheduled?: string | null
    dateCreated?: string | null
    valueDateExpected?: string | null
    destinationCashId?: string | null
    reference?: string
    comment?: string
    status: PaymentStatus
    executedById?: string
    authorizedById?: string
    paymentMetadata?: PaymentMetadataUI
}

export interface SelectedPaymentMethods {
    payerPaymentMethodDetailsId: string | null
    payeePaymentMethodDetailsId: string | null
    paymentMethodId: string | null
}
