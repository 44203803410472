import { PlusCircle } from "react-feather"

import { CreateCustomFieldConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    CreateCustomFieldNode as CreateCustomFieldNodeType,
    EditorNode,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const createCustomFieldNode: Omit<CreateCustomFieldNodeType, keyof Node> = {
    objectId: "",
    customFieldName: "",
    customFieldValue: "",
    type: NodeType.CREATE_CUSTOM_FIELD_NODE,
    nextNode: null,
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.CREATE_CUSTOM_FIELD_NODE) return false
    return Boolean(node.objectId && node.customFieldName)
}

const createCustomFieldConfig: Configuration<NodeType.CREATE_CUSTOM_FIELD_NODE> = {
    type: NodeType.CREATE_CUSTOM_FIELD_NODE,
    baseSlug: "create-custom-field",
    Icon: PlusCircle,
    Configuration: CreateCustomFieldConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): CreateCustomFieldNodeType => ({
        ...createCustomFieldNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { createCustomFieldConfig, advancedFields as createCustomFieldAdvancedFields }
