import { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import {
    PARTNERS_PAYMENT_METHOD_DETAILS,
    PAYMENT_BATCHES,
    PAYMENT_BATCH_INSTANCES,
    PAYMENT_METHOD_DETAILS,
} from "./routes"

const PaymentBatchInstancesPage = lazy(() =>
    import("~/domains/payment/payment-batches/pages").then(({ PaymentBatchInstances }) => ({
        default: PaymentBatchInstances,
    }))
)

const PaymentBatchesConfigurationsPage = lazy(() =>
    import("~/domains/payment/payment-batches/pages").then(({ PaymentBatchesConfigurations }) => ({
        default: PaymentBatchesConfigurations,
    }))
)

const MyPaymentMethodDetailsPage = lazy(() =>
    import("~/domains/payment/payment-batches/pages").then(({ MyPaymentMethodDetails }) => ({
        default: MyPaymentMethodDetails,
    }))
)

const PartnersPaymentMethodDetailsPage = lazy(() =>
    import("~/domains/payment/payment-batches/pages").then(({ PartnersPaymentMethodDetails }) => ({
        default: PartnersPaymentMethodDetails,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="payment_batches_routes">
            <Route path={PAYMENT_BATCH_INSTANCES} element={<PaymentBatchInstancesPage />} />
            <Route path={PAYMENT_BATCHES} element={<PaymentBatchesConfigurationsPage />} />
            <Route path={PAYMENT_METHOD_DETAILS} element={<MyPaymentMethodDetailsPage />} />
            <Route path={PARTNERS_PAYMENT_METHOD_DETAILS} element={<PartnersPaymentMethodDetailsPage />} />
        </Route>,
    ],
}
