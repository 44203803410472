/* eslint-disable no-nested-ternary */

/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable max-lines */

/* eslint-disable complexity */
import { Stack } from "@mui/material"
import { Dayjs } from "dayjs"
import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Card, CurrencySelector, HeaderH1, Loader, StatusChip } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { ResizableCol } from "~/components/Layout/ResizableCol"
import { ResizableGrid } from "~/components/Layout/ResizableGrid"
import { DraftDocumentI } from "~/components/UploadDocument/Documents"
import { Documents } from "~/components/UploadDocument/Documents/Documents"
import { PREFIX_PO } from "~/constants"
import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { TagsSelector } from "~/domains/analytics/tags/components/TagsSelector"
import {
    TagsSelectorForLineCells,
    TagsSelectorWithStateForLineCells,
} from "~/domains/analytics/tags/components/TagsSelector/TagsSelectorForLineCells"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { TagGroupI, TagObjectType } from "~/domains/analytics/tags/types"
import { CommunicationRoom } from "~/domains/communication/chat/components/CommunicationRoom"
import { LinesTabs } from "~/domains/identity/custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "~/domains/identity/custom-fields/types/CustomFieldObjectType"
import { DocumentObjectType } from "~/domains/identity/documents/types"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { SavePayloadType } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/types"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { Dates } from "~/domains/transactions/_shared/components/Dates/Dates"
import { Description } from "~/domains/transactions/_shared/components/Description/Description"
import { useTagsForLines } from "~/domains/transactions/_shared/components/Items/hooks/useTagsForLines"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { RequesterName } from "~/domains/transactions/_shared/components/RequesterName/RequesterName"
import { TotalAmount } from "~/domains/transactions/_shared/components/TotalAmount/TotalAmount"
import { TransactionInBudgets } from "~/domains/transactions/_shared/components/TransactionInBudgets"
import { PurchaseOrderDocumentRelations } from "~/domains/transactions/_shared/document-relations/components/PurchaseOrderDocumentRelations"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import { BudgetDataWithMetricsI, TransactionType } from "~/domains/transactions/budget/types"
import { ActionsHeader } from "~/domains/transactions/purchase-orders/components/Actions/ActionsHeader"
import { ActionsHeaderCreate } from "~/domains/transactions/purchase-orders/components/Actions/ActionsHeaderCreate"
import { POInfosVeoliaCustomFields } from "~/domains/transactions/purchase-orders/components/POInfosCustomFields/POInfosVeoliaCustomFIelds"
import { PurchaseDocumentCustomFields } from "~/domains/transactions/purchase-orders/components/PurchaseDocumentCustomFields"
import { PurchaseRequestLink } from "~/domains/transactions/purchase-orders/components/PurchaseRequestLink/PurchaseRequestLink"
import { StepsPurchaseOrder } from "~/domains/transactions/purchase-orders/components/StepsPurchaseOrder"
import { TagsForCreatingPurchaseOrder } from "~/domains/transactions/purchase-orders/components/TagsForCreatingPurchaseOrder"
import {
    isStatusReadonly,
    isStatusWithoutCommunication,
} from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import { organizationAddressToAddress } from "~/domains/transactions/purchase-orders/core/organizationAddressToAddress"
import { usePurchaseOrder } from "~/domains/transactions/purchase-orders/hooks"
import { PURCHASE_ORDERS_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useFetchPurchaseOrder, useUpdatePurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks"
import {
    purchaseOrdersActions,
    selectPurchaseOrdersError,
    selectPurchaseOrdersLoading,
    selectPurchaseViewType,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseOrderLine, PurchaseOrders } from "~/domains/transactions/purchase-orders/types"
import { useTitle } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { selectIsConnected } from "~/store/auth/authSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { organizationActions, selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { CurrencyCodes, CurrencyI, OrganizationI, OrganizationId, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"
import useQueryParams from "~/utils/hooks/useQueryParams"

const ItemsPO = lazy(() =>
    import("~/domains/transactions/purchase-orders/components/POList/ItemsPO").then(({ ItemsPO }) => ({
        default: ItemsPO,
    }))
)
const OrganizationsLazy = lazy(() =>
    import("~/domains/transactions/_shared/components/Organizations/Organizations").then(({ Organizations }) => ({
        default: Organizations,
    }))
)

const STATE_KEY = "transaction"

const messages = defineMessages({
    htmlTitle: { id: "purchase.orders.order.htmlTitle", defaultMessage: "Purchase order" },
    titleEdit: { id: "purchase.orders.order.titleEdit", defaultMessage: "Edit PO" },
    titleView: { id: "purchase.orders.order.titleView", defaultMessage: "PO" },
    titleNew: { id: "purchase.orders.order.titleNew", defaultMessage: "New PO" },
    items: { id: "purchase.orders.order.items", defaultMessage: "Items" },
    esgCustomFields: { id: "purchase.orders.order.esgCustomFields", defaultMessage: "ESG" },
    taxLabel: { id: "purchase.orders.order.taxLabel", defaultMessage: "Tax excl." },
    purchaseRequest: { id: "purchase.orders.order.purchaseRequest", defaultMessage: "Purchase request" },
    cancel: { id: "purchase.orders.order.cancel", defaultMessage: "Cancel this PO" },
    addToBudget: { id: "purchase.orders.order.addToBudget", defaultMessage: "Add to budget" },
    budgets: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
    errorChangedOrganization: {
        id: "purchase.requests.request.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this purchase request.",
    },
    tagsTitle: { id: "purchase.orders.order.tagsTitle", defaultMessage: "Tags" },
})

interface Props extends JSX.IntrinsicAttributes {
    edit?: boolean
}

interface ViewProps extends Props {
    organization?: OrganizationI
    purchaseOrder: PurchaseOrders
    isFromSharedEmail: boolean
    sharedOrganizationId?: OrganizationId
    sharedUserId?: string
    loading?: boolean
    newPO?: boolean
}

export const PurchaseOrderView: React.FC<ViewProps> = ({
    edit,
    organization,
    purchaseOrder,
    isFromSharedEmail,
    sharedOrganizationId,
    sharedUserId,
    loading = false,
    newPO = false,
}) => {
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const isConnected = useAppSelector(selectIsConnected)
    const viewType = useAppSelector(selectPurchaseViewType)
    const dispatch = useAppDispatch()

    const [draftBudgets, setDraftBudgets] = useState<BudgetDataWithMetricsI[]>([])
    const [isShared, setIshared] = useState<boolean>(isFromSharedEmail)
    const [draftDocuments, setDraftDocuments] = useState<DraftDocumentI[]>([])
    const [linesNeedApproval, setLinesNeedApproval] = useState<boolean>(false)

    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)
    const brandNames = useAppSelector(selectPartnersBrandNames)

    const { tagGroups } = useOrganizationTagGroups(organization?.id)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: purchaseOrder.lines,
        organizationId: organization?.id,
        tagGroups,
        objectType: TagObjectType.PURCHASE_ORDER_LINE,
    })

    const pageMode = newPO ? PurchaseViewType.ADD : edit ? PurchaseViewType.EDIT : PurchaseViewType.VIEW
    const title = formatMessage(messages[`title${pageMode === PurchaseViewType.EDIT ? "Edit" : "View"}`])

    const {
        updatePO,
        loading: updateLoading,
        error: updateError,
    } = useUpdatePurchaseOrder(purchaseOrder.id, organization?.id)

    const getOrganizationName = useCallback(
        (sideId: string, organizationId?: string, sideName?: string): string => {
            if (sideId && organizationId && sideId !== organizationId) {
                return brandNames[sideId] ?? sideName ?? ""
            }
            return sideName ?? ""
        },
        [brandNames]
    )

    const updateAddressesOrContact = useCallback(
        async (payload: SavePayloadType) => {
            if (pageMode === PurchaseViewType.ADD) {
                if (payload.organizationAddress) {
                    dispatch(
                        purchaseOrdersActions.updateData({
                            field: "billingAddress",
                            value: organizationAddressToAddress(payload.organizationAddress),
                        })
                    )
                }
                if (payload.sendingAddress) {
                    dispatch(
                        purchaseOrdersActions.updateData({
                            field: "shippingAddress",
                            value: organizationAddressToAddress(payload.sendingAddress),
                        })
                    )
                }
            } else {
                const updatePayload: PurchaseOrders = {
                    ...purchaseOrder,
                    ...(payload.sendingAddress
                        ? { shippingAddress: organizationAddressToAddress(payload.sendingAddress) }
                        : {}),
                    ...(payload.organizationAddress
                        ? { billingAddress: organizationAddressToAddress(payload.organizationAddress) }
                        : {}),
                }
                await updatePO(updatePayload)
            }
        },
        [purchaseOrder, pageMode, updatePO]
    )

    const setDeliveryDate = useCallback((date: Dayjs | null) => {
        if (date) {
            dispatch(
                purchaseOrdersActions.updateData({
                    field: "expectedDeliveryDate",
                    value: date.add(18, "hours").toISOString(),
                })
            )
        }
    }, [])

    const handleChangeDescription = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            purchaseOrdersActions.updateData({
                field: "description",
                value: e.target.value,
            })
        )
    }, [])

    const handleCurrencyChange = useCallback(
        (currency: CurrencyI | null) => {
            if (currency) {
                dispatch(
                    purchaseOrdersActions.updateData({
                        field: "currency",
                        value: currency.code as CurrencyCodes,
                    })
                )
            }
        },
        [dispatch]
    )

    const from: OrganizationSummary = {
        id: purchaseOrder.buyerId,
        name: getOrganizationName(purchaseOrder.buyerId, organization?.id, purchaseOrder.buyerName),
        type: ViewTypeI.buyer,
    }
    const to: OrganizationSummary = {
        id: purchaseOrder.supplierId,
        name: getOrganizationName(purchaseOrder.supplierId, organization?.id, purchaseOrder.supplierName),
        type: ViewTypeI.supplier,
    }

    const tagSelectorContext = useMemo(
        () => ({
            page: "purchase-order",
            viewType,
            purchaseOrder,
            organization,
            user,
        }),
        [purchaseOrder, organization, viewType, user]
    )

    useEffect(() => {
        if (tagGroups && tagGroups.length) {
            fetchTags()
        }
    }, [tagGroups])

    useEffect(() => {
        if (isConnected && isFromSharedEmail && sharedOrganizationId && sharedUserId && user.id === sharedUserId) {
            const organizationAlreadyExists = user.organizations.find((org) => org.id === sharedOrganizationId)
            if (!organizationAlreadyExists) {
                const membershipAlreadyRequested = user.membershipRequests?.find(
                    (org) => org.id === sharedOrganizationId
                )
                if (!membershipAlreadyRequested) {
                    organizationApi.requestMembership(sharedOrganizationId).then(() => {
                        document.location.reload()
                    })
                }
            } else if (purchaseOrder && purchaseOrder.id) {
                dispatch(organizationActions.setCurrentOrganization(sharedOrganizationId))
                setIshared(false)
            }
        }
    }, [isConnected, user, isFromSharedEmail, sharedOrganizationId, purchaseOrder, sharedUserId])

    const handleAddToBudgetSuccess = useCallback(async () => {
        if (!purchaseOrder.id) return

        await fetchPurchaseOrder(purchaseOrder.id, true)
    }, [dispatch, purchaseOrder])

    /// This handles the new approval service approval requirement for a line.
    /// The line renderer component calls the API to see if the line requires approval and fires this callback in case it does.
    const handleApprovalRequiredForLine = useCallback(() => {
        setLinesNeedApproval(true)
    }, [purchaseOrder])

    const renderLineTags = useCallback(
        (line: PurchaseOrderLine, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organization) return null

            if (line.id) {
                return (
                    <TagsSelectorForLineCells
                        objectId={line.id}
                        tags={tags}
                        setTags={setTags}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            if (line.temporaryId) {
                return (
                    <TagsSelectorWithStateForLineCells
                        temporaryId={line.temporaryId}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            return null
        },
        [user, organization, tags, setTags, purchaseOrder]
    )

    const otherOrganizations = useMemo(() => {
        const otherOrganizations: OrganizationId[] = []
        if (isStatusWithoutCommunication(purchaseOrder.status)) return otherOrganizations
        if (purchaseOrder.buyerId && (!organization || purchaseOrder.buyerId !== organization.id)) {
            otherOrganizations.push(purchaseOrder.buyerId)
        }
        if (purchaseOrder.supplierId && (!organization || purchaseOrder.supplierId !== organization.id)) {
            otherOrganizations.push(purchaseOrder.supplierId)
        }
        return otherOrganizations
    }, [organization, purchaseOrder])

    const allLinesApproved = useMemo(
        () =>
            purchaseOrder.lines.every(
                (line) =>
                    line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] ===
                    "APPROVED"
            ),
        [purchaseOrder.lines, viewType]
    )

    const hasVeoliaCustomFields = useMemo(
        () => isFeatureEnabled(Features.VeoliaCustomFields, organization?.id),
        [organization?.id]
    )

    const tagsObjectType = TagObjectType.PURCHASE_ORDER
    const documentsObjectType = DocumentObjectType.PURCHASE_ORDER
    const customFieldsObjectType = CustomFieldObjectType.PURCHASE_ORDER

    return (
        <div className="purchase-page">
            <HeaderH1
                title={`${title} ${PREFIX_PO} ${purchaseOrder.shortId}`}
                backLink={generatePath(PURCHASE_ORDERS_ROUTE, { viewType })}
                isShared={isShared}
                mainAction={
                    <StatusChip status={purchaseOrder.statusPresentation}>
                        {purchaseOrder.statusPresentation}
                    </StatusChip>
                }
            >
                {!isShared &&
                    organization &&
                    (newPO ? (
                        <ActionsHeaderCreate
                            organizationId={organization.id}
                            draftDocuments={draftDocuments}
                            draftBudgets={draftBudgets}
                            setDraftDocuments={setDraftDocuments}
                            setDraftBudgets={setDraftBudgets}
                        />
                    ) : (
                        <ActionsHeader
                            PO={purchaseOrder}
                            organization={organization}
                            buyerOrganization={{
                                id: purchaseOrder.buyerId,
                                name: getOrganizationName(
                                    organization.id,
                                    purchaseOrder.buyerId,
                                    purchaseOrder.buyerName
                                ),
                            }}
                            supplierOrganization={{
                                id: purchaseOrder.supplierId,
                                name: getOrganizationName(
                                    organization.id,
                                    purchaseOrder.supplierId,
                                    purchaseOrder.supplierName
                                ),
                            }}
                            mode={pageMode}
                            viewType={viewType}
                            allLinesApproved={allLinesApproved}
                            needsNewApproval={linesNeedApproval}
                        />
                    ))}
            </HeaderH1>
            <Stack className="main-box" gap={2}>
                {updateLoading || loading ? <Loader fullscreen /> : null}
                <StepsPurchaseOrder PO={purchaseOrder} viewType={viewType} />

                <ResizableGrid stateKey={STATE_KEY}>
                    <ResizableCol minSize={200} maxSize={400} defaultSize={300}>
                        <Stack gap={1} className="column">
                            <TotalAmount
                                amount={purchaseOrder.totalAmountExcludingTax}
                                currency={purchaseOrder.currency}
                                taxLabel={formatMessage(messages.taxLabel)}
                            />
                            <Card>
                                <Dates
                                    creationDate={purchaseOrder.creationDate}
                                    expectedDeliveryDate={purchaseOrder.expectedDeliveryDate}
                                    pageMode={pageMode}
                                    setDeliveryDate={setDeliveryDate}
                                    required={true}
                                />
                            </Card>

                            {!newPO && <RequesterName requesterName={purchaseOrder.requesterName} />}

                            {hasVeoliaCustomFields && (
                                <POInfosVeoliaCustomFields purchaseOrderId={purchaseOrder.id} pageMode={pageMode} />
                            )}

                            <PurchaseDocumentCustomFields objectId={purchaseOrder.id} pageMode={pageMode} />

                            {!isShared && organization ? (
                                <>
                                    <Card>
                                        <CurrencySelector
                                            value={purchaseOrder.currency}
                                            label={formatMessage(commonMessages.currency)}
                                            editMode={!purchaseOrder.id || pageMode === PurchaseViewType.EDIT}
                                            onChange={handleCurrencyChange}
                                        />
                                    </Card>

                                    {purchaseOrder.buyerId === organization.id && purchaseOrder.purchaseRequest?.id && (
                                        <PurchaseRequestLink
                                            purchaseRequest={purchaseOrder.purchaseRequest}
                                            label={formatMessage(messages.purchaseRequest)}
                                        />
                                    )}

                                    {purchaseOrder.id && (
                                        <PurchaseOrderDocumentRelations
                                            organizationId={organization.id}
                                            purchaseOrder={purchaseOrder}
                                        />
                                    )}
                                </>
                            ) : null}
                        </Stack>
                    </ResizableCol>

                    <ResizableCol minSize={400}>
                        <Stack gap={1}>
                            <OrganizationsLazy
                                from={from}
                                to={to}
                                updateAddressesOrContact={updateAddressesOrContact}
                                billingAddress={purchaseOrder.billingAddress}
                                shippingAddress={purchaseOrder.shippingAddress}
                                readOnly={isStatusReadonly(purchaseOrder.status)}
                                showOrganizationSelector={!purchaseOrder.supplierId || newPO}
                                objectType={documentsObjectType}
                            />
                            <ErrorMessage>{updateError}</ErrorMessage>
                            <Description
                                description={purchaseOrder.description}
                                pageMode={pageMode}
                                handleChange={handleChangeDescription}
                            />

                            <LinesTabs
                                items={purchaseOrder.lines}
                                idKey="id"
                                temporaryIdKey="temporaryId"
                                contextType={customFieldsObjectType}
                                contextId={purchaseOrder.id}
                                currency={purchaseOrder.currency}
                                organizationId={sharedOrganizationId ?? (organization?.id as OrganizationId)}
                                renderLineTags={renderLineTags}
                            >
                                <ItemsPO
                                    purchaseOrder={purchaseOrder}
                                    organizationId={sharedOrganizationId ?? (organization?.id as OrganizationId)}
                                    mode={pageMode}
                                    viewType={viewType}
                                    isShared={isShared}
                                    approvalRequired={handleApprovalRequiredForLine}
                                    userId={user.id}
                                />
                            </LinesTabs>
                        </Stack>
                    </ResizableCol>

                    <ResizableCol minSize={200} maxSize={400} defaultSize={300}>
                        <Stack gap={1} className="column">
                            {!isShared && (
                                <TransactionInBudgets
                                    transaction={purchaseOrder}
                                    objectType={TransactionType.PURCHASE_ORDER}
                                    organizationId={organization?.id || ""}
                                    handleSuccess={handleAddToBudgetSuccess}
                                    draftBudgets={draftBudgets}
                                    setDraftBudgets={setDraftBudgets}
                                    withGauge
                                />
                            )}
                            {!newPO ? (
                                <>
                                    <Card title={formatMessage(messages.tagsTitle)} expandable>
                                        <TagsSelector
                                            organizationId={organization?.id || ""}
                                            objectId={purchaseOrder.id}
                                            userId={user.id}
                                            objectType={tagsObjectType}
                                            context={tagSelectorContext}
                                        />
                                    </Card>
                                    <Documents
                                        objectId={purchaseOrder.id}
                                        objectType={documentsObjectType}
                                        organizationId={organization?.id || ""}
                                    />
                                </>
                            ) : (
                                <>
                                    <Card title={formatMessage(messages.tagsTitle)} expandable>
                                        <TagsForCreatingPurchaseOrder organizationId={organization?.id || ""} />
                                    </Card>
                                    <Documents
                                        organizationId={organization?.id || ""}
                                        objectType={documentsObjectType}
                                        draftDocuments={draftDocuments}
                                        setDraftDocuments={setDraftDocuments}
                                    />
                                </>
                            )}
                            <CommunicationRoom
                                organizationId={organization?.id || ""}
                                objectId={purchaseOrder.id}
                                objectType={SharedObjectType.PurchaseOrder}
                                otherOrganizations={otherOrganizations}
                            />
                        </Stack>
                    </ResizableCol>
                </ResizableGrid>
            </Stack>
        </div>
    )
}

export const PurchaseOrder = withSocketIOProvider(({ edit }: Props) => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const { purchaseOrderId } = useParams()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)
    const loading = useAppSelector(selectPurchaseOrdersLoading)
    const { s, o: sharedOrganizationId, u: sharedUserId } = useQueryParams()
    const isFromSharedEmail = !!(s && s === "t")

    const purchaseOrderOrganizationId =
        isFromSharedEmail && sharedOrganizationId ? sharedOrganizationId : organization?.id
    const {
        purchaseOrder: PO,
        fetchPurchaseOrder,
        changedOrganization,
    } = usePurchaseOrder(purchaseOrderId, purchaseOrderOrganizationId, organization?.id === purchaseOrderOrganizationId)
    const error = useAppSelector(selectPurchaseOrdersError)

    useEffect(() => {
        if (error) {
            changedOrganization ? toast.error(formatMessage(messages.errorChangedOrganization)) : console.error(error)
            navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }))
        }
    }, [error, changedOrganization])

    useEffect(() => {
        if (isFromSharedEmail && purchaseOrderId && sharedOrganizationId) {
            fetchPurchaseOrder(purchaseOrderId, false).then((data) => {
                if (data) {
                    dispatch(
                        purchaseOrdersActions.setViewType(
                            sharedOrganizationId === data.buyerId ? ViewTypeI.buyer : ViewTypeI.supplier
                        )
                    )
                }
            })
        }
    }, [purchaseOrderId, isFromSharedEmail, sharedOrganizationId])

    useEffect(() => {
        return () => {
            dispatch(purchaseOrdersActions.resetData())
        }
    }, [])

    if (!organization && !sharedOrganizationId) {
        return null
    }

    if (!PO || !PO.id) {
        return <Loader fullscreen={true} />
    }

    return (
        <PurchaseOrderView
            purchaseOrder={PO}
            organization={organization}
            sharedOrganizationId={sharedOrganizationId}
            sharedUserId={sharedUserId}
            edit={edit}
            isFromSharedEmail={isFromSharedEmail}
            loading={loading}
        />
    )
})
