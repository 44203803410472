import { Skeleton } from "@mui/material"
import { useEffect, useState } from "react"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { UserSurveyAnswerFile } from "~/domains/identity/custom-forms/types/CustomForms"
import { useGetDocumentById } from "~/domains/identity/documents/hooks/useGetDocumentById"

export const CustomFormsAnswerFile = ({ answerContent }: { answerContent: UserSurveyAnswerFile }) => {
    const { document, error } = useGetDocumentById(answerContent.documentId)
    const [isDownloading, setIsDownloading] = useState(true)

    useEffect(() => {
        if (document?.id === answerContent.documentId) {
            setIsDownloading(false)
        }
    }, [document, answerContent.documentId])

    const handleClick = async () => {
        if (document && document.latestFileVersion) {
            window.open(document.latestFileVersion, "_blank")
        }
    }

    if (isDownloading) {
        return <Skeleton variant="text" width={200} height={24} />
    }

    if (error) {
        return <SafeFormattedMessage {...commonMessages.error} />
    }

    return (
        <a role="button" className="text-link" onClick={handleClick}>
            {answerContent.filename}
        </a>
    )
}
