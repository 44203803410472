import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import cls from "classnames"
import dayjs, { Dayjs } from "dayjs"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { SwitchLabel } from "~/domains/orchestration/flows/components/SwitchLabel"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { adaptStringToTaskPriority, adaptStringToTaskStatus } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, CreateTaskNode, TaskPriority, TaskStatus } from "~/domains/orchestration/flows/types"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI } from "~/types"

import { ConfigurationNode } from "./ConfigurationNode"

const getOptionKey = (option: OrganizationMemberUserI) => option.userId
const getOptionLabel = (option: OrganizationMemberUserI) => option.user?.fullName ?? ""
const isOptionEqualToValue = (option: OrganizationMemberUserI, value: OrganizationMemberUserI) =>
    option.userId === value.userId

export const CreateTaskConfiguration: FC<ConfigurationProps<CreateTaskNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const { formatMessage, locale } = useIntl()
    const [pickerOpen, setPickerOpen] = useState(false)

    const organization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(organization!)

    const handleChange = (field: keyof CreateTaskNode) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            [field]: event.target.value,
            metadata: {
                ...prev.metadata,
                additionalInformation: field === "title" ? event.target.value : prev.metadata.additionalInformation,
            },
        }))
    }

    const handleSelectAssignee = (_, value: OrganizationMemberUserI | null) => {
        setCurrentNode((prev) => ({
            ...prev,
            assignee: value ? value.userId : "",
        }))
    }

    const handleSelectFollowers = (_, value: OrganizationMemberUserI[] | null) => {
        setCurrentNode((prev) => ({
            ...prev,
            followers: value ? value.map((f) => f.userId) : [],
        }))
    }

    const handleChangeParties = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            parties: event.target.value.split(","),
        }))
    }

    const handlePriorityChange = (event: SelectChangeEvent<TaskPriority>) => {
        setCurrentNode((prev) => ({
            ...prev,
            priority: adaptStringToTaskPriority(event.target.value),
        }))
    }

    const handleStatusChange = (event: SelectChangeEvent<TaskStatus>) => {
        setCurrentNode((prev) => ({
            ...prev,
            status: adaptStringToTaskStatus(event.target.value),
        }))
    }

    const handleDateChange = (date: Dayjs | null) => {
        setCurrentNode((prev) => ({
            ...prev,
            dueDate: date?.toISOString(),
        }))
    }

    const handlePublicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            public: event.target.checked,
        }))
    }

    const handleOpenPicker = () => setPickerOpen(true)
    const handleClosePicker = () => setPickerOpen(false)

    const dueDate = currentNode.dueDate ? dayjs(currentNode.dueDate) : null
    const assignee = members.find((m) => m.userId === currentNode.assignee) || null
    const followers = members.filter((m) => currentNode.followers.includes(m.userId))
    const parties = currentNode.parties.join(", ")

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack spacing={2} className={configurationNodeItemClassName}>
                <TextField
                    label={formatMessage(messages.createTaskConfiguration.title)}
                    value={currentNode.title}
                    onChange={handleChange("title")}
                    fullWidth
                />

                <TextField
                    label={formatMessage(messages.createTaskConfiguration.description)}
                    value={currentNode.description}
                    onChange={handleChange("description")}
                    multiline
                    rows={4}
                    fullWidth
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <DatePicker
                        open={pickerOpen}
                        onOpen={handleOpenPicker}
                        onClose={handleClosePicker}
                        label={<SafeFormattedMessage {...messages.createTaskConfiguration.dueDate} />}
                        value={dueDate}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                                id: "due-date",
                                onClick: handleOpenPicker,
                            },
                        }}
                    />
                </LocalizationProvider>

                <FormControl fullWidth>
                    <InputLabel>{formatMessage(messages.createTaskConfiguration.priority)}</InputLabel>
                    <Select
                        value={currentNode.priority || ""}
                        onChange={handlePriorityChange}
                        label={formatMessage(messages.createTaskConfiguration.priority)}
                    >
                        {Object.values(TaskPriority).map((priority) => (
                            <MenuItem key={priority} value={priority}>
                                <SafeFormattedMessage {...messages.taskPriority[priority]} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel>{formatMessage(messages.createTaskConfiguration.status)}</InputLabel>
                    <Select
                        value={currentNode.status || ""}
                        onChange={handleStatusChange}
                        label={formatMessage(messages.createTaskConfiguration.status)}
                    >
                        {Object.values(TaskStatus).map((status) => (
                            <MenuItem key={status} value={status}>
                                <SafeFormattedMessage {...messages.taskStatus[status]} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Autocomplete
                    autoComplete
                    options={members}
                    value={assignee}
                    getOptionKey={getOptionKey}
                    getOptionLabel={getOptionLabel}
                    filterSelectedOptions
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={handleSelectAssignee}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.createTaskConfiguration.assignee)} />
                    )}
                />

                <Autocomplete
                    autoComplete
                    multiple
                    options={members}
                    value={followers}
                    getOptionKey={getOptionKey}
                    getOptionLabel={getOptionLabel}
                    filterSelectedOptions
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={handleSelectFollowers}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.createTaskConfiguration.followers)} />
                    )}
                />
                <TextField
                    label={formatMessage(messages.createTaskConfiguration.parties)}
                    value={parties}
                    onChange={handleChangeParties}
                    fullWidth
                />

                <TextField
                    label={formatMessage(messages.createTaskConfiguration.parentTaskId)}
                    value={currentNode.parentTaskId}
                    onChange={handleChange("parentTaskId")}
                    fullWidth
                />

                <Stack direction="row" gap={1} alignItems="center">
                    <Switch checked={currentNode.public || false} onChange={handlePublicChange} />
                    <SwitchLabel toggle={currentNode.public ? "true" : "false"}>
                        <SafeFormattedMessage {...messages.createTaskConfiguration.public} />
                    </SwitchLabel>
                </Stack>
            </Stack>

            <AdvancedFields<CreateTaskNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
