import { Edit2 } from "react-feather"

import { SetPaymentMethodDetailsFieldConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    SetPaymentMethodDetailsFieldNode as SetPaymentMethodDetailsFieldNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setPaymentMethodDetailsFieldNode: Omit<SetPaymentMethodDetailsFieldNodeType, keyof Node> = {
    paymentMethodDetailsId: `{{ trigger_event.id }}`,
    fieldToUpdate: ``,
    valueToSet: ``,
    nextNode: null,
    type: NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
}

const advancedFields: (keyof typeof setPaymentMethodDetailsFieldNode)[] = [
    AllAdvancedFieldsUnion.FIELD_TO_UPDATE,
    AllAdvancedFieldsUnion.VALUE_TO_SET,
    AllAdvancedFieldsUnion.PAYMENT_METHOD_DETAILS_ID,
]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE) return false
    return Boolean(node.paymentMethodDetailsId && node.fieldToUpdate && node.valueToSet)
}

const setPaymentMethodDetailsFieldConfig: Configuration<NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE> = {
    type: NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
    baseSlug: "set-payment-method-details-field",
    Icon: Edit2,
    Configuration: SetPaymentMethodDetailsFieldConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): SetPaymentMethodDetailsFieldNodeType => ({
        ...setPaymentMethodDetailsFieldNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { setPaymentMethodDetailsFieldConfig, advancedFields as setPaymentMethodDetailsFieldAdvancedFields }
