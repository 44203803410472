import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"

export enum Features {
    ChatBot,
    InvoiceCustomFields,
    Documents,
    VeoliaCustomFields,
    SecheCustomFields,
    EiffageCustomFields,
    Flows,
    InvoiceV1,
    InvoiceV0,
    Catalog,
    Payment,
    Metrics,
    CustomForms,
    PartnerComplianceRisk,
    CommunicationPage,
    Tasks,
    EvaluationCustomForms,
    AknowledgePurchasePolicyWall,
    SecondaryOfficeProfile,
    OrganisationLegalInfo,
}

/* To enable Sidebar Feature Flag, uncomment the related sections in the index.ts file of the Sidebar component */

// These are the feature flags, they are specified per organization per feature:

// ---------------- Section: Organisation grouppings ----------------
// demo orgs, use these orgs to show new features to customers
export const demoOrgs: Record<OrganizationId, boolean> = {
    "672b824fbdc6ff7a9a787ebb": true, // Stellantis staging
    "672b86d9bdc6ff7a9a787ebf": true, // BNP Paribas staging
    "67287572bdc6ff7a9a787e17": true, // FAERHESM
    "67474ffd6b832228b77b3aa4": true, // Seche staging
}

// Developer's orgs, use these orgs to test new features your selves
export const developerOrgs: Record<OrganizationId, boolean> = {
    "64f89067e53d5f4b94626027": true, // Estelle staging
    "64eca7e3dd74306a85f11cbd": true, // Thomas staging
    "64ec7758dd74306a85f11cb5": true, // Maxence staging
    "64edd35bdd74306a85f11cd3": true, // Vivien staging
    "64f6e968e53d5f4b94626008": true, // Arthur staging
    "66fd638173291f3d4b04aea9": true, // Pierre staging
    "6703e91850789d0f5f3e5b9f": true, // Nicolas staging
    "67079b086972916694322229": true, // Nicolas production
    "6728c967bdc6ff7a9a787e29": true, // Dmitry staging
    "66b384173400753620786933": true, // Georgian staging
    "67374ed2f2b9364eaf6197b9": true, // Veronika staging
}

// Flowie orgs in staging and production
export const flowieOrgs: Record<OrganizationId, boolean> = {
    "64ec6c29dd74306a85f11c19": true, // Flowie staging
    "64872730349426738cb80712": true, // Flowie production
}

// ---------------- Section:  Customer specifc feature flags (custom fields etc.) ----------------
export const griffinOrgs: Record<OrganizationId, boolean> = {
    "66095bf2118c1f5059bf4cfa": true, // Griffin staging
    "65f9d1662be4eb771ea731ca": true, // Griffin production
}

export const veoliaOrgs: Record<OrganizationId, boolean> = {
    "666b17a3ea11870c7b2d34b1": true, // Veolia staging
}

const eiffageStagingOrg: Record<OrganizationId, boolean> = {
    "6673ee7e4d459a2ad69b16dc": true, // Eiffage staging
}
const eiffageProdOrg: Record<OrganizationId, boolean> = {
    "673b1cd159797801c4ea7184": true, // Eiffage production
}
export const eiffageOrgs: Record<OrganizationId, boolean> = {
    ...eiffageStagingOrg,
    ...eiffageProdOrg,
}

export const prerequisProdFeatureFlag: Record<OrganizationId, boolean> = {
    "65fd90412be4eb771ea732e2": true, // Prerequis production
}

export const flowieNetsuiteFeatureFlag: Record<OrganizationId, boolean> = {
    "673dc9956b832228b77b3a4a": true, // Flowie netsuite staging
}

export const smasOrgs: Record<OrganizationId, boolean> = {
    "67065bd350789d0f5f3e5bab": true, // SMAS staging
}

export const integrationTestingOrgs: Record<OrganizationId, boolean> = {
    "671a584a2c738165af24a902": true, // Integration testing
}

export const mytilimerOrgs: Record<OrganizationId, boolean> = {
    "663411dcdcd8d146b46b8fb0": true, // Mytilimer staging
    "674d9b76265e5519701e86bf": true, // Mytilimer production
}

export const promareeOrgs: Record<OrganizationId, boolean> = {
    "6630bbd596430f381bb7e93b": true, // Promaree production
}

export const secheOrgs: Record<OrganizationId, boolean> = {
    "67474ffd6b832228b77b3aa4": true, // Seche staging
    "67765a435eba671fc5a18fe4": true, // Seche production
}

export const ervimunneOrgs: Record<OrganizationId, boolean> = {
    "661fb42de4dda4374713d80c": true, // Ervimunne production
}

export const stellantisOrgs: Record<OrganizationId, boolean> = {
    "670782ec50789d0f5f3e5bb5": true, // Stellantis staging
}

const navisionOrgs: Record<OrganizationId, boolean> = {
    "677fb63b5986e16b181f5cbc": true, // Navision staging
}

const clfOrgs: Record<OrganizationId, boolean> = {
    "67443ddb265e5519701e85b3": true, // CLF production
}

const kingfisherOrgs: Record<OrganizationId, boolean> = {
    "67862f6d5986e16b181f5d10": true, // Kingfisher staging
}

const urcoopaOrgs: Record<OrganizationId, boolean> = {
    "67403e006b832228b77b3a7c": true, // Urcoopa staging
}

const mongoDBOrgs: Record<OrganizationId, boolean> = {
    "66d0be41c64c6a1546dfc91e": true, // MongoDB staging
}

const epsaOrgs: Record<OrganizationId, boolean> = {
    "67977625f4d36163a44d40a1": true, // EPSA staging
}

const pwcAuditOrgs: Record<OrganizationId, boolean> = {
    "67a375cab3fdb677c766fc6d": true, // PWC audit staging
}

// ---------------- Section:  Feature flags ----------------

export const flowsFeatureFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
    ...stellantisOrgs,
    ...kingfisherOrgs,
    ...urcoopaOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
    ...pwcAuditOrgs,
    "67474d746b832228b77b3aa2": true, // Sezane
    "674d9b76265e5519701e86bf": true, // HN service
    "673619df59797801c4ea716e": true, // SWM production
    "677e6a355986e16b181f5bb6": true, // SWM staging
    "6765343d5eba671fc5a18fac": true, // OVGA prod
    "66fd463873291f3d4b04aea7": true, // Flowil staging
}

export const invoiceV1Flag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
    ...navisionOrgs,
    ...prerequisProdFeatureFlag,
    ...flowieNetsuiteFeatureFlag,
    ...smasOrgs,
    ...integrationTestingOrgs,
    ...clfOrgs,
    ...mongoDBOrgs,
    ...ervimunneOrgs,
}

export const catalogV1Flag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
    ...stellantisOrgs,
    ...kingfisherOrgs,
    ...urcoopaOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
    ...pwcAuditOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const paymentFeatureFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
    ...mytilimerOrgs,
    ...promareeOrgs,
    ...ervimunneOrgs,
    ...kingfisherOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const metricsFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...demoOrgs,
    ...eiffageProdOrg,
    ...urcoopaOrgs,
    ...mongoDBOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const documentsFeatureFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...kingfisherOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const chatbotFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...eiffageOrgs,
    ...kingfisherOrgs,
    ...urcoopaOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
    ...pwcAuditOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const customFormsFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...demoOrgs,
    ...stellantisOrgs,
    ...kingfisherOrgs,
    ...urcoopaOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const tasksFlag: Record<OrganizationId, boolean> = {
    ...flowieOrgs,
    ...developerOrgs,
    ...secheOrgs,
    ...kingfisherOrgs,
    ...urcoopaOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const partnerComplianceRiskFlag: Record<OrganizationId, boolean> = {
    ...secheOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const partnerCustomOnboardingFlag: Record<OrganizationId, boolean> = {
    ...secheOrgs,
    "677e6a355986e16b181f5bb6": true, // SWM staging
}

export const evaluationCustomFormsFlag: Record<OrganizationId, boolean> = {
    ...secheOrgs,
    ...flowieOrgs,
    ...developerOrgs,
    ...kingfisherOrgs,
    ...urcoopaOrgs,
    ...mongoDBOrgs,
    ...epsaOrgs,
}

export const organisationLegalInfoFlag: Record<OrganizationId, boolean> = {
    ...developerOrgs,
    ...secheOrgs,
}

export const secondaryOfficeProfileFlag: Record<OrganizationId, boolean> = {
    ...developerOrgs,
}

const aknowledgePurchasePolicyToLoginFlag: Record<OrganizationId, boolean> = {
    ...stellantisOrgs,
}

type FeatureFlagValue = Record<OrganizationId, boolean> | boolean

const featuresFlags: Partial<Record<Features, FeatureFlagValue>> = {
    [Features.InvoiceCustomFields]: griffinOrgs,
    [Features.VeoliaCustomFields]: veoliaOrgs,
    [Features.EiffageCustomFields]: eiffageOrgs,
    [Features.SecheCustomFields]: partnerCustomOnboardingFlag,
    [Features.PartnerComplianceRisk]: partnerComplianceRiskFlag,
    [Features.Flows]: flowsFeatureFlag,
    [Features.ChatBot]: chatbotFlag,
    [Features.Documents]: documentsFeatureFlag,
    [Features.InvoiceV1]: invoiceV1Flag,
    [Features.Catalog]: catalogV1Flag,
    [Features.Payment]: paymentFeatureFlag,
    [Features.Metrics]: metricsFlag,
    [Features.CustomForms]: customFormsFlag,
    [Features.CommunicationPage]: {},
    [Features.Tasks]: tasksFlag,
    [Features.EvaluationCustomForms]: evaluationCustomFormsFlag,
    [Features.AknowledgePurchasePolicyWall]: aknowledgePurchasePolicyToLoginFlag,
    [Features.SecondaryOfficeProfile]: secondaryOfficeProfileFlag,
    [Features.OrganisationLegalInfo]: organisationLegalInfoFlag,
}

const reverseFeatureFlags: Partial<Record<Features, FeatureFlagValue>> = {
    [Features.InvoiceV0]: { ...eiffageOrgs, ...clfOrgs, ...mongoDBOrgs },
}

export const isFeatureEnabled = (feature: Features, organizationId?: OrganizationId): boolean => {
    const value = featuresFlags[feature]

    return value === true || (typeof value === "object" && isDefined(organizationId) && value[organizationId] === true)
}

export const isFeatureDisabled = (feature: Features, organizationId?: OrganizationId): boolean => {
    const reverseValue = reverseFeatureFlags[feature]

    return (
        reverseValue === true ||
        (typeof reverseValue === "object" && isDefined(organizationId) && reverseValue[organizationId] === true)
    )
}
