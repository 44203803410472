import { OrganizationId } from "../domains/identity/organization/types/OrganizationTypes"

export enum SharedObjectType {
    PurchaseRequest = "PurchaseRequest",
    PurchaseOrder = "PurchaseOrder",
    Invoice = "Invoice",
    Partnership = "Partnership",
    Contract = "Contract",
    Task = "Task",
}

export interface SharedObject {
    id: string
    organizationId: OrganizationId
    objectId: string
    objectType: SharedObjectType
    userId: string
    userOrganizationId: OrganizationId
    end?: string
    version?: number
}

export interface SharedObjectWithName extends SharedObject {
    userFullName?: string
    userEmail: string
}
