import { BulkActionButton } from "~/components"
import {
    isStatusApprovable,
    isStatusCurrent,
    isStatusNotCurrent,
    isStatusReceivable,
    isStatusSendable,
} from "~/domains/transactions/purchase-orders/core/lifecyclePurchaseOrder"
import {
    CreatePurchaseOrderDTO,
    CreatePurchaseOrderDTOV2,
    ListPurchaseOrders,
    PurchaseOrderLine,
    PurchaseOrderStatusCreate,
    PurchaseOrders,
    PurchaseOrdersResponseV2,
    PurchaseOrdersTab,
    UpdatePurchaseOrderDTOV2,
} from "~/domains/transactions/purchase-orders/types"
import { CreateLineDTOV2, CreatePOLineDTO } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes, ViewTypeI } from "~/types"
import { SharedObjectType, SharedObjectWithName } from "~/types/SharedObjects"
import { isDefined } from "~/utils/isDefined"

export type POStatusCounts = Partial<Record<PurchaseOrdersTab, number>>
export const countPurchaseOrderByStatus = (purchaseOrders: PurchaseOrders[]) =>
    purchaseOrders.reduce((count, { statusPresentation, fulfillmentPresentation }) => {
        count[fulfillmentPresentation] = (count[fulfillmentPresentation] ?? 0) + 1
        count[statusPresentation] = (count[statusPresentation] ?? 0) + 1

        if (statusPresentation === "OPEN" && !fulfillmentPresentation) {
            count["PENDING"] = (count["PENDING"] ?? 0) + 1
        }

        return count
    }, {})

export const getPOsByViewAndTab = (tab: PurchaseOrdersTab, purchaseOrders: ListPurchaseOrders) => {
    switch (tab) {
        case PurchaseOrdersTab.ALL:
            return purchaseOrders.filter((po) => isStatusNotCurrent(po.statusPresentation, "CLOSED"))
        case PurchaseOrdersTab.OPEN:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "OPEN"))
        case PurchaseOrdersTab.DRAFT:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "DRAFT"))
        case PurchaseOrdersTab.SUBMITTED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "SUBMITTED"))
        case PurchaseOrdersTab.INTERNALLY_APPROVED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "INTERNALLY_APPROVED"))
        case PurchaseOrdersTab.PENDING:
            return purchaseOrders.filter(
                (po) =>
                    isStatusCurrent(po.fulfillmentPresentation, "PENDING") ||
                    (!po.fulfillmentPresentation && isStatusCurrent(po.statusPresentation, "OPEN"))
            )
        case PurchaseOrdersTab.IN_PREPARATION:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "IN_PREPARATION"))
        case PurchaseOrdersTab.SHIPPED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "SHIPPED"))
        case PurchaseOrdersTab.PARTIALLY_RECEIVED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "PARTIALLY_RECEIVED"))
        case PurchaseOrdersTab.RECEIVED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.fulfillmentPresentation, "RECEIVED"))
        case PurchaseOrdersTab.CLOSED:
            return purchaseOrders.filter((po) => isStatusCurrent(po.statusPresentation, "CLOSED"))
        default:
            return purchaseOrders
    }
}

export const filterResults = (pos: ListPurchaseOrders, searchTerm: string, side: ViewTypeI): ListPurchaseOrders => {
    return pos.filter((po) =>
        side === ViewTypeI.buyer
            ? po.buyerName?.toLowerCase().includes(searchTerm)
            : po.supplierName?.toLowerCase().includes(searchTerm)
    )
}

export const allLinesApproved = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] === "APPROVED"
    )
}

export const allLinesRejected = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] === "REJECTED"
    )
}

export const getPOBulkActionButtons = (
    currentTab: PurchaseOrdersTab,
    view: ViewTypeI | string,
    handleValidate: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleSend: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleReceived: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleAddTags: (currentTab: PurchaseOrdersTab) => BulkActionButton
): BulkActionButton[] => {
    const isSupplierView = view === ViewTypeI.supplier
    switch (currentTab) {
        case PurchaseOrdersTab.ALL:
            return [
                handleAddTags(currentTab),
                handleValidate(currentTab),
                handleSend(currentTab),
                handleReceived(currentTab),
            ]
        case PurchaseOrdersTab.SUBMITTED:
            return [handleAddTags(currentTab), handleValidate(currentTab), handleSend(currentTab)]
        case PurchaseOrdersTab.IN_PREPARATION:
            return isSupplierView
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        case PurchaseOrdersTab.SHIPPED:
            return isSupplierView
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        case PurchaseOrdersTab.RECEIVED:
            return isSupplierView
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        default:
            return [handleAddTags(currentTab)]
    }
}

export const getApprovable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI
): ListPurchaseOrders => {
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter(
            (po) =>
                (isStatusApprovable(po.statusPresentation, view) ||
                    isStatusApprovable(po.fulfillmentPresentation, view)) &&
                !allLinesApproved(po.lines, view)
        )
}

export const getSendable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI,
    organizationSharings: SharedObjectWithName[]
): string[] => {
    const sharedPOs = organizationSharings.filter(
        (share) => purchaseOrderIds.includes(share.objectId) && share.objectType === SharedObjectType.PurchaseOrder
    )
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter(
            (po) =>
                sharedPOs.find((share) => share.objectId === po.id) &&
                isStatusSendable(po.statusPresentation, view) &&
                allLinesApproved(po.lines, view)
        )
        .map((po) => po.id)
}

export const getReceivable = (purchaseOrderIds: string[], purchaseOrders: ListPurchaseOrders): ListPurchaseOrders => {
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter((po) => isStatusReceivable(po.fulfillmentPresentation))
}

export const getPurchaseOrderId = (
    r: PromiseSettledResult<unknown>,
    index: number,
    purchaseOrderIds: string[]
): string | undefined => (r.status === "rejected" ? undefined : purchaseOrderIds[index])

export const showBulkActionButtonCount: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.ALL]: true,
    [PurchaseOrdersTab.SUBMITTED]: true,
}

export const convertLineToCreatePOLineDTO = (line: PurchaseOrderLine): CreatePOLineDTO => {
    return {
        description: line.description,
        quantity: line.quantity,
        units: line.units ?? "",
        unitPrice: line.unitPrice,
        unitPriceExcludingTax: line.unitPriceExcludingTax,
        taxRate: line.taxRate,
        totalAmount: line.totalAmount,
        totalAmountExcludingTax: line.totalAmountExcludingTax,
        ...(line.supplierItemId ? { supplierItemId: line.supplierItemId } : { buyerItemId: line.buyerItemId }),
        totalTax: line.totalTax,
    }
}

export const convertPOtoCreatePODTO = (
    PO: PurchaseOrders,
    status: PurchaseOrderStatusCreate = "DRAFT"
): CreatePurchaseOrderDTO => {
    return {
        supplierId: PO.supplierId,
        buyerId: PO.buyerId,
        status,
        expectedDeliveryDate: PO.expectedDeliveryDate,
        requesterUserId: PO.requesterUserId ?? undefined,
        billingAddress: PO.billingAddress ?? undefined,
        shippingAddress: PO.shippingAddress ?? undefined,
        description: PO.description,
        currency: PO.currency,
        lines: PO.lines.map((line) => {
            return convertLineToCreatePOLineDTO(line)
        }),
    }
}

export const convertPOV2toPO = (purchaseOrder: PurchaseOrdersResponseV2): PurchaseOrders => {
    const {
        buyerId,
        creationDate,
        expectedDeliveryDate,
        id,
        requesterUserId,
        shippingAddress,
        shortId,
        status,
        statusPresentation,
        fulfillmentPresentation,
        supplierId,
        purchaseRequest,
        description,
        totals: purchaseOrderTotals,
        lines: purchaseOrderLines,
    } = purchaseOrder

    const currencyKey = Object.keys(purchaseOrderTotals)[0]

    const lines = purchaseOrderLines.map((line) => {
        const {
            id: lineId,
            description: lineDescription,
            quantity,
            originalQuantity,
            units,
            taxRate,
            totals: lineTotals,
            unitPrice,
            unitPriceExcludingTax,
            deliveryNote = "",
            temporaryId = "",
            hasChanged = false,
            buyerFulfillment,
            supplierFulfillment,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
        } = line

        return {
            id: lineId,
            description: lineDescription,
            quantity,
            originalQuantity: originalQuantity ?? quantity,
            taxRate,
            units: units ?? "",
            totalAmount: lineTotals ? +lineTotals.amount.amount : 0,
            totalAmountExcludingTax: lineTotals ? +lineTotals.amountExcludingTax.amount : 0,
            totalTax: lineTotals ? +lineTotals.tax.amount : 0,
            unitPrice: unitPrice ? +unitPrice.amount : 0,
            unitPriceExcludingTax: unitPriceExcludingTax ? +unitPriceExcludingTax.amount : 0,
            deliveryNote,
            temporaryId,
            hasChanged,
            buyerFulfillment,
            supplierFulfillment,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
        }
    })

    return {
        buyerId,
        creationDate,
        currency: currencyKey as CurrencyCodes,
        expectedDeliveryDate,
        id,
        requesterUserId,
        shippingAddress,
        shortId,
        status,
        statusPresentation,
        fulfillmentPresentation,
        supplierId,
        totalAmount: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amount?.amount : 0,
        totalAmountExcludingTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amountExcludingTax?.amount : 0,
        totalTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].tax?.amount : 0,
        purchaseRequest,
        description,
        lines,
        supplierName: "",
        buyerName: "",
    }
}

const convertLines = (lines: CreatePOLineDTO[] | PurchaseOrderLine[], currency: CurrencyCodes): CreateLineDTOV2[] => {
    return lines.map((line: CreatePOLineDTO | PurchaseOrderLine) => {
        const {
            description,
            quantity,
            units,
            taxRate,
            unitPrice,
            unitPriceExcludingTax,
            id,
            buyerItemId,
            supplierItemId,
        } = line
        return {
            description,
            quantity,
            units: units ?? "",
            taxRate: taxRate.toFixed(8),
            unitPrice: { amount: unitPrice.toFixed(8), currency },
            unitPriceExcludingTax: { amount: unitPriceExcludingTax.toString(), currency },
            ...(id ? { id } : {}),
            ...(buyerItemId ? { buyerItemId } : {}),
            ...(supplierItemId ? { supplierItemId } : {}),
        }
    })
}

export const convertPOtoPOV2 = (purchaseOrder: CreatePurchaseOrderDTO): CreatePurchaseOrderDTOV2 => {
    const {
        status,
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        supplierId,
        buyerId,
        lines,
        currency,
    } = purchaseOrder

    return {
        status,
        billingAddress,
        description,
        shippingAddress,
        supplierId,
        buyerId,
        lines: convertLines(lines, currency),
        ...(expectedDeliveryDate ? { expectedDeliveryDate } : {}),
    }
}

export const convertPOtoPOV2ForUpdate = (purchaseOrder: PurchaseOrders): UpdatePurchaseOrderDTOV2 => {
    const { billingAddress, description, expectedDeliveryDate, shippingAddress, lines, currency } = purchaseOrder

    return {
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        lines: convertLines(lines, currency),
    }
}
