import { Chip, IconButton, Stack, TextField } from "@mui/material"
import React, { ChangeEvent, FC, useRef, useState } from "react"
import { PlusCircle, User, X } from "react-feather"
import { useIntl } from "react-intl"

import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/locale"
import { EmailMailType } from "~/domains/orchestration/flows/types"
import { getEmailMailType } from "~/domains/orchestration/flows/utils"

interface SharedEmailConfigurationProps {
    onChangeEmails: (emails: string[]) => void
    emails: string[]
    label?: string
}

const getEmailChipColor = (email: string): "error" | "default" | "warning" => {
    const emailMailType = getEmailMailType(email)
    switch (emailMailType) {
        case EmailMailType.ERROR:
            return "error"
        case EmailMailType.EXPRESSION:
            return "warning"
        default:
            return "default"
    }
}

const adaptEmail = (email: string) => {
    const trimmedEmail = email.trim()
    if (!trimmedEmail) return null

    const emailMailType = getEmailMailType(trimmedEmail)
    // If the email is an expression, we don't need to adapt it
    return emailMailType === EmailMailType.EXPRESSION ? trimmedEmail : adaptTextToExpression(trimmedEmail)
}

export const SharedEmailConfiguration: FC<SharedEmailConfigurationProps> = ({ onChangeEmails, emails, label }) => {
    const { formatMessage } = useIntl()
    const [emailInput, setEmailInput] = useState("")
    const [editingIndex, setEditingIndex] = useState<number | null>(null)
    const emailErrorRef = useRef<string | null>(null)

    const updateEmails = () => {
        const adaptedEmail = adaptEmail(emailInput)
        if (!adaptedEmail) return

        if (emails.includes(adaptedEmail)) return

        const result =
            editingIndex === null
                ? [...emails, adaptedEmail]
                : (emails
                      .map((email, i) => (i === editingIndex ? adaptedEmail : email))
                      .filter((email) => email !== null) as string[])

        setEmailInput("")
        setEditingIndex(null)
        onChangeEmails(result)
    }

    const handleEmailInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault()
            updateEmails()
        }
    }

    const handleEditEmail = (index: number) => () => {
        const adaptedEmail = adaptExpressionToText(emails[index])

        setEditingIndex(index)
        setEmailInput(adaptedEmail)
    }

    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailInput(e.target.value)
        emailErrorRef.current = null
    }

    const handleDeleteEmail = (index: number) => () => {
        setEditingIndex(null)
        setEmailInput("")
        onChangeEmails(emails.filter((_, i) => i !== index))
    }

    return (
        <Stack gap={1}>
            <Stack direction="row" gap={1} alignItems="center">
                <TextField
                    label={label || formatMessage(messages.sendEmailConfiguration.toLabel)}
                    value={emailInput}
                    size="small"
                    type="email"
                    onChange={handleEmailInputChange}
                    onKeyDown={handleEmailInputKeyDown}
                    onBlur={updateEmails}
                    error={!!emailErrorRef.current}
                    fullWidth
                />
                <IconButton onClick={updateEmails} size="small">
                    <PlusCircle size={16} />
                </IconButton>
            </Stack>
            <Stack direction="row" gap={0.5} flexWrap="wrap">
                {emails.map((email, index) => (
                    <Chip
                        key={index}
                        label={adaptExpressionToText(email)}
                        onDelete={handleDeleteEmail(index)}
                        onClick={handleEditEmail(index)}
                        color={getEmailChipColor(email)}
                        deleteIcon={<X size={16} />}
                        icon={<User size={16} />}
                    />
                ))}
            </Stack>
        </Stack>
    )
}
