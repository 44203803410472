import { Stack, TextField } from "@mui/material"
import React, { ChangeEvent, ReactNode } from "react"
import { defineMessages, useIntl } from "react-intl"

import { OrganizationAddressI } from "~/types"

const messages = defineMessages({
    title: {
        id: "company.address.title",
        defaultMessage: "Address",
    },
    line1: {
        id: "company.address.line1",
        defaultMessage: "Line 1",
    },
    line2: {
        id: "company.address.line2",
        defaultMessage: "Line 2",
    },
    postalCode: {
        id: "company.address.postalCode",
        defaultMessage: "Postal Code",
    },
    city: {
        id: "company.address.city",
        defaultMessage: "City",
    },
    country: {
        id: "company.address.country",
        defaultMessage: "Country",
    },
})

interface Props {
    label: ReactNode
    address: OrganizationAddressI
    onChange?: (payload: Partial<OrganizationAddressI>) => void
    readOnly?: boolean
}

export const AddressForm: React.FC<Props> = ({ label, address, onChange, readOnly }) => {
    const { formatMessage } = useIntl()
    const onAddressLineChange = (e: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange({ addressLine: e.currentTarget.value })

    const onSecondaryAddressLineChange = (e: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange({ secondaryAddressLine: e.currentTarget.value })

    const onZipCodeChange = (e: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange({ zipCode: e.currentTarget.value })

    const onCityChange = (e: ChangeEvent<HTMLInputElement>) => onChange && onChange({ city: e.currentTarget.value })

    const onCountryChange = (e: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange({ country: e.currentTarget.value })

    return (
        <Stack gap={2} width="100%">
            <h4>{label}</h4>

            <TextField
                required={!!onChange}
                id="companyDetails.address.line1"
                name="companyDetails.address.line1"
                label={formatMessage(messages.line1)}
                placeholder={formatMessage(messages.line1)}
                onChange={onAddressLineChange}
                value={address.addressLine}
                fullWidth
                disabled={readOnly}
            />

            <TextField
                id="companyDetails.address.line2"
                name="companyDetails.address.line2"
                label={formatMessage(messages.line2)}
                placeholder={formatMessage(messages.line2)}
                onChange={onSecondaryAddressLineChange}
                value={address.secondaryAddressLine ?? ""}
                fullWidth
                disabled={readOnly}
            />

            <Stack direction="row" gap={2}>
                <TextField
                    required={!!onChange}
                    id="companyDetails.address.postalCode"
                    name="companyDetails.address.postalCode"
                    label={formatMessage(messages.postalCode)}
                    placeholder={formatMessage(messages.postalCode)}
                    onChange={onZipCodeChange}
                    value={address.zipCode}
                    fullWidth
                    disabled={readOnly}
                />

                <TextField
                    required={!!onChange}
                    id="companyDetails.address.city"
                    name="companyDetails.address.city"
                    label={formatMessage(messages.city)}
                    placeholder={formatMessage(messages.city)}
                    onChange={onCityChange}
                    value={address.city}
                    fullWidth
                    disabled={readOnly}
                />
            </Stack>

            <TextField
                required={!!onChange}
                name="companyDetails.address.country"
                label={formatMessage(messages.country)}
                placeholder={formatMessage(messages.country)}
                onChange={onCountryChange}
                value={address.country}
                fullWidth
                disabled={readOnly}
            />
        </Stack>
    )
}
