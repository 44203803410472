export type CustomForm = {
    id: string
    name: string
    createdAt: string
    sections: CustomFormSection[]
}

export interface CustomFormSection {
    id: string
    title: string
    order: number
    questions: CustomFormQuestion[]
}

export interface SendFormResponse {
    id: string
    formId: string
    token: string
    email: string
    userId: string
    dueTimestamp: string
}

export interface CustomFormQuestion {
    id: string
    order: number
    questionContent: string
    required: boolean
    fieldType: QuestionFieldType
    savedValue: QuestionValue
    invalid?: boolean
}

export interface SurveyLinkedObject {
    objectId: string
    objectType: SurveyLinkedObjectType
}

export interface CustomFormSection {
    id: string
    title: string
    order: number
    questions: CustomFormQuestion[]
}

// These are used by form viewer:
export interface UserSurveyForm {
    id: string
    name: string
    createdAt: string
    sections: CustomFormSection[]
}

export type QuestionValue = string | number | string[] | Date | boolean | File | UserSurveyAnswerFile

export type QuestionFieldType =
    | { YesNo: string }
    | { TextField: string }
    | { TextArea: string }
    | { NumericField: { minValue: number; maxValue: number; unit: string } }
    | { SingleChoice: { choices: string[] } }
    | { MultiChoice: { choices: string[] } }
    | { DateField: { minDate: string; maxDate: string } }
    | { FileField: object }

export enum QuestionFieldTypeEnum {
    YesNo = "YesNo",
    TextField = "TextField",
    TextArea = "TextArea",
    NumericField = "NumericField",
    SingleChoice = "SingleChoice",
    MultiChoice = "MultiChoice",
    DateField = "DateField",
    FileField = "FileField",
}

export enum SurveyLinkedObjectType {
    PARTNERSHIP = "PARTNERSHIP",
    PURCHASE_ORDER = "PURCHASE_ORDER",
}

export interface CustomFormResponder {
    userId: string
    email: string
}

export interface FormViewerContextType {
    formData: UserSurveyForm | null
    currentSection: number
    setCurrentSection: React.Dispatch<React.SetStateAction<number>>
    formSent: boolean
    viewMode?: string
    currentSectionInvalid: boolean
    validateSection: () => boolean
    handleSaveAnswer: (question: CustomFormQuestion, value: QuestionValue) => void
    focusQuestionId: string | null
    hiddenQuestions: string[]
    surveyStateLoading: boolean
    sendForm: () => void
    uploadFileAnswerLoading: boolean
}

// Surveys
export interface UserSurvey {
    id: string
    createdAt: string
    sections: CustomFormSection[]
    name: string
    answers: UserSurveyAnswer[]
    status: CustomFormSurveyStatus
    linkedObjects?: SurveyLinkedObject[]
}

export interface CustomFormSurvey {
    id: string
    formId: string
    token: string
    respondentContacts: CustomFormResponder[]
    dueTimestamp: string
    createdAt: string
    respondentOrganizationId: string
    status: CustomFormSurveyStatus
    updatedAt: string
}

export enum CustomFormSurveyStatusEnum {
    InProgress = "InProgress",
    Completed = "Completed",
    Created = "Created",
}

export interface CustomFormSurveyStatus {
    InProgress?: {
        percentage: number
    }
    // WIP: these are empty objects for now
    Completed?: object
    Created?: object
    status: CustomFormSurveyStatusEnum
    percentage: number
}

export interface UserSurveyMetric {
    value: number
    maxAvailable: number
}

export interface UserSurveyState {
    status: CustomFormSurveyStatus
    questions: UserSurveyQuestionState[]
}

export interface UserSurveyQuestionState {
    questionId: string
    visible: boolean
    hiddenReason?: string
}

export interface UserSurveyStats extends CustomFormSurvey {
    id: string
    alertScore: number
    metrics: UserSurveyStatsMetrics
}

export interface UserSurveyStatsMetrics {
    sections: UserSurveySection[]
    total: UserSurveyMetric
}

export interface UserSurveySection {
    sectionId: string
    name: string
    metric: UserSurveyMetric
}

// Answers
export enum AnswerFieldType {
    TextFieldAnswer = "TextFieldAnswer",
    TextAreaAnswer = "TextAreaAnswer",
    NumericFieldAnswer = "NumericFieldAnswer",
    SingleChoiceAnswer = "SingleChoiceAnswer",
    MultiChoiceAnswer = "MultiChoiceAnswer",
    YesNoAnswer = "YesNoAnswer",
    FileFieldAnswer = "FileFieldAnswer",
    DateFieldAnswer = "DateFieldAnswer",
}

export interface UserSurveyAnswerField {
    TextFieldAnswer?: {
        value: string
    }
    TextAreaAnswer?: {
        value: string
    }
    NumericFieldAnswer?: {
        value: number
    }
    MultiChoiceAnswer?: {
        value: string[]
    }
    SingleChoiceAnswer?: {
        value: string
    }
    YesNoAnswer?: {
        value: boolean
    }
    FileFieldAnswer?: {
        contentType: string
        documentId: string
        filename: string
        size: number
    }
    DateFieldAnswer?: {
        value: string
    }
    fieldType: AnswerFieldType
    value: QuestionValue
}

export interface UserSurveyAnswer {
    questionId: string
    answer: UserSurveyAnswerField
    fieldType: AnswerFieldType
    value: QuestionValue
    visible: boolean
    sectionId?: string
    answerContent?: string
}

export interface UserSurveyAnswerFile {
    filename: string
    contentType: string
    documentId: string
    size: number
}

// API responses
export interface UserSurveyApiResponse {
    id: string
    form: UserSurveyForm
    answers: UserSurveyAnswer[]
    status: CustomFormSurveyStatus
    linkedObjects?: SurveyLinkedObject[]
}

export interface UserSurveyMetadata {
    id: string
    formId: string
    token: string
    respondentOrganizationId: string
    respondentContacts: CustomFormResponder[]
    linkedObjects?: SurveyLinkedObject[]
    status: CustomFormSurveyStatus
    createdAt: string
    updatedAt: string
}
