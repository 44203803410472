import { Stack } from "@mui/material"
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Button, Loader, Modal, SafeFormattedMessage } from "~/components"
import {
    DisplayContext,
    customFieldsListByOrganizationId,
    secheRequiredContactFields,
} from "~/domains/identity/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import {
    useHasRequiredSecheCustomFields,
    usePendingCustomFieldsByFieldNames,
} from "~/domains/identity/custom-fields/hooks"
import { selectPendingCustomFieldObjects } from "~/domains/identity/custom-fields/store"
import { CompanyResult } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { useGetPartnersQuery } from "~/domains/identity/partners/api/partnerApiV1"
import { PartnerOrganizationManualImport } from "~/domains/identity/partners/components/ModalCreatePartnership/PartnerOrganizationManualImport"
import { PartnerOrganizationSelector } from "~/domains/identity/partners/components/ModalCreatePartnership/PartnerOrganizationSelector"
import { PartnershipTypeSelector } from "~/domains/identity/partners/components/ModalCreatePartnership/PartnershipTypeSelector"
import {
    createEmptyPartner,
    createEmptyPartnershipPayload,
    createEmptyPartnershipPayloadWithContact,
} from "~/domains/identity/partners/components/ModalCreatePartnership/helpers"
import { ModalContact } from "~/domains/identity/partners/components/PartnerProfile/components"
import { PartnerProfileSecheCustomFields } from "~/domains/identity/partners/components/PartnerProfile/components/PartnerProfileSecheCustomFields"
import { getCustomRules } from "~/domains/identity/partners/core/partnerCustomRulesByOrgId"
import { usePartnersPermissions } from "~/domains/identity/partners/hooks/usePartnersPermissions"
import { PARTNER_DETAILS_ROUTE } from "~/domains/identity/partners/routes"
import { useCreatePartnership } from "~/domains/identity/partners/store/hooks"
import { useCreateOrganizationWithoutRegistration } from "~/domains/identity/partners/store/hooks/useCreateOrganizationWithoutRegistration"
import {
    CreatePartnerProfileContactI,
    CreatePartnershipPayload,
    CreatePartnershipPayloadWithContact,
    PartnerProfileContactI,
    PartnershipTypeOption,
} from "~/domains/identity/partners/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { CountryCode, ImportInvoiceCompanyInfoI, OrganizationId } from "~/types"

import { PartnershipStatusSwitches } from "./PartnershipStatusSwitches"

const messages = defineMessages({
    modalTitle: {
        id: "partners.bookofrelations.modal.create.title",
        defaultMessage: "Add a new partner",
    },
    modalCancel: {
        id: "partners.bookofrelations.modal.create.cancel",
        defaultMessage: "Cancel",
    },
    backButton: {
        id: "partners.bookofrelations.modal.create.back",
        defaultMessage: "Back",
    },
    modalCreateAnother: {
        id: "partners.bookofrelations.modal.create.another",
        defaultMessage: "Create & add another",
    },
    modalConfirm: {
        id: "partners.bookofrelations.modal.create.confirm",
        defaultMessage: "Create",
    },
    organization: {
        id: "partners.bookofrelations.modal.create.organization",
        defaultMessage: "Organization",
    },
    searchPartner: {
        id: "partners.bookofrelations.modal.create.searchPartner",
        defaultMessage: "Search for the partner's company",
    },
    createSuccess: {
        id: "partners.bookofrelations.modal.create.success",
        defaultMessage: "The new partner has been added to your Partners network",
    },
    createPartnershipError: {
        id: "partners.bookofrelations.modal.create.partnershipError",
        defaultMessage: "We encountered an error creating the partnership. Please try again.",
    },
    createOrganizationError: {
        id: "partners.bookofrelations.modal.create.organizationError",
        defaultMessage: "We encountered an error creating the organization. Please try again.",
    },
    addressLabel: {
        id: "partners.bookofrelations.modal.create.addressLabel",
        defaultMessage: "Address",
    },
    contactLabel: {
        id: "customrules.company.seche.modal.create.primaryContactLabel",
        defaultMessage: "Primary contact",
    },
    modalNext: {
        id: "customrules.company.seche.modal.create.next",
        defaultMessage: "Next",
    },
    errorContactCreation: {
        id: "partners.bookofrelations.modal.create.errorContactCreation",
        defaultMessage: "There has been an error creating this partnership, preventing the creation of the contact.",
    },
    firstName: {
        id: "partners.bookofrelations.modal.create.firstName",
        defaultMessage: "First name",
    },
    lastName: {
        id: "partners.bookofrelations.modal.create.lastName",
        defaultMessage: "Last name",
    },
    email: {
        id: "partners.bookofrelations.modal.create.email",
        defaultMessage: "Email",
    },
})

export const partnershipTypeMessages: Record<PartnershipTypeOption, MessageDescriptor> = defineMessages({
    [PartnershipTypeOption.BUYER]: {
        id: "partners.bookofrelations.createPartnershipType.buyer",
        defaultMessage: "client",
    },
    [PartnershipTypeOption.SUPPLIER]: {
        id: "partners.bookofrelations.createPartnershipType.supplier",
        defaultMessage: "vendor",
    },
    [PartnershipTypeOption.BOTH]: {
        id: "partners.bookofrelations.createPartnershipType.both",
        defaultMessage: "both Vendor & Client",
    },
})

interface CreatePartnershipModalProps {
    organizationId: OrganizationId
    open: boolean
    close: () => void
    displayContactModal?: () => void
}

export const ModalCreatePartnership: React.FC<CreatePartnershipModalProps> = ({ organizationId, open, close }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [companyResult, setCompanyResult] = useState<CompanyResult | undefined>()
    const [partnershipPayload, setPartnershipPayload] = useState<CreatePartnershipPayload>(
        createEmptyPartnershipPayload()
    )

    const setPartnershipPayloadWithContactRef = useRef<CreatePartnershipPayloadWithContact>(
        createEmptyPartnershipPayloadWithContact()
    )
    const [displayManualImport, setDisplayManualImport] = useState(false)
    const [displayContactCreation, setDisplayContactCreation] = useState(false)

    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const currentOrganizationCountryCode = currentOrganization?.registration?.countryCode ?? CountryCode.FR
    const [partnerOrganization, setPartnerOrganization] = useState(createEmptyPartner(currentOrganizationCountryCode))

    const { createPartnerships, createPartnershipWithContact } = useCreatePartnership(organizationId)
    const { refetch: refetchPartners } = useGetPartnersQuery(
        { organizationId, withMetrics: true },
        { skip: !organizationId }
    )
    const { createNewOrganization } = useCreateOrganizationWithoutRegistration()

    const onManualImportClick = () => setDisplayManualImport(true)
    const cancelManualImport = () => setDisplayManualImport(false)

    const partnersPermissions = usePartnersPermissions(organizationId)

    // Custom rules & custom fields
    const customRules = getCustomRules(organizationId)
    const customFieldsList = customFieldsListByOrganizationId[organizationId ?? ""]
    const pendingCustomFieldObject = useAppSelector(selectPendingCustomFieldObjects)
    const pendingCustomFields = usePendingCustomFieldsByFieldNames(customFieldsList, pendingCustomFieldObject)
    const hasRequiredCustomFields = useHasRequiredSecheCustomFields(pendingCustomFields)

    // Set the DEFAULT partner organization country code to the current organization country code
    // This will default the company selector flag to the current organization country code
    useEffect(() => {
        setPartnerOrganization(createEmptyPartner(currentOrganizationCountryCode))
    }, [currentOrganizationCountryCode])

    // Organization & Partnership related functions
    const updatePartnerOrganizationData = useCallback(
        (data: Partial<ImportInvoiceCompanyInfoI>) => {
            setPartnerOrganization((currentState) =>
                data ? { ...currentState, ...data } : createEmptyPartner(currentState.countryCode)
            )
        },
        [setPartnerOrganization]
    )

    const updatePartnershipPayload = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name, type, checked } = e.target
        setPartnershipPayload((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }))
    }

    const handleCreatePartnership = async (partnerOrgId: OrganizationId | undefined) => {
        if (!partnerOrgId) {
            toast.error(formatMessage(messages.createOrganizationError))
            return
        }

        if (customRules.hasContactCreation) {
            if (currentOrganization) {
                await createPartnershipWithContact(
                    setPartnershipPayloadWithContactRef.current,
                    currentOrganization.id,
                    partnerOrgId
                )
            } else {
                console.error("currentOrganization is undefined")
                toast.error(formatMessage(messages.createOrganizationError))
            }
        } else {
            await createPartnerships({ ...partnershipPayload, partnerId: partnerOrgId })
        }

        return partnerOrgId
    }

    const handleNextSteps = (createdPartnerId: OrganizationId | undefined, createAnother = false) => {
        if (customRules.hasContactCreation && createdPartnerId) {
            handleClose()
            toast.success(formatMessage(messages.createSuccess))
            refetchPartners()
            navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: createdPartnerId }))
        } else if (!createAnother && createdPartnerId) {
            toast.success(formatMessage(messages.createSuccess))
            handleClose()
            partnersPermissions.create &&
                navigate(generatePath(PARTNER_DETAILS_ROUTE, { organizationId: createdPartnerId }))
        } else {
            toast.success(formatMessage(messages.createSuccess))
            clearComponent()
            refetchPartners()
        }
    }

    const handleOrganizationAndPartnership = useCallback(
        async (createAnother = false) => {
            setLoading(true)
            const createdPartnerId = await handleCreatePartnership(
                partnerOrganization.organizationId || (await createNewOrganization(partnerOrganization))?.id
            )
            handleNextSteps(createdPartnerId, createAnother)
            setLoading(false)
        },
        [partnerOrganization, partnershipPayload, createPartnerships, handleNextSteps, createNewOrganization]
    )

    const onSubmit = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            handleOrganizationAndPartnership()
        },
        [handleOrganizationAndPartnership]
    )

    const clearComponent = () => {
        setPartnerOrganization(createEmptyPartner(currentOrganizationCountryCode))
        setPartnershipPayload(createEmptyPartnershipPayload())
        setDisplayContactCreation(false)
        setCompanyResult(undefined)
    }

    const handleClose = () => {
        clearComponent()
        close()
    }

    const handleCreateAnother = () => {
        handleOrganizationAndPartnership(true)
    }

    const saveDisabled =
        !partnerOrganization.registrationNumber || (customRules.hasCustomFields && !hasRequiredCustomFields)

    const showContactModal = () => setDisplayContactCreation(true)

    const handleSavePartnershipWithContact = (contact: CreatePartnerProfileContactI | PartnerProfileContactI) => {
        // state variable doesn't work in the async function so using refs:
        setPartnershipPayloadWithContactRef.current = {
            partnership: partnershipPayload,
            contact: {
                // TODO: perform normal casting
                firstName: contact.firstName || "",
                lastName: contact.lastName || "",
                email: contact.email || "",
                phoneNumber: contact.phoneNumber || "",
                position: contact.position || "",
                language: contact.language || "",
                timezone: contact.timezone || "",
            },
        }
        handleOrganizationAndPartnership()
        setDisplayContactCreation(false)
    }

    return (
        <>
            {customRules.hasContactCreation && displayContactCreation ? (
                <ModalContact
                    save={handleSavePartnershipWithContact}
                    open={displayContactCreation}
                    close={handleClose}
                    requiredFields={secheRequiredContactFields}
                    label={formatMessage(messages.contactLabel)}
                />
            ) : (
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-request">
                    <Modal.Header>
                        <h4>
                            <SafeFormattedMessage {...messages.modalTitle} />
                        </h4>
                    </Modal.Header>
                    {!displayManualImport ? (
                        <>
                            {loading ? (
                                <Loader />
                            ) : (
                                <>
                                    <Modal.Content>
                                        <Stack gap={2}>
                                            {customRules.hasOnlySupplierCreation ? null : (
                                                <PartnershipTypeSelector
                                                    value={partnershipPayload?.partnershipType}
                                                    onChange={updatePartnershipPayload}
                                                />
                                            )}
                                            <PartnerOrganizationSelector
                                                partnerOrganization={partnerOrganization}
                                                onManualImportClick={onManualImportClick}
                                                updateData={updatePartnerOrganizationData}
                                                companyResult={companyResult}
                                                setCompanyResult={setCompanyResult}
                                            />
                                            {customRules.hasCustomFields && (
                                                <>
                                                    <hr />
                                                    <PartnerProfileSecheCustomFields
                                                        partnershipId={partnerOrganization?.organizationId ?? ""}
                                                        currentOrganizationId={organizationId}
                                                        readOnly={false}
                                                        customFieldsList={customFieldsList}
                                                        displayContext={DisplayContext.PARTNER_MODAL}
                                                    />
                                                </>
                                            )}
                                            {customRules.hasPartnershipAttributes && (
                                                <PartnershipStatusSwitches
                                                    partnershipPayload={partnershipPayload}
                                                    updatePartnershipPayload={updatePartnershipPayload}
                                                />
                                            )}
                                        </Stack>
                                    </Modal.Content>
                                    <Modal.Footer>
                                        {customRules.hasContactCreation ? (
                                            <>
                                                <Button onClick={handleClose} type="transparent">
                                                    <SafeFormattedMessage {...messages.modalCancel} />
                                                </Button>
                                                <Button onClick={showContactModal} disabled={saveDisabled}>
                                                    <SafeFormattedMessage {...messages.modalNext} />
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button onClick={handleClose} type="transparent">
                                                    <SafeFormattedMessage {...messages.modalCancel} />
                                                </Button>
                                                <Button
                                                    onClick={handleCreateAnother}
                                                    type="primary-light"
                                                    disabled={saveDisabled}
                                                >
                                                    <SafeFormattedMessage {...messages.modalCreateAnother} />
                                                </Button>
                                                <Button onClick={onSubmit} disabled={saveDisabled}>
                                                    <SafeFormattedMessage {...messages.modalConfirm} />
                                                </Button>
                                            </>
                                        )}
                                    </Modal.Footer>
                                </>
                            )}
                        </>
                    ) : (
                        <PartnerOrganizationManualImport
                            updateData={updatePartnerOrganizationData}
                            cancelManualImport={cancelManualImport}
                            companyResult={companyResult}
                            setCompanyResult={setCompanyResult}
                        />
                    )}
                </Modal>
            )}
        </>
    )
}
