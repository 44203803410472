import { Box, Stack } from "@mui/material"
import * as Sentry from "@sentry/react"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Loader, Modal, Size } from "~/components"
import {
    useShareObjectPermissionsMutation,
    useUserObjectPermissionsCheckQuery,
} from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { ObjectsList } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/ObjectsList"
import { OnlyListView } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/OnlyListView"
import { PermissionsSelect } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/PermissionsSelect"
import { SearchObjects } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/SearchObjects"
import { useFetchObjectPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import {
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
    sharedObjectPermissionsMessages,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"
import { getCurrentUserPermissions } from "~/domains/identity/roles-permissions/utils/spiceDB"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { AuthorizationName, OrganizationId } from "~/types"

interface ShareObjectPermissionsModalProps {
    open: boolean
    close: () => void
    organizationId: OrganizationId
    objectType: SpiceDBObjectType
    objectId: string
}

export const ShareObjectPermissionsModal: React.FC<ShareObjectPermissionsModalProps> = ({
    open,
    close,
    objectType,
    objectId,
    organizationId,
}) => {
    const { formatMessage } = useIntl()
    const [searchPermissions, setSearchPermissions] = useState<AuthorizationName[]>([])
    const [selectedObjects, setSelectedObjects] = useState<SpiceDBPermissionsLiteI[]>([])
    const [showOnlyList, setShowOnlyList] = useState(false)

    const currentUser = useAppSelector(selectUser)
    const { sharedPermissions, loading, refetch } = useFetchObjectPermissions(objectId, objectType)
    const [shareObjectPermissions, { isLoading: shareObjectPermissionsLoading }] = useShareObjectPermissionsMutation()

    const { data: userObjectPermissions, isLoading: loadingPermissions } = useUserObjectPermissionsCheckQuery({
        userId: currentUser.id,
        objectType,
        organizationId,
        authorizations: [
            AuthorizationName.READ,
            AuthorizationName.UPDATE,
            AuthorizationName.DELETE,
            AuthorizationName.APPROVE,
            AuthorizationName.CONVERT,
        ],
    })

    const objectTypeTranslation = sharedObjectPermissionsMessages[objectType]

    const currentUserPermissions = useMemo(() => {
        return sharedPermissions && userObjectPermissions
            ? getCurrentUserPermissions(currentUser.id, userObjectPermissions, sharedPermissions)
            : []
    }, [sharedPermissions, currentUser.id, userObjectPermissions])

    const handleChangePermission = async (permissions: AuthorizationName[]) => {
        setSearchPermissions(permissions)
    }

    const handleInvite = useCallback(async () => {
        if (!selectedObjects.length || !searchPermissions.length) return

        try {
            await shareObjectPermissions({
                objectId,
                objectType,
                body: {
                    objects: selectedObjects,
                    authorizations: searchPermissions,
                    isFirstTimeShare: true,
                    creatorOrganizationId: organizationId,
                    recepientOrganizationId: organizationId,
                },
            })

            toast.success(
                formatMessage(sharedObjectPermissionsModalMessages.shareMultiPermissionsSuccess, {
                    count: searchPermissions.length,
                })
            )
            setSelectedObjects([])
            setSearchPermissions([])

            // For race issue
            setTimeout(refetch, 500)
        } catch (error) {
            toast.error(formatMessage(sharedObjectPermissionsModalMessages.sharePermissionsError))
            Sentry.captureException(error, {
                extra: {
                    objectId,
                    objectType,
                    organizationId,
                },
            })
        }
    }, [
        selectedObjects,
        searchPermissions,
        organizationId,
        objectId,
        objectType,
        formatMessage,
        shareObjectPermissions,
    ])

    return (
        <>
            <Modal open={open} onClose={close} size={Size.MD}>
                {!showOnlyList && (
                    <Modal.Header>
                        <h4>
                            {formatMessage(sharedObjectPermissionsModalMessages.title, {
                                objectType: objectTypeTranslation ? formatMessage(objectTypeTranslation) : "",
                            })}
                        </h4>
                    </Modal.Header>
                )}
                <Modal.Content>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Box display="flex" flexDirection="column">
                            {showOnlyList ? (
                                <OnlyListView
                                    objectType={objectType}
                                    objectId={objectId}
                                    setShowOnlyList={setShowOnlyList}
                                    sharedPermissions={sharedPermissions}
                                />
                            ) : (
                                <>
                                    <Stack direction="row" alignItems="center" gap={2} marginBottom={3}>
                                        <Stack position="relative" direction="row" alignItems="center" flex={1}>
                                            <SearchObjects
                                                objectType={objectType}
                                                objectId={objectId}
                                                selectedObjects={selectedObjects}
                                                setSelectedObjects={setSelectedObjects}
                                            />
                                            <Box>
                                                <PermissionsSelect
                                                    currentUserPermissions={currentUserPermissions}
                                                    objectPermissions={searchPermissions}
                                                    objectType={objectType}
                                                    handleChangePermission={handleChangePermission}
                                                    showRemoveAll={false}
                                                />
                                            </Box>
                                        </Stack>
                                        <Button
                                            type="primary"
                                            onClick={handleInvite}
                                            disabled={shareObjectPermissionsLoading || loadingPermissions}
                                        >
                                            <span>{formatMessage(sharedObjectPermissionsModalMessages.share)}</span>
                                        </Button>
                                    </Stack>
                                    <Box color="var(--color-grey-light)" marginBottom={1}>
                                        {formatMessage(sharedObjectPermissionsModalMessages.sharedObjectsText, {
                                            objectType: objectTypeTranslation
                                                ? formatMessage(objectTypeTranslation)
                                                : "",
                                        })}
                                    </Box>
                                    <ObjectsList
                                        objectId={objectId}
                                        objectType={objectType}
                                        sharedPermissions={sharedPermissions}
                                        setShowOnlyList={setShowOnlyList}
                                    />
                                </>
                            )}
                        </Box>
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" onClick={close}>
                        {formatMessage(sharedObjectPermissionsModalMessages.close)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
