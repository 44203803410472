import { styled } from "@mui/material"
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from "@xyflow/react"
import React from "react"
import { X } from "react-feather"

import { EdgeLabel } from "~/domains/orchestration/flows/components/EdgeLabel"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { isAssignTagGroupNode } from "~/domains/orchestration/flows/types"

const DeleteButton = styled(IconButton)({
    opacity: 0,
})

export const ButtonEdge = ({
    id,
    target,
    source,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}: EdgeProps) => {
    const { setEdges } = useReactFlow()
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const state = useEditor()
    const currentFlow = state.flow
    const dispatch = useEditorDispatch()

    const handleDelete = () => {
        if (!currentFlow) return

        const node = currentFlow.nodes.find((n) => n.slug === source)
        if (!node) return

        let keyToUpdate = ""

        Object.entries(node).forEach(([key, value]) => {
            if (value === target) {
                keyToUpdate = key
            }
        })

        const groupNode = isAssignTagGroupNode(node)
        if (groupNode) {
            const updatedNodes = node.nodes.map((n, idx, arr) => ({
                ...n,
                nextNode: idx === arr.length - 1 ? null : n.nextNode,
            }))

            dispatch({
                type: "UPDATE_NODE",
                payload: {
                    ...node,
                    nodes: updatedNodes,
                    nextNode: null,
                },
            })
        } else {
            dispatch({
                type: "UPDATE_NODE",
                payload: {
                    ...node,
                    [keyToUpdate]: null,
                },
            })
        }

        setEdges((edges) => edges.filter((edge) => edge.id !== id))
    }

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <EdgeLabel labelX={labelX} labelY={labelY} className="nodrag nopan">
                    <DeleteButton type="close" onClick={handleDelete}>
                        <X size={14} />
                    </DeleteButton>
                </EdgeLabel>
            </EdgeLabelRenderer>
        </>
    )
}
