import { FC, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Card } from "~/components"
import { approvalObjectType } from "~/domains/orchestration/approval/locale"
import { RefusalReason } from "~/domains/orchestration/flows-v0/components/ApprovalBlock/RefusalReason"
import { UserReviewLine } from "~/domains/orchestration/flows-v0/components/ApprovalBlock/UserReviewLine"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"

import { RefusalReasonsModal } from "./RefusalReasonsModal"
import { UserReviewCard } from "./UserReviewCard"

export const messages = defineMessages({
    completed: { id: "common.status.completed", defaultMessage: "Completed" },
    approval: { id: "approval.title.approval", defaultMessage: "Flow of validation" },
    approve: { id: "approval.button.approve", defaultMessage: "Approve" },
    refuse: { id: "approval.button.refuse", defaultMessage: "Refuse" },
    showMyApprovals: {
        id: "approval.checkbox.showMyApprovals",
        defaultMessage: "Show only my approvals",
    },
    refusalReason: { id: "approval.button.refusalReason", defaultMessage: "Refusal reason" },
    refusalMessage: { id: "approval.button.refusalMessage", defaultMessage: "Refusal message" },
    retractReview: { id: "common.cancel", defaultMessage: "Cancel" },
    retractAllReviews: { id: "approval.button.retractAllReviews", defaultMessage: "Invalidate all reviews" },
    invalidationSuccessful: {
        id: "approval.description.invalidationSuccessful",
        defaultMessage: "All reviews have been invalidated.",
    },
    invoiceReviewPrompt: {
        id: "approval.description.invoiceReviewPrompt",
        defaultMessage: "This invoice needs your review",
    },
    partnerReviewPrompt: {
        id: "approval.description.partnerReviewPrompt",
        defaultMessage: "This partner needs your review",
    },

    objectAlreadyReviewed: {
        id: "approval.description.objectAlreadyReviewed",
        defaultMessage: "You have already reviewed this {objectType}",
    },
    objectApproved: {
        id: "approval.description.objectApproved",
        defaultMessage: "This {objectType} has been approved.",
    },
    objectRefused: {
        id: "approval.description.objectRefused",
        defaultMessage: "This {objectType} has been refused.",
    },
    objectRetracted: {
        id: "approval.description.objectRetracted",
        defaultMessage: "Your review for this {objectType} has been retracted.",
    },
})

interface Props {
    organisationId: string
    objectId: string
    objectType: ApprovalObjectType
    className?: string
    displayType?: "inline" | "block"
    showRetract?: boolean
    showRetractAll?: boolean
    onReviewed?: (reviewed: boolean) => void
    onApproved?: () => void
    onRefused?: () => void
    onRetract?: () => void
    onApprovalRequired?: () => void
    onRetractAll?: () => void
}

export const ApprovalBlock: FC<Props> = ({
    organisationId,
    objectId,
    objectType,
    onReviewed,
    onApproved,
    onRefused,
    onRetract,
    onApprovalRequired,
    onRetractAll,
    className,
    displayType = "block",
    showRetract = true,
    showRetractAll = false,
}: Props) => {
    const [showRefusalReason, setShowRefusalReason] = useState(false)
    const { formatMessage } = useIntl()
    // Action handlers
    const handleApprove = async () => {
        toast.success(
            formatMessage(messages.objectApproved, {
                objectType: formatMessage(approvalObjectType[objectType]),
            })
        )
        onApproved?.()
    }

    const handleRefuse = async () => {
        toast.warn(
            formatMessage(messages.objectRefused, {
                objectType: formatMessage(approvalObjectType[objectType]),
            })
        )
        onRefused?.()
    }

    const handleOpenRefusalReason = () => {
        setShowRefusalReason(true)
    }

    const handleCloseRefusalReason = () => {
        setShowRefusalReason(false)
    }

    const handleUpdateApproval = async () => {
        toast.warn(
            formatMessage(messages.objectRetracted, {
                objectType: formatMessage(approvalObjectType[objectType]),
            })
        )
        onRetract?.()
    }

    const handleRetractAll = async () => {
        toast.warn(formatMessage(messages.invalidationSuccessful))
        onRetractAll?.()
    }

    if (displayType === "block") {
        if (showRefusalReason) {
            return (
                <Card title={formatMessage(messages.refusalReason)}>
                    <RefusalReason
                        organisationId={organisationId}
                        objectId={objectId}
                        objectType={objectType}
                        onRefused={handleRefuse}
                        onCancel={handleCloseRefusalReason}
                        onReviewed={onReviewed}
                    />
                </Card>
            )
        }

        return (
            <UserReviewCard
                organisationId={organisationId}
                objectId={objectId}
                objectType={objectType}
                onReviewed={onReviewed}
                onApproved={handleApprove}
                onRefused={handleOpenRefusalReason}
                onRetract={handleUpdateApproval}
                onApprovalRequired={onApprovalRequired}
                onRetractAll={handleRetractAll}
                className={className}
                showRetract={showRetract}
                showRetractAll={showRetractAll}
            />
        )
    }

    if (showRefusalReason) {
        return (
            <RefusalReasonsModal visible={showRefusalReason} title={formatMessage(messages.refusalReason)}>
                <RefusalReason
                    organisationId={organisationId}
                    objectId={objectId}
                    objectType={objectType}
                    onRefused={handleRefuse}
                    onCancel={handleCloseRefusalReason}
                    onReviewed={onReviewed}
                />
            </RefusalReasonsModal>
        )
    }

    return (
        <UserReviewLine
            organisationId={organisationId}
            objectId={objectId}
            objectType={objectType}
            showRetract={showRetract}
            showRetractAll={showRetractAll}
            onReviewed={onReviewed}
            onApproved={handleApprove}
            onRefused={handleOpenRefusalReason}
            onRetract={handleUpdateApproval}
            onRetractAll={handleRetractAll}
            onApprovalRequired={onApprovalRequired}
        />
    )
}
