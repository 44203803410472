/* eslint-disable max-lines */
import { Box } from "@mui/material"
import { lazy, useCallback, useEffect, useMemo, useState } from "react"
import { Check, FileText, Trash2, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { BulkActionButton, BulkActions, ConfirmModal, DatatableFiltersActions, Tabs } from "~/components"
import { FilterProvider } from "~/components/Filters/FilterProvider"
import { getFilterProps } from "~/components/Filters/utils/filters"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"
import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { filterTagGroupsByTagObjectRecord } from "~/domains/analytics/tags/core/tagGroupsAndTags"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { useUserObjectPermissionsCheckQuery } from "~/domains/identity/roles-permissions/api/spiceDbApi"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { useTagsWithTagGroupName } from "~/domains/transactions/invoices/components/list/InvoicesList/useTagsWithTagGroupName"
import { Filters } from "~/domains/transactions/purchase-requests/components"
import {
    getApprovable,
    getConvertable,
    getNonConvertedToPOForDeletion,
    getPRBulkActionButtons,
    getRefusable,
    purchaseRequestsTabMessages,
    statusCounts,
} from "~/domains/transactions/purchase-requests/core/purchaseRequest"
import { PURCHASE_REQUEST_NEW_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { useFetchPurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks"
import { useApprovePurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks/useApprovePurchaseRequests"
import { useConvertPurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks/useConvertPurchaseRequests"
import { useDeletePurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks/useDeletePurchaseRequests"
import { useImportBatchPurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useImportBatchPurchaseRequest"
import { useRejectPurchaseRequests } from "~/domains/transactions/purchase-requests/store/hooks/useRejectPurchaseRequests"
import {
    purchaseRequestsActions,
    selectPurchaseRequestsTags,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import {
    PurchaseRequestStatus,
    PurchaseRequestSummary,
    PurchaseRequestsTab,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useTitle } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { GlobalFilterType } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationName } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"
import { isDefined } from "~/utils/isDefined"

const ModalBatchImportLazy = lazy(() =>
    import("~/components/ModalBatchImport/ModalBatchImport").then(({ ModalBatchImport }) => ({
        default: ModalBatchImport,
    }))
)
const ModalResultImportLazy = lazy(() =>
    import("~/components/ModalBatchImport/ModalResultImport").then(({ ModalResultImport }) => ({
        default: ModalResultImport,
    }))
)
const ListLazy = lazy(() =>
    import("~/domains/transactions/purchase-requests/components/PRList/List").then(({ List }) => ({ default: List }))
)

const messages = defineMessages({
    title: { id: "purchase.requests.list.title", defaultMessage: "Purchase requests" },
    fullPR: { id: "purchase.requests.fullPR", defaultMessage: "Create a full PR" },
    errorWrongFileType: {
        id: "purchase.requests.modalImportBatch.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept CSV and XLSX files.",
    },
    modalUploadTitle: {
        id: "purchase.requests.modalImportBatch.title",
        defaultMessage: "Import Purchase Requests in batch",
    },
    modalUploadTip: {
        id: "purchase.requests.modalImportBatch.uploadBox.tip",
        defaultMessage: "Supported format : csv, xlsx. No more than 2mb",
    },
    selected: { id: "purchase.requests.table.selected", defaultMessage: "selected" },
    delete: { id: "purchase.requests.table.selected.delete", defaultMessage: "Delete" },
    approve: { id: "purchase.requests.table.selected.approve", defaultMessage: "Approve" },
    reject: { id: "purchase.requests.table.selected.reject", defaultMessage: "Reject" },
    convert: { id: "purchase.requests.table.selected.convert", defaultMessage: "Convert" },
    confirmDeleteTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice",
        defaultMessage: "Delete purchase requests",
    },
    confirmDeleteMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmDeleteMessage",
        defaultMessage:
            "Are you sure to delete {count} purchase {count, plural, =0 {request} one {request} other {requests}}? Any deletion is permanent.",
    },
    nothingToDelete: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToDelete",
        defaultMessage:
            "Nothing to delete. To be able to delete a purchase request, you must have the permission and it shouldn't be converted to a PO yet.",
    },
    nothingToApprove: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToApprove",
        defaultMessage:
            "Nothing to approve. To be able to approve a purchase request, you must have the permission and it shouldn't be a draft or be converted to a PO yet.",
    },
    nothingToConvert: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToConvert",
        defaultMessage:
            "Nothing to convert. To be able to convert a purchase request, you must have the permission and it should have an expected delivery date and a vendor.",
    },
    confirmApproveTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmApproveTitle",
        defaultMessage: "Approve purchase requests",
    },
    confirmApproveMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmApproveMessage",
        defaultMessage:
            "Are you sure to approve {count} purchase {count, plural, =0 {request} one {request} other {requests}}?",
    },
    nothingToReject: {
        id: "purchase.requests.table.bulk.deleteInvoice.nothingToReject",
        defaultMessage:
            "Nothing to reject. To be able to reject a purchase request, you must have the permission and it shouldn't be a draft or be converted to a PO yet.",
    },
    confirmRejectTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmRejectTitle",
        defaultMessage: "Reject purchase requests",
    },
    confirmRejectMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmRejectMessage",
        defaultMessage:
            "Are you sure to reject {count} purchase {count, plural, =0 {request} one {request} other {requests}}?",
    },
    confirmConvertTitle: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmConvertTitle",
        defaultMessage: "Convert purchase requests",
    },
    confirmConvertMessage: {
        id: "purchase.requests.table.bulk.deleteInvoice.confirmConvertMessage",
        defaultMessage:
            "Are you sure to convert {count} purchase {count, plural, =0 {request} one {request} other {requests}}?",
    },
})

const getTabLabel = (message: string, tab: PurchaseRequestsTab, count: number = 0): JSX.Element => {
    return (
        <span>
            {message}
            {tab === PurchaseRequestsTab.ALL || count === 0 ? null : <div className="tab-count"> {count} </div>}
        </span>
    )
}

const ACCEPTED_FILE_EXTENSIONS: string[] = ["csv", "xlsx"]
const NOTION_URL_BATCH_PR =
    "https://get-flowie.notion.site/Import-Batch-Purchase-Request-fa65a4aedcf344fda1bd1168d66ca675"

export const PurchaseRequests = withSocketIOProvider(() => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    const [modalBatchImportVisible, setModalBatchImportVisible] = useState<boolean>(false)
    const [modalResultImportVisible, setModalResultImportVisible] = useState<boolean>(false)
    const [resultBatchImport, setResultBatchImport] = useState<ImportBatchResponseI | undefined>(undefined)
    const [selectedPRs, setSelectedPRs] = useState<string[]>([])
    const [bulkActionButtons, setBulkActionButtons] = useState<BulkActionButton[]>([])
    const [selectedForDelete, setSelectedForDelete] = useState<string[]>([])
    const [displayModalDelete, setDisplayModalDelete] = useState<boolean>(false)
    const [selectedForApprove, setSelectedForApprove] = useState<string[]>([])
    const [displayModalApprove, setDisplayModalApprove] = useState<boolean>(false)
    const [selectedForReject, setSelectedForReject] = useState<string[]>([])
    const [displayModalReject, setDisplayModalReject] = useState<boolean>(false)
    const [selectedForConvert, setSelectedForConvert] = useState<PurchaseRequestSummary[]>([])
    const [displayModalConvert, setDisplayModalConvert] = useState<boolean>(false)
    const [tabValue, setTabValue] = useState<PurchaseRequestsTab>(PurchaseRequestsTab.ALL)

    const currentUser = useAppSelector(selectUser)
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const purchaseRequestsTags = useAppSelector(selectPurchaseRequestsTags)

    const { importBatchPR, loading } = useImportBatchPurchaseRequest(organizationId as string)
    const {
        refetchPurchaseRequests,
        purchaseRequests,
        loading: loadingPurchaseRequests,
    } = useFetchPurchaseRequests(organizationId)
    const navigate = useNavigate()
    const deletePurchaseRequests = useDeletePurchaseRequests()
    const approvePurchaseRequests = useApprovePurchaseRequests()
    const rejectPurchaseRequests = useRejectPurchaseRequests()
    const convertPurchaseRequests = useConvertPurchaseRequests(organizationId)

    const { data: purchaseRequestsPermissions, isLoading: loadingPermissions } = useUserObjectPermissionsCheckQuery({
        userId: currentUser?.id ?? "",
        objectType: SpiceDBObjectType.PURCHASE_REQUEST,
        organizationId: organizationId as string,
        authorizations: [AuthorizationName.READ, AuthorizationName.CREATE],
    })

    const pageName = formatMessage(messages.title)
    useTitle(pageName)

    const { tagGroups, loading: loadingTagGroups } = useOrganizationTagGroups(organizationId)
    const tags = useTagsWithTagGroupName(tagGroups)
    const tagGroupsFiltered = useMemo(
        () => filterTagGroupsByTagObjectRecord(tagGroups, purchaseRequestsTags),
        [tagGroups, purchaseRequestsTags]
    )

    const isAllTab = useMemo(() => tabValue === PurchaseRequestsTab.ALL, [tabValue])

    const handleApprovePurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToApprove))
            }

            setSelectedForApprove(selected)
            setDisplayModalApprove(true)
        },
        [formatMessage]
    )

    const handleRejectPurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToReject))
            }

            setSelectedForReject(selected)
            setDisplayModalReject(true)
        },
        [formatMessage]
    )

    const handleDeletePurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToDelete))
            }

            setSelectedForDelete(selected)
            setDisplayModalDelete(true)
        },
        [formatMessage]
    )

    const handleConvertPurchaseRequest = useCallback(
        (selected: string[]) => () => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToConvert))
            }

            setSelectedForConvert(selected.map((id) => purchaseRequests.find((pr) => pr.id === id)).filter(isDefined))
            setDisplayModalConvert(true)
        },
        [purchaseRequests, formatMessage]
    )

    const getApproveBulkActionButton = useCallback((): BulkActionButton => {
        const approvablePRs = getApprovable(selectedPRs, purchaseRequests)
        return {
            label: formatMessage(messages.approve),
            count: isAllTab ? approvablePRs.length : undefined,
            icon: <Check size={16} />,
            onClick: handleApprovePurchaseRequest(approvablePRs),
        }
    }, [selectedPRs, purchaseRequests, formatMessage, isAllTab, handleApprovePurchaseRequest])

    const getRejectBulkActionButton = useCallback((): BulkActionButton => {
        const refusablePRs = getRefusable(selectedPRs, purchaseRequests)
        return {
            label: formatMessage(messages.reject),
            count: isAllTab ? refusablePRs.length : undefined,
            icon: <X color="var(--color-red)" size={16} />,
            type: "error-light",
            onClick: handleRejectPurchaseRequest(refusablePRs),
        }
    }, [selectedPRs, purchaseRequests, formatMessage, isAllTab, handleRejectPurchaseRequest])

    const getDeleteBulkActionButton = useCallback((): BulkActionButton => {
        const deletablePRs = getNonConvertedToPOForDeletion(selectedPRs, purchaseRequests)
        return {
            label: formatMessage(messages.delete),
            count: isAllTab ? deletablePRs.length : undefined,
            icon: <Trash2 color="var(--color-red)" size={16} />,
            type: "error-light",
            onClick: handleDeletePurchaseRequest(deletablePRs),
        }
    }, [selectedPRs, purchaseRequests, formatMessage, isAllTab, handleDeletePurchaseRequest])

    const getConvertBulkActionButton = useCallback((): BulkActionButton => {
        const convertablePRs = getConvertable(selectedPRs, purchaseRequests)
        return {
            label: formatMessage(messages.convert),
            count: isAllTab ? convertablePRs.length : undefined,
            icon: <FileText size={16} />,
            onClick: handleConvertPurchaseRequest(convertablePRs),
        }
    }, [selectedPRs, purchaseRequests, formatMessage, isAllTab, handleConvertPurchaseRequest])

    useEffect(() => {
        const buttons = getPRBulkActionButtons(
            tabValue,
            getApproveBulkActionButton,
            getRejectBulkActionButton,
            getDeleteBulkActionButton,
            getConvertBulkActionButton
        )
        setBulkActionButtons(buttons)
    }, [
        formatMessage,
        selectedPRs,
        purchaseRequests,
        tabValue,
        getApproveBulkActionButton,
        getRejectBulkActionButton,
        getDeleteBulkActionButton,
        getConvertBulkActionButton,
    ])

    const handleFile = useCallback(
        async (file: File) => {
            try {
                const result: ImportBatchResponseI = await importBatchPR(file)
                setResultBatchImport(result)
                closeModalBatchImport()
                showResultImportModal()
                refetchPurchaseRequests()
            } catch (error) {
                console.error(error)
            }
        },
        [importBatchPR, refetchPurchaseRequests]
    )

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            const fileExtension = file.name.split(".").pop()
            if (fileExtension && ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
                return { result: true, error: null }
            }

            return { result: false, error: formatMessage(messages.errorWrongFileType) }
        },
        [formatMessage]
    )

    const handleSelectedPR = useCallback(
        (rowSelectionIds: string[]) => {
            setSelectedPRs(rowSelectionIds)
        },
        [setSelectedPRs]
    )

    const handleHideModalDelete = useCallback(() => setDisplayModalDelete(false), [])
    const handleHideModalApprove = useCallback(() => setDisplayModalApprove(false), [])
    const handleHideModalReject = useCallback(() => setDisplayModalReject(false), [])
    const handleHideModalConvert = useCallback(() => setDisplayModalConvert(false), [])

    const handleConfirmModalApprove = useCallback(async () => {
        if (organizationId) {
            setDisplayModalApprove(false)
            await approvePurchaseRequests(selectedForApprove, organizationId)
            setSelectedPRs([])
            setSelectedForApprove([])
            return true
        }
        return false
    }, [selectedForApprove, organizationId, approvePurchaseRequests])

    const handleConfirmModalReject = useCallback(async () => {
        if (organizationId) {
            setDisplayModalReject(false)
            await rejectPurchaseRequests(selectedForReject, organizationId)
            setSelectedPRs([])
            setSelectedForReject([])
            return true
        }
        return false
    }, [selectedForReject, organizationId, rejectPurchaseRequests])

    const handleConfirmModalDelete = useCallback(async () => {
        if (organizationId) {
            setDisplayModalDelete(false)
            await deletePurchaseRequests(selectedForDelete, organizationId)
            setSelectedPRs([])
            setSelectedForDelete([])
            return true
        }
        return false
    }, [selectedForDelete, organizationId, deletePurchaseRequests])

    const handleConfirmModalConvert = useCallback(async () => {
        if (organizationId) {
            setDisplayModalConvert(false)
            await convertPurchaseRequests(selectedForConvert)
            setSelectedPRs([])
            setSelectedForConvert([])
            return true
        }
        return false
    }, [selectedForConvert, organizationId, convertPurchaseRequests])

    const handleClickInstructions = () => {
        window.open(NOTION_URL_BATCH_PR, "_blank")
    }

    const onClickNewPr = () => navigate(PURCHASE_REQUEST_NEW_ROUTE)

    const onClickBatchImport = () => setModalBatchImportVisible(true)
    const closeModalBatchImport = () => setModalBatchImportVisible(false)

    const showResultImportModal = () => setModalResultImportVisible(true)
    const closeModalResultImport = () => setModalResultImportVisible(false)

    const clearSelection = () => setSelectedPRs([])

    const handleChangeTab = (newValue: number | string) => {
        setTabValue(newValue as PurchaseRequestsTab)
        setSelectedPRs([])
        dispatch(purchaseRequestsActions.setCurrentPurchaseRequestsTab(newValue as PurchaseRequestsTab))
    }

    const actions: DatatableFiltersActions[] = [
        { label: formatMessage(messages.fullPR), action: onClickNewPr },
        { label: formatMessage(commonMessages.batchImport), action: onClickBatchImport },
    ]

    const countByStatus = useMemo(() => statusCounts(purchaseRequests), [purchaseRequests])

    const tabConfig = useMemo(
        () => [
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.ALL]),
                    PurchaseRequestsTab.ALL
                ),
                value: PurchaseRequestsTab.ALL,
                purchaseRequests: purchaseRequests,
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.DRAFT]),
                    PurchaseRequestsTab.DRAFT,
                    countByStatus.DRAFT
                ),
                value: PurchaseRequestsTab.DRAFT,
                purchaseRequests: purchaseRequests.filter((pr) => pr.status === PurchaseRequestStatus.DRAFT),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.SUBMITTED]),
                    PurchaseRequestsTab.SUBMITTED,
                    countByStatus.SUBMITTED
                ),
                value: PurchaseRequestsTab.SUBMITTED,
                purchaseRequests: purchaseRequests.filter((pr) => pr.status === PurchaseRequestStatus.SUBMITTED),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.APPROVED]),
                    PurchaseRequestsTab.APPROVED,
                    countByStatus.APPROVED
                ),
                value: PurchaseRequestsTab.APPROVED,
                purchaseRequests: purchaseRequests.filter(
                    (pr) => !pr.purchaseOrder && pr.status === PurchaseRequestStatus.APPROVED
                ),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.REJECTED]),
                    PurchaseRequestsTab.REJECTED,
                    countByStatus.REJECTED
                ),
                value: PurchaseRequestsTab.REJECTED,
                purchaseRequests: purchaseRequests.filter((pr) => pr.status === PurchaseRequestStatus.REJECTED),
            },
            {
                label: getTabLabel(
                    formatMessage(purchaseRequestsTabMessages[PurchaseRequestsTab.CONVERTED]),
                    PurchaseRequestsTab.CONVERTED,
                    countByStatus.CONVERTED
                ),
                value: PurchaseRequestsTab.CONVERTED,
                purchaseRequests: purchaseRequests.filter((pr) => !!pr.purchaseOrder),
            },
        ],
        [countByStatus, formatMessage, purchaseRequests]
    )

    const getFilterPropsFunction = (type: GlobalFilterType) => getFilterProps(type, { tagGroups: tagGroupsFiltered })

    return (
        <Box className="main-box no-header">
            {organizationId && !loadingPermissions ? (
                <>
                    <FilterProvider registerTypes={[GlobalFilterType.TAGS]} getFilterProps={getFilterPropsFunction}>
                        <Filters actions={actions} permissions={purchaseRequestsPermissions} />
                    </FilterProvider>
                    <Tabs
                        defaultValue={tabValue}
                        setChange={handleChangeTab}
                        tabs={tabConfig.map((tab) => ({
                            ...tab,
                            component: (
                                <ListLazy
                                    purchaseRequests={tab.purchaseRequests}
                                    organizationId={organizationId}
                                    tags={tags}
                                    handleSelectedRows={handleSelectedPR}
                                    rowSelectionModel={selectedPRs}
                                    currentTab={tabValue}
                                    loadingTagGroups={loadingTagGroups}
                                    loading={loadingPurchaseRequests}
                                />
                            ),
                        }))}
                    />

                    <ModalBatchImportLazy
                        open={modalBatchImportVisible}
                        close={closeModalBatchImport}
                        handleFile={handleFile}
                        checkFileType={checkFileType}
                        handleClickInstructions={handleClickInstructions}
                        loading={loading}
                        uploadTip={messages.modalUploadTip}
                        title={messages.modalUploadTitle}
                    />
                    <ModalResultImportLazy
                        open={modalResultImportVisible}
                        close={closeModalResultImport}
                        showNewImportModal={onClickBatchImport}
                        resultBatchImport={resultBatchImport}
                        title={messages.modalUploadTitle}
                    />
                    <ConfirmModal
                        title={formatMessage(messages.confirmDeleteTitle)}
                        open={displayModalDelete}
                        close={handleHideModalDelete}
                        onConfirm={handleConfirmModalDelete}
                    >
                        {formatMessage(messages.confirmDeleteMessage, {
                            count: selectedForDelete.length,
                            s: selectedForDelete.length > 1 ? "s" : "",
                        })}
                    </ConfirmModal>
                    <ConfirmModal
                        title={formatMessage(messages.confirmApproveTitle)}
                        open={displayModalApprove}
                        close={handleHideModalApprove}
                        onConfirm={handleConfirmModalApprove}
                        confirmButtonType="primary"
                    >
                        {formatMessage(messages.confirmApproveMessage, {
                            count: selectedForApprove.length,
                            s: selectedForApprove.length > 1 ? "s" : "",
                        })}
                    </ConfirmModal>
                    <ConfirmModal
                        title={formatMessage(messages.confirmRejectTitle)}
                        open={displayModalReject}
                        close={handleHideModalReject}
                        onConfirm={handleConfirmModalReject}
                    >
                        {formatMessage(messages.confirmRejectMessage, {
                            count: selectedForReject.length,
                            s: selectedForReject.length > 1 ? "s" : "",
                        })}
                    </ConfirmModal>
                    <ConfirmModal
                        title={formatMessage(messages.confirmConvertTitle)}
                        open={displayModalConvert}
                        close={handleHideModalConvert}
                        onConfirm={handleConfirmModalConvert}
                    >
                        {formatMessage(messages.confirmConvertMessage, {
                            count: selectedForConvert.length,
                            s: selectedForConvert.length > 1 ? "s" : "",
                        })}
                    </ConfirmModal>
                    {selectedPRs.length ? (
                        <BulkActions
                            selected={selectedPRs}
                            onClose={clearSelection}
                            buttons={bulkActionButtons}
                            selectedMessage={formatMessage(messages.selected, {
                                s: selectedPRs.length > 1 ? "s" : "",
                            })}
                        />
                    ) : null}
                </>
            ) : null}
        </Box>
    )
})
