import { Tooltip } from "@mui/material"
import { Edit } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    edit: { id: "invoice.actions.button.edit", defaultMessage: "Edit" },
    editButtonTooltip: {
        id: "buyer.invoice.actions.button.edit.tooltip",
        defaultMessage: "Edit the invoice",
    },
})

interface EditInvoiceProps {
    handleEdit?: () => void
    display?: "menu-item" | "button"
}

export const EditInvoice = ({ handleEdit, display = "button" }: EditInvoiceProps) => {
    const { formatMessage } = useIntl()

    return display === "button" ? (
        <Tooltip title={formatMessage(messages.editButtonTooltip)} arrow>
            <div>
                <Button onClick={handleEdit} type="grey">
                    <Edit size={18} />
                    <SafeFormattedMessage {...messages.edit} />
                </Button>
            </div>
        </Tooltip>
    ) : (
        <Button onClick={handleEdit} type="menu-item">
            <Edit size={18} />
            <SafeFormattedMessage {...messages.edit} />
        </Button>
    )
}
