import { InputAdornment, Stack } from "@mui/material"
import { MuiFileInput } from "mui-file-input"
import { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Edit, File as FileIcon, Loader } from "react-feather"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { QuestionValue } from "~/domains/identity/custom-forms/types/CustomForms"

// TODO: get file types from the backend
const ACCEPTED_FILE_TYPES = ["application/pdf", "image/png", "image/webp", "image/jpeg"]

// A dropzone for the file upload is neeed as the MuiFileInput component does not support the drop functionality
// This is not in a separate file as FileDropzone is used only in this component and the way to think about this
// is that it's all one component together with the CustomFormsFileInput component.
const FileDropzone = ({ onDrop, children, disabled, ...props }) => {
    const onDropFiles = useCallback(
        (acceptedFiles) => {
            onDrop(acceptedFiles)
        },
        [onDrop]
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onDropFiles,
        noClick: true,
        ...props,
    })

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} disabled={disabled} />
            {children}
        </div>
    )
}

interface CustomFormsFileInputProps {
    savedValue: QuestionValue
    onValueChanged: (value: QuestionValue) => void
    uploadFileAnswerLoading: boolean
    disabled?: boolean
    isInvalid?: boolean
    isFocused?: boolean
}

export const CustomFormsFileInput = ({
    savedValue,
    onValueChanged,
    disabled,
    isInvalid,
    isFocused,
    uploadFileAnswerLoading,
}: CustomFormsFileInputProps) => {
    const [value, setValue] = useState<File | null>(null)
    const { formatMessage } = useIntl()
    const [invalid, setInvalid] = useState(isInvalid)
    const [focused, setFocused] = useState(isFocused)
    const [inputRef, setInputRef] = useState<HTMLElement | null>(null)

    const handleChange = (newValue: File | null) => {
        setValue(newValue)
        newValue && onValueChanged(newValue)
        if (invalid && newValue) {
            setInvalid(false)
        }
    }

    // When file is dropped, the file is passed as an array of files
    const handleDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            handleChange(acceptedFiles[0])
        }
    }

    const handleRemoveFile = () => {
        setValue(null)
        onValueChanged("")
    }

    useEffect(() => {
        setInvalid(isInvalid)
        setFocused(isFocused)
    }, [isInvalid, isFocused])

    useEffect(() => {
        if (focused && inputRef) {
            inputRef.scrollIntoView({ behavior: "smooth", block: "center" })
            inputRef.focus()
        }
    }, [focused, inputRef])

    if (savedValue) {
        let fileName = ""
        if (typeof savedValue === "string") {
            fileName = savedValue
        } else if (typeof savedValue === "object" && "filename" in savedValue) {
            fileName = savedValue.filename
        } else if (typeof savedValue === "object" && "name" in savedValue) {
            fileName = savedValue.name
        }

        let icon = <Edit size={18} onClick={handleRemoveFile} />
        if (uploadFileAnswerLoading) {
            icon = <Loader size={18} />
        } else if (disabled) {
            icon = <FileIcon size={18} />
        }
        return (
            <Stack direction="row" gap={1} alignItems="center" minHeight={52}>
                {fileName}
                {icon}
            </Stack>
        )
    }

    return (
        <FileDropzone
            disabled={disabled || uploadFileAnswerLoading}
            onDrop={handleDrop}
            accept={ACCEPTED_FILE_TYPES}
            multiple={false}
        >
            <MuiFileInput
                value={value}
                onChange={handleChange}
                disabled={disabled || uploadFileAnswerLoading}
                error={isInvalid}
                focused={isFocused}
                inputRef={setInputRef}
                placeholder={formatMessage(commonMessages.uploadFile)}
                InputProps={{
                    color: "secondary",
                    startAdornment: (
                        <InputAdornment position="start">
                            <FileIcon size={18} />
                        </InputAdornment>
                    ),
                }}
            />
        </FileDropzone>
    )
}
