import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"

import { selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

import { BackLink } from "./BackLink"
import Header from "./Header"

interface HeaderH1Props extends React.PropsWithChildren {
    title: React.ReactNode
    icon?: React.ReactNode
    backLink?: string | (() => void)
    className?: string
    isShared?: boolean
    mainAction?: React.ReactNode
}

export const HeaderH1 = ({ title, icon, backLink, children, className, isShared, mainAction }: HeaderH1Props) => {
    const theme = useTheme()
    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
    const sideBarWidth = sideBarOpened ? "var(--sidebar-width)" : "var(--sidebar-width-compact)"
    const titleLeftPadding = isMobile ? 0 : sideBarWidth

    return (
        <Header className={className}>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between" flexGrow={1}>
                <Stack
                    justifyContent="flex-start"
                    justifyItems="flex-start"
                    className="animate-left"
                    paddingInlineStart={titleLeftPadding}
                >
                    {backLink && !isShared && (
                        <span>
                            <BackLink urlOrFunction={backLink} />
                        </span>
                    )}
                    <Stack direction="row" alignItems="center" gap={1} className="title">
                        {icon}

                        <Typography variant="h1" color="primary">
                            {title}
                        </Typography>
                        {mainAction}
                    </Stack>
                </Stack>
                {children}
            </Stack>
        </Header>
    )
}
