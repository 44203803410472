import { Badge, Box, Grid, IconButton, InputBase, Paper, Stack, Tooltip } from "@mui/material"
import classNames from "classnames"
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react"
import { Edit, Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { CopilotPromptSuggestions } from "~/domains/orchestration/copilot/components/CopilotPromptSuggestions"
import { CopilotSpeechToText } from "~/domains/orchestration/copilot/components/CopilotSpeechToText"
import { CopilotUploadDocument } from "~/domains/orchestration/copilot/components/CopilotUploadDocument"
import { Conversation } from "~/domains/orchestration/copilot/types/Copilot"

const messages = defineMessages({
    askQuestion: {
        id: "copilot.askQuestion",
        defaultMessage: "Ask a question or get some help",
    },
    newConversation: {
        id: "copilot.newConversation",
        defaultMessage: "Start a new conversation",
    },
})

interface CopilotPromptBarProps {
    displayMenu: boolean
    file: File | undefined
    conversation: Conversation[]
    message: string
    handleChangeMessage: Dispatch<SetStateAction<string>>
    handleUpload: (file: File) => void
    handlePressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void
    handleSendMessage: () => void
    setTextToSpeechEnabled: (value: boolean) => void
    handleNewConversation: () => void
}

export const CopilotPromptBar: FC<CopilotPromptBarProps> = ({
    displayMenu,
    file,
    conversation,
    message,
    handleChangeMessage,
    handleUpload,
    handlePressEnter,
    handleSendMessage,
    handleNewConversation,
    setTextToSpeechEnabled,
}) => {
    const { formatMessage } = useIntl()

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => handleChangeMessage(event.currentTarget.value)

    return (
        <Box
            className={classNames("prompt-container", {
                "sidebar-opened": displayMenu,
                centered: !conversation.length,
            })}
            rowGap={3}
        >
            <Stack direction="row" gap={3}>
                <Paper className="prompt-container-form" sx={{ flexGrow: 1, display: "flex" }}>
                    <Badge color="secondary" badgeContent={file ? 1 : 0}>
                        <CopilotUploadDocument onUpload={handleUpload} />
                    </Badge>
                    <InputBase
                        required
                        multiline
                        id="message"
                        name="message"
                        placeholder={formatMessage(messages.askQuestion)}
                        onChange={onInputChange}
                        value={message}
                        onKeyDown={handlePressEnter}
                        sx={{ flex: 1 }}
                    />

                    <CopilotSpeechToText
                        handleChangeMessage={handleChangeMessage}
                        handleSendMessage={handleSendMessage}
                        setTextToSpeechEnabled={setTextToSpeechEnabled}
                    />

                    <IconButton color="primary" onClick={handleSendMessage}>
                        <Send />
                    </IconButton>
                </Paper>
                <Grid item className="prompt-container-form" display="flex">
                    <Tooltip title={<SafeFormattedMessage {...messages.newConversation} />}>
                        <IconButton onClick={handleNewConversation}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Stack>
            {!conversation.length && <CopilotPromptSuggestions setMessage={handleChangeMessage} />}
        </Box>
    )
}
