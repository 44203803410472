import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, UpdateCustomFieldNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const UpdateCustomFieldConfiguration: FC<ConfigurationProps<UpdateCustomFieldNode>> = ({
    selectedNode,
    advancedFields,
    validateNode,
}) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    const handleCustomFieldIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            customFieldId: e.target.value,
        }))
    }

    const handleCustomFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            value: e.target.value,
        }))
    }

    return (
        <ConfigurationNode selectedNode={currentNode} validateNode={validateNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <TextField
                    fullWidth
                    label={formatMessage(messages.updateCustomFieldConfiguration.customFieldIdLabel)}
                    value={currentNode.customFieldId}
                    onChange={handleCustomFieldIdChange}
                />

                <TextField
                    fullWidth
                    label={formatMessage(messages.updateCustomFieldConfiguration.customFieldValueLabel)}
                    value={currentNode.value}
                    onChange={handleCustomFieldValueChange}
                />
            </Stack>

            <AdvancedFields<UpdateCustomFieldNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
