import { Edit } from "react-feather"

import { SetPoStatusConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    Node,
    NodeType,
    SetPoStatusNode as SetPoStatusNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const setPoStatusNode: Omit<SetPoStatusNodeType, keyof Node> = {
    purchaseOrderId: "{{ trigger_event.id }}",
    statusToSet: null,
    type: NodeType.SET_PO_STATUS_NODE,
    nextNode: null,
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.PURCHASE_ORDER_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.SET_PO_STATUS_NODE) return false
    return Boolean(node.purchaseOrderId && node.statusToSet)
}

const setPoStatusConfig: Configuration<NodeType.SET_PO_STATUS_NODE> = {
    type: NodeType.SET_PO_STATUS_NODE,
    baseSlug: "set-po-status",
    Icon: Edit,
    Configuration: SetPoStatusConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): SetPoStatusNodeType => ({
        ...setPoStatusNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { setPoStatusConfig, advancedFields as setPoStatusAdvancedFields }
