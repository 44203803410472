import { PurchaseOrderProgressStatus } from "~/domains/transactions/purchase-orders/types"

export type StatusesPurchaseOrder = {
    name: PurchaseOrderProgressStatus
    type: string
    adjacency: string[]
    position: number
    actions: ("receive" | "edit" | "approve_client" | "approve_supplier" | "send" | "delete" | "communicate")[]
}

export const statusesPurchaseOrder: Record<PurchaseOrderProgressStatus, StatusesPurchaseOrder> = {
    DRAFT: {
        name: "DRAFT",
        adjacency: ["SUBMITTED"],
        actions: ["edit", "delete", "communicate"],
        type: "status",
        position: 0,
    },
    SUBMITTED: {
        name: "SUBMITTED",
        adjacency: ["INTERNALLY_APPROVED", "OPEN"],
        actions: ["edit", "delete", "approve_client", "communicate"],
        type: "status",
        position: 1,
    },
    INTERNALLY_APPROVED: {
        name: "INTERNALLY_APPROVED",
        adjacency: ["OPEN"],
        actions: ["edit", "delete", "send", "communicate"],
        type: "status",
        position: 2,
    },
    OPEN: {
        name: "OPEN",
        adjacency: ["CLOSED"],
        actions: ["communicate", "approve_supplier"],
        type: "status",
        position: 3,
    },
    PENDING: {
        name: "PENDING",
        type: "fulfilment",
        adjacency: ["PENDING", "IN_PREPARATION", "SHIPPED", "RECEIVED", "OUT_FOR_DELIVERY", "CANCELED"],
        actions: ["edit", "delete", "communicate"],
        position: 4,
    },
    IN_PREPARATION: {
        name: "IN_PREPARATION",
        type: "fulfilment",
        adjacency: ["PENDING", "IN_PREPARATION", "SHIPPED", "RECEIVED", "OUT_FOR_DELIVERY", "CANCELED"],
        actions: ["receive", "edit", "delete", "communicate"],
        position: 5,
    },
    SHIPPED: {
        name: "SHIPPED",
        type: "fulfilment",
        adjacency: ["PENDING", "IN_PREPARATION", "SHIPPED", "RECEIVED", "OUT_FOR_DELIVERY", "CANCELED"],
        actions: ["receive", "edit", "delete", "communicate"],
        position: 6,
    },
    PARTIALLY_RECEIVED: {
        name: "PARTIALLY_RECEIVED",
        type: "fulfilment",
        adjacency: ["PENDING", "IN_PREPARATION", "SHIPPED", "RECEIVED", "OUT_FOR_DELIVERY", "CANCELED"],
        actions: ["edit", "delete", "communicate"],
        position: 7,
    },
    RECEIVED: {
        name: "RECEIVED",
        type: "fulfilment",
        adjacency: ["PENDING", "IN_PREPARATION", "SHIPPED", "RECEIVED", "OUT_FOR_DELIVERY", "CANCELED"],
        actions: ["edit", "delete", "communicate"],
        position: 8,
    },
    CANCELED: {
        name: "CANCELED",
        type: "fulfilment",
        adjacency: ["PENDING", "IN_PREPARATION", "SHIPPED", "RECEIVED", "OUT_FOR_DELIVERY", "CANCELED"],
        actions: ["edit", "delete", "communicate"],
        position: 9,
    },
    CLOSED: {
        name: "CLOSED",
        adjacency: ["OPEN"],
        actions: ["communicate"],
        type: "status",
        position: 9,
    },
    INVOICED: {
        name: "INVOICED",
        adjacency: ["OPEN"],
        actions: ["communicate"],
        type: "status",
        position: 9,
    },
}
