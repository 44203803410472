/* eslint-disable max-lines */
import { XYPosition } from "@xyflow/react"
import groupBy from "lodash.groupby"

import { TRANSACTION_TYPES } from "~/domains/orchestration/flows/constants"
import {
    ApiAddToBudgetNode,
    ApiApprovePurchaseOrderLineNode,
    ApiApprovePurchaseOrderNode,
    ApiApprovePurchaseRequestLineNode,
    ApiApprovePurchaseRequestNode,
    ApiAssignTagNode,
    ApiBranch,
    ApiBranchNode,
    ApiCheckNode,
    ApiConvertPrToPoNode,
    ApiCreateCustomFieldNode,
    ApiCreateSurveyNode,
    ApiCreateTaskNode,
    ApiEventTriggerNode,
    ApiFetchCustomFieldsNode,
    ApiFetchPartnershipNode,
    ApiFitsToBudgetNode,
    ApiFlow,
    ApiFlowItem,
    ApiFlowNode,
    ApiFlows,
    ApiFulfillmentStatus,
    ApiGetTagByGroupNode,
    ApiIfNode,
    ApiInvoiceToPurchaseOrderMatchNode,
    ApiMappingNode,
    ApiMappingNodeElement,
    ApiNodeType,
    ApiRefusePurchaseOrderNode,
    ApiRefusePurchaseRequestNode,
    ApiRetractReviewsNode,
    ApiReviewer,
    ApiRun,
    ApiRuns,
    ApiSendEmailNode,
    ApiSetInvoiceLifecycleStatusNode,
    ApiSetPartnershipFieldNode,
    ApiSetPaymentMethodDetailsFieldNode,
    ApiSetPoFulfillmentStatusNode,
    ApiSetPoStatusNode,
    ApiSurveyRespondent,
    ApiSuspendUntilSurveyCompletedNode,
    ApiTaskPriority,
    ApiTaskStatus,
    ApiUpdateCustomFieldNode,
    ApiUpdateTripletexLedgerNode,
    AssignTagGroupNode,
    AssignTagNode,
    Branch,
    CreateTaskNode,
    EditorNode,
    EmailRespondent,
    Event,
    Flow,
    FlowId,
    FlowNode,
    Flows,
    MappingNode,
    MappingNodeElement,
    Node,
    NodeType,
    ObjectType,
    RespondentType,
    Reviewer,
    Run,
    RunId,
    RunStatus,
    Runs,
    TaskPriority,
    TaskStatus,
    TeamReviewer,
    UserRespondent,
    UserReviewer,
    UserType,
    isPoStatus,
    isPosition,
} from "~/domains/orchestration/flows/types"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types"

const adaptMetadata = (metadata: Record<string, unknown>) => {
    const defaultPosition: XYPosition = { x: 0, y: 0 }

    return {
        position: isPosition(metadata?.position) ? metadata.position : defaultPosition,
        additionalInformation: metadata?.additionalInformation as string | undefined,
        group: metadata?.group as { id: string; name: string } | undefined,
    }
}

const adaptReviewerFromApi = (reviewer: ApiReviewer): Reviewer => {
    if ("userId" in reviewer) {
        return {
            userId: reviewer.userId,
            type: UserType.USER,
        } as UserReviewer
    }
    return {
        teamId: reviewer.teamId,
        type: UserType.TEAM,
    } as TeamReviewer
}

const adaptCommonNodeProperties = (node: ApiFlowNode): Node => {
    return {
        slug: node.slug,
        name: node.name || "",
        metadata: adaptMetadata(node.metadata),
        error: false,
    }
}

const adaptExpressionFromApi = (value?: string) => {
    if (!value) return ""
    if (value.startsWith("'") && value.endsWith("'")) {
        return value.slice(1, -1).replace(/\\/g, "")
    }
    return value
}

const adaptBranchFromApi = (branch: ApiBranch): Branch => {
    return {
        name: branch.name,
        nextNode: branch.nextNode || null,
        conditions: branch.conditions.filter((condition) => condition.length > 0),
    }
}

const adaptTransactionTypeFromApi = (value: string) => {
    const adaptedValue = adaptExpressionFromApi(value)
    const transactionType = TRANSACTION_TYPES.find((type) => type === adaptedValue)
    return transactionType || null
}

const adaptEventTriggerNode = (node: ApiEventTriggerNode) => ({
    ...adaptCommonNodeProperties(node),
    event: node.event as unknown as Event,
    type: node.type as unknown as NodeType.EVENT_TRIGGER_NODE,
    filter: node.filter ? node.filter : { conditions: [] },
    nextNode: node.nextNode || null,
})

const adaptIfNode = (node: ApiIfNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.IF_NODE,
    condition: node.condition,
    nextIfSuccess: node.nextIfTrue || null,
    nextIfFailure: node.nextIfFalse || null,
})

const adaptSetPartnershipFieldNode = (node: ApiSetPartnershipFieldNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.SET_PARTNERSHIP_FIELD_NODE,
    partnershipId: node.partnershipId,
    fieldToUpdate: node.fieldToUpdate,
    valueToSet: node.valueToSet,
    nextNode: node.nextNode || null,
})

const adaptCheckNode = (node: ApiCheckNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.CHECK_NODE,
    objectId: node.objectId,
    objectType: node.objectType as unknown as ObjectType,
    reviewers: (node.reviewers || []).map(adaptReviewerFromApi),
    passThreshold: node.passThreshold,
    refuseThreshold: node.refuseThreshold,
    nextIfSuccess: node.nextIfPassed || null,
    nextIfFailure: node.nextIfRefused || null,
})

const adaptSendEmailNode = (node: ApiSendEmailNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.SEND_EMAIL_NODE,
    subject: node.subject,
    recipientAddresses: node.recipientAddresses,
    body: adaptExpressionFromApi(node.body),
    nextNode: node.nextNode || null,
})

const adaptAddToBudgetNode = (node: ApiAddToBudgetNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.ADD_TO_BUDGET_NODE,
    amount: node.amount,
    budgetId: adaptExpressionFromApi(node.budgetId),
    transactionId: node.transactionId,
    transactionType: adaptTransactionTypeFromApi(node.transactionType),
    failIfOverbudget: node.failIfOverbudget,
    currency: node.currency || "",
    nextNode: node.nextNode || null,
})

const adaptInvoiceToPurchaseOrderMatchNode = (node: ApiInvoiceToPurchaseOrderMatchNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE,
    invoiceId: node.invoiceId,
    nextIfSuccess: node.nextIfMatched || null,
    nextIfFailure: node.nextIfNotMatched || null,
    metadata: adaptMetadata(node.metadata),
})

const adaptSetInvoiceLifecycleStatusNode = (node: ApiSetInvoiceLifecycleStatusNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE,
    invoiceId: node.invoiceId,
    statusToSet: node.statusToSet,
    nextNode: node.nextNode || null,
})

const adaptFitsToBudgetNode = (node: ApiFitsToBudgetNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.FITS_TO_BUDGET_NODE,
    budgetId: adaptExpressionFromApi(node.budgetId),
    transactionId: node.transactionId,
    transactionType: adaptTransactionTypeFromApi(node.transactionType),
    amount: node.amount,
    currency: node.currency,
    nextIfSuccess: node.nextIfFits || null,
    nextIfFailure: node.nextIfDoesNotFit || null,
})

const adaptAssignTagNode = (node: ApiAssignTagNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.ASSIGN_TAG_NODE,
    objectId: node.objectId,
    objectType: node.objectType as unknown as ObjectType,
    tagId: node.tagId,
    nextNode: node.nextNode || null,
})

const adaptUpdateTripletexLedgerNode = (node: ApiUpdateTripletexLedgerNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.UPDATE_TRIPLETEX_LEDGER_NODE,
    ledgerId: node.ledgerId,
    ledgerDate: node.ledgerDate || "",
    ledgerDescription: adaptExpressionFromApi(node.ledgerDescription || ""),
    accountToCredit: node.accountToCredit,
    accountToDebit: node.accountToDebit,
    amount: node.amount,
    amountGross: node.amountGross,
    currency: node.currency,
    nextNode: node.nextNode || null,
})

const adaptApprovePurchaseOrderNode = (node: ApiApprovePurchaseOrderNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.APPROVE_PURCHASE_ORDER_NODE,
    purchaseOrderId: node.purchaseOrderId,
    nextNode: node.nextNode || null,
})

const adaptApprovePurchaseOrderLineNode = (node: ApiApprovePurchaseOrderLineNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE,
    purchaseOrderLineId: node.purchaseOrderLineId,
    purchaseOrderId: node.purchaseOrderId,
    nextNode: node.nextNode || null,
})

const adaptConvertPrToPoNode = (node: ApiConvertPrToPoNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.CONVERT_PR_TO_PO_NODE,
    purchaseRequestId: node.purchaseRequestId,
    nextNode: node.nextNode || null,
})

const adaptApprovePurchaseRequestNode = (node: ApiApprovePurchaseRequestNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.APPROVE_PURCHASE_REQUEST_NODE,
    purchaseRequestId: node.purchaseRequestId,
    nextNode: node.nextNode || null,
})

const adaptApprovePurchaseRequestLineNode = (node: ApiApprovePurchaseRequestLineNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE,
    purchaseRequestId: node.purchaseRequestId,
    purchaseRequestLineId: node.purchaseRequestLineId,
    nextNode: node.nextNode || null,
})

const adaptBranchNode = (node: ApiBranchNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.BRANCH_NODE,
    branches: node.branches.filter((branch) => branch.conditions.length > 0).map(adaptBranchFromApi),
    default: node.default,
})

const adaptSetPaymentMethodDetailsFieldNode = (node: ApiSetPaymentMethodDetailsFieldNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE,
    paymentMethodDetailsId: node.paymentMethodDetailsId,
    fieldToUpdate: node.fieldToUpdate,
    valueToSet: node.valueToSet,
    nextNode: node.nextNode || null,
})

const adaptSurveyRespondentsFromApi = (respondents?: ApiSurveyRespondent[]) => {
    if (!respondents) return []

    const adaptedRespondents: (EmailRespondent | UserRespondent)[] = []

    for (const respondent of respondents) {
        if (respondent.type === RespondentType.EMAIL) {
            adaptedRespondents.push({ type: RespondentType.EMAIL, emailAddress: respondent.emailAddress })
        } else if (respondent.type === RespondentType.USER) {
            adaptedRespondents.push({ type: RespondentType.USER, userId: respondent.userId })
        }
    }

    return adaptedRespondents
}

const adaptCreateSurveyNode = (node: ApiCreateSurveyNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.CREATE_SURVEY_NODE,
    formId: adaptExpressionFromApi(node.formId),
    respondentOrganizationId: node.respondentOrganizationId || null,
    sendNotifications: node.sendNotifications ?? false,
    respondents: adaptSurveyRespondentsFromApi(node.respondents),
    nextNode: node.nextNode || null,
})

const adaptRetractReviewsNode = (node: ApiRetractReviewsNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.RETRACT_REVIEWS_NODE,
    objectId: node.objectId,
    objectType: node.objectType as unknown as ObjectType,
    nextNode: node.nextNode || null,
})

const adaptFetchCustomFieldsNode = (node: ApiFetchCustomFieldsNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.FETCH_CUSTOM_FIELDS_NODE,
    objectId: node.objectId,
    nextNode: node.nextNode || null,
})

const adaptMappingNodeElementToApi = (element: ApiMappingNodeElement): MappingNodeElement => ({
    label: element.label,
    value: element.value === "''" ? "" : element.value,
})

const adaptMappingNode = (node: ApiMappingNode): MappingNode => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.MAPPING_NODE,
    valueToMap: node.valueToMap,
    mappingTable: Object.fromEntries(
        Object.entries(node.mappingTable).map(([key, value]) => [key, value.map(adaptMappingNodeElementToApi)])
    ),
    defaultValues: node.defaultValues.map(adaptMappingNodeElementToApi),
    nextNode: node.nextNode || null,
})

const adaptGetTagByGroupNode = (node: ApiGetTagByGroupNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.GET_TAG_BY_GROUP_NODE,
    objectId: node.objectId,
    tagGroupId: adaptExpressionFromApi(node.tagGroupId),
    nextNode: node.nextNode || null,
})

const adaptUpdateCustomFieldNode = (node: ApiUpdateCustomFieldNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.UPDATE_CUSTOM_FIELD_NODE,
    customFieldId: node.customFieldId,
    value: node.value,
    nextNode: node.nextNode || null,
})

const adaptTaskPriorityFromApi = (priority?: ApiTaskPriority): TaskPriority | undefined => {
    switch (priority) {
        case ApiTaskPriority.LOW:
            return TaskPriority.LOW
        case ApiTaskPriority.MEDIUM:
            return TaskPriority.MEDIUM
        case ApiTaskPriority.HIGH:
            return TaskPriority.HIGH
        case ApiTaskPriority.URGENT:
            return TaskPriority.URGENT
    }
}

const adaptTaskStatusFromApi = (status?: ApiTaskStatus): TaskStatus | undefined => {
    switch (status) {
        case ApiTaskStatus.PENDING:
            return TaskStatus.PENDING
        case ApiTaskStatus.IN_PROGRESS:
            return TaskStatus.IN_PROGRESS
        case ApiTaskStatus.COMPLETED:
            return TaskStatus.COMPLETED
    }
}

const adaptCreateTaskNode = (node: ApiCreateTaskNode): CreateTaskNode => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.CREATE_TASK_NODE,
    title: adaptExpressionFromApi(node.title),
    description: adaptExpressionFromApi(node.description),
    dueDate: node.dueDate,
    priority: adaptTaskPriorityFromApi(node.priority),
    status: adaptTaskStatusFromApi(node.status),
    assignee: adaptExpressionFromApi(node.assignee),
    parentTaskId: node.parentTaskId || "",
    public: Boolean(node.public),
    followers: node.followers?.map(adaptExpressionFromApi) || [],
    parties: node.parties?.map(adaptExpressionFromApi) || [],
    nextNode: node.nextNode || null,
})

const adaptFetchPartnershipNode = (node: ApiFetchPartnershipNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.FETCH_PARTNERSHIP_NODE,
    partnerId: node.partnerId,
    nextNode: node.nextNode || null,
})

const adaptCreateCustomFieldNode = (node: ApiCreateCustomFieldNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.CREATE_CUSTOM_FIELD_NODE,
    objectId: node.objectId,
    customFieldName: node.customFieldName,
    customFieldValue: node.customFieldValue,
    nextNode: node.nextNode || null,
})

const adaptFulfillmentStatusFromApi = (status?: ApiFulfillmentStatus): FulfillmentStatus | null => {
    switch (status) {
        case ApiFulfillmentStatus.IN_PREPARATION:
            return "IN_PREPARATION"
        case ApiFulfillmentStatus.OUT_FOR_DELIVERY:
            return "OUT_FOR_DELIVERY"
        case ApiFulfillmentStatus.DELIVERED:
            return "DELIVERED"
        case ApiFulfillmentStatus.CANCELED:
            return "CANCELED"
        default:
            return null
    }
}
const adaptSetPoFulfillmentStatusNode = (node: ApiSetPoFulfillmentStatusNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.SET_PO_FULFILLMENT_STATUS_NODE,
    purchaseOrderId: node.purchaseOrderId,
    statusToSet: adaptFulfillmentStatusFromApi(node.statusToSet),
    nextNode: node.nextNode || null,
})

const adaptSuspendUntilSurveyCompletedNode = (node: ApiSuspendUntilSurveyCompletedNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE,
    surveyId: node.surveyId,
    nextNode: node.nextNode || null,
})

const adaptSetPoStatusNode = (node: ApiSetPoStatusNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.SET_PO_STATUS_NODE,
    purchaseOrderId: node.purchaseOrderId,
    statusToSet: isPoStatus(node.statusToSet) ? node.statusToSet : null,
    nextNode: node.nextNode || null,
})

const adaptRefusePurchaseRequestNode = (node: ApiRefusePurchaseRequestNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.REFUSE_PURCHASE_REQUEST_NODE,
    purchaseRequestId: node.purchaseRequestId,
    nextNode: node.nextNode || null,
})

const adaptRefusePurchaseOrderNode = (node: ApiRefusePurchaseOrderNode) => ({
    ...adaptCommonNodeProperties(node),
    type: node.type as unknown as NodeType.REFUSE_PURCHASE_ORDER_NODE,
    purchaseOrderId: node.purchaseOrderId,
    nextNode: node.nextNode || null,
})

const adaptNodeFromApi = (node: ApiFlowNode): FlowNode | null => {
    const adapters: Record<ApiNodeType, (node: never) => FlowNode> = {
        [ApiNodeType.EVENT_TRIGGER_NODE]: adaptEventTriggerNode,
        [ApiNodeType.IF_NODE]: adaptIfNode,
        [ApiNodeType.SET_PARTNERSHIP_FIELD_NODE]: adaptSetPartnershipFieldNode,
        [ApiNodeType.CHECK_NODE]: adaptCheckNode,
        [ApiNodeType.SEND_EMAIL_NODE]: adaptSendEmailNode,
        [ApiNodeType.ADD_TO_BUDGET_NODE]: adaptAddToBudgetNode,
        [ApiNodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: adaptInvoiceToPurchaseOrderMatchNode,
        [ApiNodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: adaptSetInvoiceLifecycleStatusNode,
        [ApiNodeType.FITS_TO_BUDGET_NODE]: adaptFitsToBudgetNode,
        [ApiNodeType.ASSIGN_TAG_NODE]: adaptAssignTagNode,
        [ApiNodeType.UPDATE_TRIPLETEX_LEDGER_NODE]: adaptUpdateTripletexLedgerNode,
        [ApiNodeType.APPROVE_PURCHASE_ORDER_NODE]: adaptApprovePurchaseOrderNode,
        [ApiNodeType.APPROVE_PURCHASE_ORDER_LINE_NODE]: adaptApprovePurchaseOrderLineNode,
        [ApiNodeType.CONVERT_PR_TO_PO_NODE]: adaptConvertPrToPoNode,
        [ApiNodeType.APPROVE_PURCHASE_REQUEST_NODE]: adaptApprovePurchaseRequestNode,
        [ApiNodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE]: adaptApprovePurchaseRequestLineNode,
        [ApiNodeType.BRANCH_NODE]: adaptBranchNode,
        [ApiNodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE]: adaptSetPaymentMethodDetailsFieldNode,
        [ApiNodeType.CREATE_SURVEY_NODE]: adaptCreateSurveyNode,
        [ApiNodeType.RETRACT_REVIEWS_NODE]: adaptRetractReviewsNode,
        [ApiNodeType.FETCH_CUSTOM_FIELDS_NODE]: adaptFetchCustomFieldsNode,
        [ApiNodeType.MAPPING_NODE]: adaptMappingNode,
        [ApiNodeType.GET_TAG_BY_GROUP_NODE]: adaptGetTagByGroupNode,
        [ApiNodeType.UPDATE_CUSTOM_FIELD_NODE]: adaptUpdateCustomFieldNode,
        [ApiNodeType.CREATE_TASK_NODE]: adaptCreateTaskNode,
        [ApiNodeType.FETCH_PARTNERSHIP_NODE]: adaptFetchPartnershipNode,
        [ApiNodeType.CREATE_CUSTOM_FIELD_NODE]: adaptCreateCustomFieldNode,
        [ApiNodeType.SET_PO_FULFILLMENT_STATUS_NODE]: adaptSetPoFulfillmentStatusNode,
        [ApiNodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE]: adaptSuspendUntilSurveyCompletedNode,
        [ApiNodeType.SET_PO_STATUS_NODE]: adaptSetPoStatusNode,
        [ApiNodeType.REFUSE_PURCHASE_REQUEST_NODE]: adaptRefusePurchaseRequestNode,
        [ApiNodeType.REFUSE_PURCHASE_ORDER_NODE]: adaptRefusePurchaseOrderNode,
    }

    const adapter = adapters[node.type]
    if (!adapter) return null
    return adapter(node as never)
}

// Adapt ApiFlow to Flow

export const adaptFlowFromApi = (flow: ApiFlow): Flow => {
    // Add group to assign tag nodes
    const convertedNodes = flow.nodes.map((node) => {
        if (node.type !== ApiNodeType.ASSIGN_TAG_NODE || node.metadata?.group) return node

        return {
            ...node,
            metadata: {
                ...node.metadata,
                group: {
                    id: node.slug,
                    name: node.name || "",
                },
            },
        }
    })

    // Find and create groups
    // will find all nodes with the same groupId and create a group
    // will remove the nodes from the flow
    // will add the group node to the flow

    const groups = groupBy(
        convertedNodes.filter((node) => node.metadata?.group?.id),
        (node: ApiFlowNode) => node.metadata?.group?.id
    )

    // TEMP : For now, we only support assign tag group node
    const groupNodes: AssignTagGroupNode[] = Object.entries<ApiFlowNode[]>(groups).map(([groupId, nodes]) => {
        const adaptedNodes = nodes.map(adaptNodeFromApi).filter(Boolean) as unknown as AssignTagNode[]

        return {
            name: nodes[0].metadata?.group?.name || "",
            slug: groupId,
            nodes: adaptedNodes,
            type: NodeType.ASSIGN_TAG_GROUP_NODE,
            objectId: adaptedNodes[0].objectId,
            objectType: adaptedNodes[0].objectType,
            nextNode: adaptedNodes.at(-1)?.nextNode || null,
            error: false,
            metadata: {
                position: nodes[0].metadata?.position,
                additionalInformation: nodes[0].metadata?.additionalInformation,
            },
        }
    })

    const nodesWithoutGroups = convertedNodes
        .filter((node) => !node.metadata?.group?.id)
        .map(adaptNodeFromApi)
        .filter(Boolean) as EditorNode[]

    const nodes = [...nodesWithoutGroups, ...groupNodes]

    return {
        id: flow.id as FlowId,
        version: flow.version,
        name: flow.name,
        enabled: flow.enabled,
        archived: flow.archived,
        nodes,
        author: flow.author,
    }
}

// Adapt ApiFlows to Flows
export const adaptFlowsFromApi = (flow: ApiFlows): Flows => {
    return {
        flows: flow.flows.map((f: ApiFlowItem) => ({
            id: f.id as FlowId,
            version: f.version,
            name: f.name,
            enabled: f.enabled,
            archived: f.archived,
            createdAt: f.createdAt,
        })),
    }
}

// Adapt ApiRuns to Runs
export const adaptRunsFromApi = (run: ApiRuns): Runs => {
    return {
        runs: run.runs.map((r) => ({
            id: r.id as RunId,
            status: r.status as unknown as RunStatus,
            startedAt: r.startedAt,
            finishedAt: r.finishedAt || null,
        })),
    }
}

// Adapt ApiRun to Run
export const adaptRunFromApi = (run: ApiRun): Run => {
    return {
        id: run.id as RunId,
        flowId: run.flowId as FlowId,
        flowVersion: run.flowVersion,
        status: run.status as unknown as RunStatus,
        state: {
            pathTaken: run.state.pathTaken,
            storage: run.state.storage,
        },
        error: run.error,
        startedAt: run.startedAt,
        finishedAt: run.finishedAt || null,
    }
}
