import { Tooltip } from "@mui/material"
import { X } from "react-feather"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types"

interface CancelEditPOProps {
    PO: PurchaseOrders
    display?: "button" | "menu-item"
}

export const CancelEditPO = ({ PO, display = "button" }: CancelEditPOProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const handleCancelEdit = () => {
        navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: PO.id }))
    }

    return display === "button" ? (
        <Tooltip title={formatMessage(commonMessages.edit)}>
            <Button type="grey" onClick={handleCancelEdit}>
                <X size={16} />
                <SafeFormattedMessage {...commonMessages.cancel} />
            </Button>
        </Tooltip>
    ) : (
        <Button type="menu-item" onClick={handleCancelEdit}>
            <X size={16} />
            <SafeFormattedMessage {...commonMessages.cancel} />
        </Button>
    )
}
