import { Filter } from "react-feather"

import { IfConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { IfNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { EditorNode, IfNode as IfNodeType, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const ifNode: Omit<IfNodeType, keyof Node> = {
    condition: ``,
    nextIfSuccess: null,
    nextIfFailure: null,
    type: NodeType.IF_NODE,
}

const advancedFields: (keyof typeof ifNode)[] = []

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.IF_NODE) return false
    return Boolean(node.condition?.trim())
}

const ifConfig: Configuration<NodeType.IF_NODE> = {
    type: NodeType.IF_NODE,
    baseSlug: "if",
    Icon: Filter,
    Configuration: IfConfiguration,
    advancedFields,
    Node: IfNode,
    factory: (props: FactoryProps): IfNodeType => ({
        ...ifNode,
        ...createCommonNodeProperties(props),
    }),
    validateNode,
}

export { ifConfig, advancedFields as ifAdvancedFields }
