import {
    Autocomplete,
    AutocompleteOwnerState,
    AutocompleteRenderGroupParams,
    AutocompleteRenderOptionState,
    Box,
    TextField,
} from "@mui/material"
import React, { SyntheticEvent } from "react"

interface Props<T> {
    options: T[]
    selectedEntities: T[]
    setSelectedEntities: (selected: T[]) => void
    numberOfTagsToShow?: number
    width?: string
    getOptionLabel: (option: T) => string
    isOptionEqualToValue: (option: T, value: T) => boolean
    placeholder?: string
    classname?: string
    disabled?: boolean
    groupBy?: (option: T) => string
    renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode
    renderOption?: (
        props: any,
        option: T,
        state: AutocompleteRenderOptionState,
        ownerState: AutocompleteOwnerState<T, true, false, false, "div">
    ) => React.ReactNode
    isChips?: boolean
}

export const AutocompleteWithTags = <T,>({
    options,
    selectedEntities,
    setSelectedEntities,
    numberOfTagsToShow = 2,
    width = "100%",
    getOptionLabel,
    isOptionEqualToValue,
    placeholder,
    classname,
    disabled,
    groupBy,
    renderGroup,
    renderOption,
    isChips,
}: Props<T>) => {
    const handleChange = (_event: SyntheticEvent<Element, Event>, value: T[]) => {
        setSelectedEntities(value)
    }

    const renderTagsHandler = (values: T[]) => {
        const additionalTagsCount = values.length - numberOfTagsToShow
        const displayedValue = values.slice(0, numberOfTagsToShow).map(getOptionLabel).join(", ")

        return additionalTagsCount > 0 ? `${displayedValue} +${additionalTagsCount}` : displayedValue
    }

    const renderOptionHandler = (
        props: any,
        option: T,
        _state: AutocompleteRenderOptionState,
        ownerState: AutocompleteOwnerState<T, true, false, false, "div">
    ) => {
        const optionId =
            option && typeof option === "object" && "id" in option ? option.id : ownerState.getOptionLabel(option)
        return (
            <Box component="li" {...props} key={`${optionId}`}>
                {ownerState.getOptionLabel(option)}
            </Box>
        )
    }

    return (
        <Autocomplete
            className={classname}
            multiple
            autoComplete
            options={options}
            getOptionLabel={getOptionLabel}
            value={selectedEntities}
            onChange={handleChange}
            fullWidth={false}
            limitTags={numberOfTagsToShow}
            renderTags={isChips ? undefined : renderTagsHandler}
            style={{ width, zIndex: 11 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={!placeholder || selectedEntities.length ? undefined : placeholder}
                    variant="outlined"
                />
            )}
            renderOption={renderOption ?? renderOptionHandler}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
            groupBy={groupBy}
            renderGroup={renderGroup}
            size="small"
        />
    )
}
