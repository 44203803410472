import { Tooltip } from "@mui/material"
import { ArrowDownCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button } from "~/components"
import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"

const messages = defineMessages({
    button: { id: "buyer.invoice.actions.button.request", defaultMessage: "Request update" },
    tooltip: {
        id: "buyer.invoice.actions.button.request.tooltip",
        defaultMessage: "You need more information about this invoice? Send a request to your supplier!",
    },
})

interface RequestChangeInvoiceProps {
    handleRequest: () => void
}

export const RequestChangeInvoice = ({ handleRequest }: RequestChangeInvoiceProps) => {
    const { formatMessage } = useIntl()

    return (
        <Tooltip title={formatMessage(messages.tooltip)} arrow>
            <div>
                <Button onClick={handleRequest} type="menu-item">
                    <ArrowDownCircle size={16} />
                    <SafeFormattedMessage {...messages.button} />
                </Button>
            </div>
        </Tooltip>
    )
}
