/* eslint-disable @typescript-eslint/no-shadow */
import { Stack } from "@mui/material"
import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from "react"
import { defineMessage } from "react-intl"

import { SafeFormattedMessage, SkeletonInput } from "~/components"
import { LightButton } from "~/components/LightButton"
import { registrationNumberIsPrefered } from "~/domains/identity/account/components/Company/FoundOrganization"
import { CompanyAutocomplete, CompanyResult } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { PartnerOrganizationI } from "~/domains/identity/partners/types"
import { CompanyI, CountryCode, ImportInvoiceCompanyInfoI, OrganizationI, WhitePagesIdTypes } from "~/types"

import { ModalOrganizationSelectorDetails } from "../ModalOrganizationSelectorDetails"
import { SavePayloadType } from "../ModalOrganizationSelectorDetails/types"

const editMessage = defineMessage({
    id: "company.autocomplete.EditOrganization",
    defaultMessage: "Edit",
})

export interface BaseProps extends PropsWithChildren {
    label: ReactNode
    company?: CompanyResult
    suggestedCompanies: CompanyI[] | undefined
    countryCode: CountryCode
    onCountryCodeChange: (countryCode: CountryCode) => void
    groupLabel?: Partial<Record<CompanyAutocompleteType, ReactNode>>
    updateData?: (payload: Partial<ImportInvoiceCompanyInfoI>) => void
    dataLoaded?: boolean
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    onSave: (payload: SavePayloadType) => void
}

interface Props extends BaseProps {
    organizations: (OrganizationI | PartnerOrganizationI)[]
}

export const OrganizationSelector: React.FC<Props> = ({
    label,
    company: initialCompany,
    organizations,
    suggestedCompanies,
    countryCode,
    onCountryCodeChange,
    groupLabel,
    children,
    updateData,
    dataLoaded = true,
    withOrganizationAddress,
    withSendingAddress,
    onSave,
}) => {
    const [company, setCompany] = useState<CompanyResult | undefined>()
    const [displayEditModal, setDisplayEditModal] = useState<boolean>(false)

    const showEditModal = useCallback(() => setDisplayEditModal(true), [])
    const hideEditModal = useCallback(() => setDisplayEditModal(false), [])

    useEffect(() => {
        if (initialCompany) {
            setCompany(initialCompany)
        }
    }, [initialCompany])

    useEffect(() => {
        if (!company) {
            if (initialCompany?.type === CompanyAutocompleteType.Organization) {
                setCompany(initialCompany)
            } else if (initialCompany?.type === CompanyAutocompleteType.WhitePagesResult) {
                setCompany(initialCompany)
            }
        }
    }, [initialCompany, company])

    const onCompanyChange = useCallback(
        (value: CompanyResult | undefined) => {
            setCompany(value)
            if (updateData && value && value.value.id !== initialCompany?.value.id) {
                if (value.type === CompanyAutocompleteType.Organization) {
                    const {
                        id,
                        name,
                        registration: { countryCode, dunsNumber, vatNumber, preferredRegistrationNumber },
                    } = value.value

                    updateData({
                        name,
                        countryCode,
                        dunsNumber,
                        organizationId: id,
                        taxId: vatNumber,
                        registrationNumber: preferredRegistrationNumber?.registrationNumber,
                    })
                } else {
                    const { id, idType, countryCode, name, registrationNumbers, taxId } = value.value
                    const internationalRegistrationNumber =
                        registrationNumbers.find(registrationNumberIsPrefered)?.registrationNumber || id || null

                    updateData({
                        name,
                        countryCode,
                        taxId,
                        organizationId: null,
                        dunsNumber: idType === WhitePagesIdTypes.DUNS ? id : null,
                        registrationNumber:
                            idType === WhitePagesIdTypes.FRENCH_SIRET ? id : internationalRegistrationNumber,
                    })
                }
            }
        },
        [setCompany, updateData, initialCompany]
    )

    return (
        <div>
            <Stack direction="row" alignItems="center" gap={1}>
                <h4>{label}</h4>
                {company?.type === CompanyAutocompleteType.Organization ||
                    (company?.type === CompanyAutocompleteType.WhitePagesResult && (
                        <LightButton type="button" disabled={!dataLoaded} onClick={showEditModal}>
                            <SafeFormattedMessage {...editMessage} />
                        </LightButton>
                    ))}
                {children}
            </Stack>
            {dataLoaded ? (
                <CompanyAutocomplete
                    company={initialCompany}
                    setCompany={onCompanyChange}
                    organizations={organizations}
                    suggestedCompanies={suggestedCompanies}
                    countryCode={countryCode}
                    setCountryCode={onCountryCodeChange}
                    groupLabel={groupLabel}
                />
            ) : (
                <SkeletonInput />
            )}
            <ModalOrganizationSelectorDetails
                open={displayEditModal}
                onClose={hideEditModal}
                company={company}
                withOrganizationAddress={withOrganizationAddress}
                withSendingAddress={withSendingAddress}
                onSave={onSave}
            />
        </div>
    )
}
