import { CornerDownRight } from "react-feather"

import { FetchCustomFieldsConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { RegularNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    EditorNode,
    FetchCustomFieldsNode as FetchCustomFieldsNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const fetchCustomFieldsNode: Omit<FetchCustomFieldsNodeType, keyof Node> = {
    objectId: `{{ trigger_event.id }}`,
    nextNode: null,
    type: NodeType.FETCH_CUSTOM_FIELDS_NODE,
}

const advancedFields: (keyof typeof fetchCustomFieldsNode)[] = [AllAdvancedFieldsUnion.OBJECT_ID]

const validateNode = (node: EditorNode): boolean => {
    if (node.type !== NodeType.FETCH_CUSTOM_FIELDS_NODE) return false
    return Boolean(node.objectId)
}

const fetchCustomFieldsConfig: Configuration<NodeType.FETCH_CUSTOM_FIELDS_NODE> = {
    type: NodeType.FETCH_CUSTOM_FIELDS_NODE,
    baseSlug: "fetch-custom-fields",
    Icon: CornerDownRight,
    Configuration: FetchCustomFieldsConfiguration,
    advancedFields,
    Node: RegularNode,
    factory: (props: FactoryProps): FetchCustomFieldsNodeType => ({
        ...fetchCustomFieldsNode,
        ...createCommonNodeProperties({ ...props, error: false }),
    }),
    validateNode,
}

export { fetchCustomFieldsConfig, advancedFields as fetchCustomFieldsAdvancedFields }
