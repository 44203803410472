import { Box, Stack, TextField, Typography } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { FLOW_NODE_LIST } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/locale"
import { NodeType } from "~/domains/orchestration/flows/types"

const listDirection = {
    row: "row",
    column: "column",
} as const

type Direction = (typeof listDirection)[keyof typeof listDirection]

interface Props {
    onDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    onNodeClick: (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => void
    showLabels?: boolean
    direction?: Direction
}

export const NodeList: FC<Props> = ({ onDragStart, onNodeClick, direction = listDirection.row }) => {
    const [searchQuery, setSearchQuery] = useState("")
    const { formatMessage } = useIntl()

    const filteredNodes = useMemo(() => {
        return FLOW_NODE_LIST.filter(({ type }) => {
            const title = formatMessage(messages.nodeTitle[type])
            const description = formatMessage(messages.nodeDescription[type])
            const searchTerm = searchQuery.toLowerCase()

            return (
                title.toLowerCase().includes(searchTerm) ||
                description.toLowerCase().includes(searchTerm) ||
                type.toLowerCase().includes(searchTerm)
            )
        })
    }, [formatMessage, searchQuery])

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value)
    }

    return (
        <Stack gap={1} direction="column">
            <TextField
                size="small"
                placeholder={formatMessage(messages.nodeList.searchPlaceholder)}
                value={searchQuery}
                onChange={handleSearch}
                fullWidth
                className="mb-12"
                autoComplete="off"
            />
            <Stack direction={direction} gap={1}>
                {filteredNodes.map(({ type, Icon }) => (
                    <Box
                        key={type}
                        className="flows-editor-sideBar-item"
                        gap={1}
                        onDragStart={onDragStart(type)}
                        onClick={onNodeClick(type)}
                        draggable
                    >
                        <Stack direction="row" justifyContent="space-between">
                            <Icon size={18} className="flows-editor-sideBar-item-icon" />
                            <Stack className="flows-editor-sideBar-item-content" gap={0.5}>
                                <SafeFormattedMessage tagName="h5" {...messages.nodeTitle[type]} />
                                <Typography variant="caption" lineHeight={1.4} color="text.secondary">
                                    <SafeFormattedMessage {...messages.nodeDescription[type]} />
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </Stack>
    )
}
