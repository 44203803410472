import { Typography } from "@mui/material"
import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Modal, Size } from "~/components"
import { AknowledgeCheckItem } from "~/domains/identity/account/components/AknowledgePurchasePolicyWall/AknowledgeCheckItem"

const messages = defineMessages({
    title: {
        id: "identity.account.acknowledgePurchasePolicyWall.stepFourTitle",
        defaultMessage: "Commitment to Global Framework",
    },
    clickToAknowledge: {
        id: "identity.account.acknowledgePurchasePolicyWall.clickToAknowledge",
        defaultMessage: "Click to acknowledge",
    },
})

interface Props {
    onConfirm: (confirmDate: Date) => void
}

export const FinalPurchasePolicyAknowledgeModal: FC<Props> = ({ onConfirm }) => {
    const { formatMessage } = useIntl()
    return (
        <Modal open size={Size.XL}>
            <Modal.Header>
                <Typography variant="h4" gutterBottom>
                    {formatMessage(messages.title)}
                </Typography>
            </Modal.Header>
            <Modal.Content>
                <AknowledgeCheckItem primary="J'ai suivi la formation initiale" />
                <AknowledgeCheckItem primary="Je connais de l'emplacement de stockage de la politique et des procédures d'achat." />
                <AknowledgeCheckItem primary="Je m'engagement à consulter et à me conformer aux documents de référence." />
            </Modal.Content>
            <Modal.Footer>
                <Button onClick={() => onConfirm(new Date())}>{formatMessage(messages.clickToAknowledge)}</Button>
            </Modal.Footer>
        </Modal>
    )
}
