/* eslint-disable no-shadow */
import { XYPosition } from "@xyflow/react"

import { FulfillmentStatus } from "~/domains/transactions/_shared/types"
import { Opaque } from "~/utils"

export type FlowId = Opaque<string, { readonly T: unique symbol }>

export type Filter = {
    conditions: Array<Array<string>>
}

export type Branch = Filter & {
    name: string
    nextNode: string | null
}

export type FlowNode =
    | EventTriggerNode
    | IfNode
    | SetPartnershipFieldNode
    | CheckNode
    | SendEmailNode
    | AddToBudgetNode
    | InvoiceToPurchaseOrderMatchNode
    | SetInvoiceLifecycleStatusNode
    | FitsToBudgetNode
    | AssignTagNode
    | UpdateTripletexLedgerNode
    | ApprovePurchaseOrderNode
    | ApprovePurchaseOrderLineNode
    | ConvertPrToPoNode
    | ApprovePurchaseRequestNode
    | ApprovePurchaseRequestLineNode
    | BranchNode
    | SetPaymentMethodDetailsFieldNode
    | CreateSurveyNode
    | RetractReviewsNode
    | FetchCustomFieldsNode
    | MappingNode
    | GetTagByGroupNode
    | UpdateCustomFieldNode
    | CreateTaskNode
    | FetchPartnershipNode
    | CreateCustomFieldNode
    | SetPoFulfillmentStatusNode
    | SuspendUntilSurveyCompletedNode
    | SetPoStatusNode
    | RefusePurchaseRequestNode
    | RefusePurchaseOrderNode

/**
 * EditorNode represents nodes used in the frontend editor that may not have a direct
 * 1:1 mapping to backend nodes. This allows for more flexible node handling in the UI.
 *
 * Key differences from FlowNode:
 * - Can contain composite/grouped nodes (e.g. AssignTagGroupNode)
 * - May have additional UI-specific properties and behavior
 */
export type EditorNode =
    | EventTriggerNode
    | IfNode
    | SetPartnershipFieldNode
    | CheckNode
    | SendEmailNode
    | AddToBudgetNode
    | InvoiceToPurchaseOrderMatchNode
    | SetInvoiceLifecycleStatusNode
    | FitsToBudgetNode
    | AssignTagGroupNode
    | UpdateTripletexLedgerNode
    | ApprovePurchaseOrderNode
    | ApprovePurchaseOrderLineNode
    | ConvertPrToPoNode
    | ApprovePurchaseRequestNode
    | ApprovePurchaseRequestLineNode
    | BranchNode
    | SetPaymentMethodDetailsFieldNode
    | CreateSurveyNode
    | RetractReviewsNode
    | FetchCustomFieldsNode
    | MappingNode
    | GetTagByGroupNode
    | UpdateCustomFieldNode
    | CreateTaskNode
    | FetchPartnershipNode
    | CreateCustomFieldNode
    | SetPoFulfillmentStatusNode
    | SuspendUntilSurveyCompletedNode
    | SetPoStatusNode
    | RefusePurchaseRequestNode
    | RefusePurchaseOrderNode

export type Node = {
    name: string
    slug: string
    metadata: {
        position: XYPosition
        additionalInformation?: string
        group?: {
            id: string
            name: string
        }
    }
    error: boolean
}

export type ConditionalNode = Node & {
    nextIfSuccess: string | null
    nextIfFailure: string | null
}

export type RegularNode = Node & {
    nextNode: string | null
}

export enum UserType {
    USER = "User",
    TEAM = "Team",
}

export type UserReviewer = {
    userId: string
    type: UserType.USER
}

export type TeamReviewer = {
    teamId: string
    type: UserType.TEAM
}

export type Reviewer = UserReviewer | TeamReviewer

export type IfNode = ConditionalNode & {
    condition: string
    type: NodeType.IF_NODE
}

export type CheckNode = ConditionalNode & {
    objectId: string
    objectType: ObjectType | null
    reviewers: Reviewer[]
    passThreshold: number
    refuseThreshold: number
    type: NodeType.CHECK_NODE
}

export type EventTriggerNode = RegularNode & {
    event: Event | null
    filter: Filter
    type: NodeType.EVENT_TRIGGER_NODE
}

export type SetPartnershipFieldNode = RegularNode & {
    partnershipId: string
    fieldToUpdate: string
    valueToSet: string
    type: NodeType.SET_PARTNERSHIP_FIELD_NODE
}

export type SetPaymentMethodDetailsFieldNode = RegularNode & {
    paymentMethodDetailsId: string
    fieldToUpdate: string
    valueToSet: string
    type: NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE
}

export enum RespondentType {
    EMAIL = "EmailRespondent",
    USER = "UserRespondent",
}

export type EmailRespondent = {
    type: RespondentType.EMAIL
    emailAddress: string
}

export type UserRespondent = {
    type: RespondentType.USER
    userId: string
}

export type SurveyRespondent = EmailRespondent | UserRespondent

export type CreateSurveyNode = RegularNode & {
    formId: string
    respondentOrganizationId: string | null
    sendNotifications: boolean
    respondents: SurveyRespondent[]
    type: NodeType.CREATE_SURVEY_NODE
}

export type RetractReviewsNode = RegularNode & {
    objectId: string
    objectType: ObjectType | null
    type: NodeType.RETRACT_REVIEWS_NODE
}

export type SendEmailNode = RegularNode & {
    subject: string
    recipientAddresses: string[]
    body: string
    type: NodeType.SEND_EMAIL_NODE
}

export type AddToBudgetNode = RegularNode & {
    budgetId: string
    transactionId: string
    transactionType: TransactionType | null
    amount: string
    currency: string
    failIfOverbudget: boolean
    type: NodeType.ADD_TO_BUDGET_NODE
}

export type InvoiceToPurchaseOrderMatchNode = ConditionalNode & {
    invoiceId: string
    type: NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE
}

export type SetInvoiceLifecycleStatusNode = RegularNode & {
    invoiceId: string
    statusToSet: string
    type: NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE
}

export type FitsToBudgetNode = ConditionalNode & {
    budgetId: string
    transactionId: string
    transactionType: TransactionType | null
    amount: string
    currency: string
    type: NodeType.FITS_TO_BUDGET_NODE
}

export type AssignTagNode = RegularNode & {
    objectId: string
    objectType: ObjectType | null
    tagId: string
    type: NodeType.ASSIGN_TAG_NODE
}

export type AssignTagGroupNode = RegularNode & {
    nodes: AssignTagNode[]
    type: NodeType.ASSIGN_TAG_GROUP_NODE
    objectId: string
    objectType: ObjectType | null
}

export type UpdateTripletexLedgerNode = RegularNode & {
    ledgerId: string
    ledgerDate: string
    ledgerDescription: string
    accountToCredit: string
    accountToDebit: string
    amount: string
    amountGross: string
    currency: string
    type: NodeType.UPDATE_TRIPLETEX_LEDGER_NODE
}

export type ApprovePurchaseOrderNode = RegularNode & {
    purchaseOrderId: string
    type: NodeType.APPROVE_PURCHASE_ORDER_NODE
}

export type ApprovePurchaseOrderLineNode = RegularNode & {
    purchaseOrderLineId: string
    purchaseOrderId: string
    type: NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE
}

export type ConvertPrToPoNode = RegularNode & {
    purchaseRequestId: string
    type: NodeType.CONVERT_PR_TO_PO_NODE
}

export type ApprovePurchaseRequestNode = RegularNode & {
    purchaseRequestId: string
    type: NodeType.APPROVE_PURCHASE_REQUEST_NODE
}

export type ApprovePurchaseRequestLineNode = RegularNode & {
    purchaseRequestId: string
    purchaseRequestLineId: string
    type: NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE
}

export type BranchNode = Node & {
    branches: Branch[]
    default: string
    type: NodeType.BRANCH_NODE
}

export type FetchCustomFieldsNode = RegularNode & {
    objectId: string
    type: NodeType.FETCH_CUSTOM_FIELDS_NODE
}

export type MappingNodeElement = {
    label: string
    value: string
}

export type MappingNode = RegularNode & {
    valueToMap: string
    mappingTable: Record<string, MappingNodeElement[]>
    defaultValues: MappingNodeElement[]
    type: NodeType.MAPPING_NODE
}

export type GetTagByGroupNode = RegularNode & {
    objectId: string
    tagGroupId: string
    type: NodeType.GET_TAG_BY_GROUP_NODE
}

export type UpdateCustomFieldNode = RegularNode & {
    customFieldId: string
    value: string
    type: NodeType.UPDATE_CUSTOM_FIELD_NODE
}

export type FetchPartnershipNode = RegularNode & {
    partnerId: string
    type: NodeType.FETCH_PARTNERSHIP_NODE
}

export type CreateCustomFieldNode = RegularNode & {
    objectId: string
    customFieldName: string
    customFieldValue?: string
    type: NodeType.CREATE_CUSTOM_FIELD_NODE
}

export type SetPoFulfillmentStatusNode = RegularNode & {
    purchaseOrderId: string
    statusToSet: FulfillmentStatus | null
    type: NodeType.SET_PO_FULFILLMENT_STATUS_NODE
}

export type SuspendUntilSurveyCompletedNode = RegularNode & {
    surveyId: string
    type: NodeType.SUSPEND_UNTIL_SURVEY_COMPLETED_NODE
}

export type SetPoStatusNode = RegularNode & {
    purchaseOrderId: string
    statusToSet: PoStatus | null
    type: NodeType.SET_PO_STATUS_NODE
}

export type CreateTaskNode = RegularNode & {
    title: string
    description: string
    dueDate?: string
    priority?: TaskPriority
    status?: TaskStatus
    assignee: string
    parentTaskId: string
    public: boolean
    followers: string[]
    parties: string[]
    type: NodeType.CREATE_TASK_NODE
}

export type RefusePurchaseRequestNode = RegularNode & {
    purchaseRequestId: string
    type: NodeType.REFUSE_PURCHASE_REQUEST_NODE
}

export type RefusePurchaseOrderNode = RegularNode & {
    purchaseOrderId: string
    type: NodeType.REFUSE_PURCHASE_ORDER_NODE
}

export enum PoStatus {
    DRAFT = "Draft",
    SUBMITTED = "Submitted",
    OPEN = "Open",
    CLOSED = "Closed",
}

export type Flow = {
    id: FlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    nodes: EditorNode[]
    author: string
}

export enum NodeType {
    EVENT_TRIGGER_NODE = "EventTriggerNodeV2",
    SET_PARTNERSHIP_FIELD_NODE = "SetPartnershipFieldNode",
    SET_PAYMENT_METHOD_DETAILS_FIELD_NODE = "SetPaymentMethodDetailsFieldNode",
    IF_NODE = "IfNode",
    CHECK_NODE = "CheckNode",
    SEND_EMAIL_NODE = "SendEmailNode",
    ADD_TO_BUDGET_NODE = "AddToBudgetNode",
    INVOICE_TO_PURCHASE_ORDER_MATCH_NODE = "InvoiceToPurchaseOrderMatchNode",
    SET_INVOICE_LIFECYCLE_STATUS_NODE = "SetInvoiceLifecycleStatusNode",
    FITS_TO_BUDGET_NODE = "FitsToBudgetNode",
    ASSIGN_TAG_NODE = "AssignTagNode",
    ASSIGN_TAG_GROUP_NODE = "AssignTagGroupNode",
    UPDATE_TRIPLETEX_LEDGER_NODE = "UpdateTripletexLedgerNode",
    APPROVE_PURCHASE_ORDER_NODE = "ApprovePurchaseOrderNode",
    APPROVE_PURCHASE_ORDER_LINE_NODE = "ApprovePurchaseOrderLineNode",
    CONVERT_PR_TO_PO_NODE = "ConvertPrToPoNode",
    APPROVE_PURCHASE_REQUEST_NODE = "ApprovePurchaseRequestNode",
    APPROVE_PURCHASE_REQUEST_LINE_NODE = "ApprovePurchaseRequestLineNode",
    BRANCH_NODE = "BranchNode",
    CREATE_SURVEY_NODE = "CreateSurveyNode",
    RETRACT_REVIEWS_NODE = "RetractReviewsNode",
    FETCH_CUSTOM_FIELDS_NODE = "FetchCustomFieldsNode",
    MAPPING_NODE = "MappingNode",
    GET_TAG_BY_GROUP_NODE = "GetTagByGroupNode",
    UPDATE_CUSTOM_FIELD_NODE = "UpdateCustomFieldNode",
    CREATE_TASK_NODE = "CreateTaskNode",
    FETCH_PARTNERSHIP_NODE = "FetchPartnershipNode",
    CREATE_CUSTOM_FIELD_NODE = "CreateCustomFieldNode",
    SET_PO_FULFILLMENT_STATUS_NODE = "SetPoFulfillmentStatusNode",
    SUSPEND_UNTIL_SURVEY_COMPLETED_NODE = "SuspendUntilSurveyCompletedNode",
    SET_PO_STATUS_NODE = "SetPoStatusNode",
    REFUSE_PURCHASE_REQUEST_NODE = "RefusePurchaseRequestNode",
    REFUSE_PURCHASE_ORDER_NODE = "RefusePurchaseOrderNode",
}

export enum Event {
    PARTNERSHIP_CREATED = "PartnershipCreated",
    BUDGET_CREATED = "BudgetCreated",
    BUDGET_UPDATED = "BudgetUpdated",
    INVOICE_CREATED = "InvoiceCreated",
    INVOICE_UPDATED = "InvoiceUpdated",
    PURCHASE_REQUEST_CREATED = "PurchaseRequestCreated",
    PURCHASE_REQUEST_UPDATED = "PurchaseRequestUpdated",
    PURCHASE_REQUEST_LINE_CREATED = "PurchaseRequestLineCreated",
    PURCHASE_REQUEST_APPROVED = "PurchaseRequestApproved",
    INVOICE_TO_PURCHASE_ORDER_LINKED = "InvoiceToPurchaseOrderLinked",
    SUPPLIER_PURCHASE_ORDER_CREATED = "SupplierPurchaseOrderCreated",
    SUPPLIER_PURCHASE_ORDER_LINE_CREATED = "SupplierPurchaseOrderLineCreated",
    SUPPLIER_PURCHASE_ORDER_SENT = "SupplierPurchaseOrderSent",
    SUPPLIER_PURCHASE_ORDER_APPROVED = "SupplierPurchaseOrderApproved",
    BUYER_PURCHASE_ORDER_CREATED = "BuyerPurchaseOrderCreated",
    BUYER_PURCHASE_ORDER_LINE_CREATED = "BuyerPurchaseOrderLineCreated",
    BUYER_PURCHASE_ORDER_SENT = "BuyerPurchaseOrderSent",
    PURCHASE_ORDER_INTERNALLY_APPROVED = "PurchaseOrderInternallyApproved",
    PAYMENT_METHOD_DETAILS_CREATED = "PaymentMethodDetailsCreated",
    PARTNER_PAYMENT_METHOD_DETAILS_CREATED = "PartnerPaymentMethodDetailsCreated",
    SURVEY_COMPLETED = "SurveyCompleted",
}

export enum FlowTrigger {
    INVOICE = "INVOICE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
}

export enum ObjectType {
    ORGANIZATION_RELATIONSHIP = "OrganizationRelationship",
    INVOICE = "Invoice",
    BUDGET = "Budget",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_ORDER_LINE = "PurchaseOrderLine",
    PURCHASE_REQUEST = "PurchaseRequest",
    PURCHASE_REQUEST_LINE = "PurchaseRequestLine",
    PAYMENT_METHOD_DETAILS = "PaymentMethodDetails",
    PARTNER_PAYMENT_METHOD_DETAILS = "PartnerPaymentMethodDetails",
    // TODO: add these once we have the API for them
    // CONTACT = "Contact",
    // PAYMENT = "Payment",
}

export enum TransactionType {
    INVOICE = "Invoice",
    PURCHASE_ORDER = "PurchaseOrder",
    PURCHASE_REQUEST = "PurchaseRequest",
    // TODO: add these once we have the API for them
    // PAYMENT = "Payment",
    // DEPOSIT = "Deposit",
}

export type Flows = {
    flows: FlowItem[]
}

export type FlowItem = {
    id: FlowId
    version: number
    name: string
    enabled: boolean
    archived: boolean
    createdAt: string
}

export type CreateFlowBody = {
    name: string
    enabled: boolean
    nodes: EditorNode[]
}

export type CreateFlowQuery = {
    body: CreateFlowBody
}

type GetFlowParams = {
    version: number
}

export type GetFlowQuery = {
    flowId: FlowId
    params: GetFlowParams
}

export type UpdateFlowMutation = {
    flowId: FlowId
    body: Flow
}

export type ValidFlow = {
    isValid: true
}

export type InvalidFlow = {
    isNotValid: true
    error: string
}

export type FlowValidation = ValidFlow | InvalidFlow

export enum TaskPriority {
    HIGH = "High",
    LOW = "Low",
    MEDIUM = "Medium",
    URGENT = "Urgent",
}

export enum TaskStatus {
    PENDING = "Pending",
    IN_PROGRESS = "InProgress",
    COMPLETED = "Completed",
}
