import { Stack } from "@mui/material"
import dayjs from "dayjs"
import { defineMessages, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { Button, Card, ItemLabel, SafeFormattedMessage } from "~/components"
import { PlusIcon } from "~/components/Icons"
import { useGetInvoicesAnalyticsQuery } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { UPLOAD_ROUTE } from "~/routes/routes"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    newInvoice: { id: "invoice.list.header.uploadButton", defaultMessage: "Upload an invoice" },
    totalAmountDueWithin7Days: { id: "invoices.totalAmountDueWithin7Days", defaultMessage: "Amount due within 7 days" },
})

export const InvoicesListHeader = () => {
    const navigate = useNavigate()
    const { formatNumber } = useIntl()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const { data: invoiceAnalytics } = useGetInvoicesAnalyticsQuery({
        organizationId: currentOrganizationId ?? "",
        property: "totalAmountDue",
        operation: "SUM",
        filters: {
            dueDateTo: dayjs().add(7, "day").format("YYYY-MM-DD"),
            statusIn: [],
        },
    })

    const metric = invoiceAnalytics?.metric
    const totalAmountDue = +(metric?.value || 0)
    const currency = metric?.currency

    const handleClick = () => {
        navigate(UPLOAD_ROUTE)
    }

    return (
        <Stack spacing={2}>
            <Card>
                <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                    <Stack>
                        <ItemLabel>
                            <SafeFormattedMessage {...messages.totalAmountDueWithin7Days} />
                        </ItemLabel>
                        {formatNumber(totalAmountDue, {
                            style: "currency",
                            currency,
                        })}
                    </Stack>
                    <Button onClick={handleClick}>
                        <SafeFormattedMessage {...messages.newInvoice} />
                        <PlusIcon size={16} />
                    </Button>
                </Stack>
            </Card>
        </Stack>
    )
}
