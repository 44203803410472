import type { NodeProps, Node as XYFlowNode } from "@xyflow/react"
import { FC } from "react"

import { NodeType, RegularNode } from "~/domains/orchestration/flows/types"

export type Handle = {
    id: string
}

export enum EmailMailType {
    ERROR = "error",
    VALID = "valid",
    EXPRESSION = "expression",
}

export type RegularNodeProps = FC<NodeProps<XYFlowNode<RegularNode & { type: NodeType }>>>
