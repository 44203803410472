/* eslint-disable max-lines */
import type {} from "@mui/lab/themeAugmentation"
import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material"

/* https://stackoverflow.com/questions/69608945/fix-typescript-error-when-customizing-muidatagrid-in-createtheme */
import type {} from "@mui/x-data-grid-pro/themeAugmentation"
import type {} from "@mui/x-data-grid/themeAugmentation"
import { PropsWithChildren, useEffect, useMemo, useState } from "react"

import { palette } from "~/assets/theme/palette"
import { selectDarkMode } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

interface ThemeProviderProps extends PropsWithChildren {}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const darkMode = useAppSelector(selectDarkMode)
    const [activeTheme, setActiveTheme] = useState<"light" | "dark">("light")

    useEffect(() => {
        const theme = darkMode ? "dark" : "light"
        setActiveTheme(theme)
        document.documentElement.setAttribute("data-theme", theme)
    }, [darkMode])

    const theme = useMemo(
        () =>
            createTheme({
                palette: palette(activeTheme),
                typography: {
                    fontFamily: ["Noto Sans"].join(","),
                    body1: {
                        fontSize: "var(--font-body-size)",
                        fontWeight: 500,
                    },
                    h1: {
                        fontSize: "var(--h1-font-size)",
                        fontWeight: "var(--h1-font-weight)",
                    },
                    h2: {
                        fontSize: "var(--h2-font-size)",
                        fontWeight: "var(--h2-font-weight)",
                        lineHeight: "18px",
                        color: "var(--color-secondary)",
                    },
                    h3: {
                        fontSize: "var(--h3-font-size)",
                        fontWeight: "var(--h3-font-weight)",
                    },
                    h4: {
                        fontSize: "var(--h4-font-size)",
                        fontWeight: "var(--h4-font-weight)",
                    },
                    button: {
                        fontSize: 16,
                        fontWeight: 700,
                        lineHeight: 1.12,
                    },
                },
                components: {
                    MuiContainer: {
                        styleOverrides: {
                            root: {
                                paddingLeft: "var(--spacing-md) !important",
                                paddingRight: "var(--spacing-md) !important",
                            },
                        },
                    },
                    MuiAppBar: {
                        styleOverrides: {
                            root: (props) => ({
                                minHeight: "var(--header-height)",
                                boxShadow: "none",
                                paddingRight: "0 !important",
                                background: "var(--background-color)",

                                [props.theme.breakpoints.down("md")]: {
                                    position: "relative",
                                },

                                "& .MuiContainer-root": {
                                    height: "100%",

                                    [props.theme.breakpoints.down("md")]: {
                                        padding: 0,
                                    },
                                },

                                "& .MuiToolbar-root": {
                                    minHeight: "100px",
                                    paddingBlock: "var(--spacing-sm)",

                                    ".title": {
                                        svg: {
                                            color: "var(--color-primary)",
                                        },
                                    },

                                    "& .MuiTypography-h1": {
                                        fontSize: "var(--h2-font-size)",
                                        fontWeight: 900,
                                    },
                                },
                            }),
                        },
                    },
                    MuiToolbar: {
                        styleOverrides: {
                            root: {
                                minHeight: "32px",
                            },
                        },
                    },
                    MuiMenu: {
                        styleOverrides: {
                            list: {
                                padding: "var(--spacing-xxs)",
                            },
                        },
                    },
                    MuiMenuItem: {
                        styleOverrides: {
                            root: {
                                gap: "var(--spacing-sx)",
                                position: "relative",
                                minHeight: "30px !important",

                                "& .MuiButtonBase-root": {
                                    padding: "0 var(--spacing-xxs)",
                                },
                            },
                        },
                    },
                    MuiCard: {
                        styleOverrides: {
                            root: {
                                boxShadow: "none",
                            },
                        },
                    },
                    MuiCardHeader: {
                        styleOverrides: {
                            root: {
                                color: "var(--color-grey)",
                            },
                        },
                    },
                    MuiCardContent: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                                "&:last-child": {
                                    paddingBottom: 0,
                                },
                            },
                        },
                    },
                    MuiCardActions: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                            },
                        },
                    },
                    MuiButtonBase: {
                        styleOverrides: {
                            root: {
                                "&:disabled": {
                                    cursor: "not-allowed",
                                    pointerEvents: "auto",
                                },
                            },
                        },
                    },
                    MuiButton: {
                        styleOverrides: {
                            sizeSmall: {
                                height: "34px",
                            },
                        },
                    },
                    MuiBreadcrumbs: {
                        styleOverrides: {
                            root: {
                                width: "100%",
                            },
                            ol: {
                                flexWrap: "nowrap",
                                overflow: "auto",
                            },
                            li: {
                                flex: 1,
                                textAlign: "center",
                            },
                        },
                    },
                    MuiTypography: {
                        styleOverrides: {
                            body1: {
                                fontSize: "var(--font-body-size)",
                            },
                            h2: {
                                fontWeight: 700,
                                fontSize: "var(--h2-font-size)",
                                marginBottom: 0,
                                "&.MuiTypography-gutterBottom": {
                                    marginBottom: "16px",
                                },
                            },
                        },
                    },
                    MuiSkeleton: {
                        styleOverrides: {
                            root: {
                                background: "var(--color-silver)",
                            },
                        },
                    },
                    MuiSelect: {
                        styleOverrides: {
                            root: {
                                border: "0",
                                dropShadow: "none",
                                minHeight: "39px",

                                "&.MuiInputBase-sizeSmall": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        top: "-10px !important",
                                        border: "0",
                                    },
                                },
                            },
                            select: {
                                border: "0",
                            },
                        },
                        defaultProps: {
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 400,
                                        overflow: "auto",
                                    },
                                },
                            },
                        },
                    },
                    MuiAutocomplete: {
                        styleOverrides: {
                            groupLabel: {
                                fontSize: "var(--font-size-xs)",
                                lineHeight: "30px",
                                color: "var(--color-grey-light)",
                            },
                            tag: {
                                maxWidth: "calc(100% - 30px)",
                            },
                        },
                    },
                    MuiTableHead: {
                        styleOverrides: {
                            root: {
                                backgroundColor:
                                    palette(activeTheme)?.background?.default || "var(--color-grey-lighter)",

                                "& th": {
                                    borderBottom: "1px solid var(--color-silver-light)",
                                },
                            },
                        },
                    },
                    MuiTableRow: {
                        styleOverrides: {
                            root: {
                                "&:nth-of-type(odd)": {
                                    backgroundColor:
                                        palette(activeTheme)?.action?.selected || "var(--color-grey-lighter)",
                                },
                            },
                        },
                    },
                    MuiTableCell: {
                        styleOverrides: {
                            root: {
                                padding: "var(--spacing-sm)",
                                borderBottom: "1px solid var(--color-border-workflow)",
                                minHeight: "var(--spacing-md)",

                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                },

                                "&.MuiTableCell-sizeSmall": {
                                    fontSize: "var(--font-size-sm)",
                                    padding: "var(--spacing-xs)",
                                    lineHeight: "var(--spacing-md)",

                                    "& *": {
                                        fontSize: "var(--font-size-sm)",
                                    },

                                    "& .MuiCheckbox-root svg": {
                                        fontSize: "var(--font-size-lg)",
                                    },

                                    "& .MuiAvatar-root": {
                                        width: 26,
                                        height: 26,
                                        fontSize: "var(--font-size-sm)",
                                    },

                                    "& .MuiChip-root": {
                                        padding: "0 var(--spacing-xxs)",
                                        height: 26,

                                        "& .MuiChip-label": {
                                            padding: "0 var(--spacing-xxs)",
                                        },
                                    },

                                    "& .flowie-button": {
                                        padding: "var(--spacing-xxs)",
                                        height: 26,
                                        width: 26,
                                    },

                                    "& .MuiInputBase-input": {
                                        padding: "var(--spacing-xs) var(--spacing-sm)",
                                    },
                                },
                            },
                            head: {
                                color: "var(--color-grey-light)",
                            },
                        },
                    },
                    MuiTablePagination: {
                        styleOverrides: {
                            root: {
                                overflow: "hidden !important",
                                color: "var(--color-grey-light)",
                            },
                            select: {
                                border: "0",
                            },
                            actions: {
                                "& .Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                    },
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    MuiDataGrid: {
                        styleOverrides: {
                            root: {
                                border: "1px solid var(--color-border-workflow)",
                                borderRadius: "var(--border-radius-sm)",
                                minHeight: "350px",
                                maxWidth: "calc(100vw - 2*var(--spacing-md))",
                                cursor: "pointer",

                                "& .MuiCheckbox-root": {
                                    color: "var(--color-grey-light)",
                                },

                                "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                },

                                "&.MuiDataGrid-root--densityCompact": {
                                    "& .MuiDataGrid-main": {
                                        "& *": {
                                            fontSize: "var(--font-size-sm)",
                                        },

                                        "& .MuiCheckbox-root svg": {
                                            fontSize: "var(--font-size-lg)",
                                        },

                                        "& .MuiChip-root, & .status-chip": {
                                            padding: "0 var(--spacing-xxs)",
                                            height: 26,

                                            "& .MuiChip-label": {
                                                padding: "0 var(--spacing-xxs)",
                                            },
                                        },

                                        "& .MuiAvatar-root": {
                                            width: 26,
                                            height: 26,
                                        },

                                        "& .flowie-button": {
                                            padding: "var(--spacing-xxs)",
                                            height: 26,
                                            width: 26,
                                        },

                                        "& .MuiInputBase-input": {
                                            padding: "var(--spacing-xs) var(--spacing-sm)",
                                        },
                                    },
                                },
                            },
                            row: {
                                "&:nth-of-type(odd)": {
                                    backgroundColor:
                                        palette(activeTheme)?.action?.selected || "var(--color-grey-lighter)",
                                },
                            },
                            cell: {
                                "&:focus": {
                                    outline: 0,
                                },

                                "&[data-field='__detail_panel_toggle__'], &[data-field='__check__']": {
                                    width: 40,
                                    minWidth: "40px !important",
                                    maxWidth: "40px !important",
                                    paddingInline: 4,
                                },
                            },
                            columnHeader: {
                                lineHeight: "1",
                                backgroundColor:
                                    palette(activeTheme)?.background?.default || "var(--color-grey-lighter)",

                                "& img": {
                                    maxHeight: 30,
                                },
                                "& label div": {
                                    marginBottom: 0,
                                },
                                "&[data-field='__detail_panel_toggle__'], &[data-field='__check__']": {
                                    width: 40,
                                    minWidth: "40px !important",
                                    maxWidth: "40px !important",
                                    paddingInline: 4,
                                },
                                "& .MuiCheckbox-root": {
                                    padding: 0,
                                },
                            },
                            virtualScroller: {
                                minHeight: 200,
                            },
                            toolbarContainer: {
                                padding: 0,
                                marginBottom: "var(--spacing-xs)",

                                "& .MuiButton-root": {
                                    color: "var(--color-grey)",
                                    paddingInline: "var(--spacing-sm)",
                                },
                            },
                            menu: {
                                outline: "1px solid var(--color-grey-lighter)",
                            },
                            panel: {
                                boxShadow: "none",
                                outline: "1px solid var(--color-grey-lighter)",
                            },
                            panelFooter: {
                                "& .MuiButton-root": {
                                    padding: "var(--spacing-sm)",
                                    color: "var(--color-grey-light)",
                                },
                            },
                            footerContainer: {
                                border: "0",
                            },
                        },
                    },
                    MuiFormGroup: {
                        styleOverrides: {
                            root: {
                                "& .MuiInputBase-colorGrey": {
                                    backgroundColor: "var(--color-grey-light)",
                                    boxShadow: "0 0 0 1px var(--color-grey-light) inset",
                                    color: "var(--color-grey-light)",
                                },
                                "& .MuiInputBase-colorPrimary": {
                                    backgroundColor: "var(--color-primary-light)",
                                    boxShadow: "0 0 0 1px var(--primary-color) inset",
                                    color: "var(--primary-color)",
                                },
                                "& .MuiInputBase-colorSecondary": {
                                    border: "0",
                                    boxShadow: "0 0 0 1px var(--color-silver-light) inset",
                                },
                                "& .MuiInputBase-colorError": {
                                    backgroundColor: "var(--color-red-light)",
                                    border: "0",
                                    boxShadow: "0 0 0 1px var(--color-red) inset",
                                },
                                "& .MuiInputBase-colorSuccess": {
                                    backgroundColor: "var(--color-green-light)",
                                    border: "0",
                                    boxShadow: "0 0 0 1px var(--color-green) inset",
                                },
                            },
                        },
                    },
                    MuiTextField: {
                        styleOverrides: {
                            root: {
                                borderColor: "var(--color-border-workflow)",
                                "&[aria-readonly='true'] .MuiInputBase-input": {
                                    cursor: "default",
                                },
                            },
                        },
                    },
                    MuiInputBase: {
                        styleOverrides: {
                            root: {
                                borderColor: "var(--color-border-workflow)",
                                backgroundColor: "transparent",
                                "&:focus": {
                                    backgroundColor: "transparent",
                                },
                            },
                            sizeSmall: {
                                height: "34px",
                                fontSize: "var(--font-size-md)",
                            },
                        },
                    },
                    MuiFilledInput: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "transparent",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                                "&:focus": {
                                    backgroundColor: "transparent",
                                },
                            },
                        },
                    },
                    MuiOutlinedInput: {
                        styleOverrides: {
                            root: {
                                borderColor: "var(--color-silver-light)",
                                "&:hover": {
                                    borderColor: "var(--color-silver-light)",
                                },
                                "&.Mui-disabled": {
                                    "& .MuiOutlinedInput-input": {
                                        WebkitTextFillColor: "var(--color-grey-light)",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "var(--color-silver-light)",
                                    },
                                },
                            },
                            notchedOutline: {
                                borderColor: "var(--color-grey-light)",
                                "&:hover": {
                                    borderColor: "var(--color-silver-light)",
                                },
                            },
                        },
                    },
                    MuiInput: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "transparent",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                                "&:focus": {
                                    backgroundColor: "transparent",
                                },
                            },
                        },
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                color: "var(--color-placeholder)",
                                backgroundColor: "var(--color-bg-input)",
                                "&.Mui-disabled": {
                                    color: "var(--color-grey-light)",
                                },
                            },
                            sizeSmall: {
                                lineHeight: "1.1rem",
                            },
                        },
                    },
                    MuiPaper: {
                        styleOverrides: {
                            root: {
                                boxShadow: "none",
                                borderRadius: "var(--border-radius-sm)",
                            },
                        },
                    },
                    MuiTabs: {
                        styleOverrides: {
                            root: {
                                marginBottom: "0 !important",
                                borderBottom: "1px solid var(--color-grey-lighter)",
                            },
                            scrollButtons: {
                                "&.Mui-disabled": {
                                    opacity: 0.3,
                                },
                            },
                        },
                    },
                    MuiTab: {
                        styleOverrides: {
                            root: {
                                paddingRight: "12px",
                                paddingLeft: "12px",
                                paddingBottom: "8px",
                                color: "var(--color-grey-light)",
                                background: "transparent",
                                "&:hover, &.Mui-selected": {
                                    color: "var(--primary-color)",
                                },
                                textTransform: "none",
                                fontWeight: 400,
                                fontSize: "var(--font-size-md)",
                            },
                        },
                    },
                    MuiToggleButtonGroup: {
                        styleOverrides: {
                            root: {
                                marginInline: "auto",
                                marginTop: "var(--spacing-sm)",

                                "& .MuiToggleButton-root": {
                                    padding: "var(--spacing-sm)",
                                },
                            },
                        },
                    },
                    MuiToggleButton: {
                        styleOverrides: {
                            root: {
                                textTransform: "none",
                                fontWeight: 400,
                                fontSize: "var(--font-size-md)",
                            },
                        },
                    },
                    MuiDrawer: {
                        styleOverrides: {
                            paper: {
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                border: "0",
                            },
                        },
                    },
                    MuiChip: {
                        styleOverrides: {
                            root: {
                                borderColor: "var(--color-grey-light)",
                                backgroundColor: "var(--color-grey-lighter)",
                                borderRadius: "var(--border-radius-xs)",

                                "& .MuiChip-label": {
                                    padding: "0 var(--spacing-xs)",
                                },
                            },
                            colorPrimary: {
                                borderColor: "var(--primary-color)",
                                backgroundColor: "var(--color-primary-light)",
                            },
                            colorSecondary: {
                                borderColor: "var(--color-slate)",
                                backgroundColor: "var(--color-slate-light)",
                            },
                            colorSuccess: {
                                borderColor: "var(--color-green)",
                                backgroundColor: "var(--color-green-lighter)",
                            },
                            colorError: {
                                borderColor: "var(--color-red)",
                                backgroundColor: "var(--color-red-light)",
                            },
                            colorWarning: {
                                borderColor: "var(--color-yellow)",
                                backgroundColor: "var(--color-yellow-light)",
                            },
                            colorInfo: {
                                borderColor: "var(--color-blue)",
                                backgroundColor: "var(--color-blue-light)",
                            },
                        },
                    },
                    MuiPopover: {
                        styleOverrides: {
                            paper: {
                                borderRadius: "var(--border-radius-sm)",
                                boxShadow: "0 0 2px 1px var(--color-silver-light)",
                                marginTop: "var(--spacing-md)",
                                overflow: "visible",

                                "&:before": {
                                    content: '""',
                                    position: "absolute",
                                    top: -6,
                                    right: "var(--spacing-sm)",
                                    width: 12,
                                    height: 12,
                                    background: "white",
                                    transform: "rotate(45deg)",
                                    boxShadow: "-1px -1px 1px 0px var(--color-silver-light)",
                                    zIndex: 10,
                                },
                            },
                        },
                    },
                    MuiTimeline: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                                "& .MuiTimelineItem-root:before": {
                                    flex: 0,
                                    padding: 0,
                                },
                            },
                        },
                    },
                    MuiTimelineItem: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                            },
                        },
                    },
                    MuiTimelineSeparator: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                            },
                        },
                    },
                    MuiTimelineDot: {
                        styleOverrides: {
                            root: {
                                padding: 0,
                                margin: "var(--spacing-xxs)",
                                border: 0,
                            },
                        },
                    },
                    MuiSnackbar: {
                        styleOverrides: {
                            root: {
                                "& .MuiAlert-filledWarning": {
                                    backgroundColor: "var(--color-yellow-light)",
                                    border: "1px solid var(--color-yellow)",
                                    borderBottom: "3px solid var(--color-yellow)",

                                    "& .MuiTypography-root, & .MuiIconButton-root": {
                                        color: "var(--color-yellow)",
                                    },

                                    "& .MuiTypography-h5": {
                                        fontSize: "var(--h5-font-size)",
                                    },
                                },
                            },
                        },
                    },
                    MuiAvatar: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "var(--color-grey-lighter)",
                                color: "var(--color-grey)",
                                fontSize: "var(--font-size-md)",
                            },
                        },
                    },
                },
                // These breakpoints are consistent with the ones in /src/assets/css/breakpoints.scss.
                // Both need to be updated together.
                breakpoints: {
                    values: {
                        xs: 480,
                        sm: 576,
                        md: 768,
                        lg: 992,
                        xl: 1200,
                    },
                },
            }),
        [activeTheme]
    )

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    )
}
