import * as t from "io-ts"

import { TaskI, TaskId, TaskPriority, TaskRelativeDocument, TaskStatus } from "~/domains/communication/tasks/types"
import { DocumentObjectType } from "~/domains/identity/documents/types"
import { OrganizationId, UserId } from "~/types"
import { decodeAndCaptureErrors, optional } from "~/utils"

const TaskRelativeDocumentIO = t.type({
    document_id: t.string,
    document_type: t.string,
})

const TaskIO = t.intersection([
    t.type({
        id: t.string,
        title: t.string,
        status: t.string,
        public: optional(t.boolean),
        parties: t.array(t.string),
        followers: t.array(t.string),
        creation_date: t.string,
        last_update_date: t.string,
        organization_id: t.string,
    }),
    t.partial({
        description: optional(t.string),
        due_date: optional(t.string),
        priority: optional(t.string),
        assignee: optional(t.string),
        parent_task_id: optional(t.string),
        subtasks: optional(t.array(t.unknown)),
        relative_documents: optional(t.array(TaskRelativeDocumentIO)),
    }),
])

const TaskStatusIO = t.keyof<Record<TaskStatus, null>>({
    [TaskStatus.COMPLETED]: null,
    [TaskStatus.IN_PROGRESS]: null,
    [TaskStatus.PENDING]: null,
})

const TaskPriorityIO = t.keyof<Record<TaskPriority, null>>({
    [TaskPriority.LOW]: null,
    [TaskPriority.MEDIUM]: null,
    [TaskPriority.HIGH]: null,
    [TaskPriority.URGENT]: null,
})

const sortTaskByCreationDate = (t1: TaskI, t2: TaskI) => {
    if (t1.creationDate < t2.creationDate) {
        return -1
    } else if (t2.creationDate < t1.creationDate) {
        return 1
    }
    return 0
}
const documentApiAdapter = (data: t.TypeOf<typeof TaskRelativeDocumentIO>): TaskRelativeDocument => ({
    documentId: data.document_id,
    documentType: Object.values(DocumentObjectType).includes(data.document_type as DocumentObjectType)
        ? (data.document_type as DocumentObjectType)
        : DocumentObjectType.OTHER,
})

export const taskFromApiAdapter = (data: unknown): TaskI => {
    const taskData = decodeAndCaptureErrors(data, TaskIO)
    return {
        id: taskData.id as TaskId,
        title: taskData.title ?? "",
        description: taskData.description ?? "",
        status: TaskStatusIO.is(taskData.status) ? (taskData.status as TaskStatus) : TaskStatus.PENDING,
        dueDate: taskData.due_date ?? null,
        priority: TaskPriorityIO.is(taskData.priority) ? (taskData.priority as TaskPriority) : null,
        assignee: taskData.assignee as UserId | null,
        public: !!taskData.public,
        parties: taskData.parties as OrganizationId[],
        parentTaskId: taskData.parent_task_id as TaskId | null,
        followers: taskData.followers as UserId[],
        creationDate: taskData.creation_date,
        lastUpdateDate: taskData.last_update_date,
        organizationId: taskData.organization_id as OrganizationId,
        subTasks: Array.isArray(taskData.subtasks) ? taskData.subtasks.map(taskFromApiAdapter).sort(sortTaskByCreationDate) : null,
        relativeDocuments: Array.isArray(taskData.relative_documents) ? taskData.relative_documents.map(documentApiAdapter) : [],
    }
}
