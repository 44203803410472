import { Stack, TextField } from "@mui/material"
import React, { ChangeEvent } from "react"
import { defineMessages, useIntl } from "react-intl"

import usePartnerBrandname from "~/domains/identity/partners/store/hooks/usePartnerBrandname"
import { OrganizationRegistrationI } from "~/types"

const messages = defineMessages({
    legalName: {
        id: "company.registration.legalName",
        defaultMessage: "Company Legal Name",
    },
    name: {
        id: "company.registration.name",
        defaultMessage: "Company Name",
    },
    brandName: {
        id: "company.registration.brandName",
        defaultMessage: "Brand Name",
    },
    siret: {
        id: "company.registration.siret",
        defaultMessage: "Siret",
    },
    siren: {
        id: "company.registration.siren",
        defaultMessage: "Siren",
    },
    registrationNumber: {
        id: "company.registrationNumber",
        defaultMessage: "Registration Number",
    },
    vatNumber: {
        id: "company.registration.vatNumber",
        defaultMessage: "VAT number",
    },
})

interface OrganizationRegistrationFormProps {
    registration: OrganizationRegistrationI
    onChange: (payload: Partial<OrganizationRegistrationI>) => void
    readOnly: boolean
    organizationId?: string
}

export const OrganizationRegistrationForm: React.FC<OrganizationRegistrationFormProps> = ({
    registration,
    onChange,
    readOnly,
    organizationId,
}) => {
    const { formatMessage } = useIntl()
    const brandName = usePartnerBrandname(organizationId)

    const onLegalNameChange = (event: ChangeEvent<HTMLInputElement>) =>
        onChange({ legalName: event.currentTarget.value })

    const onVatNumberChange = (event: ChangeEvent<HTMLInputElement>) =>
        onChange({ vatNumber: event.currentTarget.value })

    const onRegistrationNumberChange = (event: ChangeEvent<HTMLInputElement>) =>
        onChange({
            preferredRegistrationNumber: {
                registrationType: "UNKNOWN",
                registrationNumber: event.currentTarget.value,
            },
        })

    return (
        <Stack gap={2} width="100%">
            <h4>Infos</h4>

            <TextField
                required={!readOnly}
                id="companyDetails.legalName"
                name="companyDetails.legalName"
                label={formatMessage(messages.legalName)}
                placeholder={formatMessage(messages.legalName)}
                onChange={onLegalNameChange}
                value={registration.legalName}
                fullWidth
                aria-readonly={readOnly}
                inputProps={{
                    readOnly,
                }}
                disabled={readOnly}
            />

            {brandName && (
                <TextField
                    required={!readOnly}
                    id="companyDetails.brandName"
                    name="companyDetails.brandName"
                    label={formatMessage(messages.brandName)}
                    placeholder={formatMessage(messages.brandName)}
                    onChange={() => {}}
                    value={brandName}
                    fullWidth
                    aria-readonly={true}
                    inputProps={{
                        readOnly,
                    }}
                    disabled={true}
                />
            )}

            <TextField
                required={!readOnly}
                id="companyDetails.vatNumber"
                name="companyDetails.vatNumber"
                label={formatMessage(messages.vatNumber)}
                placeholder={formatMessage(messages.vatNumber)}
                onChange={onVatNumberChange}
                value={registration.vatNumber ?? ""}
                fullWidth
                aria-readonly={readOnly}
                inputProps={{
                    readOnly,
                }}
                disabled={readOnly}
            />

            <TextField
                required={!readOnly}
                id="companyDetails.registrations.registrationNumber"
                name="companyDetails.registrations.registrationNumber"
                label={formatMessage(messages.registrationNumber)}
                placeholder={formatMessage(messages.siren)}
                value={registration.preferredRegistrationNumber?.registrationNumber}
                onChange={onRegistrationNumberChange}
                fullWidth
                aria-readonly={readOnly}
                inputProps={{
                    readOnly,
                }}
                disabled={readOnly}
            />
        </Stack>
    )
}
